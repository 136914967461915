const getFile = async (props: any) => {
  const { src, title } = props;
  const response = await fetch(src.replace('uploads', 'files'));
  const blob = await response.blob();
  const file = new File([blob], title, {
    lastModified: Date.now(),
    type: blob.type,
  });
  return file;
};

export const parseFile = async (origin: any) => {
  if (`${origin?.src}`.includes('szpro.ru')) {
    origin.rawFile = await getFile(origin);
  }
};

export const parseArray = async (arr: any) => {
  if (Array.isArray(arr)) {
    for (let i = 0; i < arr.length; i++) {
      await parseFile(arr[i]);
    }
  }
};

export function composeUrl(src: string, thumb: string) {
  if (!src) {
    return '';
  }
  // return src;
  // if (`${src}`.includes('szpro.ru')) {
  //   return src;
  // }
  const sep = '%2F';
  const arr0 = src.split('?');
  const arr1 = arr0[0].split(sep);
  const originalFileName = arr1.pop();
  const fnArr = originalFileName?.split('.') || [];
  const filename = [fnArr[0], `_${thumb}x${thumb}`];
  if (fnArr && fnArr[1]) {
    filename.push(`.${fnArr[1]}`);
  }
  arr1.push('thumbs');
  arr1.push(filename.join(''));
  arr0[0] = arr1.join(sep);
  return arr0.join('?');
}
