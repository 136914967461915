import React from 'react';
import { AppBar, usePermissions, NumberInput } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import EventNoteIcon from '@material-ui/icons/EventNote';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import Badge from '@material-ui/core/Badge';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { compareKey } from 'search/SearchDetailsRoomInfo';
import { impersonalKey } from 'components/auth-provider';

import { Form } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
  button: {
    marginRight: 10,
  },
  dialog: {
    width: '560px',
    position: 'relative',
    overflow: 'auto',
    padding: 5
  },
});

const CustomAppBar = (props: any) => {
  const classes = useStyles();
  const { permissions = {} } = usePermissions();
  const { email, role, user } = permissions;
  const [compareCount, setCompareCount] = React.useState(0);
  const [impersonate, setImpersonate] = React.useState(false);
  const theme = useTheme();
  const is_sm = useMediaQuery(theme.breakpoints.up('sm'));
  const [showCalc, setShowCalc] = React.useState(false);
  const [ipotekaResult, setIpotekaResult] = React.useState(0);
  // const { list, setList } = React.useContext(ObjectViewContext);

  // const handleClick = () => {
  //   setList('');
  // };

  React.useEffect(() => {
    let timer: any;
    const checkStorage = () => {
      try {
        const data = localStorage.getItem(compareKey);
        const parsed = JSON.parse(data ?? '');
        setCompareCount(Object.keys(parsed).length);
      } catch (ex) {
        setCompareCount(0);
      }
      timer = setTimeout(() => {
        checkStorage();
      }, 2000);
    }

    checkStorage();

    return () => {
      clearTimeout(timer);
    }
  }, []);

  React.useEffect(() => {
    if (localStorage.getItem(impersonalKey)) {
      setImpersonate(true);
    }
  }, []);


  const handleRemoveImpersonal = React.useCallback(() => {
    localStorage.removeItem(impersonalKey);
    window.location.reload();
  }, []);

  const onSubmitCalc = (data: any) => {
    const { price, ipotekaFirst, ipotekaPercent, ipotekaMonths } = data;

    const s = Number(price) - Number(ipotekaFirst);
    const i = Number(ipotekaPercent) / 12 / 100;
    const n = Number(ipotekaMonths);
    const k = (i * Math.pow(1 + i, n)) / (Math.pow(1 + i, n) - 1);
    let a = k * s;
    if (!a || isNaN(a) || a === Infinity) {
      a = 0;
    }
    setIpotekaResult(a);
  }


  const handleCloseCalc = () => {
    setShowCalc(false);
    setIpotekaResult(0);
  }

  return (
    <AppBar {...props}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <span className={classes.spacer} />
      {/* {list && (
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={handleClick}
          component={Link as any}
          to={`list2/${list.id}/show`}
        >
          Закончить Подбор Объектов
        </Button>
      )} */}
      {
        Boolean(impersonate) &&
        <Button onClick={handleRemoveImpersonal}>Remove Impersonal</Button>
      }
      {
        Boolean(compareCount) &&
        <Badge badgeContent={compareCount} color="secondary">
          <Button variant="contained" component={Link} to="/compare" target="_blank" color="primary" style={{ marginRight: 5 }} disabled={compareCount === 0}>
            Сравнение
        </Button>
        </Badge>
      }
      {
        is_sm &&
        <Typography>
          {
            (user) ? <Link to={`/users/${user}/edit`} style={{ textDecoration: 'none', color: 'white' }}>
              {email} ({role})
        </Link> : <span>{email} ({role})</span>
          }

        </Typography>
      }
      <IconButton
        color="inherit"
        aria-label="calc"
        onClick={() => setShowCalc(true)}
        title="Ипотечный калькулятор"
      >
        <KeyboardIcon />
      </IconButton>
      {/* <IconButton
        color="inherit"
        aria-label="calendar"
        component={Link as any}
        to="/calendar"
      >
        <EventNoteIcon />
      </IconButton> */}
      <Dialog open={showCalc} onClose={handleCloseCalc}>
        <DialogTitle>Ипотечный калькулятор</DialogTitle>
        <div className={classes.dialog}>
          <Form onSubmit={onSubmitCalc}>
            {
              ({ handleSubmit, valid }) => (
                <form onSubmit={handleSubmit}>
                  <DialogContent>
                    <NumberInput source="price" label="Стоимость объекта, руб" fullWidth />
                    <NumberInput source="ipotekaFirst" label="Первый взнос, руб" fullWidth />
                    <NumberInput source="ipotekaPercent" label="Процентная ставка, %" fullWidth />
                    <NumberInput source="ipotekaMonths" label="Срок кредитования, мес" fullWidth />
                    {
                      !!ipotekaResult && (
                        <Typography>
                          <b>Ежемесячный платеж:</b>{' '}
                          <span>
                            {Number(ipotekaResult.toFixed(2)).toLocaleString()}{' '}
                          руб.
                        </span>
                        </Typography>
                      )
                    }
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseCalc} color="primary">Закрыть</Button>
                    <Button variant="outlined" color="primary" type="submit" disabled={!valid}>Рассчитать</Button>
                  </DialogActions>
                </form>
              )
            }
          </Form>
        </div>
      </Dialog>
    </AppBar>
  );
};

export default CustomAppBar;
