import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  complexes: [],
  roomTypes: [],
  selected: '', //'5a5c5c562fd720256181f591',
  city: '',
  districts: [],
  main_districts: [],
  priceFrom: '',
  priceTo: '',
  spaceFrom: '',
  spaceTo: '',
  selectedRoom: '',
  citiesData: [],
  complexesData: [],
  secondRoomsData: [],
  sellDate: '',
  filteredComplexes: [],
  viewMap: '',
  metro_stations: [],
  metro_lines: [],
  apartments: false,
  dealtype: '',
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setComplexes(state, action) {
      state.complexes = action.payload;
    },
    setRoomTypes(state, action) {
      state.roomTypes = action.payload;
    },
    setObject(state, action) {
      state.selected = action.payload;
    },
    setCity(state, action) {
      if (action.payload === state.city) {
        return state;
      }
      return {
        ...initialState,
        city: action.payload,
        citiesData: state.citiesData,
      };
    },
    setDistricts(state, action) {
      state.districts = action.payload;
      state.complexes = [];
    },
    setMainDistricts(state, action) {
      state.main_districts = action.payload;
      state.complexes = [];
    },
    setPriceFrom(state, action) {
      state.priceFrom = action.payload;
    },
    setPriceTo(state, action) {
      state.priceTo = action.payload;
    },
    setSpaceFrom(state, action) {
      state.spaceFrom = action.payload;
    },
    setSpaceTo(state, action) {
      state.spaceTo = action.payload;
    },
    setRoom(state, action) {
      state.selectedRoom = action.payload;
    },
    setCitiesData(state, action) {
      state.citiesData = action.payload;
    },
    setComplexesData(state, action) {
      state.complexesData = action.payload;
    },
    setSecondRoomsData(state, action) {
      state.secondRoomsData = action.payload;
    },
    setSellDate(state, action) {
      state.sellDate = action.payload;
    },
    setFilteredComplexes(state, action) {
      state.filteredComplexes = action.payload;
    },
    setViewMap(state, action) {
      state.viewMap = action.payload;
    },
    setMetroLines(state, action) {
      state.metro_lines = action.payload;
    },
    setMetroStations(state, action) {
      state.metro_stations = action.payload;
    },
    setApartments(state, action) {
      state.apartments = action.payload;
    },
    setDealtype(state, action) {
      state.dealtype = action.payload;
    },
    reset(state, action) {
      return {
        ...initialState,
        city: state.city,
        citiesData: state.citiesData,
        complexesData: state.complexesData,
        secondRoomsData: state.secondRoomsData,
      };
    },
  },
});

export const {
  setComplexes,
  setRoomTypes,
  setPriceFrom,
  setPriceTo,
  setSpaceFrom,
  setSpaceTo,
  setObject,
  setCity,
  setDistricts,
  setRoom,
  setCitiesData,
  setComplexesData,
  setSecondRoomsData,
  reset,
  setSellDate,
  setFilteredComplexes,
  setViewMap,
  setMainDistricts,
  setMetroLines,
  setMetroStations,
  setDealtype,
} = searchSlice.actions;

export default searchSlice.reducer;
