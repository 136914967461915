import React from 'react';

import Typography from '@material-ui/core/Typography';

const SelectedInfo = (props: any) => {
  const { room, floor, record } = props;
  const { rooms = [], floors = [], floorsnum } = record;
  const selectedRoom = React.useMemo(() => rooms[room] || {}, [room, rooms]);
  const selectedFloor = React.useMemo(() => floors[floor] || {}, [floor, floors]);

  if (room > -1 && selectedRoom?.floor === 0) {
    return <Typography>Типовая квартира, номер на этаже: {selectedRoom?.rpf}</Typography>;
  }

  if (room > -1) {
    return <Typography>Квартира {selectedRoom?.number}, этаж: {selectedRoom?.floor}, номер на этаже: {selectedRoom?.rpf}</Typography>;
  }

  if (selectedFloor?.number === 0) {
    return <Typography>Типовой этаж</Typography>
  }

  return <Typography>Этаж {selectedFloor?.number}</Typography>
}

export default SelectedInfo;