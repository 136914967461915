import { Card2 } from './Card';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { composeUrl } from 'libs/parseHelper';

function Description(props: any) {
  const { complex, photo } = props;
  return (
    <Card2>
      <Grid container>
        <Grid item xs={7}>
          <Typography gutterBottom variant="h5">Описание</Typography>
          <Typography variant="body1" dangerouslySetInnerHTML={{ __html: complex?.info }} />
        </Grid>
        <Grid item xs={5}>
          <img src={composeUrl(photo, '600')} alt="building" style={{ maxWidth: '100%', marginLeft: 8 }} />
        </Grid>
      </Grid>

    </Card2>
  )
}

export default Description;