import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
// import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { setCity, setDistricts, setComplexes, setRoomTypes, setPriceFrom, setPriceTo, setSpaceFrom, setSpaceTo, setCitiesData, setComplexesData, setSecondRoomsData, reset, setSellDate, setMainDistricts, setMetroLines, setMetroStations } from 'reducers/search-slice';
import { useQueryWithStore } from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import endOfQuarter from 'date-fns/endOfQuarter';
import format from 'date-fns/format';
import add from 'date-fns/add'

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    maxWidth: 200,
  },
  iconFormControl: {
    margin: theme.spacing(1),
    minWidth: 50,
    maxWidth: 50,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const City = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { city } = useSelector((state: any) => state.search);
  const { data, loaded } = useQueryWithStore({
    type: 'getList',
    resource: city ? 'cities' : '',
    payload: { pagination: { page: 1, perPage: 1000 }, filter: { active: true }, sort: { field: 'name', order: 'ASC' } }
  });

  const handleCityChange = React.useCallback(
    (event: any) => {
      dispatch(setCity(event.target.value));
    },
    [dispatch]
  );

  React.useEffect(() => {
    if (loaded && data) {
      dispatch(setCitiesData(data));
    }
  }, [data, loaded, dispatch]);

  return (
    <FormControl className={classes.formControl}>
      <InputLabel>Город</InputLabel>
      <Select value={city} onChange={handleCityChange}>
        {loaded && data?.map((c: any) => (
          <MenuItem key={c.id} value={c.id}>
            {c.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export const District = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { districts, city } = useSelector((state: any) => state.search);
  const { data, loaded } = useQueryWithStore({
    type: 'getList',
    resource: city ? 'districts' : '',
    payload: { pagination: { page: 1, perPage: 1000 }, filter: { active: true, city }, sort: { field: 'name', order: 'ASC' } }
  });

  const handleDistrictChange = React.useCallback(
    (event: any) => {
      dispatch(setDistricts(event.target.value));
    },
    [dispatch]
  );

  return (
    <FormControl className={classes.formControl}>
      <InputLabel>Район</InputLabel>
      <Select
        value={districts}
        onChange={handleDistrictChange}
        input={<Input />}
        multiple
      >
        {loaded && data?.map((d: any) => (
          <MenuItem key={d.id} value={d.id}>
            {d.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export const Complex = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { complexes, districts, city } = useSelector((state: any) => state.search);

  const { data, loaded } = useQueryWithStore({
    type: 'getList',
    resource: city ? 'rooms_search' : '',
    payload: {
      pagination: { page: 1, perPage: 1000 },
      filter: { active: true, city },
      sort: { field: 'name', order: 'ASC' }
    }
  });

  React.useEffect(() => {
    if (loaded && data) {
      dispatch(setComplexesData(data));
      localStorage.setItem('szpro::complex-search-data', JSON.stringify(data));
    }
  }, [data, loaded, dispatch]);

  const complexData = React.useMemo(() => {
    if (data && Array.isArray(districts) && districts.length) {
      return data.filter((d: any) => districts.includes(d.district));
    }
    return data;
  }, [districts, data]);

  const handleComplexChange = React.useCallback(
    (event: any) => {
      dispatch(setComplexes(event.target.value));
    },
    [dispatch]
  );

  return (
    <FormControl className={classes.formControl}>
      <InputLabel>ЖК</InputLabel>
      <Select
        value={complexes}
        onChange={handleComplexChange}
        input={<Input />}
        multiple
      >
        {loaded && complexData?.map((d: any) => (
          <MenuItem key={d.id} value={d.id}>
            {d.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export const RoomTypes = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { roomTypes } = useSelector((state: any) => state.search);

  const handleRoomTypeChange = React.useCallback(
    (event: any) => {
      dispatch(setRoomTypes(event.target.value));
    },
    [dispatch]
  );

  return (
    <FormControl className={classes.formControl}>
      <InputLabel>Тип Квартиры</InputLabel>
      <Select
        value={roomTypes}
        onChange={handleRoomTypeChange}
        input={<Input />}
        multiple
      >
        <MenuItem value="C">C</MenuItem>
        <MenuItem value="1">1k</MenuItem>
        <MenuItem value="2">2k</MenuItem>
        <MenuItem value="3">3k</MenuItem>
        <MenuItem value="4+">4k+</MenuItem>
      </Select>
    </FormControl>
  )
}

export const PriceFrom = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { priceFrom } = useSelector((state: any) => state.search);

  const handlePriceFrom = React.useCallback(
    (event: any) => {
      dispatch(setPriceFrom(event.target.value));
    },
    [dispatch]
  );

  return (
    <FormControl className={classes.formControl}>
      <TextField
        label="Цена от"
        onChange={handlePriceFrom}
        value={priceFrom}
        type="number"
      />
    </FormControl>
  )
}

export const PriceTo = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { priceTo } = useSelector((state: any) => state.search);

  const handlePriceTo = React.useCallback(
    (event: any) => {
      dispatch(setPriceTo(event.target.value));
    },
    [dispatch]
  );

  return (
    <FormControl className={classes.formControl}>
      <TextField
        label="Цена до"
        onChange={handlePriceTo}
        value={priceTo}
        type="number"
      />
    </FormControl>
  )
}

export const SpaceFrom = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { spaceFrom } = useSelector((state: any) => state.search);

  const handleSpaceFrom = React.useCallback(
    (event: any) => {
      dispatch(setSpaceFrom(event.target.value));
    },
    [dispatch]
  );

  return (
    <FormControl className={classes.formControl}>
      <TextField
        label="Площадь от"
        onChange={handleSpaceFrom}
        value={spaceFrom}
        type="number"
      />
    </FormControl>
  )
}

export const SpaceTo = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { spaceTo } = useSelector((state: any) => state.search);

  const handleSpaceTo = React.useCallback(
    (event: any) => {
      dispatch(setSpaceTo(event.target.value));
    },
    [dispatch]
  );

  return (
    <FormControl className={classes.formControl}>
      <TextField
        label="Площадь до"
        onChange={handleSpaceTo}
        value={spaceTo}
        type="number"
      />
    </FormControl>
  )
}

export const SecondRooms = () => {
  const dispatch = useDispatch();
  const { city } = useSelector((state: any) => state.search);

  const { data, loaded } = useQueryWithStore({
    type: 'getList',
    resource: city ? 'secondroom' : '',
    payload: {
      pagination: { page: 1, perPage: 1000 },
      filter: { active: true, city }
    }
  });

  React.useEffect(() => {
    if (loaded && data) {
      dispatch(setSecondRoomsData(data));
    }
  }, [data, loaded, dispatch]);

  return null;
}

export const ResetSearch = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return <FormControl className={classes.iconFormControl}>
    {/* <Button color="primary" onClick={() => dispatch(reset(''))}>
      Сбросить
    </Button> */}
    <IconButton color="primary" aria-label="reset" title="Сбросить фильтры" component="span" onClick={() => dispatch(reset(''))} >
      <ClearIcon />
    </IconButton>
  </FormControl>
}

export const SellDate = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { sellDate } = useSelector((state: any) => state.search);

  const handleSellDateChange = React.useCallback(
    (event: any) => {
      dispatch(setSellDate(event.target.value));
    },
    [dispatch]
  );

  const data = getSellDates();

  return (
    <FormControl className={classes.formControl}>
      <InputLabel>Срок Сдачи</InputLabel>
      <Select
        value={sellDate}
        onChange={handleSellDateChange}
      >
        {
          data.map((d: any) => <MenuItem key={d.id} value={d.id}>{d.name}</MenuItem>)
        }
      </Select>
    </FormControl>
  )
}

export function getSellDates() {
  const ret = [];
  const date = new Date();
  for (let i = 0; i < 14; i++) {
    const q = add(endOfQuarter(date), { months: 3 * i });
    const name = i === 0 ? 'сдан' : format(q, 'до Q кв. yyyy г.');
    ret.push({ id: i + 1, q, name });
  }
  return ret;
}

export const MainDistrict = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { main_districts, city } = useSelector((state: any) => state.search);
  const { data, loaded } = useQueryWithStore({
    type: 'getList',
    resource: city ? 'main_districts' : '',
    payload: { pagination: { page: 1, perPage: 1000 }, filter: { active: true, city }, sort: { field: 'name', order: 'ASC' } }
  });

  const handleMainDistrictChange = React.useCallback(
    (event: any) => {
      dispatch(setMainDistricts(event.target.value));
    },
    [dispatch]
  );

  return (
    <FormControl className={classes.formControl}>
      <InputLabel>Округ</InputLabel>
      <Select
        value={main_districts}
        onChange={handleMainDistrictChange}
        input={<Input />}
        multiple
      >
        {loaded && data?.map((d: any) => (
          <MenuItem key={d.id} value={d.id}>
            {d.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export const Apartments = () => {
  const classes = useStyles();
  // const dispatch = useDispatch();
  // const { apartments } = useSelector((state: any) => state.search);

  // const handlePriceFrom = React.useCallback(
  //   (event: any) => {
  //     dispatch(setPriceFrom(event.target.value));
  //   },
  //   [dispatch]
  // );

  return (
    <FormControl className={classes.formControl}>
      <FormControlLabel
        control={
          <Switch
            name="checkedB"
            color="primary"
          />
        }
        label="Апартаменты"
      />
    </FormControl>
  )
}

export const MetroLine = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { metro_lines, city } = useSelector((state: any) => state.search);
  const { data, loaded } = useQueryWithStore({
    type: 'getList',
    resource: city ? 'metro_lines' : '',
    payload: { pagination: { page: 1, perPage: 1000 }, filter: { active: true, city }, sort: { field: 'name', order: 'ASC' } }
  });

  const handleChange = React.useCallback(
    (event: any) => {
      dispatch(setMetroLines(event.target.value));
    },
    [dispatch]
  );

  return (
    <FormControl className={classes.formControl}>
      <InputLabel>Линия Метро</InputLabel>
      <Select
        value={metro_lines}
        onChange={handleChange}
        input={<Input />}
        multiple
      >
        {loaded && data?.map((d: any) => (
          <MenuItem key={d.id} value={d.id}>
            {d.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export const MetroStation = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { metro_stations, city } = useSelector((state: any) => state.search);
  const { data, loaded } = useQueryWithStore({
    type: 'getList',
    resource: city ? 'metro_stations' : '',
    payload: { pagination: { page: 1, perPage: 1000 }, filter: { active: true, city }, sort: { field: 'name', order: 'ASC' } }
  });

  const handleChange = React.useCallback(
    (event: any) => {
      dispatch(setMetroStations(event.target.value));
    },
    [dispatch]
  );

  return (
    <FormControl className={classes.formControl}>
      <InputLabel>Станция Метро</InputLabel>
      <Select
        value={metro_stations}
        onChange={handleChange}
        input={<Input />}
        multiple
      >
        {loaded && data?.map((d: any) => (
          <MenuItem key={d.id} value={d.id}>
            {d.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}