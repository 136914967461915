import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  FunctionField
} from 'react-admin';

import format from 'date-fns/format';

export const name = 'compares';

const ResList = (props: any) => {
  const { permissions = {} } = props;
  const { user } = permissions;

  return (
    <List {...props} bulkActionButtons={false} filter={{ user }}>
      <Datagrid>
        <TextField source="name" />
        <FunctionField label="Создан" render={(record: any) => format(record.createdate.toDate(), 'dd.MM.yyy HH:mm')} />
        <FunctionField label="Объекты" render={(record: any) => record.rooms?.length} />
      </Datagrid>
    </List>
  );
};


export {
  ResList as list,
};
