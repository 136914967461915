import React from 'react';

import { parse } from 'query-string';

import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  // BooleanInput,
  NumberInput,
  SelectField,
  SelectInput,
  FilterList,
  FilterListItem,
  NumberField,
  // BooleanField,
  DateField,
  ReferenceInput,
  ReferenceField,
  DateInput,
  Toolbar,
  SaveButton,
} from 'react-admin';

// import { Card as MuiCard, CardContent, withStyles } from '@material-ui/core';

export const name = 'strahrequests';

export const list = (props: any) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="show">
      <ReferenceField source="strah" reference="strahs">
        <TextField source="name" />
      </ReferenceField>
      {/* <TextField source="list" /> */}
      <NumberField source="live" />
      <NumberField source="things" />
      <NumberField source="titul" />
      <NumberField source="dop" />
      <TextField source="info" fullWidth />
    </Datagrid>
  </List>
);

export const show = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField source="strah" reference="strahs">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="live" />
      <NumberField source="things" />
      <NumberField source="titul" />
      <NumberField source="dop" />
      <TextField source="info" fullWidth />
    </SimpleShowLayout>
  </Show>
);

const ResCreate = (props: any) => {

  const { list } = parse(props.location.search);
  const redirect = list ? `/ipotekalist/${list}/show` : 'show';

  return (
    <Create {...props}>
      <SimpleForm initialValues={{ list }} redirect={redirect}>
        <DateInput source="requestDate" />
        <ReferenceInput source="strah" reference="strahs" perPage={100} filter={{ active: true }}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="live" />
        <NumberInput source="things" />
        <NumberInput source="titul" />
        <NumberInput source="dop" />
        <TextInput source="info" />
      </SimpleForm>
    </Create>
  );
};

const CustomToolbar = (props: any) => {
  return (
    <Toolbar {...props} >
      <SaveButton />
    </Toolbar>
  )
};

export const ResEdit = (props: any) => {

  const redirect = (basePath: string, id: string, data: any) => `/ipotekalist/${data.list}/show`;

  return (
    <Edit {...props}>
      <SimpleForm toolbar={<CustomToolbar />} redirect={redirect}>
        <DateInput source="requestDate" />
        <ReferenceInput source="strah" reference="strahs" perPage={100} filter={{ active: true }}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="live" />
        <NumberInput source="things" />
        <NumberInput source="titul" />
        <NumberInput source="dop" />
        <TextInput source="info" />
      </SimpleForm>
    </Edit>
  )
};

export { ResCreate as create, ResEdit as edit };
