import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  BooleanField,
  EmailField,
  ReferenceField,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  Filter,
  PasswordInput,
  SaveButton, Toolbar, DateInput, ImageInput, ImageField, DateField
} from 'react-admin';

import { FbUsersResetPassword } from 'components/fb-users';

import Button from '@material-ui/core/Button';

import { impersonalKey } from 'components/auth-provider';

const roles = [
  { id: 'admin' },
  { id: 'supermanager' },
  { id: 'superofficemanager' },
  { id: 'manager' },
  { id: 'cityhead' },
  { id: 'officehead' },
  { id: 'officemanager' },
  { id: 'user' },
  { id: 'broker' },
  { id: 'marketolog' },
  { id: 'guest' },
];

const rolesList = (role: string) => {
  if (role === 'admin') {
    return roles;
  }
  if (role === 'supermanager') {
    return roles.filter(r => !['admin'].includes(r.id));
  }
  if (role === 'superofficemanager') {
    return [];
  }
  if (role === 'manager') {
    return roles.filter(r => !['admin', 'supermanager', 'superofficemanager', 'manager'].includes(r.id));
  }
  if (role === 'officehead') {
    return roles.filter(r => !['admin', 'supermanager', 'superofficemanager', 'manager', 'officehead', 'cityhead'].includes(r.id));
  }
  if (role === 'user') {
    return [];
  }
  if (role === 'broker') {
    return [];
  }
  if (role === 'guest') {
    return [];
  }
}

export const name = 'users';

const UserListFilter = (props: any) => {
  // const { permissions = {} } = props;
  // const { role } = permissions;
  const { role, ...otherProps } = props;

  if (['admin', 'supermanager'].includes(role)) {
    return (
      <Filter {...otherProps}>
        <ReferenceInput
          source="office"
          reference="offices"
          alwaysOn
          sort={{ field: 'name', order: 'ASC' }}
          allowEmpty={true}
          perPage={1000}
        >
          <SelectInput source="name" />
        </ReferenceInput>
        <TextInput source="name" alwaysOn resettable />
        <TextInput source="email" alwaysOn resettable />
      </Filter>
    )
  }
  return null;
};

export const list = (props: any) => {
  const { permissions = {} } = props;
  const { office, role } = permissions;
  if (!office || !role) {
    return null;
  }
  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<UserListFilter role={role} />}
      filterDefaultValues={{ office }}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <Datagrid rowClick="show">
        <BooleanField source="fb_active" />
        <TextField source="fb_uid" />
        <EmailField source="email" />
        <TextField source="phone" />
        <TextField source="name" />
        <TextField source="role" />
        <ReferenceField source="office" reference="offices" link="show">
          <TextField source="name" />
        </ReferenceField>
        <EmailField source="amo" />
        {
          role === 'admin' && <ImpersonalButton />
        }
      </Datagrid>
    </List>
  );
};

export const show = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="fb_uid" />
      <TextField source="id" />
      <BooleanField source="fb_active" />
      <EmailField source="email" />
      <TextField source="phone" />
      <TextField source="name" />
      <TextField source="role" />
      <ReferenceField source="office" reference="offices" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <DateField source="notInManagerReportsFrom" />
      <ImageField source="photo.src" label="" />
      <FbUsersResetPassword />
    </SimpleShowLayout>
  </Show>
);

const validateForm = (values: any) => {
  const errors: any = {};

  if (!values.email) {
    errors.email = ['Required'];
  }

  if (!values.name) {
    errors.name = ['Required'];
  }

  if (!values.role) {
    errors.role = ['Required'];
  }

  if (!values.office) {
    errors.office = ['Required'];
  }

  return errors;
};

export const create = (props: any) => {
  const { permissions = {} } = props;
  const { role, office } = permissions;
  if (!role) {
    return null;
  }

  return (
    <Create {...props}>
      <SimpleForm initialValues={{ office }} validate={validateForm}>
        {role === 'admin' && <TextInput source="fb_uid" />}
        <BooleanInput source="fb_active" />
        <TextInput source="email" required />
        <TextInput source="amo" />
        <TextInput source="amo2" />
        <TextInput source="phone" />
        <TextInput source="name" required />
        <SelectInput
          source="role"
          choices={rolesList(role)}
          optionText="id"
          optionValue="id"
          required
        />
        <ReferenceInput
          source="office"
          reference="offices"
          sort={{ field: 'name', order: 'ASC' }}
          allowEmpty={false}
          perPage={1000}
        >
          <SelectInput source="name" required />
        </ReferenceInput>
        <PasswordInput source="password" />
        <DateInput source="notInManagerReportsFrom" />
        <ImageInput source="photo">
          <ImageField source="src" title="title" />
        </ImageInput>
        <BooleanInput source="spr" />
      </SimpleForm>
    </Create>
  )
};

const EditToolbar = (props: any) => {
  const { record, role } = props;

  if (record.role === 'admin' && role !== 'admin') {
    return null;
  }

  return (
    <Toolbar {...props} >
      <SaveButton />
    </Toolbar>
  )
};

const SimpleEditToolbar = (props: any) => {
  return (
    <Toolbar {...props} >
      <SaveButton />
    </Toolbar>
  )
};

export const edit = (props: any) => {
  const { permissions = {}, id } = props;
  const { role, user } = permissions;
  if (!role) {
    return null;
  }

  if (!['admin', 'supermanager'].includes(role) && id !== user) {
    return null;
  }

  if (!['admin', 'supermanager'].includes(role)) {
    return (
      <Edit {...props}>
        <SimpleForm toolbar={<SimpleEditToolbar />}>
          <ImageInput source="photo">
            <ImageField source="src" title="title" />
          </ImageInput>
        </SimpleForm>
      </Edit>
    )
  }

  return (
    <Edit {...props}>
      <SimpleForm validate={validateForm} toolbar={<EditToolbar role={role} />}>
        {role === 'admin' && <TextInput source="fb_uid" />}
        <BooleanInput source="fb_active" />
        <TextInput source="email" required />
        <TextInput source="amo" />
        <TextInput source="amo2" />
        <TextInput source="phone" />
        <TextInput source="name" required />
        <SelectInput
          source="role"
          choices={rolesList(role)}
          optionText="id"
          optionValue="id"
          required
        />
        <ReferenceInput
          source="office"
          reference="offices"
          sort={{ field: 'name', order: 'ASC' }}
          allowEmpty={false}
          perPage={1000}
        >
          <SelectInput source="name" required />
        </ReferenceInput>
        <PasswordInput source="password" />
        <DateInput source="notInManagerReportsFrom" />
        <ImageInput source="photo">
          <ImageField source="src" title="title" />
        </ImageInput>
        <BooleanInput source="spr" />
      </SimpleForm>
    </Edit>
  )
};


function ImpersonalButton(props: any) {
  const { record } = props;
  const { id, name } = record;

  const handleSetImpersonalId = () => {
    localStorage.setItem(impersonalKey, id);
    window.location.reload();
  }

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleSetImpersonalId}
    >
      {name}
    </Button>
  )
}