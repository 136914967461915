import React from 'react';
import { parse } from 'query-string';

import {
  List,
  Datagrid,
  TextField,
  Show,
  Create,
  TextInput,
  Edit,
  NumberInput,
  // FormTab,
  // TabbedForm,
  // ArrayInput,
  // SimpleFormIterator,
  // ImageInput,
  // ImageField,
  // TabbedShowLayout,
  // Tab,
  // ShowGuesser,
  ResourceComponentProps,
  SimpleShowLayout,
  NumberField,
  BooleanField,
  BooleanInput,
  SimpleForm,
  FormDataConsumer,
  RadioButtonGroupInput,
  ReferenceInput,
  SelectInput,
  ImageInput,
  ImageField,
  // ReferenceArrayInput,
  ReferenceField,
  SelectArrayInput,
  TabbedForm,
  FormTab,
  ArrayInput,
  SimpleFormIterator
} from 'react-admin';


import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';

import RoomsGrid, { EditRoomsGrid } from 'components/RoomsGrid/RoomsGrid';
import { roomSides } from 'components/RoomsGrid/Room';
import SelectedInfo from 'components/RoomsGrid/SelectedInfo';
import RoomPrice from 'components/RoomPrice';

import { composedRoomTypes } from 'search/SearchData';

export const statusChoises = [
  { id: 0, name: 'В продаже' },
  { id: 1, name: 'Бронь' },
  { id: 2, name: 'Продана' },
  { id: 3, name: 'Не для продажи' }
];

export const stateChoises = [
  { id: 0, name: 'с ремонтом' },
  { id: 1, name: 'чистовая' },
  { id: 2, name: 'с ремонтом и мебелью' },
  { id: 3, name: 'черновая' },
];

export const dealtypeChoises = [
  { id: 0, name: 'от застройщика' },
  // { id: 1, name: 'от дольщика' },
  { id: 2, name: 'от подрядчика' },
  { id: 3, name: 'уступка СЗ' },
];

export const name = 'entrances';

const validateEntranceForm = (values: any) => {
  const errors: any = {};

  if (!values.number) {
    errors.number = ['Required'];
  }

  if (!values.floorsnum) {
    errors.floorsnum = ['Required'];
  }

  if (!values.roomsPerFloor) {
    errors.roomsPerFloor = ['Required'];
  }


  return errors;
};

export const list = (props: any) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="number" />
    </Datagrid>
  </List>
);

export const show = (props: ResourceComponentProps) => {
  const { hasShow, ...otherProps } = props;
  return (
    <Show {...otherProps}>
      <SimpleShowLayout>
        <BooleanField source="active" />
        <ReferenceField source="building" reference="buildings" link="show">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="number" />
        <NumberField source="floorsnum" />
        <NumberField source="roomsPerFloor" />
        <NumberField source="roomsFromFloor" />
        <NumberField source="roomsFromNumber" />
        <TextField source="info" />
        <RoomsGrid />
      </SimpleShowLayout>
    </Show>
  )
};

export const CreateEntrance = (props: ResourceComponentProps) => {
  const [selected, setSelected] = React.useState<number>(-1);
  const [selectedFloor, setSelectedFloor] = React.useState<number>(-1);

  const { building } = parse(props?.location?.search as string);
  const redirect = building ? `/buildings/${building}/show` : 'show';

  return (
    <Create {...props}>
      <SimpleForm
        initialValues={{ floorsnum: 1, roomsPerFloor: 1, roomsFromFloor: 1, roomsFromNumber: 1, rooms: [], floors: [] }}
        validate={validateEntranceForm}
        defaultValue={{ building }} redirect={redirect}
      >
        <BooleanInput source="active" />
        <ReferenceInput source="building" reference="buildings" disabled>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="number" required />
        <NumberInput source="floorsnum" required />
        <NumberInput source="roomsPerFloor" required />
        <NumberInput source="roomsFromFloor" />
        <NumberInput source="roomsFromNumber" />
        <TextInput source="info" multiline />
        <FormDataConsumer>
          {
            ({ formData }) => (<EditRoomsGrid record={formData} onSelectRoom={setSelected} onSelectFloor={setSelectedFloor} />)
          }
        </FormDataConsumer>
        <Drawer anchor="right" open={selected > -1} onClose={() => setSelected(-1)}>
          <FormDataConsumer>
            {
              ({ formData }) => (<SelectedInfo record={formData} room={selected} floor={selectedFloor} />)
            }
          </FormDataConsumer>
          <ReferenceInput source={`rooms[${selected}].type`} reference="roomtypes" perPage={100} label="custom.roomType" style={{ margin: '0 10px' }}>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <SelectArrayInput source={`rooms[${selected}].sides`} choices={roomSides} label="custom.roomSides" style={{ margin: '0 10px' }} />
          <FormDataConsumer>
            {
              () => (<RoomPrice selected={selected} />)
            }
          </FormDataConsumer>
          {/* <NumberInput source={`rooms[${selected}].price`} label="custom.price" style={{ margin: '0 10px' }} />
          <NumberInput source={`rooms[${selected}].space`} label="custom.space" style={{ margin: '0 10px' }} /> */}
          <NumberInput source={`rooms[${selected}].space_k`} label="custom.space_k" style={{ margin: '0 10px' }} />
          <NumberInput source={`rooms[${selected}].space_r`} label="custom.space_r" style={{ margin: '0 10px' }} />
          <RadioButtonGroupInput
            source={`rooms[${selected}].status`}
            choices={statusChoises}
            label="custom.roomStatus"
            style={{ margin: '0 10px' }}
          />
          <RadioButtonGroupInput
            source={`rooms[${selected}].state`}
            choices={stateChoises}
            label="custom.roomState"
            style={{ margin: '0 10px' }}
          />
          <RadioButtonGroupInput
            source={`rooms[${selected}].dealtype`}
            choices={dealtypeChoises}
            label="custom.roomDealtype"
            style={{ margin: '0 10px' }}
          />
          <ImageInput source={`rooms[${selected}].photo`} label="custom.roomPhoto" style={{ margin: '0 10px' }}>
            <ImageField source="src" title="title" />
          </ImageInput>
          <TextInput source={`rooms[${selected}].info`} label="custom.info" style={{ margin: '0 10px' }} multiline />
          <BooleanInput source={`rooms[${selected}].euro`} label="custom.euro" style={{ margin: '0 10px' }} />
        </Drawer>
        <Drawer anchor="right" open={selectedFloor > -1} onClose={() => setSelectedFloor(-1)}>
          <Typography>Этаж </Typography>
          <NumberInput source={`floors[${selectedFloor}].roomsPerFloor`} label="custom.rpf" style={{ margin: '0 10px' }} />
          <ImageInput source={`floors[${selectedFloor}].photo`} label="custom.floorPhoto" style={{ margin: '0 10px' }}>
            <ImageField source="src" title="title" />
          </ImageInput>
        </Drawer>
      </SimpleForm>
    </Create>
  )
};

export const EditEntrance = (props: ResourceComponentProps) => {
  const [selected, setSelected] = React.useState<number>(-1);
  const [selectedFloor, setSelectedFloor] = React.useState<number>(-1);
  return (
    <Edit {...props}>
      <TabbedForm redirect="show" validate={validateEntranceForm}>
        <FormTab label="Основное">
          <BooleanInput source="active" />
          <NumberInput source="number" required />
          <NumberInput source="floorsnum" required />
          <NumberInput source="roomsPerFloor" required />
          <NumberInput source="roomsFromFloor" />
          <NumberInput source="roomsFromNumber" />
          <TextInput source="info" multiline />
          <FormDataConsumer>
            {
              ({ formData }) => (<EditRoomsGrid record={formData} onSelectRoom={setSelected} onSelectFloor={setSelectedFloor} />)
            }
          </FormDataConsumer>
          <Drawer anchor="right" open={selected > -1} onClose={() => setSelected(-1)}>
            <FormDataConsumer>
              {
                ({ formData }) => (<SelectedInfo record={formData} room={selected} floor={selectedFloor} />)
              }
            </FormDataConsumer>
            <ReferenceInput source={`rooms[${selected}].type`} reference="roomtypes" perPage={100} label="custom.roomType" style={{ margin: '0 10px' }}>
              <SelectInput optionText="name" />
            </ReferenceInput>
            <SelectArrayInput source={`rooms[${selected}].sides`} choices={roomSides} label="custom.roomSides" style={{ margin: '0 10px' }} />
            <FormDataConsumer>
              {
                () => (<RoomPrice selected={selected} />)
              }
            </FormDataConsumer>
            {/* <NumberInput source={`rooms[${selected}].price`} label="custom.price" style={{ margin: '0 10px' }} />
          <NumberInput source={`rooms[${selected}].space`} label="custom.space" style={{ margin: '0 10px' }} /> */}
            <NumberInput source={`rooms[${selected}].space_k`} label="custom.space_k" style={{ margin: '0 10px' }} />
            <NumberInput source={`rooms[${selected}].space_r`} label="custom.space_r" style={{ margin: '0 10px' }} />
            <RadioButtonGroupInput
              source={`rooms[${selected}].status`}
              choices={statusChoises}
              label="custom.roomStatus"
              style={{ margin: '0 10px' }}
            />
            <RadioButtonGroupInput
              source={`rooms[${selected}].state`}
              choices={stateChoises}
              label="custom.roomState"
              style={{ margin: '0 10px' }}
            />
            <RadioButtonGroupInput
              source={`rooms[${selected}].dealtype`}
              choices={dealtypeChoises}
              label="custom.roomDealtype"
              style={{ margin: '0 10px' }}
            />
            <ImageInput source={`rooms[${selected}].photo`} label="custom.roomPhoto" style={{ margin: '0 10px' }}>
              <ImageField source="src" title="title" />
            </ImageInput>
            <TextInput source={`rooms[${selected}].info`} label="custom.info" style={{ margin: '0 10px' }} multiline />
            <BooleanInput source={`rooms[${selected}].euro`} label="custom.euro" style={{ margin: '0 10px' }} />
          </Drawer>
          <Drawer anchor="right" open={selectedFloor > -1} onClose={() => setSelectedFloor(-1)}>
            <FormDataConsumer>
              {
                ({ formData }) => (<SelectedInfo record={formData} room={selected} floor={selectedFloor} />)
              }
            </FormDataConsumer>
            <NumberInput source={`floors[${selectedFloor}].roomsPerFloor`} label="custom.rpf" style={{ margin: '0 10px' }} />
            <ImageInput source={`floors[${selectedFloor}].photo`} label="custom.floorPhoto" style={{ margin: '0 10px' }}>
              <ImageField source="src" title="title" />
            </ImageInput>
          </Drawer>
        </FormTab>
        <FormTab label="Квартиры">
          <ArrayInput source="rooms" label="">
            <SimpleFormIterator disableAdd>
              <FormDataConsumer>
                {({ formData, scopedFormData, getSource, ...rest }) => <RoomDetails data={scopedFormData} formData={formData} />}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
};

export { EditEntrance as edit, CreateEntrance as create };

function RoomDetails(props: any) {
  const { data, formData } = props;
  const { rpf, type, floor, status, space, number, price } = data || {};

  const style = React.useMemo(() => {
    if (formData && data) {
      const { floorsnum, roomsPerFloor } = formData || {};
      if (data.floor > floorsnum || data.rpf > roomsPerFloor) {
        return {
          background: 'red'
        }
      }
    }
    return {};
  }, [formData, data]);

  return (
    <div style={style}>
      {/* <pre>
        {JSON.stringify(data, null, ' ')}
      </pre> */}
      <Typography variant="caption">{`${composedRoomTypes[type]}:${floor}:${rpf}`}</Typography><br />
      <Typography variant="caption">{floor === 0 ? 'Типовая квартира' : `Этаж: ${floor}`}</Typography><br />
      <Typography variant="caption">{`Номер на этаже: ${rpf}`}</Typography><br />
      <Typography variant="caption">{`Площадь: ${space ?? 0}`}</Typography><br />
      <Typography variant="caption">{`Стоимость: ${price ?? 0}`}</Typography><br />
      <Typography variant="caption">{`Тип: ${composedRoomTypes[type]}`}</Typography><br />
    </div>
  )
}