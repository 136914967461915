import * as React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title, DateInput, SelectInput, useDataProvider, usePermissions, ReferenceInput, FormDataConsumer, ReferenceArrayInput, TextInput, SelectArrayInput, RadioButtonGroupInput } from 'react-admin';
import { Form } from 'react-final-form';
import { Box, Button } from '@material-ui/core';
import startOfMonth from 'date-fns/startOfMonth';
import endOfDay from 'date-fns/endOfDay';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';

import { dateChoices } from 'list/ListFilter';
import { parseISO, format } from "date-fns";
import { Link } from "react-router-dom";

const ReportFilter = (props: any) => {
  const { setFilter, filterValues, role, disabled, ...otherProps } = props;
  console.log('---otherProps reports---', otherProps);
  const onSubmit = (values: any) => {
    if (setFilter) {
      setFilter(values);
    }
  }

  return (
    <div>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
        {
          ({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Box display="fex" alignItems="flex-end">
                <Box component="span" mr={2}>
                  <DateInput source="date_gt" label="Период с" />
                </Box>
                <Box component="span" mr={2}>
                  <DateInput source="date_lt" label="Период по" />
                </Box>
                {
                  ['supermanager', 'admin'].includes(role) && ([
                    <Box component="span" mr={2} key="city">
                      <ReferenceInput source="city" reference="cities" filter={{ active: true }} sort={{ field: 'name', order: 'ASC' }} perPage={500} label="Город">
                        <SelectInput optionText="name" />
                      </ReferenceInput>
                    </Box>,
                    <Box component="span" mr={2} minWidth={150} key="office">
                      <FormDataConsumer>
                        {
                          ({ formData }) => (
                            formData?.city ?
                              <ReferenceArrayInput basePath="/listreports" source="offices" reference="offices" resource="lists" filter={{ active: true, city: formData.city }} sort={{ field: 'name', order: 'ASC' }} perPage={500} label="Офисы">
                                <SelectArrayInput optionText="name" />
                              </ReferenceArrayInput> :
                              <TextInput source="office" disabled />
                          )
                        }
                      </FormDataConsumer>
                    </Box>
                  ])
                }
                {
                  ['cityhead'].includes(role) && ([
                    <Box component="span" mr={2} minWidth={150} key="office">
                      <FormDataConsumer>
                        {
                          ({ formData }) => (
                            formData?.city ?
                              <ReferenceArrayInput basePath="/listreports" source="offices" reference="offices" resource="lists" filter={{ active: true, city: formData.city }} sort={{ field: 'name', order: 'ASC' }} perPage={500} label="Офисы">
                                <SelectArrayInput optionText="name" />
                              </ReferenceArrayInput> :
                              <TextInput source="office" disabled />
                          )
                        }
                      </FormDataConsumer>
                    </Box>
                  ])
                }
                {/* <Box component="span" mr={2}>
                  <SelectInput source="date_type" label="Выборка по" choices={dateChoices} />
                </Box> */}
                <Box component="span" mr={2}>
                  <Button variant="outlined" color="primary" type="submit" disabled={disabled}>Сформировать</Button>
                </Box>
              </Box>
              <Box>
                <Box>
                  <RadioButtonGroupInput source="date_type" label="" choices={dateChoices} row={false} />
                </Box>
              </Box>
            </form>
          )
        }
      </Form>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  cell: {
    fontSize: 12,
    borderLeft: '1px solid #ccc',
    padding: 5
  },
  headCell: {
    fontSize: 12,
    borderLeft: '1px solid #ccc',
    fontWeight: 'bold',
    padding: 5,
    borderTop: '1px solid #ccc',
  },
}));

const ListReports = () => {
  const [filter, setFilter] = React.useState<Record<string, any>>({});
  const [listData, setListData] = React.useState<any>([]);
  const dataProvider = useDataProvider();
  const { permissions, loaded } = usePermissions();
  const [parsing, setParsing] = React.useState(false);

  const initialFilterValues = React.useMemo(() => {
    const { role, city, office } = permissions || {};
    const ret: any = {
      list_kind: 'all',
      date_type: 'reservedate',
      date_lt: endOfDay(Date.now()).toISOString(),
      date_gt: startOfMonth(Date.now()).toISOString(),
    };
    if (role) {
      if (['admin', 'supermanager', 'cityhead'].includes(role)) {
        ret.city = city;
        ret.offices = [office];
      } else if (['officehead'].includes(role)) {
        ret.office = office;
      }
    }
    return ret;
  }, [permissions]);

  React.useEffect(() => {
    const fetchData = async () => {
      const resp = await dataProvider.getList('lists',
        {
          pagination: { page: 1, perPage: 10000 },
          filter,
          sort: { field: 'id', order: 'ASC' }
        });
      return resp.data.filter(d => !d.reject);
    }
    if (Object.keys(filter).length > 0) {
      setListData([]);
      fetchData().then(data => setListData(data));
    }
  }, [filter, dataProvider])

  if (!loaded) {
    return null;
  }

  return (
    <Card>
      <Title title="Отчеты по сделкам" />
      <CardContent>
        <ButtonGroup>
          <Button disabled>СПРАВКА</Button>
          <Button component={Link} to="/expertreport">Рейтинг Экспертов</Button>
          <Button component={Link} to="/developerreport">Работа с застройщиками</Button>
        </ButtonGroup>
        <ReportFilter setFilter={setFilter} filterValues={initialFilterValues} role={permissions?.role} disabled={parsing} />
        <Table1 data={listData} parsing={parsing} setParsing={setParsing} />
        {/* <div>
          filter
          <pre>
            {JSON.stringify(filter, null, ' ')}
          </pre>
          data
          <pre>
            {JSON.stringify(listData, null, ' ')}
          </pre>
        </div> */}
      </CardContent>
    </Card>
  )
};

export default ListReports;

function Table1(props: any) {
  const { data: rawData, parsing, setParsing } = props;
  const [data, setData] = React.useState([]);
  const timer = React.useRef<any>();
  const dataProvider = useDataProvider();
  const classes = useStyles();
  // const [parsing, setParsing] = React.useState(false);

  React.useEffect(() => {
    let didCancel = false;
    const getResourceData = async (resource: string, id: string) => {
      if (!id || didCancel) {
        return {} as any;
      }
      try {
        const resp = await dataProvider.getOne(resource, { id });
        return resp.data;
      } catch (ex) { }
      return {} as any;
    };
    const fillData = async () => {
      const ret: any = [];
      for (let i = 0; i < rawData.length; i++) {
        if (didCancel) {
          break;
        }
        const item: any = rawData[i];
        const manager = (await getResourceData('users', item.manager))?.name;
        const officeData = (await getResourceData('offices', item.office));
        const office = officeData?.name;
        const complexData = (await getResourceData('complexes', item.complex));
        const complex = complexData?.name;
        const developer = (await getResourceData('developers', complexData?.developer))?.name;
        const contragent = `${item.contragentsurname ?? ''} ${item.contragentname ?? ''} ${item.contragentforename ?? ''}`;
        const liststatus = (await getResourceData('liststatuses', item.liststatus))?.name;
        ret.push({ ...item, manager, office, contragent, developer, complex, liststatus });
      }
      return ret;
    }
    if (rawData && !didCancel) {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        setData([]);
        setParsing(true);
        fillData().then(filledData => setData(filledData)).finally(() => setParsing(false));
      }, 1000);
    } else if (!didCancel) {
      setData([]);
    }

    return () => {
      didCancel = true;
    }
  }, [rawData, dataProvider, setParsing]);

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.headCell}>#</TableCell>
            <TableCell className={classes.headCell}>Офис</TableCell>
            <TableCell className={classes.headCell}>Эксперт</TableCell>
            <TableCell className={classes.headCell}>Застройщик</TableCell>
            <TableCell className={classes.headCell}>ЖК</TableCell>
            <TableCell className={classes.headCell}>ФИО Покупателя</TableCell>
            <TableCell className={classes.headCell}>Ст-ть квартиры</TableCell>
            <TableCell className={classes.headCell}>% комиссии</TableCell>
            <TableCell className={classes.headCell}>Сумма КВ</TableCell>
            <TableCell className={classes.headCell}>Статус сделки</TableCell>
            <TableCell className={classes.headCell}>Дата Заключения договора</TableCell>
            <TableCell className={classes.headCell}>Дата оплаты КВ</TableCell>
            <TableCell className={classes.headCell}>Дата оплаты квартиры застройщику</TableCell>
            <TableCell className={classes.headCell}>Дата бронирования</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            parsing && <TableRow><TableCell colSpan={14}>Обработка данных</TableCell></TableRow>
          }
          {
            !parsing && data.map((row: any, ind: number) => (
              <TableRow key={row.id}>
                <TableCell size="small" className={classes.cell}>{ind + 1}</TableCell>
                <TableCell size="small" className={classes.cell}>{row.office}</TableCell>
                <TableCell size="small" className={classes.cell}>{row.manager}</TableCell>
                <TableCell size="small" className={classes.cell}>{row.developer}</TableCell>
                <TableCell size="small" className={classes.cell}>{row.complex}</TableCell>
                <TableCell size="small" className={classes.cell}>{row.contragent}</TableCell>
                <TableCell size="small" className={classes.cell} align="right"><NumberField data={row.sell} /></TableCell>
                <TableCell size="small" className={classes.cell} align="right"><NumberField data={row.kvpr} /></TableCell>
                <TableCell size="small" className={classes.cell} align="right"><NumberField data={row.kv} /></TableCell>
                <TableCell size="small" className={classes.cell}>{row.liststatus}</TableCell>
                <TableCell size="small" className={classes.cell}><DateField data={row.contractdate} /></TableCell>
                <TableCell size="small" className={classes.cell}><DateField data={row.kvpaiddate} /></TableCell>
                <TableCell size="small" className={classes.cell}><DateField data={row.developerpaiddate} /></TableCell>
                <TableCell size="small" className={classes.cell}><DateField data={row.reservedate} /></TableCell>
              </TableRow>
            ))
          }
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell className={classes.cell} align="right"><TotalNumberField data={data} source="sell" /></TableCell>
            <TableCell className={classes.cell}></TableCell>
            <TableCell className={classes.cell} align="right"><TotalNumberField data={data} source="kv" /></TableCell>
            <TableCell className={classes.cell}></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function NumberField(props: any) {
  const { data } = props;
  if (!data) {
    return null;
  }
  return <span>{Number(data).toLocaleString()}</span>;
}

function DateField(props: any) {
  const { data } = props;

  const val = React.useMemo(() => {
    if (!data) {
      return '';
    }
    let val = `${data}`;
    try {
      val = format(parseISO(data), 'dd.MM.yyy');
    } catch (ex) {
      console.log('---DateField---', ex, data);
    }
    return val;
  }, [data]);

  if (!data) {
    return null;
  }

  return <span>{val}</span>
}

function TotalNumberField(props: any) {
  const { data, source } = props;

  const sum = React.useMemo(() => {
    if (data && source) {
      return data.reduce((m: number, d: any) => Number(d[source]) ? m + Number(d[source]) : m, 0);
    }
    return undefined;
  }, [data, source]);

  if (sum === undefined) {
    return null;
  }

  return <span>{Number(sum).toLocaleString()}</span>;
}