import React from 'react';
import startOfDay from 'date-fns/startOfDay';

import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  BooleanInput,
  NumberInput,
  SelectField,
  SelectInput,
  FilterList,
  FilterListItem,
  NumberField,
  BooleanField,
  DateField,
  ReferenceField,
  ReferenceManyField,
  FileInput,
  FileField,
  DateInput,
  ReferenceInput,
  TabbedForm,
  FormTab,
  ArrayInput,
  SimpleFormIterator,
  Toolbar,
  SaveButton,
  usePermissions,
  Filter,
  useDataProvider,
  useRefresh,
  Pagination,
  FormDataConsumer
} from 'react-admin';

import { Box, Grid } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';

import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import { status as bankRequestStatus } from './bankrequest-res';

import IpotekaListDataGrid from 'list/ipoteka';

// import { Card as MuiCard, CardContent, withStyles } from '@material-ui/core';

export const name = 'ipotekalist';

export const status = [
  { id: 1, name: 'Новый Клиент' },
  { id: 2, name: 'Консультация проведена' },
  { id: 3, name: 'Сбор Документов' },
  { id: 4, name: 'Кредитный Анализ' },
  { id: 5, name: 'Ипотека одобрена' },
  { id: 6, name: 'Готовим подписание' },
  { id: 7, name: 'Подписана' },
  { id: 8, name: 'Отказ банка' },
  { id: 9, name: 'Отказ клиента' },
];

export const opStatus = [
  { id: 1, name: 'Встреча проведена' },
  { id: 2, name: 'Показ проведен' }
]

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

// const Card = withStyles((theme) => ({
//   root: {
//     [theme.breakpoints.up('sm')]: {
//       order: -1, // display on the left rather than on the right of the list
//       width: '15em',
//       marginRight: '1em',
//     },
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
// }))(MuiCard);

// const StatusFilter = () => (
//   <FilterList label="Статус" icon="">
//     {status.map((s) => (
//       <FilterListItem label={s.name} key={s.id} value={{ status: s.id }} />
//     ))}
//   </FilterList>
// );

// const FilterSidebar = () => (
//   <Card>
//     <CardContent>
//       <StatusFilter />
//     </CardContent>
//   </Card>
// );

const listKindChoices = [
  { id: 'active', name: 'Активные' },
  { id: 'success', name: 'Успешные' },
  { id: 'reject', name: 'Отказ от работы' },
  { id: 'all', name: 'Все' }
];

const typeChoices = [
  { id: 'requests', name: 'Заявки' },
  { id: 'consultants', name: 'Консультации' }
];

const IpotekaListFilter = (props: any) => (
  <Filter {...props}>
    <SelectInput source="list_kind" label="Статус РЛ" choices={listKindChoices} alwaysOn allowEmpty={false} />
    <SelectInput source="list_type" label="Вид РЛ" choices={typeChoices} alwaysOn allowEmpty={false} />
  </Filter>
);

const ListPagination = (props: any) => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200, 500, 1000, 2000]} {...props} />

const ListRes = (props: any) => {
  const { permissions = {} } = props;
  const { office, user, role, spr } = permissions;

  const filter = React.useMemo(() => {
    if (spr || ['admin', 'supermanager'].includes(role)) {
      return {};
    }
    if (['officehead'].includes(role)) {
      return { managerOffice: office };
    }
    if (['user'].includes(role)) {
      return { manager: user };
    }
    return { broker: user };
  }, [role, spr, user, office]);

  if (!office) {
    return null;
  }


  return (
    <List {...props}
      pagination={<ListPagination />}
      // sort={{ field: 'firstSendDate', order: 'DESC' }}
      exporter={false}
      perPage={200}
      filter={filter}
      filters={<IpotekaListFilter />}
      filterDefaultValues={{ list_kind: 'active', list_type: 'requests' }}
    >
      <IpotekaListDataGrid />
    </List>
  )
};

export { ListRes as list };

export const show = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      {/* <SelectField source="status" choices={status} />
      <TextField source="name" />
      <NumberField source="sum" />
      <NumberField source="first_sum" />
      <NumberField source="credit_sum" />
      <TextField source="credit_period" />
      <BooleanField source="borrower" />
      <TextField source="credit_program" />
      <TextField source="object_type" />
      <TextField source="source_money" />
      <TextField source="address_fact" />
      <TextField source="family_status" />
      <TextField source="education_status" />
      <NumberField source="phone" />
      <TextField source="work_place" />
      <TextField source="work_phone" />
      <TextField source="stag_on_last_work_place" />
      <TextField source="stag_months" />
      <TextField source="work_role" />
      <NumberField source="inn" />
      <TextField source="work_address_fact" />
      <BooleanField source="surname_changed" />
      <TextField source="surname_prev" />
      <NumberField source="children" />
      <TextField source="actives" />
      <TextField source="info" fullWidth /> */}
      {/* <TextField source="name" />
      <NumberField source="phone" />
      <SelectField source="status" choices={status} />
      <ReferenceField source="broker" reference="users" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="office" reference="offices" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="manager" reference="users" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="sum" />
      <NumberField source="credit_sum" />
      <NumberField source="first_sum" />
      <TextField source="info" /> */}
      <TextField source="name" />
      <NumberField source="phone" />
      <SelectField source="status" choices={status} />
      <ReferenceField source="broker" reference="users" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="office" reference="offices" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="manager" reference="users" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <SelectField source="liststatus" choices={opStatus} />
      {/* <ReferenceField source="liststatus" reference="liststatuses" link={false}>
        <TextField source="name" />
      </ReferenceField> */}
      <NumberField source="sum" />
      <NumberField source="credit_sum" />
      <TextField source="strahstatus" />
      <TextField source="info" />
      <CreateBankRequestButton />
      <ReferenceManyField reference="bankrequests" target="list" label="Банки">
        <Datagrid rowClick="show">
          <DateField source="requestDate" />
          <ReferenceField source="bank" reference="banks" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <SelectField source="status" choices={bankRequestStatus} />
          <DateField source="requestStatusDate" />
          <NumberField source="sum" />
          <TextField source="info" />
        </Datagrid>
      </ReferenceManyField>
      <CreateStrahRequestButton />
      <ReferenceManyField reference="strahrequests" target="list" label="Страховые компании">
        <Datagrid rowClick="edit">
          <DateField source="requestDate" />
          <ReferenceField source="strah" reference="strahs" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <NumberField source="live" />
          <NumberField source="things" />
          <NumberField source="titul" />
          <NumberField source="dop" />
          <TextField source="info" />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

const GuestToolbar = (props: any) => {
  const { permissions, loaded } = usePermissions();

  if (!loaded) {
    return null;
  }

  return (
    <Toolbar {...props} >
      <SaveButton disabled={permissions?.role === 'guest'} />
    </Toolbar>
  )
};

const ResCreate = (props: any) => {
  const { permissions = {} } = props;
  const { office, user, role } = permissions;

  if (!office) {
    return null;
  }

  return (
    <Create {...props}>
      <TabbedForm initialValues={{ office, broker: user, list_kind: 'active' }} toolbar={<GuestToolbar />} className="ipotekaform">
        <FormTab label="Общие данные">
          <Grid container spacing={1} style={{ width: '100%' }}>
            <Grid item xs={6}>
              <TextInput source="name" resource="ipotekalist" required fullWidth />
              <SelectInput source="status" resource="ipotekalist" choices={status} required fullWidth />
              <NumberInput source="sum" resource="ipotekalist" fullWidth />
              <NumberInput source="credit_sum" resource="ipotekalist" fullWidth />
              <DateInput source="firstSendDate" resource="ipotekalist" fullWidth />
              <DateInput source="approveDate" resource="ipotekalist" fullWidth />
            </Grid>
            <Grid item xs={6}>
              <NumberInput source="phone" resource="ipotekalist" fullWidth />
              {/* <ReferenceInput source="liststatus" resource="ipotekalist" reference="liststatuses" sort={{ field: 'sort', order: 'ASC' }} perPage={100} >
                <SelectInput source="name" fullWidth />
              </ReferenceInput> */}
              <SelectInput source="liststatus" resource="ipotekalist" choices={opStatus} fullWidth allowEmpty />
              {/* <ReferenceInput source="manager" reference="users" resource="ipotekalist" filter={{ office, active: true }} sort={{ field: 'name', order: 'ASC' }} perPage={1000}>
                <SelectInput source="name" fullWidth />
              </ReferenceInput> */}
              <ReferenceInput source="managerOffice" reference="offices" resource="ipotekalist" filter={{ active: true }} sort={{ field: 'name', order: 'ASC' }} perPage={1000} allowEmpty>
                <SelectInput source="name" fullWidth />
              </ReferenceInput>
              <FormDataConsumer>
                {
                  ({ formData, ...rest }) => (
                    formData?.managerOffice ?
                      <ReferenceInput {...rest} source="manager" reference="users" resource="ipotekalist" filter={{ office: formData.managerOffice, fb_active: true }} sort={{ field: 'name', order: 'ASC' }} perPage={1000}>
                        <SelectInput source="name" fullWidth />
                      </ReferenceInput> :
                      <TextInput {...rest} source="manager" resource="ipotekalist" disabled fullWidth />
                  )
                }
              </FormDataConsumer>
              <ReferenceInput source="bank" reference="banks" resource="ipotekalist" filter={{ active: true }} sort={{ field: 'name', order: 'ASC' }} perPage={1000}>
                <SelectInput source="name" fullWidth />
              </ReferenceInput>
              <TextInput source="strahstatus" resource="ipotekalist" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <TextInput source="info" resource="ipotekalist" fullWidth multiline />
            </Grid>
          </Grid>

          {/* <TextInput source="first_sum" />
          <TextInput source="credit_period" />
          <BooleanInput source="borrower" />
          <TextInput source="credit_program" />
          <TextInput source="object_type" />
          <TextInput source="source_money" />
          <TextInput source="address_fact" />
          <TextInput source="family_status" />
          <TextInput source="education_status" />
          
          <TextInput source="work_place" />
          <TextInput source="work_phone" />
          <TextInput source="stag_on_last_work_place" />
          <TextInput source="stag_months" />
          <TextInput source="work_role" />
          <NumberInput source="inn" />
          <TextInput source="work_address_fact" />
          <BooleanInput source="surname_changed" />
          <TextInput source="surname_prev" />
          <NumberInput source="children" />
          <TextInput source="actives" />
          <TextInput source="info" />
          <DateInput source="requestDateBank" />
          <DateInput source="requestDateStrah" /> */}
        </FormTab>
        <FormTab label="Документы">
          <ArrayInput source="documents">
            <SimpleFormIterator>
              <TextInput source="docsname" fullWidth />
              <TextInput source="comment" multiline fullWidth />
              <FileInput source="docs" multiple>
                <FileField source="src" title="title" />
              </FileInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

const transform = (data: any) => {
  if ([7].includes(data.status)) {
    data.list_kind = 'success';
  } else if ([8, 9].includes(data.status)) {
    data.list_kind = 'archive';
  } else {
    data.list_kind = 'active';
  }

  return data;
}

export const edit = (props: any) => {
  const { permissions = {} } = props;
  const { office, user, role } = permissions;

  if (!office) {
    return null;
  }
  return (
    <Edit {...props} transform={transform}>
      <TabbedForm initialValues={{ office, broker: user }} toolbar={<GuestToolbar />} className="ipotekaform">
        <FormTab label="Общие данные">
          <Grid container spacing={1} style={{ width: '100%' }}>
            <Grid item xs={6}>
              <TextInput source="name" resource="ipotekalist" required fullWidth />
              <SelectInput source="status" resource="ipotekalist" choices={status} required fullWidth />
              <NumberInput source="sum" resource="ipotekalist" fullWidth />
              <NumberInput source="credit_sum" resource="ipotekalist" fullWidth />
              <DateInput source="firstSendDate" resource="ipotekalist" fullWidth />
              <DateInput source="approveDate" resource="ipotekalist" fullWidth />
            </Grid>
            <Grid item xs={6}>
              <NumberInput source="phone" resource="ipotekalist" fullWidth />
              {/* <ReferenceInput source="liststatus" resource="ipotekalist" reference="liststatuses" sort={{ field: 'sort', order: 'ASC' }} perPage={100} >
                <SelectInput source="name" fullWidth />
              </ReferenceInput> */}
              <SelectInput source="liststatus" resource="ipotekalist" choices={opStatus} fullWidth allowEmpty />
              <ReferenceInput source="managerOffice" reference="offices" resource="ipotekalist" filter={{ active: true }} sort={{ field: 'name', order: 'ASC' }} perPage={1000} allowEmpty>
                <SelectInput source="name" fullWidth />
              </ReferenceInput>
              <FormDataConsumer>
                {
                  ({ formData, ...rest }) => (
                    formData?.managerOffice ?
                      <ReferenceInput {...rest} source="manager" reference="users" resource="ipotekalist" filter={{ office: formData.managerOffice, fb_active: true }} sort={{ field: 'name', order: 'ASC' }} perPage={1000}>
                        <SelectInput source="name" fullWidth />
                      </ReferenceInput> :
                      <TextInput {...rest} source="manager" resource="ipotekalist" disabled fullWidth />
                  )
                }
              </FormDataConsumer>
              {/* <ReferenceInput source="manager" reference="users" resource="ipotekalist" filter={{ office, active: true }} sort={{ field: 'name', order: 'ASC' }} perPage={1000}>
                <SelectInput source="name" fullWidth />
              </ReferenceInput> */}
              <ReferenceInput source="bank" reference="banks" resource="ipotekalist" filter={{ active: true }} sort={{ field: 'name', order: 'ASC' }} perPage={1000}>
                <SelectInput source="name" fullWidth />
              </ReferenceInput>
              <TextInput source="strahstatus" resource="ipotekalist" fullWidth />
            </Grid>
            <Grid item xs={12}>
              <TextInput source="info" resource="ipotekalist" fullWidth multiline />
            </Grid>
          </Grid>

          {/* <TextInput source="name" required />
          <NumberInput source="phone" />
          <SelectInput source="status" choices={status} required />
          <ReferenceInput source="manager" reference="users" resource="lists" filter={{ office, active: true }} sort={{ field: 'name', order: 'ASC' }} perPage={1000}>
            <SelectInput source="name" />
          </ReferenceInput>
          <ReferenceInput source="liststatus" reference="liststatuses" resource="lists" sort={{ field: 'sort', order: 'ASC' }} perPage={100}>
            <SelectInput source="name" />
          </ReferenceInput>
          <NumberInput source="sum" />
          <NumberInput source="credit_sum" />
          <ReferenceInput source="bank" reference="banks" filter={{ active: true }} sort={{ field: 'name', order: 'ASC' }} perPage={1000}>
            <SelectInput source="name" />
          </ReferenceInput>
          <TextInput source="strahstatus" />
          <TextInput source="info" /> */}

          {/* <TextInput source="first_sum" />
          <TextInput source="credit_period" />
          <BooleanInput source="borrower" />
          <TextInput source="credit_program" />
          <TextInput source="object_type" />
          <TextInput source="source_money" />
          <TextInput source="address_fact" />
          <TextInput source="family_status" />
          <TextInput source="education_status" />
          
          <TextInput source="work_place" />
          <TextInput source="work_phone" />
          <TextInput source="stag_on_last_work_place" />
          <TextInput source="stag_months" />
          <TextInput source="work_role" />
          <NumberInput source="inn" />
          <TextInput source="work_address_fact" />
          <BooleanInput source="surname_changed" />
          <TextInput source="surname_prev" />
          <NumberInput source="children" />
          <TextInput source="actives" />
          <TextInput source="info" />
          <DateInput source="requestDateBank" />
          <DateInput source="requestDateStrah" /> */}
        </FormTab>
        <FormTab label="Документы">
          <ArrayInput source="documents">
            <SimpleFormIterator>
              <TextInput source="docsname" fullWidth />
              <TextInput source="comment" multiline fullWidth />
              <FileInput source="docs" multiple>
                <FileField source="src" title="title" />
              </FileInput>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Запросы">
          <CreateBankRequestButton />
          <ReferenceManyField reference="bankrequests" target="list" label="Банки">
            <Datagrid rowClick="edit">
              <DateField source="requestDate" />
              <ReferenceField source="bank" reference="banks" link={false}>
                <TextField source="name" />
              </ReferenceField>
              <SelectField source="status" choices={bankRequestStatus} />
              <DateField source="requestStatusDate" />
              <NumberField source="sum" />
              <TextField source="info" />
            </Datagrid>
          </ReferenceManyField>
          <CreateStrahRequestButton />
          <ReferenceManyField reference="strahrequests" target="list" label="Страховые компании">
            <Datagrid rowClick="edit">
              <DateField source="requestDate" />
              <ReferenceField source="strah" reference="strahs" link={false}>
                <TextField source="name" />
              </ReferenceField>
              <NumberField source="live" />
              <NumberField source="things" />
              <NumberField source="titul" />
              <NumberField source="dop" />
              <TextField source="info" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
};

export { ResCreate as create };


function CreateBankRequestButton(props: any) {
  const { record } = props;
  const { id } = record;
  const [open, setOpen] = React.useState(false);
  const [banks, setBanks] = React.useState<string[]>([]);
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [banksList, setBanksList] = React.useState<Record<string, unknown>[]>([]);
  const refresh = useRefresh();

  const handleClose = () => {
    setOpen(false);
  }

  const handleCreate = () => {
    const addBankRequests = async () => {
      const requests = banks.map(b => dataProvider.create('bankrequests', {
        data: {
          bank: b,
          requestDate: startOfDay(Date.now()).toISOString(),
          list: id,
          status: 1
        }
      }));
      Promise.all(requests).then(() => setOpen(false)).then(() => refresh());
    }

    // console.log('---selected banks---', banks);
    if (banks.length) {
      addBankRequests();
    } else {
      setOpen(false);
    }
  }

  const handleChange = (event: any) => {
    setBanks(event.target.value ?? '');
  };

  React.useEffect(() => {
    const getBanks = async () => {
      const resp = await dataProvider.getList('banks', {
        pagination: { page: 1, perPage: 1000 },
        filter: { active: true },
        sort: { field: 'name', order: 'ASC' }
      });
      setBanksList(resp?.data);
    }
    getBanks();
  }, [dataProvider]);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        Добавить запрос в банк
      </Button>
      <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>Быберите банки для создания запросов</DialogTitle>
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={classes.formControl}>
              <InputLabel>Банки</InputLabel>
              <Select multiple value={banks} onChange={handleChange} input={<Input />}>
                {
                  banksList?.map((b: any) => <MenuItem key={b.id} value={b.id}>{b.name}</MenuItem>)
                }
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Отмена
          </Button>
          <Button onClick={handleCreate} color="primary">
            Создать
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )

  // return (
  //   <Button
  //     variant="contained"
  //     color="primary"
  //     component={Link as any}
  //     to={`/bankrequests/create?list=${id}`}
  //   >
  //     Добавить запрос в банк
  //   </Button>
  // )
}

function CreateStrahRequestButton(props: any) {
  const { record } = props;
  const { id } = record;

  return (
    <Button
      variant="contained"
      color="primary"
      component={Link as any}
      to={`/strahrequests/create?list=${id}`}
    >
      Добавить запрос в СК
    </Button>
  )
}


// Invalid query. You have a where filter with an inequality (<, <=, !=, not-in, >, or >=) on field 'firstSendDate'
// and so you must also use 'firstSendDate' as your first argument to Query.orderBy(), 
// but your first orderBy() is on field 'createdate' instead.