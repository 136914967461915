import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useQueryWithStore } from 'react-admin';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { setObject, setFilteredComplexes, setViewMap } from '../reducers/search-slice';
import { simpleRoomTypes, complexStat } from '../search/SearchData';
import isBefore from 'date-fns/isBefore';
import { getSellDates, SelectControl } from './Controls';
import { ViewContext } from './Context';
import { composeUrl } from 'libs/parseHelper';

import MapIcon from '@material-ui/icons/Map';
import ViewListIcon from '@material-ui/icons/ViewList';

import heartImage from './images/heart.svg';

const cardHeight = 500 + 12 * 2;

const cardHeight_list = 216;

const useStyles = makeStyles((theme) => ({
  container: {
    // width: 632,
    height: '100%',
    overflowY: 'scroll',
    [theme.breakpoints.up('md')]: {
      width: 350
    },
    [theme.breakpoints.up('lg')]: {
      width: 670
    }
  },
  listContainer: {
    flex: 1
  },
  sortContainer: {
    width: 210,
    height: 60
  },
  card: {
    position: 'relative',
    width: 300,
    height: 500,
    borderRadius: 8,
    margin: '12px 0px 12px 24px',
    boxShadow: '2px 2px 9px 2px #999',
    overflow: 'hidden'
  },

  card_list: {
    position: 'relative',
    // width: 300,
    // height: 500,
    borderRadius: 8,
    margin: '12px 12px 12px 24px',
    boxShadow: '2px 2px 9px 2px #999'
  },

  cardImage: {
    height: 180,
    borderRadius: '8px 8px 0 0',
  },
  innerCardContainer: {
    padding: 16
  },
  imageBanner: {
    height: 36,
    background: 'rgba(0, 0, 0, 0.3)',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  action: {
    background: '#519872',
    borderRadius: 4,
    fontWeight: 600,
    fontSize: 12,
    marginLeft: 13,
    marginRight: 3,
    padding: '4px 8px',
    color: '#fff',
    cursor: 'pointer'
  },
  space: {
    flex: 1
  },
  hit: {
    background: '#EFA00B',
    borderRadius: 4,
    fontWeight: 600,
    fontSize: 12,
    padding: '4px 8px',
    color: '#fff',
    cursor: 'pointer'
  },
  heart: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 14,
    cursor: 'pointer'
  },
  doneDate: {
    color: '#7A7A7A',
    fontSize: 12
  },
  complexLink: {
    textDecoration: 'none',
    fontSize: 24,
    color: '#1E2019',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px'
  },
  complexName: {
    height: 60
  },
  addr: {
    color: '#1E2019',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 'normal',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  geoIcon: {
    position: 'absolute',
    bottom: 5,
    right: 5,
    cursor: 'pointer',
    color: '#3F51B5'
  },
  container2: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

function Cards() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { complexes: selectedComplexes, districts: selectedDistricts, priceFrom, priceTo, spaceFrom, spaceTo, roomTypes: selectedRoomTypes, sellDate, dealtype } = useSelector((state: any) => state.search);
  const data = useSelector((state: any) => state.search.complexesData);
  const [viewMode] = React.useContext(ViewContext);

  const handleSelect = React.useCallback(
    (id) =>
      dispatch(setObject(id)),
    [dispatch]
  );

  const complexData = React.useMemo(() => {
    return data?.map((d: any) => complexStat(d, dealtype)).filter((c: any) => Number(c.priceFrom) > 0);
  }, [data, dealtype]);

  const complexes = React.useMemo(() => {
    if (!complexData) {
      return null;
    }

    let filtered = complexData;

    if (sellDate) {
      const sellDates = getSellDates();
      const foundSellDate = sellDates.find(sd => sd.id === sellDate);
      if (foundSellDate) {
        filtered = filtered.filter((c: any) => {
          const { dates } = c;
          if (dates) {
            return dates.some((d: any) => {
              const date1 = d;
              const date2 = foundSellDate.q;
              const before = isBefore(date1, date2);
              return before;
            });
          }
          return false;
        });
      }
    }

    if (selectedDistricts && selectedDistricts.length) {
      filtered = filtered.filter((c: any) =>
        selectedDistricts.includes(c.district)
      );
    }

    if (selectedComplexes && selectedComplexes.length) {
      filtered = filtered.filter((c: any) =>
        selectedComplexes.includes(c.id)
      );
    }

    if (selectedRoomTypes && selectedRoomTypes.length) {
      filtered = filtered.filter((c: any) => {
        const { roomsStat } = c;
        const keys = Object.keys(roomsStat);
        const intersection = selectedRoomTypes.filter((x: any) => keys.includes(x));
        if (!intersection.length) {
          return false;
        }

        // console.log('---rooms stat---', intersection, roomsStat);
        if (!priceFrom && !priceTo && !spaceFrom && !spaceTo) {
          return true;
        }
        let passedArr: any = [];
        intersection.forEach((k: string) => {
          let passed: any = [];
          const { p_min, s_min } = roomsStat[k];
          if (priceFrom) {
            passed.push(Number(p_min) > Number(priceFrom));
          }
          if (priceTo) {
            passed.push(Number(p_min) < Number(priceTo));
          }
          if (spaceFrom) {
            passed.push(Number(s_min) > Number(spaceFrom));
          }
          if (spaceTo) {
            passed.push(Number(s_min) < Number(spaceTo));
          }
          passedArr.push(passed.every(Boolean));
        });
        return passedArr.some(Boolean);
        // return intersection.length;
      });
    } else {

      if (priceFrom) {
        filtered = filtered.filter((c: any) => Number(c.priceFrom) > Number(priceFrom));
      }

      if (priceTo) {
        filtered = filtered.filter((c: any) => Number(c.priceFrom) < Number(priceTo));
      }

      if (spaceFrom) {
        filtered = filtered.filter((c: any) => Number(c.spaceFrom) > Number(spaceFrom));
      }

      if (spaceTo) {
        filtered = filtered.filter((c: any) => Number(c.spaceFrom) < Number(spaceTo));
      }
    }

    return filtered;
  }, [
    complexData,
    selectedComplexes,
    selectedDistricts,
    selectedRoomTypes,
    priceFrom,
    priceTo,
    spaceFrom,
    spaceTo,
    sellDate
  ]);

  React.useEffect(() => {
    dispatch(setFilteredComplexes(complexes.map((c: any) => c.id)));
  }, [complexes, dispatch]);

  return (
    <div className={viewMode === 'list' ? classes.listContainer : classes.container}>
      {
        <CardsList data={complexes} onSelect={handleSelect} />
      }
    </div>
  )
}

export default Cards;

const sortVariants = [
  { id: '0', name: 'умолчанию' },
  { id: '1', name: 'цене, сначала дешевые' },
  { id: '2', name: 'цене, сначала дорогие' },
  { id: '3', name: 'площади, сначала малые' },
  { id: '4', name: 'площади, сначала большие' },
];

function CardsList(props: any) {
  const theme = useTheme();
  const is_lg = useMediaQuery(theme.breakpoints.up('lg'));
  const { data } = props;
  const classes = useStyles();
  const [sort, setSort] = React.useState('0');
  const [viewMode, setViewMode] = React.useContext(ViewContext);


  const complexes = React.useMemo(() => {
    if (sort === '0') {
      return data;
    }
    const obj = [...data];
    if (sort === '1') {
      obj.sort((a, b) => a.priceFrom - b.priceFrom);
    }
    if (sort === '2') {
      obj.sort((a, b) => b.priceFrom - a.priceFrom);
    }
    if (sort === '3') {
      obj.sort((a, b) => a.spaceFrom - b.spaceFrom);
    }
    if (sort === '4') {
      obj.sort((a, b) => b.spaceFrom - a.spaceFrom);
    }
    return obj;
  }, [sort, data]);

  if (viewMode === 'list') {
    return (
      <>
        <div className={classes.container2}>
          <div className={classes.sortContainer}>
            <SelectControl data={sortVariants} value={sort} onChange={setSort} label="Сортировать по..." allowClear={false} />
          </div>
          <div>
            <MapIcon onClick={() => setViewMode('map')} style={{ cursor: 'pointer', color: viewMode === 'map' ? 'silver' : 'black' }} />
            <ViewListIcon onClick={() => setViewMode('list')} style={{ cursor: 'pointer', color: viewMode === 'list' ? 'silver' : 'black' }} />

          </div>
        </div>
        <div style={{ overflowY: 'scroll', height: 'calc(100% - 60px)' }}>
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                width={width}
                itemSize={cardHeight_list}
                itemCount={Math.ceil(complexes.length)}
              >
                {({ index, style }) => (
                  <div style={style}>
                    <CardList
                      data={complexes[index]}
                    />
                  </div>
                )}
              </List>
            )}
          </AutoSizer>
        </div>
      </>
    )
  }

  return (
    <>
      <div className={classes.container2}>
        <div className={classes.sortContainer}>
          <SelectControl data={sortVariants} value={sort} onChange={setSort} label="Сортировать по..." allowClear={false} />
        </div>
        <div>
          <MapIcon onClick={() => setViewMode('map')} style={{ cursor: 'pointer', color: viewMode === 'map' ? 'silver' : 'black' }} />
          <ViewListIcon onClick={() => setViewMode('list')} style={{ cursor: 'pointer', color: viewMode === 'list' ? 'silver' : 'black' }} />

        </div>
      </div>
      <div style={{ overflowY: 'scroll', height: 'calc(100% - 60px)' }}>
        {
          !is_lg ?
            (<AutoSizer>
              {({ height, width }) => (
                <List
                  height={height}
                  width={width}
                  itemSize={cardHeight}
                  itemCount={Math.ceil(complexes.length)}
                >
                  {({ index, style }) => (
                    <div style={style}>
                      <Card
                        data={complexes[index]}
                      />
                    </div>
                  )}
                </List>
              )}
            </AutoSizer>) :
            (<AutoSizer>
              {({ height, width }) => (
                <List
                  height={height}
                  width={width}
                  itemSize={cardHeight}
                  itemCount={Math.ceil(complexes.length / 2)}
                >
                  {({ index, style }) => (
                    <div style={style}>
                      <Grid container>
                        <Grid item md={6}>
                          <Card
                            data={complexes[2 * index]}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <Card
                            data={complexes[2 * index + 1]}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </List>
              )}
            </AutoSizer>)
        }
      </div>
    </>
  );
}

function Card(props: any) {
  const { data } = props;
  const classes = useStyles();

  const {
    name,
    photo,
    priceFrom,
    addr,
    min_date,
    max_date,
    roomsStat,
    totalRooms,
    district,
    common,
    geo
  } = data || {};

  const imageStyle = React.useMemo(() => {
    if (photo) {
      return {
        backgroundImage: `url(${composeUrl(photo, '600')})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }
    }
    return {};
  }, [photo]);

  if (!data) {
    return null;
  }

  return (
    <div className={classes.card}>
      <div className={classes.cardImage} style={imageStyle}>
        <div className={classes.imageBanner}>
          <span className={classes.action}><Typography style={{ fontSize: 12 }}>Акции</Typography></span>
          <span className={classes.hit}><Typography style={{ fontSize: 12 }}>Хит</Typography></span>
          <div className={classes.space} />
          <div className={classes.heart}>
            <img src={heartImage} alt="heart" />
          </div>
        </div>
      </div>
      <div className={classes.innerCardContainer}>
        <Typography gutterBottom style={{ fontSize: 18 }}>
          {priceFrom !== Infinity ? (
            <b>от {Number(priceFrom).toLocaleString()} руб. </b>
          ) : (
            <br />
          )}
        </Typography>
        <Typography
          className={classes.doneDate}
          gutterBottom
        >
          Срок сдачи: {min_date} - {max_date}
        </Typography>
        <Typography gutterBottom className={classes.complexName}>
          <Link to={{ pathname: '/complexinfo', search: `?id=${data.id}&v=4` }} target="_blank" className={classes.complexLink}>ЖК {name}</Link>
        </Typography>
        <Typography className={classes.addr}>{addr && addr[0]}</Typography>
        <Typography className={classes.addr} gutterBottom>
          {district ? <District id={district} /> : <span style={{ color: '#fff' }}>.</span>}
        </Typography>
        <RoomsStat data={roomsStat} />
        {
          !common && (<Typography className={classes.addr} variant="subtitle2">
            В продаже {totalRooms} квартир
          </Typography>)
        }
      </div>
      {
        geo && <LocationOnIcon className={classes.geoIcon} />
      }
    </div>
  )
}

function District(props: any) {
  const { id } = props;
  const { data, loaded } = useQueryWithStore({
    type: 'getOne',
    resource: 'districts',
    payload: { id }
  });

  if (!id || !loaded) {
    return null;
  }

  return <span>{data?.name}</span>;
}

function RoomsStat(props: any) {
  const { data } = props;
  const classes = useStyles();
  const { roomTypes } = useSelector((state: any) => state.search);

  const getRoomStat = React.useCallback(
    (srt) => {
      const found = (data || {})[srt];
      const suff = isNaN(Number(srt)) ? '' : 'к';
      if (found) {
        return `${srt}${suff} - от ${Number(found.s_min).toFixed(
          2
        )} кв.м. от ${Number(found.p_min).toLocaleString()} р`;
      }
      // return `${srt}${suff} - нет в продаже`;
      return `${srt}${suff} — `;
    },
    [data]
  );

  const getStyle = React.useCallback((srt) => {
    if (Array.isArray(roomTypes) && roomTypes.length && roomTypes.includes(srt)) {
      return { color: '#3F51B5', fontWeight: 'normal' };
    }
    return {};
  }, [roomTypes]);

  return (
    <div>
      {simpleRoomTypes.map((srt) => (
        <Typography key={srt} variant="subtitle2" className={classes.addr} style={getStyle(srt) as any}>
          {getRoomStat(srt)}
        </Typography>
      ))}
    </div>
  );
}

function CardList(props: any) {
  const { data } = props;
  const classes = useStyles();

  const {
    name,
    photo,
    priceFrom,
    addr,
    min_date,
    max_date,
    roomsStat,
    totalRooms,
    district,
    common,
    geo
  } = data || {};

  const imageStyle = React.useMemo(() => {
    if (photo) {
      return {
        backgroundImage: `url(${composeUrl(photo, '600')})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100%',
        borderRadius: 0
      }
    }
    return {};
  }, [photo]);

  if (!data) {
    return null;
  }

  return (
    <div className={classes.card_list}>
      <div style={{ display: 'flex' }}>
        <div style={{ width: 370 }}>
          <div className={classes.cardImage} style={imageStyle}>
            <div className={classes.imageBanner}>
              <span className={classes.action}><Typography style={{ fontSize: 12 }}>Акции</Typography></span>
              <span className={classes.hit}><Typography style={{ fontSize: 12 }}>Хит</Typography></span>
              <div className={classes.space} />
              <div className={classes.heart}>
                <img src={heartImage} alt="heart" />
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: 200, overflow: 'hidden', padding: '8px 32px' }}>
          <Typography gutterBottom style={{ fontSize: 18 }}>
            {priceFrom !== Infinity ? (
              <b>от {Number(priceFrom).toLocaleString()} руб. </b>
            ) : (
              <br />
            )}
          </Typography>
          <Typography
            className={classes.doneDate}
            gutterBottom
          >
            Срок сдачи: {min_date} - {max_date}
          </Typography>
          <Typography gutterBottom className={classes.complexName}>
            <Link to={{ pathname: '/complexinfo', search: `?id=${data.id}&v=4` }} target="_blank" className={classes.complexLink}>ЖК {name}</Link>
          </Typography>
          <Typography className={classes.addr}>{addr && addr[0]}</Typography>
          <Typography className={classes.addr} gutterBottom>
            {district && <District id={district} />}
          </Typography>
        </div>
        <div style={{ padding: '8px 32px' }}>
          <RoomsStat data={roomsStat} />
          {
            !common && (<Typography className={classes.addr} variant="subtitle2">
              В продаже {totalRooms} квартир
            </Typography>)
          }
        </div>
      </div>
    </div>
  )
}