export const firebaseConfig = {
  apiKey: 'AIzaSyBRzB8TmC3H6rcR9omJrkoJDgDQIOOf1D4',
  authDomain: 'rooms-232b0.firebaseapp.com',
  databaseURL: 'https://rooms-232b0.firebaseio.com',
  projectId: 'rooms-232b0',
  storageBucket: 'rooms-232b0.appspot.com',
  messagingSenderId: '988885795295',
  appId: '1:988885795295:web:d812857de3c4976549446a',
  measurementId: 'G-YSYNK6BMFW',
};
