import React from 'react';

import { parse } from 'query-string';

import {
  // List,
  // Datagrid,
  // TextField,
  // Show,
  // SimpleShowLayout,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  ListGuesser,
  Toolbar,
  SaveButton,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  NumberInput,
  ImageInput,
  ImageField
} from 'react-admin';

import { composedRoomTypes, sortOrder } from 'search/SearchData';

export const name = 'rooms';

export const list = (props: any) => <ListGuesser {...props} />;

const CustomToolbar = (props: any) => {
  const { record, role } = props;

  return (
    <Toolbar {...props} >
      <SaveButton />
    </Toolbar>
  )
};


const ResCreate = (props: any) => {

  const { building } = parse(props.location.search);
  const redirect = building ? `/buildings/${building}/show` : 'show';

  return (
    <Create {...props} transform={transform}>
      <SimpleForm initialValues={{ building }} redirect={redirect} toolbar={<CustomToolbar />}>
        <BooleanInput source="active" />
        <ReferenceInput source="type" reference="roomtypes" perPage={100} filter={{ active: true }}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="priceFrom" />
        <NumberInput source="priceTo" />
        <NumberInput source="spaceFrom" />
        <NumberInput source="spaceTo" />
        <ImageInput source="roomPhoto" multiple>
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageInput source="planPhoto" multiple>
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageInput source="floorPlanPhoto" multiple>
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput source="floor" />
        <TextInput source="entrance" />
        <TextInput source="number" />
        <TextInput source="numberOnFloor" />
        <BooleanInput source="typical" />
        <TextInput source="info" fullWidth multiline />
      </SimpleForm>
    </Create>
  );
};

export const ResEdit = (props: any) => {
  const redirect = (basePath: string, id: string, data: any) => `/buildings/${data.building}/show`;
  return (
    <Edit {...props} transform={transform}>
      <SimpleForm toolbar={<CustomToolbar />} redirect={redirect}>
        <BooleanInput source="active" />
        <ReferenceInput source="type" reference="roomtypes" perPage={100} filter={{ active: true }}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="priceFrom" />
        <NumberInput source="priceTo" />
        <NumberInput source="spaceFrom" />
        <NumberInput source="spaceTo" />
        <ImageInput source="roomPhoto" multiple>
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageInput source="planPhoto" multiple>
          <ImageField source="src" title="title" />
        </ImageInput>
        <ImageInput source="floorPlanPhoto" multiple>
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput source="floor" />
        <TextInput source="entrance" />
        <TextInput source="number" />
        <TextInput source="numberOnFloor" />
        <BooleanInput source="typical" />
        <TextInput source="info" fullWidth multiline />
      </SimpleForm>
    </Edit>
  )
};

export { ResCreate as create, ResEdit as edit };

function transform(data: any) {
  // const rt: string = composedRoomTypes[data.type];
  // const n1 = sortOrder[rt];
  // const n2 = Number(data.spaceFrom) ?? 1;
  // data.sort = Number(`${Math.ceil(n1) * 1000}${n2 * 1000}`) ?? 1;
  return data;
}