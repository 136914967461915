import React from 'react';
import Typography from '@material-ui/core/Typography';
import Card from './Card';
import { ViewContext } from './Context';

function ActionSmall() {
  const [viewMode] = React.useContext(ViewContext);

  if (viewMode !== 'broker') {
    return null;
  }

  return (
    <Card style={{ marginLeft: 24, background: '#E42535' }}>
      <div style={{ textAlign: 'center' }}>
        <Typography variant="h1" style={{ color: '#fff', fontSize: 120, fontWeight: 'bold', margin: '0 auto' }}>2%</Typography>
      </div>
      <Typography variant="body2" style={{ color: '#fff', marginBottom: 8 }}>2% при продаже 1 квартиры</Typography>
      <Typography variant="body2" style={{ color: '#fff', marginBottom: 8 }}>2% при продаже 1 квартиры</Typography>
      <Typography variant="body2" style={{ color: '#fff', marginBottom: 8 }}>2% при продаже 1 квартиры</Typography>
      <Typography variant="body2" style={{ color: '#fff', marginBottom: 8 }}>2% при продаже 1 квартиры</Typography>
    </Card>
  )
}

export default ActionSmall;