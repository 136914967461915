import React, { Suspense } from 'react';
import { Loading } from 'react-admin';


const RoomPrice = React.lazy(() => import('./RoomPrice'));

function RoomPriceLoader(props: any) {
  return (
    <Suspense fallback={<Loading />}>
      <RoomPrice {...props} />
    </Suspense>
  )
}


export default RoomPriceLoader;