import { Card2 as Card } from './Card';
import MapComponent from 'search/SearchDeatilsMap';

import Typography from '@material-ui/core/Typography';

function Map(props: any) {
  const { complex } = props;
  return (
    <Card>
      <Typography gutterBottom variant="h5">Карта</Typography>
      <MapComponent value={complex?.geo} name={complex?.name} id={complex?.id} zoom="16" />
    </Card>

  )
}

export default Map;