import { Card2 } from './Card';
import Typography from '@material-ui/core/Typography';

function Documents(props: any) {
  const { complex } = props;

  return (
    <Card2>
      <Typography gutterBottom variant="h5">Документы</Typography>
      <div style={{ marginTop: 24 }}>

      </div>
    </Card2>
  )
}

export default Documents;