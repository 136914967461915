import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { useQueryWithStore, useMutation, usePermissions } from 'react-admin';
import { useReactToPrint } from 'react-to-print';

import calcRooms from 'components/RoomsGrid/calcrooms';

import { compareKey } from './SearchDetailsRoomInfo';
import { composeUrl } from 'libs/parseHelper';
import { composedRoomTypes } from 'search/SearchData';

const columnsPerSheet = 3;

const range = (n: number) => Array.from(Array(n).keys());

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(1),
        width: theme.spacing(16),
        height: theme.spacing(16),
        cursor: 'pointer'
      },
    },
    formControl: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      minWidth: 200,
      maxWidth: 350,
    },
  }),
);

const Compare = () => {
  const { permissions } = usePermissions();
  const { user } = permissions || {};
  const [storedData, setStoredData] = React.useState<any>([]);
  const printRef = React.useRef<any>();
  const classes = useStyles();

  const [selected, setSelected] = React.useState<any>([]);
  const [compareName, setCompareName] = React.useState('');

  React.useEffect(() => {
    try {
      const data = localStorage.getItem(compareKey);
      const parsed = JSON.parse(data ?? '');
      setStoredData(Object.values(parsed));
      setSelected(Object.keys(parsed));
    } catch (ex) { }
  }, []);

  const filteredData = React.useMemo(() =>
    storedData.filter((d: any) => selected.includes(d.room))
    , [storedData, selected]);

  const sheetNums = React.useMemo(() => {
    if (filteredData?.length) {
      return Math.ceil(filteredData.length / columnsPerSheet);
    }
    return 0;
  }, [filteredData]);

  const handlePrint = useReactToPrint({
    content: () => printRef?.current,
  });

  const handleResetComparison = () => {
    localStorage.removeItem(compareKey);
  }

  const [save, { loading }] = useMutation({
    type: 'create',
    resource: 'compares',
    payload: { data: { name: compareName, rooms: filteredData, user } }
  })

  React.useEffect(() => {
    if (!loading) {
      setCompareName('');
    }
  }, [loading]);

  return (
    <div>
      <Card style={{ marginBottom: 10 }}>
        <CardContent>
          <Button variant="contained" color="primary" onClick={handlePrint} style={{ marginRight: 5 }}>
            Печать
          </Button>
          <Button variant="contained" color="primary" onClick={handleResetComparison} style={{ marginRight: 5 }}>
            Очистить подборку
          </Button>
          <FormControl className={classes.formControl}>
            <TextField
              label="Наименование подборки"
              onChange={ev => setCompareName(ev.target.value)}
              value={compareName}
            />
          </FormControl>
          <Button variant="contained" color="primary" onClick={save} style={{ marginRight: 5 }} disabled={!compareName || loading}>
            Сохранить как
          </Button>
        </CardContent>
      </Card>

      <Card style={{ marginBottom: 10 }}>
        <CardContent>
          <div className={classes.root}>
            {
              storedData.map((r: any) => <PaperRoom key={r.room} data={r} selected={selected} setSelected={setSelected} />)
            }
          </div>
        </CardContent>
      </Card>


      <div ref={printRef}>
        {
          range(sheetNums).map(num => <Sheet key={num} num={num} data={filteredData} />)
        }
      </div>
    </div>
  )
}

export default Compare;

function Sheet(props: any) {
  const { num, data } = props;

  const columns = React.useMemo(() => {
    const ret: any = [];
    for (let i = 0; i < columnsPerSheet; i++) {
      const index = i + columnsPerSheet * num;
      if (data[index]) {
        ret.push(data[index]);
      }
    }
    return ret;
  }, [num, data]);

  return (
    <div className="sheet padding-10mm">
      <Grid container>
        {
          columns.map((c: any) => <Column key={c.id} data={c} />)
        }
      </Grid>
    </div>
  )
}

function Column(props: any) {
  const { data } = props;
  const { room, ipoteka } = data;
  const [building, section, floor, rpf, room_id] = (room || '').split('_');

  const { data: buildingData } = useQueryWithStore({
    type: 'getOne',
    resource: building ? 'buildings' : '',
    payload: { id: building }
  });

  const { data: sectionData } = useQueryWithStore({
    type: 'getOne',
    resource: section && section !== '0' ? 'entrances' : '',
    payload: { id: section }
  });

  const { data: complexData } = useQueryWithStore({
    type: 'getOne',
    resource: section ? 'complexes' : '',
    payload: { id: buildingData?.complex }
  });

  const { data: districtData } = useQueryWithStore({
    type: 'getOne',
    resource: complexData?.district ? 'districts' : '',
    payload: { id: complexData?.district }
  });

  const { data: developerData } = useQueryWithStore({
    type: 'getOne',
    resource: complexData?.developer ? 'developers' : '',
    payload: { id: complexData?.developer }
  });

  const { data: singleRoomData } = useQueryWithStore({
    type: 'getOne',
    resource: room_id ? 'rooms' : '',
    payload: { id: room_id }
  });

  // const roomInfo: any = React.useMemo(() => {
  //   if (sectionData) {
  //     const rooms = calcRooms(sectionData);
  //     return rooms.find(r => Number(r.floor) === Number(floor) && Number(r.rpf) === Number(rpf)) || {};
  //   }
  //   return {};
  // }, [sectionData, floor, rpf]);

  const roomInfo: any = React.useMemo(() => {
    // console.log('---roomInfo calc---', sectionData, singleRoomData);
    if (sectionData && sectionData?.id) {
      const rooms = calcRooms(sectionData);
      return rooms.find(r => Number(r.floor) === Number(floor) && Number(r.rpf) === Number(rpf)) || {};
    } else if (singleRoomData) {
      let photos: any = [];
      if (singleRoomData.roomPhoto) {
        photos = [...photos, ...singleRoomData.roomPhoto];
      }
      if (singleRoomData.planPhoto) {
        photos = [...photos, ...singleRoomData.planPhoto];
      }
      let price = singleRoomData.priceFrom;
      if (singleRoomData.priceTo && singleRoomData.priceFrom !== singleRoomData.priceTo) {
        price = `${Number(singleRoomData.priceFrom).toLocaleString()} - ${Number(singleRoomData.priceTo).toLocaleString()}`
      } else {
        price = `${Number(singleRoomData.priceFrom).toLocaleString()}`;
      }

      let space = singleRoomData.spaceFrom;
      if (singleRoomData.priceTo && singleRoomData.spaceFrom !== singleRoomData.spaceTo) {
        space = `${singleRoomData.spaceFrom} - ${singleRoomData.spaceTo}`
      } else {
        space = `${singleRoomData.spaceFrom}`;
      }

      return {
        rpf: 0,
        floor: 0,
        number: 0,
        floorPhoto: null,
        photo: photos[0],
        type: singleRoomData.type,
        price: singleRoomData.priceFrom,
        space: singleRoomData.spaceFrom,
        priceRange: price,
        spaceRange: space,
        rt: composedRoomTypes[singleRoomData.type || 'unknown'],
      }
    }
    return {};
  }, [sectionData, floor, rpf, singleRoomData]);

  const endDate = React.useMemo(() => {
    try {
      return format(parseISO(buildingData?.dateOfConstruction), 'Q кв. yyyy г.');
    } catch (err) {

    }
    return '';
  }, [buildingData]);

  return (
    <Grid item sm={4} xs={12}>
      <div style={{ width: '100%', overflow: 'hidden' }}>
        <Typography variant="caption">Планировка</Typography>
        <div style={{ height: 300 }}>
          {
            roomInfo?.photo?.src &&
            <img src={composeUrl(roomInfo?.photo?.src, '600')} alt="room" style={{ maxWidth: '95%', maxHeight: 300 }} />
          }
        </div>
        <Typography variant="caption">ЖК</Typography>
        <Typography gutterBottom>{complexData?.name}</Typography>
        <Typography variant="caption">Дата сдачи</Typography>
        <Typography gutterBottom>{endDate}</Typography>
        <Typography variant="caption">Застройщик</Typography>
        <Typography gutterBottom>{developerData?.name}</Typography>
        <Typography variant="caption">Адрес</Typography>
        <Typography gutterBottom>{buildingData?.address}</Typography>
        <Typography variant="caption">Район</Typography>
        <Typography gutterBottom>{districtData?.name}</Typography>
        <Typography variant="caption">Площадь</Typography>
        <Typography gutterBottom>{roomInfo?.space} кв.м.</Typography>
        <Typography variant="caption">Стоимость</Typography>
        <Typography gutterBottom>{Number(roomInfo?.price).toLocaleString()} руб.</Typography>
        <Typography variant="caption">Стоимость за кв.м.</Typography>
        <Typography gutterBottom>{roomInfo?.space && Number(roomInfo?.price / roomInfo?.space).toLocaleString()} руб/кв.м.</Typography>
        <Typography variant="caption">Этаж</Typography>
        <Typography gutterBottom>{roomInfo?.floor}</Typography>
        <Typography variant="caption">Количество комнат</Typography>
        <Typography gutterBottom>{roomInfo?.rt}</Typography>

        <Typography variant="caption">Первый взнос</Typography>
        <Typography gutterBottom>{ipoteka?.ipotekaFirst && Number(ipoteka?.ipotekaFirst).toLocaleString()} руб.</Typography>
        <Typography variant="caption">Процент</Typography>
        <Typography gutterBottom>{ipoteka?.ipotekaPercent}</Typography>
        <Typography variant="caption">Срок мес.</Typography>
        <Typography gutterBottom>{ipoteka?.ipotekaMonths}</Typography>
        <Typography variant="caption">Платеж в месяц</Typography>
        <Typography gutterBottom>{ipoteka?.ipotekaPerMonth && Number(Number(ipoteka?.ipotekaPerMonth).toFixed(2)).toLocaleString()} руб./мес.</Typography>
      </div>
    </Grid>
  )
}

function PaperRoom(props: any) {
  const { data, selected, setSelected } = props;
  const { room } = data;
  const [building, section, floor, rpf, room_id] = (room || '').split('_');

  const { data: buildingData } = useQueryWithStore({
    type: 'getOne',
    resource: building ? 'buildings' : '',
    payload: { id: building }
  });

  const { data: sectionData } = useQueryWithStore({
    type: 'getOne',
    resource: section && section !== '0' ? 'entrances' : '',
    payload: { id: section }
  });

  const { data: complexData } = useQueryWithStore({
    type: 'getOne',
    resource: section ? 'complexes' : '',
    payload: { id: buildingData?.complex }
  });

  const { data: singleRoomData } = useQueryWithStore({
    type: 'getOne',
    resource: room_id ? 'rooms' : '',
    payload: { id: room_id }
  });

  // const roomInfo: any = React.useMemo(() => {
  //   if (sectionData) {
  //     const rooms = calcRooms(sectionData);
  //     return rooms.find(r => Number(r.floor) === Number(floor) && Number(r.rpf) === Number(rpf)) || {};
  //   }
  //   return {};
  // }, [sectionData, floor, rpf]);

  const roomInfo: any = React.useMemo(() => {
    // console.log('---roomInfo calc---', sectionData, singleRoomData);
    if (sectionData && sectionData?.id) {
      const rooms = calcRooms(sectionData);
      return rooms.find(r => Number(r.floor) === Number(floor) && Number(r.rpf) === Number(rpf)) || {};
    } else if (singleRoomData) {
      let photos: any = [];
      if (singleRoomData.roomPhoto) {
        photos = [...photos, ...singleRoomData.roomPhoto];
      }
      if (singleRoomData.planPhoto) {
        photos = [...photos, ...singleRoomData.planPhoto];
      }
      let price = singleRoomData.priceFrom;
      if (singleRoomData.priceTo && singleRoomData.priceFrom !== singleRoomData.priceTo) {
        price = `${Number(singleRoomData.priceFrom).toLocaleString()} - ${Number(singleRoomData.priceTo).toLocaleString()}`
      } else {
        price = `${Number(singleRoomData.priceFrom).toLocaleString()}`;
      }

      let space = singleRoomData.spaceFrom;
      if (singleRoomData.priceTo && singleRoomData.spaceFrom !== singleRoomData.spaceTo) {
        space = `${singleRoomData.spaceFrom} - ${singleRoomData.spaceTo}`
      } else {
        space = `${singleRoomData.spaceFrom}`;
      }

      return {
        rpf: 0,
        floor: 0,
        number: 0,
        floorPhoto: null,
        photo: photos[0],
        type: singleRoomData.type,
        price: singleRoomData.priceFrom,
        space: singleRoomData.spaceFrom,
        priceRange: price,
        spaceRange: space,
        rt: composedRoomTypes[singleRoomData.type || 'unknown'],
      }
    }
    return {};
  }, [sectionData, floor, rpf, singleRoomData]);

  const isSelected = selected.includes(room);

  const handleSelect = () => {
    if (isSelected) {
      setSelected((state: string[]) => state.filter(r => r !== room));
    } else {
      setSelected((state: string[]) => ([...state, room]));
    }
  }

  return (
    <Paper elevation={isSelected ? 3 : 0} onClick={handleSelect}>
      <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {
          roomInfo?.photo?.src &&
          <img src={composeUrl(roomInfo?.photo?.src, '600')} alt="room" style={{ maxWidth: '95%', maxHeight: '95%' }} />
        }
      </div>
    </Paper>
  )
}

