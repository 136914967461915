import React from 'react';
import { useQueryWithStore, usePermissions } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { complexStat, composedRoomTypes } from 'search/SearchData';

import { ViewContext, BuildingContext } from './Context';

import Header from '../Header';
import TitleCard from './TitleCard';
import Photos from './Photos';
import Rooms from './Rooms';
import RoomsGrid from './RoomsGrid';
import Map from './Map';
import Description from './Description';
import Properties from './Properties';
import Advantages from './Advantages';
import Benefits from './Benefits';
import Actions from './Actions';
import Genplan from './Genplan';
import PricesGraph from './PricesGraph';
import BuildingsPhotos from './BuildingsPhoto';
import Ipoteka from './Ipoteka';
import Documents from './Documents';

import ComplexInfoSmall from './ComplexInfoSmall';
import ActionSmall from './ActionSmall';

const useStyles = makeStyles((theme) => ({
  container: {
    background: '#F5F5F5',
    width: '100vw',
    minHeight: '100vh',
  },
  innerContainer: {
    padding: '0 24px',
  },
}));

function ComplexInfo(props: any) {
  const { selected } = props;
  const viewState = React.useState('broker');
  const [selectedBuilding, setSelectedBuilding] = React.useState('');

  const { permissions } = usePermissions();
  const classes = useStyles();

  const { data: statInfo, loaded: statLoaded } = useQueryWithStore({
    type: 'getOne',
    resource: selected ? 'rooms_search' : '',
    payload: { id: selected }
  });

  const { data: complexInfo, loaded: complexLoaded } = useQueryWithStore({
    type: 'getOne',
    resource: statLoaded && selected ? 'complexes' : '',
    payload: { id: selected }
  });

  const { data: developerInfo, loaded: developerLoaded } = useQueryWithStore({
    type: 'getOne',
    resource: complexInfo?.developer ? 'developers' : '',
    payload: { id: complexInfo?.developer }
  });

  const { data: buildingsData, loaded: buildingsDataLoaded } = useQueryWithStore({
    type: 'getList',
    resource: complexInfo?.id ? 'buildings' : '',
    payload: {
      pagination: { page: 1, perPage: 1000 },
      filter: { active: true, complex: complexInfo?.id },
      sort: { field: 'name', order: 'ASC' }
    }
  });

  const statData = React.useMemo(() => statLoaded && complexStat(statInfo), [statInfo, statLoaded]);

  const roomsStat = React.useMemo(() => {
    if (selectedBuilding) {
      const buildingId = `${selectedBuilding}`.replace('b_', '');
      const m: any = {};
      if (!statData.common) {
        Object.entries(statData).forEach(([kk, vv]: any) => {
          if (`${kk}`.startsWith('e_') && vv?.building === buildingId) {
            Object.entries(vv).forEach(([k, v]: any) => {
              if (`${k}`.startsWith('r_') && !`${k}`.includes('_unknown')) {
                const rt = composedRoomTypes[`${k}`.replace('r_', '')] || '0';
                if (!m[rt]) {
                  m[rt] = v;
                } else {
                  const r = m[rt];
                  r.num += v.num || 0;
                  r.p_min = r.p_min < v.p_min ? r.p_min : v.p_min;
                  r.s_min = r.s_min < v.s_min ? r.s_min : v.s_min;
                  r.p_max = r.p_max > v.p_max ? r.p_max : v.p_max;
                  r.s_max = r.s_max > v.s_max ? r.s_max : v.s_max;
                }
              }
            });
          }
        });
      } else {
        Object.entries(statData).forEach(([k, v]: any) => {
          if (`${k}`.startsWith('rs_') && v?.building === buildingId) {
            // if (`${k}`.startsWith('rs_')) {
            const rt = composedRoomTypes[v?.type] || '0';
            if (!m[rt]) {
              m[rt] = v;
            } else {
              const r = m[rt];
              r.num += v.num || 0;
              r.p_min = r.p_min < v.p_min ? r.p_min : v.p_min;
              r.s_min = r.s_min < v.s_min ? r.s_min : v.s_min;
              r.p_max = r.p_max > v.p_max ? r.p_max : v.p_max;
              r.s_max = r.s_max > v.s_max ? r.s_max : v.s_max;
            }
          }
        });
      }
      return m;
    }
    return {};
  }, [statData, selectedBuilding]);

  React.useEffect(() => {
    if (buildingsData && buildingsData.length && !selectedBuilding) {
      setSelectedBuilding(buildingsData[0]?.id);
    }
  }, [buildingsData, selectedBuilding]);

  const loading = !(statLoaded && complexLoaded && developerLoaded && buildingsDataLoaded);

  const allowLink = React.useMemo(() => {
    if (permissions && complexInfo) {
      const { city, role } = permissions;
      if (['admin', 'superofficemanager'].includes(role)) {
        return true;
      }
      if (['officemanager'].includes(role)) {
        return complexInfo?.city === city;
      }
    }
    return false;
  }, [permissions, complexInfo])

  if (!permissions) {
    return null;
  }

  return (
    <ViewContext.Provider value={viewState}>
      <BuildingContext.Provider value={[selectedBuilding, setSelectedBuilding]}>
        <div className={classes.container}>
          <div className={classes.innerContainer}>
            <Header />
            <Grid container>
              <Grid item xs={9}>
                <TitleCard name={`ЖК ${statData?.name}`} addr={statData?.addr && statData?.addr[0]} price={Number(statData?.priceFrom).toLocaleString()} count={statData?.totalRooms} />
                <Photos statData={statData} />
                <Rooms buildings={buildingsData} roomsStat={roomsStat} />
                <RoomsGrid building={selectedBuilding} />
                <Ipoteka />
                <Map complex={complexInfo} />
                <Description complex={complexInfo} photo={statData?.photos[0]} />
                <Properties building={selectedBuilding} />
                <Advantages building={selectedBuilding} />
                <Benefits building={selectedBuilding} />
                <Actions building={selectedBuilding} />
                <Genplan complex={complexInfo} />
                <PricesGraph buildings={buildingsData} building={selectedBuilding} />
                <BuildingsPhotos statData={statData} />

                <Documents />
              </Grid>
              <Grid item xs={3}>
                <ComplexInfoSmall complex={complexInfo} developer={developerInfo} />
                <ActionSmall />
              </Grid>
            </Grid>
          </div>
        </div>
      </BuildingContext.Provider>
    </ViewContext.Provider>
  )
}

export default ComplexInfo;