import React from 'react';
import 'react-image-lightbox/style.css';
import firebase from 'firebase/app';
import 'firebase/firestore';
import preval from 'preval.macro';
import Typography from '@material-ui/core/Typography';

import { Admin } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import russianMessages from 'ra-language-russian';
import ruStrings from 'i18n/strings';
import ResourceWithPermissions from 'components/ResourceWithPermissions';
import {
  FirebaseAuthProvider,
  FirebaseDataProvider,
  RAFirebaseOptions,
} from 'react-admin-firebase';

import customAuthProvider from 'components/auth-provider';
import customDataProvider from 'components/data-provider';
import searchReducer from 'reducers/search-slice';

import { firebaseConfig as config } from './FIREBASE_CONFIG';

import AppLayout from 'layout/AppLayout';
import Search from 'search/Search';
import ObjectViewContext from './ObjectViewContext';
import customRoutes from './customRoutes';

import * as countryResource from 'resources/country-res';
import * as regionResource from 'resources/region-res';
import * as cityResource from 'resources/city-res';
import * as districtResource from 'resources/district-res';
import * as developerResource from 'resources/developer-res';
import * as buildingResource from 'resources/building-res';
import * as bankResource from 'resources/bank-res';
import * as accreditationResource from 'resources/accreditation-res';
import * as materialResource from 'resources/material-res';
import * as heatingResource from 'resources/heating-res';
import * as canalizationResource from 'resources/canalization-res';
import * as buildingstateResource from 'resources/buildingstate-res';
import * as buildigtechnologyResource from 'resources/buildingtechnology-res';
import * as liftbrandResource from 'resources/liftbrand-res';
import * as complexResource from 'resources/complex-res';
import * as roomtypeResource from 'resources/roomtype-res';
import * as entranceResource from 'resources/entrance-res';
import * as officeResource from 'resources/office-res';
import * as userResource from 'resources/user-res';
import * as actionResource from 'resources/action-res';
import * as ipotekastatusResource from 'resources/ipotekastatus-res';
import * as liststatusResource from 'resources/liststatus-res';
import * as listsourceResource from 'resources/listsource-res';
import * as selltypeResource from 'resources/selltype-res';
import * as strahResource from 'resources/strah-res';
import * as list2Resource from 'resources/list2-res';
import * as ipotekalistResource from 'resources/ipotekalits-res';
import * as listResource from 'resources/list-res';
import * as roomResource from 'resources/room-res';
import * as roomaddResource from 'resources/roomadd-res';
import * as secondRoomResource from 'resources/secondroom-res';
import * as taskResource from 'resources/task-res';
import * as bankrequestResource from 'resources/bankrequest-res';
import * as compareResource from 'resources/compare-res';
import * as strahrequestResource from 'resources/strahrequest-res';
import * as ipotekaProgramResource from 'resources/iprogram-res';
import * as roomReserveResource from 'resources/reserve-res';
import * as mainDistrictResource from 'resources/main_district-res';
import * as metroLineResource from 'resources/metro_line-res';
import * as metroStationResource from 'resources/metro_station-res';
import * as amostatResource from 'resources/amostat-res';
import * as amouserResource from 'resources/amousers-res';
import * as amopipelineResource from 'resources/amopipeline-res';

import ruMessages from 'devextreme/localization/messages/ru.json';
import { locale, loadMessages } from 'devextreme/localization';

const dateTimeStamp = preval`module.exports = new Date().toLocaleString();`

russianMessages.ra.navigation.skip_nav = 'SKIP_NAV';

const i18nProvider = polyglotI18nProvider(
  () => ({ ...russianMessages, ...ruStrings }),
  'ru'
);

const options: RAFirebaseOptions = {
  logging: false,
  persistence: 'local'
};

const dataProvider = customDataProvider(FirebaseDataProvider(config, options));
const authProvider = customAuthProvider(FirebaseAuthProvider(config, options));

// if (window.location.hostname === 'localhost') {
//   try {
//     const db = firebase.firestore();
//     db.settings({
//       host: 'localhost:8080',
//       ssl: false,
//     });
//   } catch (ex) { }
// }

function App() {
  const [list, setList] = React.useState('');

  React.useEffect(() => {
    loadMessages(ruMessages);
    locale(navigator.language);
  }, []);

  return (
    <ObjectViewContext.Provider value={{ list, setList }}>
      <Typography variant="subtitle2" style={{ position: "absolute", left: 5, top: 0, fontSize: 9, color: 'white', zIndex: 100000 }}>{process.env.REACT_APP_VERSION} ({dateTimeStamp})</Typography>
      <Admin
        layout={AppLayout}
        dashboard={Search}
        dataProvider={dataProvider}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
        customRoutes={customRoutes}
        customReducers={{ search: searchReducer }}
        disableTelemetry
      >
        <ResourceWithPermissions {...countryResource} />
        <ResourceWithPermissions {...regionResource} />
        <ResourceWithPermissions {...cityResource} />
        <ResourceWithPermissions {...mainDistrictResource} />
        <ResourceWithPermissions {...districtResource} />
        <ResourceWithPermissions {...developerResource} />
        <ResourceWithPermissions {...complexResource} />
        <ResourceWithPermissions {...bankResource} />
        <ResourceWithPermissions {...accreditationResource} />
        <ResourceWithPermissions {...materialResource} />
        <ResourceWithPermissions {...heatingResource} />
        <ResourceWithPermissions {...canalizationResource} />
        <ResourceWithPermissions {...buildingstateResource} />
        <ResourceWithPermissions {...buildigtechnologyResource} />
        <ResourceWithPermissions {...liftbrandResource} />
        <ResourceWithPermissions {...buildingResource} />
        <ResourceWithPermissions {...roomtypeResource} />
        <ResourceWithPermissions {...entranceResource} />
        <ResourceWithPermissions {...officeResource} />
        <ResourceWithPermissions {...userResource} />
        <ResourceWithPermissions {...actionResource} />
        <ResourceWithPermissions {...ipotekastatusResource} />
        <ResourceWithPermissions {...liststatusResource} />
        <ResourceWithPermissions {...listsourceResource} />
        <ResourceWithPermissions {...selltypeResource} />
        <ResourceWithPermissions {...strahResource} />
        <ResourceWithPermissions {...list2Resource} />
        <ResourceWithPermissions {...ipotekalistResource} />
        <ResourceWithPermissions {...listResource} />
        <ResourceWithPermissions {...roomResource} />
        <ResourceWithPermissions {...roomaddResource} />
        <ResourceWithPermissions {...secondRoomResource} />
        <ResourceWithPermissions {...taskResource} />
        <ResourceWithPermissions {...bankrequestResource} />
        <ResourceWithPermissions {...compareResource} />
        <ResourceWithPermissions {...strahrequestResource} />
        <ResourceWithPermissions {...ipotekaProgramResource} />
        <ResourceWithPermissions {...roomReserveResource} />
        <ResourceWithPermissions {...metroLineResource} />
        <ResourceWithPermissions {...metroStationResource} />
        <ResourceWithPermissions {...amostatResource} />
        <ResourceWithPermissions {...amouserResource} />
        <ResourceWithPermissions {...amopipelineResource} />
      </Admin>
    </ObjectViewContext.Provider>
  );
}

export default App;
