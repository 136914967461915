// import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  Filter,
  ReferenceInput,
  BooleanInput,
  SelectInput,
} from 'react-admin';

export const name = 'amo_users';


const ResList = (props: any) => {
  const { permissions } = props;
  const { city } = permissions || {};

  if (!city) {
    return null;
  }

  return (
    <List
      {...props}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="email" />
        <TextField source="name" />
      </Datagrid>
    </List>
  );
};

export {
  ResList as list
};
