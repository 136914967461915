import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';


import Typography from '@material-ui/core/Typography';


import { useDispatch, useSelector } from 'react-redux';
import { setCity, setCitiesData, setDistricts, setComplexesData, setComplexes, setRoomTypes, setPriceFrom, setPriceTo, setSpaceFrom, setSpaceTo, setSellDate, setDealtype } from 'reducers/search-slice';
import { useQueryWithStore, usePermissions } from 'react-admin';

import { SelectControl, TextControl, getSellDates } from './Controls';
import { dealtypeChoises } from 'resources/entrance-res';

import { ViewContext } from './Context';

import Header from './Header';

import Map from './Map';
import Cards from './Cards';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100vh',
    position: 'fixed',
    background: '#F5F5F5',
    overflow: 'hidden',
  },
  header: {
    height: 44,
    background: '#FFFFFF',
    borderRadius: '0 0 10px 10px',
    margin: '0 24px 16px',
    // padding: '16'
  },
  main: {
    background: '#FFFFFF',
    borderRadius: '10px 10px 0 0',
    display: 'flex',
    position: 'relative',
    height: `calc(100% - ${44 + 2 * 16 + 16 + 16 + 1}px)`,
    margin: '0 24px',
    padding: '16px 16px 0',
  },
  filter: {
    width: 316,
    height: '100%',
  },
  cards: {
    width: 632,
    height: '100%',
    overflowY: 'scroll'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '85%',
  },
  card: {
    position: 'relative',
    width: 278,
    height: 498,
    borderRadius: 8,
    // background: '#ccc',
    margin: '12px 12px 12px 24px',
    boxShadow: '2px 2px 9px 2px #999'
  }
}));

function Search2() {
  const [viewMode, setViewMode] = React.useState('map');
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const is_sm = useMediaQuery(theme.breakpoints.up('sm'));

  const { permissions = {}, loaded } = usePermissions();
  const { city } = permissions;

  React.useEffect(() => {
    if (city) {
      dispatch(setCity(city));
    }
  }, [city, dispatch]);

  if (!loaded) {
    return null;
  }

  return (
    <div className={classes.container}>
      <header className={classes.header}><Header /></header>
      <div style={{ height: 32 }} />
      <ViewContext.Provider value={[viewMode, setViewMode]}>
        <main className={classes.main}>
          <div className={classes.filter}>
            <div style={{ height: 60, marginLeft: 8 }}><Typography variant="h6">Фильтр</Typography></div>
            <div style={{ overflowY: 'scroll', height: 'calc(100% - 30px)' }}>
              <SearchType />
              <City />
              <District />
              <Complex />
              <RoomTypes />
              <SellDate />
              <PriceFrom />
              <PriceTo />
              <SpaceFrom />
              <SpaceTo />
              <Dealtype />
              <MainDistrict />
              <Appartments />
              <MetroLine />
              <MetroStation />
              <StartSellDate />
              <PaymentType />
              <Otdelka />
              <div style={{ height: 32 }} />
            </div>
          </div>
          {/* <div className={classes.map}><Map /></div> */}
          {
            viewMode === 'map' && <Map />
          }
          <Cards />
        </main>
      </ViewContext.Provider>
    </div>
  )
}

export default Search2;



function City() {
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.search);
  const { city } = state;
  const { data, loaded } = useQueryWithStore({
    type: 'getList',
    resource: 'cities',
    payload: { pagination: { page: 1, perPage: 1000 }, filter: { active: true }, sort: { field: 'name', order: 'ASC' } }
  });

  const handleCityChange = React.useCallback(
    (value) => {
      dispatch(setCity(value));
    },
    [dispatch]
  );

  React.useEffect(() => {
    if (loaded && data) {
      dispatch(setCitiesData(data));
    }
  }, [data, loaded, dispatch]);
  return (
    <SelectControl value={city} onChange={handleCityChange} label="Город" data={data} allowClear={false} />
  )
}

export const District = () => {
  const dispatch = useDispatch();
  const { districts, city } = useSelector((state: any) => state.search);
  const { data, loaded } = useQueryWithStore({
    type: 'getList',
    resource: city ? 'districts' : '',
    payload: { pagination: { page: 1, perPage: 1000 }, filter: { active: true, city }, sort: { field: 'name', order: 'ASC' } }
  });

  const handleDistrictChange = React.useCallback(
    (value: any) => {
      dispatch(setDistricts(value));
    },
    [dispatch]
  );

  return <SelectControl value={districts} onChange={handleDistrictChange} label="Район" data={data} multiple />
}

export const Complex = () => {
  const dispatch = useDispatch();
  const { complexes, districts, city } = useSelector((state: any) => state.search);

  const { data, loaded } = useQueryWithStore({
    type: 'getList',
    resource: city ? 'rooms_search' : '',
    payload: {
      pagination: { page: 1, perPage: 1000 },
      filter: { active: true, city },
      sort: { field: 'name', order: 'ASC' }
    }
  });

  React.useEffect(() => {
    if (loaded && data) {
      dispatch(setComplexesData(data));
    }
  }, [data, loaded, dispatch]);

  const complexData = React.useMemo(() => {
    if (data && Array.isArray(districts) && districts.length) {
      return data.filter((d: any) => districts.includes(d.district));
    }
    return data;
  }, [districts, data]);

  const handleComplexChange = React.useCallback(
    (value) => {
      dispatch(setComplexes(value));
    },
    [dispatch]
  );

  return <SelectControl value={complexes} onChange={handleComplexChange} label="ЖК" data={complexData} multiple />
}

export const RoomTypes = () => {
  const dispatch = useDispatch();
  const { roomTypes } = useSelector((state: any) => state.search);

  const handleRoomTypeChange = React.useCallback(
    (value) => {
      dispatch(setRoomTypes(value));
    },
    [dispatch]
  );

  const data = React.useMemo(() => {
    return [
      { id: 'C', name: 'C' },
      { id: '1', name: '1k' },
      { id: '2', name: '2k' },
      { id: '3', name: '3k' },
      { id: '4+', name: '4k+' },
    ];
  }, []);

  return <SelectControl value={roomTypes} onChange={handleRoomTypeChange} label="Тип Квартиры" data={data} multiple />
}

export const PriceFrom = () => {
  const dispatch = useDispatch();
  const { priceFrom } = useSelector((state: any) => state.search);

  const handlePriceFrom = React.useCallback(
    (value) => {
      dispatch(setPriceFrom(value));
    },
    [dispatch]
  );

  return <TextControl label="Цена от" value={priceFrom} onChange={handlePriceFrom} />
}

export const PriceTo = () => {
  const dispatch = useDispatch();
  const { priceTo } = useSelector((state: any) => state.search);

  const handlePriceTo = React.useCallback(
    (value) => {
      dispatch(setPriceTo(value));
    },
    [dispatch]
  );

  return <TextControl label="Цена до" value={priceTo} onChange={handlePriceTo} />
}

export const SpaceFrom = () => {
  const dispatch = useDispatch();
  const { spaceFrom } = useSelector((state: any) => state.search);

  const handleSpaceFrom = React.useCallback(
    (value) => {
      dispatch(setSpaceFrom(value));
    },
    [dispatch]
  );

  return <TextControl label="Площадь от" value={spaceFrom} onChange={handleSpaceFrom} />
}

export const SpaceTo = () => {
  const dispatch = useDispatch();
  const { priceTo } = useSelector((state: any) => state.search);

  const handleSpaceTo = React.useCallback(
    (value) => {
      dispatch(setSpaceTo(value));
    },
    [dispatch]
  );

  return <TextControl label="Площадь до" value={priceTo} onChange={handleSpaceTo} />
}

export const SellDate = () => {
  const dispatch = useDispatch();
  const { sellDate } = useSelector((state: any) => state.search);

  const handleSellDateChange = React.useCallback(
    (value) => {
      dispatch(setSellDate(value));
    },
    [dispatch]
  );

  const data = React.useMemo(() => getSellDates(), []);

  return <SelectControl value={sellDate} onChange={handleSellDateChange} label="Срок Сдачи" data={data} />
}

export const Dealtype = () => {
  const dispatch = useDispatch();
  const { dealtype } = useSelector((state: any) => state.search);

  const handledealtypeChange = React.useCallback(
    (value) => {
      dispatch(setDealtype(value));
    },
    [dispatch]
  );

  return <SelectControl value={dealtype} onChange={handledealtypeChange} label="Тип Сделки" data={dealtypeChoises} />
}


function SearchType() {
  const [value, setValue] = React.useState('0');

  const data = React.useMemo(() => {
    return [
      { id: '0', name: 'Новостройки' },
      { id: '1', name: 'Вторичная недвижимость' },
      { id: '2', name: 'Коммерческая недвижимость' },
      { id: '3', name: 'Загородная недвижимость' }
    ]
  }, [])

  return <SelectControl value={value} onChange={setValue} label="Вид недвижимости" data={data} allowClear={false} />
}

function MainDistrict() {
  const [value, setValue] = React.useState('');

  const data = React.useMemo(() => {
    return [

    ]
  }, [])

  return <SelectControl value={value} onChange={setValue} label="Округ" data={data} allowClear={false} disabled />
}

function Appartments() {
  const [value, setValue] = React.useState('1');

  const data = React.useMemo(() => {
    return [
      { id: '0', name: 'Да' },
      { id: '1', name: 'Нет' },
    ]
  }, [])

  return <SelectControl value={value} onChange={setValue} label="Аппартаменты" data={data} allowClear={false} />
}

function MetroLine() {
  const [value, setValue] = React.useState('');

  const data = React.useMemo(() => {
    return [

    ]
  }, [])

  return <SelectControl value={value} onChange={setValue} label="Линия метро" data={data} allowClear={false} disabled />
}


function MetroStation() {
  const [value, setValue] = React.useState('');

  const data = React.useMemo(() => {
    return [

    ]
  }, [])

  return <SelectControl value={value} onChange={setValue} label="Станция метро" data={data} allowClear={false} disabled />
}

function StartSellDate() {
  const [value, setValue] = React.useState('');

  const data = React.useMemo(() => {
    return [

    ]
  }, [])

  return <SelectControl value={value} onChange={setValue} label="Старт продаж" data={data} allowClear={false} disabled />
}

function PaymentType() {
  const [value, setValue] = React.useState('');

  const data = React.useMemo(() => {
    return [

    ]
  }, [])

  return <SelectControl value={value} onChange={setValue} label="Способы оплаты" data={data} allowClear={false} disabled />
}

function Otdelka() {
  const [value, setValue] = React.useState('');

  const data = React.useMemo(() => {
    return [

    ]
  }, [])

  return <SelectControl value={value} onChange={setValue} label="Отделка" data={data} allowClear={false} disabled />
}