import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    // borderRight: '1px solid #E0E0E0',
    width: 175,
    // paddingRight: 35,
    // paddingLeft: 35,
    // '&:last-child': {
    //   paddingRight: 0,
    //   borderRight: 0
    // },
    // '&:first-child': {
    //   paddingLeft: 0
    // }
  },
  image: {
    backgroundColor: '#F5F5F5',
    marginRight: 8
  },
  title: {
    color: '#828282'
  }
}));

function TitleIcon(props: any) {
  const {
    img,
    title,
    text
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Avatar className={classes.image}>
        <img src={img} alt="icon" />
      </Avatar>
      <div>
        <Typography variant="body2" className={classes.title}>{title}</Typography>
        <Typography variant="body1">{text}</Typography>
      </div>
    </div>
  )

}

export default TitleIcon;

export function IconLine() {
  return <div style={{ border: '1px solid #E0E0E0' }} />
}