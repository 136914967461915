import React from 'react';

export const ViewContext = React.createContext<any>([]);

export const BuildingContext = React.createContext<any>('');

export const FiltersContext = React.createContext<any>({
  roomsFilter: [],
  ownerFilter: [],
});

export const viewModes = [
  { id: 'client', name: 'КЛИЕНТ' },
  { id: 'broker', name: 'БРОКЕР' }
]