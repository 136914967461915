import React from 'react';
import format from 'date-fns/format';
import { makeStyles } from '@material-ui/core/styles';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// import Input from '@material-ui/core/Input';
// import TextField from '@material-ui/core/TextField';
// import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { simpleRoomTypes, complexStat, composedRoomTypes } from './SearchData';

import { useDispatch, useSelector } from 'react-redux';
import { setObject, setViewMap } from 'reducers/search-slice';
import { useQueryWithStore } from 'react-admin';
import { composeUrl } from 'libs/parseHelper';

import LocationOnIcon from '@material-ui/icons/LocationOn';

import SearchViewContext from './SearchViewContext';

const complexItemHeight = 550 * 0.7;

const complexItemHeight_list = 250;

const useStyles = makeStyles((theme) => ({
  container: {
    flex: '0 0 auto',
    width: 700,
    background: 'silver',
    [theme.breakpoints.up('md')]: {
      width: 350
    },
    [theme.breakpoints.up('lg')]: {
      width: 700
    }
  },
  listContainer: {
    flex: 1,
    background: 'silver'
  },
  complexItem: {
    height: complexItemHeight,
    // cursor: 'pointer',
    margin: '0 5px 10px',
    // display: 'flex-inline',
    position: 'relative'
  },
  complexItem_list: {
    // height: complexItemHeight_list,
    // cursor: 'pointer',
    margin: '0 5px 10px',
    // display: 'flex-inline',
    position: 'relative'
  },
  media: {
    height: 250 * 0.7,
  },
  container0: {
    // paddingBottom: 10,
    // height: complexItemHeight + 10,
    // overflow: 'hidden',
  },
  container2: {
    height: complexItemHeight,
    width: 'calc(50% - 10px)',
    display: 'inline-block',
    // background: 'green',
    padding: '0 5px',
  },
  greyText: {
    color: 'grey',
  },
  blueText: {
    color: '#4682B4',
    textDecoration: 'none'
  },
  formControl: {
    // padding: '0 5px',
    margin: 5,
    // width: '100%',
    background: 'white',
  },
  geoIcon: {
    position: 'absolute',
    bottom: 5,
    right: 5,
    cursor: 'pointer'
  },
}));

const SearchDetails = React.memo(() => {
  const classes = useStyles();
  const { complexes: selectedComplexes, districts: selectedDistricts, priceFrom, priceTo, spaceFrom, spaceTo, roomTypes: selectedRoomTypes } = useSelector((state: any) => state.search);
  const viewMode = React.useContext(SearchViewContext);
  const data = useSelector((state: any) => state.search.secondRoomsData);


  const roomsData = React.useMemo(() => {
    // return data?.map((d: any) => complexStat(d)).filter((c: any) => Number(c.priceFrom) > 0);
    // return data?.map((d: any) => complexStat(d));
    return data;
  }, [data]);

  const rooms = React.useMemo(() => {
    if (!roomsData) {
      return null;
    }

    let filtered = roomsData;
    if (selectedDistricts && selectedDistricts.length) {
      filtered = filtered.filter((c: any) =>
        selectedDistricts.includes(c.district)
      );
    }

    if (selectedComplexes && selectedComplexes.length) {
      filtered = filtered.filter((c: any) =>
        selectedComplexes.includes(c.id)
      );
    }

    if (selectedRoomTypes && selectedRoomTypes.length) {
      filtered = filtered.filter((c: any) =>
        selectedRoomTypes.includes(composedRoomTypes[c.roomtype])
      );
    }

    if (priceFrom) {
      filtered = filtered.filter((c: any) => Number(c.price) > Number(priceFrom));
    }

    if (priceTo) {
      filtered = filtered.filter((c: any) => Number(c.price) < Number(priceTo));
    }

    if (spaceFrom) {
      filtered = filtered.filter((c: any) => Number(c.space_total) > Number(spaceFrom));
    }

    if (spaceTo) {
      filtered = filtered.filter((c: any) => Number(c.space_total) < Number(spaceTo));
    }

    return filtered;
  }, [
    roomsData,
    selectedComplexes,
    selectedDistricts,
    selectedRoomTypes,
    priceFrom,
    priceTo,
    spaceFrom,
    spaceTo
  ]);

  return (
    <div className={viewMode === 'list' ? classes.listContainer : classes.container}>
      {
        (rooms) &&
        <RoomsList rooms={rooms} />
      }
    </div>
  );
});

const sortVariants = [
  { id: '0', name: 'По умолчанию' },
  { id: '1', name: 'По цене, сначала дешевые' },
  { id: '2', name: 'По цене, сначала дорогие' },
  { id: '3', name: 'По площади, сначала малые' },
  { id: '4', name: 'По площади, сначала большие' },
  { id: '5', name: 'По дате, сначала новые' },
  { id: '6', name: 'По дате, сначала старые' }
];

const RoomsList = React.memo((props: any) => {
  const theme = useTheme();
  const is_lg = useMediaQuery(theme.breakpoints.up('lg'));
  const [sort, setSort] = React.useState('0');
  const { rooms: baseRooms } = props;
  const classes = useStyles();
  const viewMode = React.useContext(SearchViewContext);

  const rooms = React.useMemo(() => {
    if (sort === '0') {
      return baseRooms;
    }
    const obj = [...baseRooms];
    if (sort === '1') {
      obj.sort((a, b) => a.price - b.price);
    }
    if (sort === '2') {
      obj.sort((a, b) => b.price - a.price);
    }
    if (sort === '3') {
      obj.sort((a, b) => a.space_total - b.space_total);
    }
    if (sort === '4') {
      obj.sort((a, b) => b.space_total - a.space_total);
    }
    if (sort === '6') {
      obj.sort((a, b) => a.createdate.toMillis() - b.createdate.toMillis());
    }
    if (sort === '5') {
      obj.sort((a, b) => b.createdate.toMillis() - a.createdate.toMillis());
    }
    return obj;
  }, [sort, baseRooms]);

  if (viewMode === 'list') {
    return (
      <>
        <FormControl className={classes.formControl} style={{ position: 'absolute', left: 0, top: 0, zIndex: 1 }}>
          <InputLabel>Сортировка</InputLabel>
          <Select
            value={sort}
            onChange={(event: any) => setSort(event.target.value)}
          >
            {sortVariants.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <AutoSizer>
          {({ height, width }) => (
            <List
              height={height}
              width={width}
              itemSize={complexItemHeight_list + 10}
              itemCount={Math.ceil(rooms.length)}
            >
              {({ index, style }) => (
                <div style={style}>
                  <RoomItemList
                    data={rooms[index]}
                  />
                </div>
              )}
            </List>
          )}
        </AutoSizer>
      </>
    )
  }

  return (
    <>
      <FormControl className={classes.formControl} style={{ position: 'absolute', left: 0, top: 0, zIndex: 1 }}>
        <InputLabel>Сортировка</InputLabel>
        <Select
          value={sort}
          onChange={(event: any) => setSort(event.target.value)}
        >
          {sortVariants.map((c) => (
            <MenuItem key={c.id} value={c.id}>
              {c.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {
        !is_lg ? (
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                width={width}
                itemSize={complexItemHeight + 10}
                itemCount={Math.ceil(rooms.length)}
              >
                {({ index, style }) => (
                  <div style={style}>
                    <RoomItem
                      data={rooms[index]}
                    />
                  </div>
                )}
              </List>
            )}
          </AutoSizer>
        ) : (
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                width={width}
                itemSize={complexItemHeight + 10}
                itemCount={Math.ceil(rooms.length / 2)}
              >
                {({ index, style }) => (
                  <div style={style}>
                    <Grid container>
                      <Grid item md={6}>
                        <RoomItem
                          data={rooms[2 * index]}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <RoomItem
                          data={rooms[2 * index + 1]}
                        />
                      </Grid>
                    </Grid>
                  </div>
                )}
              </List>
            )}
          </AutoSizer>
        )
      }

    </>
  );
});

export default SearchDetails;

export const RoomItem = React.memo((props: any) => {
  const { data } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const { geo, price, address, roomtype, photo, district, space_total, space_kitchen, space_live, floor, buildingStages, agent, office, createdate } = data || {};

  const handleClick = React.useCallback(() => {
    if (geo) {
      // console.log('---selected complex---', data.id);
      dispatch(setViewMap(geo))
    }
  }, [dispatch, geo]);

  if (!data) {
    return null;
  }

  // return (
  //   <Card className={classes.complexItem}>
  //     <pre>
  //       {JSON.stringify(data, null, ' ')}
  //     </pre>
  //   </Card>
  // );

  return (
    <Card className={classes.complexItem} >
      <CardMedia className={classes.media} image={composeUrl(photo && photo[0]?.src, '600')} title={data.name} />
      <CardContent style={{ transform: 'scaleX(0.7) scaleY(0.7)', transformOrigin: 'left top' }}>
        <Typography gutterBottom variant="h6">
          <b>{Number(price).toLocaleString()} руб. </b>
        </Typography>

        <Typography gutterBottom>
          <Link to={{ pathname: '/secondroominfo', search: `?id=${data.id}` }} target="_blank" className={classes.blueText}><b><RoomType id={roomtype} /></b></Link>
        </Typography>
        <Typography variant="subtitle2">{address}</Typography>
        <Typography variant="subtitle2" gutterBottom>
          {district && <District id={district} />}
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          Площадь: {space_total} | {space_kitchen} | {space_live} кв.м.
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          Этаж: {floor ?? 1} / {buildingStages ?? 1}
        </Typography>
        <AgentInfo id={agent} />
        <OfficeInfo id={office} />
        <DateInfo id={createdate} />
      </CardContent>
      {
        geo && <LocationOnIcon onClick={handleClick} className={classes.geoIcon} />
      }
    </Card>
  );
});

export const RoomItemList = React.memo((props: any) => {
  const { data } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const { geo, price, address, roomtype, photo, district, space_total, space_kitchen, space_live, floor, buildingStages, agent, office, createdate } = data || {};

  const handleClick = React.useCallback(() => {
    if (geo) {
      // console.log('---selected complex---', data.id);
      dispatch(setViewMap(geo))
    }
  }, [dispatch, geo]);

  if (!data) {
    return null;
  }

  // return (
  //   <Card className={classes.complexItem}>
  //     <pre>
  //       {JSON.stringify(data, null, ' ')}
  //     </pre>
  //   </Card>
  // );

  return (
    <Card className={classes.complexItem_list}>
      <Grid container>
        <Grid item sm={4} xs={12}>
          <CardMedia className={classes.media} image={composeUrl(photo && photo[0]?.src, '600')} title={data.name} />
        </Grid>
        <Grid item sm={4} xs={12}>
          <CardContent>
            <Typography gutterBottom variant="h6">
              <b>{Number(price).toLocaleString()} руб. </b>
            </Typography>
            <Typography gutterBottom>
              <Link to={{ pathname: '/secondroominfo', search: `?id=${data.id}` }} target="_blank" className={classes.blueText}><b><RoomType id={roomtype} /></b></Link>
            </Typography>
            <Typography variant="subtitle2">{address}</Typography>
            <Typography variant="subtitle2" gutterBottom>
              {district && <District id={district} />}
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Площадь: {space_total} | {space_kitchen} | {space_live} кв.м.
            </Typography>
            <Typography variant="subtitle2" gutterBottom>
              Этаж: {floor ?? 1} / {buildingStages ?? 1}
            </Typography>
          </CardContent>
        </Grid>
        <Grid item sm={4} xs={12}>
          <CardContent>
            <AgentInfo id={agent} />
            <OfficeInfo id={office} />
            <DateInfo id={createdate} />
          </CardContent>
        </Grid>
      </Grid>
      {/* <CardMedia className={classes.media} image={composeUrl(photo && photo[0]?.src, '600')} title={data.name} />
      <CardContent>
        <Typography gutterBottom variant="h6">
          <b>{Number(price).toLocaleString()} руб. </b>
        </Typography>

        <Typography gutterBottom>
          <Link to={{ pathname: '/secondroominfo', search: `?id=${data.id}` }} target="_blank" className={classes.blueText}><b><RoomType id={roomtype} /></b></Link>
        </Typography>
        <Typography variant="subtitle2">{address}</Typography>
        <Typography variant="subtitle2" gutterBottom>
          {district && <District id={district} />}
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          Площадь: {space_total} | {space_kitchen} | {space_live} кв.м.
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          Этаж: {floor ?? 1} / {buildingStages ?? 1}
        </Typography>
        <AgentInfo id={agent} />
        <OfficeInfo id={office} />
        <DateInfo id={createdate} />
      </CardContent>
      {
        geo && <LocationOnIcon onClick={handleClick} className={classes.geoIcon} />
      } */}
    </Card>
  );
});


function District(props: any) {
  // const { } = useSelector((state: any) => )
  const { id } = props;
  const { data, loaded } = useQueryWithStore({
    type: 'getOne',
    resource: id ? 'districts' : '',
    payload: { id }
  });

  if (!id || !loaded) {
    return null;
  }

  return <span>{data?.name}</span>;
  // return <span />
}

function RoomType(props: any) {
  const { id } = props;
  const { data, loaded } = useQueryWithStore({
    type: 'getOne',
    resource: id ? 'roomtypes' : '',
    payload: { id }
  });

  if (!id || !loaded) {
    return null;
  }

  return <span>{data?.name}</span>;
}

function AgentInfo(props: any) {
  const { id } = props;
  const { data, loaded } = useQueryWithStore({
    type: 'getOne',
    resource: id ? 'users' : '',
    payload: { id }
  });

  if (!id || !loaded) {
    return null;
  }

  // return <span>{data?.name} {data?.phone}</span>;
  return (
    <>
      <Typography>Агент: {data?.name}</Typography>
      <Typography gutterBottom>тел.: {data?.phone}</Typography>
    </>
  )
}

function OfficeInfo(props: any) {
  const { id } = props;
  const { data, loaded } = useQueryWithStore({
    type: 'getOne',
    resource: id ? 'offices' : '',
    payload: { id }
  });

  if (!id || !loaded) {
    return null;
  }

  // return <span>{data?.name} {data?.phone}</span>;
  return (
    <Typography>Офис: {data?.realName ?? data?.name} </Typography>
  )
}

function DateInfo(props: any) {
  const { id } = props;
  if (!id) {
    return null;
  }

  const text = format(id.toDate(), 'dd MM yyyy')

  return (
    <Typography variant="caption">Дата создания: {text}</Typography>
  )
}