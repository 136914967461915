import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  ReferenceField,
  SelectInput,
} from 'react-admin';

export const name = 'main_districts';

const DistrictListFilter = (props: any) => (
  <Filter {...props}>
    <BooleanInput source="active" alwaysOn />
    <ReferenceInput
      source="city"
      reference="cities"
      alwaysOn
      sort={{ field: 'name', order: 'ASC' }}
      allowEmpty={false}
      perPage={1000}
    >
      <SelectInput source="name" />
    </ReferenceInput>
  </Filter>
);

const ResList = (props: any) => {
  const { permissions } = props;
  const { city } = permissions || {};

  if (!city) {
    return null;
  }

  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<DistrictListFilter />}
      filterDefaultValues={{ active: true, city }}
    >
      <Datagrid rowClick="show">
        <TextField source="name" />
        <ReferenceField source="city" reference="cities" link="show">
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="active" />
      </Datagrid>
    </List>
  );
};

const ResShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <BooleanField source="active" />
      <TextField source="name" />
      <ReferenceField source="city" reference="cities" link="show">
        <TextField source="name" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

const ResCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <ReferenceInput source="city" reference="cities" link="show">
        <SelectInput source="name" />
      </ReferenceInput>
      <BooleanInput source="active" />
    </SimpleForm>
  </Create>
);

const ResEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" />
      <ReferenceInput source="city" reference="cities" link="show">
        <SelectInput source="name" />
      </ReferenceInput>
      <BooleanInput source="active" />
    </SimpleForm>
  </Edit>
);

export {
  ResList as list,
  ResShow as show,
  ResCreate as create,
  ResEdit as edit,
};
