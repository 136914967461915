import React, { Suspense } from 'react';
import { Loading } from 'react-admin';


const ListDataGrid = React.lazy(() => import('./ListDataGrid'));

function ListDataGridLoader() {
  return (
    <Suspense fallback={<Loading />}>
      <ListDataGrid />
    </Suspense>
  )
}


export default ListDataGridLoader;
