import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  // BooleanInput,
  DateTimeInput,
  // BooleanField,
  DateField,
  ReferenceField,
  ReferenceInput,
  SelectInput
} from 'react-admin';

export const name = 'tasks';

export const list = (props: any) => {
  const { permissions = {} } = props;
  const { role, user } = permissions;

  if (!role) {
    return null;
  }

  const filter: any = { user };

  return (
    <List {...props} bulkActionButtons={false} filter={filter}>
      <Datagrid rowClick="show">
        {/* <BooleanField source="active" /> */}
        <ReferenceField source="office" reference="offices" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="user" reference="users" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="name" />
        {/* <TextField source="info" />
      <TextField source="type" />
      <TextField source="status" /> */}
        <DateField source="start" showTime />
        <DateField source="end" showTime />
      </Datagrid>
    </List>
  )
};

export const show = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      {/* <BooleanField source="active" /> */}
      <ReferenceField source="office" reference="offices" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="user" reference="users" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" />
      {/* <TextField source="info" />
      <TextField source="type" />
      <TextField source="status" /> */}
      <DateField source="start" showTime />
      <DateField source="end" showTime />
    </SimpleShowLayout>
  </Show>
);

export const create = (props: any) => {
  const { permissions = {} } = props;
  const { office, user } = permissions;

  if (!user) {
    return null;
  }

  return (
    <Create {...props}>
      <SimpleForm defaultValue={{ office, user }}>
        <ReferenceInput source="user" reference="users" filter={{ office }} disabled>
          <SelectInput source="name" />
        </ReferenceInput>
        <ReferenceInput source="office" reference="offices" disabled>
          <SelectInput source="name" />
        </ReferenceInput>
        {/* <ReferenceField source="office" reference="offices">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="user" reference="users">
          <TextField source="name" />
        </ReferenceField> */}
        {/* <BooleanInput source="active" /> */}
        <TextInput source="name" />
        {/* <TextInput source="info" />
      <TextInput source="type" />
      <TextInput source="status" /> */}
        <DateTimeInput source="start" />
        <DateTimeInput source="end" />
      </SimpleForm>
    </Create>
  )
};

export const edit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceInput source="user" reference="users" disabled>
        <SelectInput source="name" />
      </ReferenceInput>
      <ReferenceInput source="office" reference="offices" disabled>
        <SelectInput source="name" />
      </ReferenceInput>
      {/* <BooleanInput source="active" /> */}
      <TextInput source="name" />
      {/* <TextInput source="info" />
      <TextInput source="type" />
      <TextInput source="status" /> */}
      <DateTimeInput source="start" />
      <DateTimeInput source="end" />
    </SimpleForm>
  </Edit>
);
