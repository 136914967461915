import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  DateField,
  RadioButtonGroupInput,
  DateInput,
  TextInput,
} from 'react-admin';

export const name = 'roomadds';

export const list = (props: any) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="show">
      <TextField source="list" />
      <TextField source="price" />
      <TextField source="space" />
      <TextField source="rt" />
      <TextField source="complex" />
      <TextField source="building" />
      <TextField source="type" />
      <TextField source="photo" />
    </Datagrid>
  </List>
);

const statusChoises = [
  { id: '1', name: 'Презентация' },
  { id: '2', name: 'Показ' },
];

const redirect: any = (basePath: string, id: string, data: any) =>
  `/list2/${data.list}/show`;

export const edit = (props: any) => (
  <Edit {...props}>
    <SimpleForm redirect={redirect}>
      <RadioButtonGroupInput source="status" choices={statusChoises} />
      <DateInput source="showDate" />
      <TextInput source="showResult" fullWidth multiline />
      <TextField source="price" label="Стоимость" />
      <TextField source="space" label="Площадь" />
      <TextField source="rt" label="Комнат" />
      <TextField source="floor" label="Этаж" />
      <TextField source="section" label="Секция" />
      <TextField source="number" label="Номер кв." />
      <DateField source="date" label="Добавлен" />
    </SimpleForm>
  </Edit>
);
