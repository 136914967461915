import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { parse } from 'query-string';
import { useQueryWithStore } from 'react-admin';
import { composeUrl } from 'libs/parseHelper';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Lightbox from 'react-image-lightbox';
import Typography from '@material-ui/core/Typography';
import Map from './SearchDeatilsMap';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

import { remontChoises } from 'resources/secondroom-res';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    background: 'silver',
  },
  card: {
    // margin: '10px 10px 10px 10px',
    margin: '10px 0',
  },
  photoPaper: {
    // marginRight: '10px',
  },
  buildingInfoContainer: {
    margin: '0 10px 10px 10px',
  },
  photo: {
    height: 183,
  },
  floor: {
    display: 'flex',
    height: '50px',
    marginBottom: '2px',
  },
  floorNumber: {
    minWidth: '30px',
    height: '100%',
    flex: 0,
    textAlign: 'center',
  },
  singleSection: {
    margin: '10px 10px 10px',
  },
  room: {
    width: '50px',
    marginRight: '2px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // cursor: 'pointer',
  },
  roomLink: {
    // width: '100%',
    // height: '100%',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'inherit',
    textDecoration: 'none'
  },
  sectionSelect: {
    margin: '10px 0 10px',
  },
  roomPhoto: {
    width: 250,
  },
  sectionButton: {
    marginRight: 5,
    marginBottom: 5,
  },
  photosRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  photosGridList: {
    width: '100%',
    height: 370,
  },
  buildingDescContainer: {
    height: 446,
    margin: '10px 10px 10px 10px',
  },
  sectionsRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 350,
  },
}));

const RoomInfo = (props: any) => {
  const { id } = parse(props.location.search);
  const classes = useStyles();
  const [openLightbox, setOpenLightbox] = React.useState(false);
  const [photoIndex, setPhotoIndex] = React.useState(0);

  const { data, loaded } = useQueryWithStore({
    type: 'getOne',
    resource: id ? 'secondroom' : '',
    payload: { id }
  });

  const {
    roomtype,
    complex,
    space_total,
    space_kitchen,
    space_live,
    floor,
    buildingStages,
    price,
    info,
    address,
    geo,
    agent,
    height,
    remontChoise,
    buildingDate,
    office,

    roomNums,
    sanuzel,
    balkon,
    balkonGlassing,
    sunSides,
    waterCalc,
    ironDoor,
    services,
    thingsRemains,
    dvor,
    parking
  } = data || {};

  const pm = () => {
    if (space_total) {
      const v = Number(price) / Number(space_total);
      return `${Number(v.toFixed()).toLocaleString()} руб./кв.м.`;
    }
    return '';
  }

  if (!id || !data || !loaded) {
    return null
  }

  return (
    <Container fixed>
      <Card className={classes.card}><OfficeInfo id={office} /></Card>
      <Grid container>
        <Grid item xs={8}>
          <Card onClick={() => setOpenLightbox(true)} className={classes.card}>
            <CardContent className={classes.photosRoot} >
              {data?.photo && (
                <GridList
                  className={classes.photosGridList}
                  cols={3}
                >
                  {data.photo.map((p: any, i: number) => (
                    <GridListTile key={i}>
                      <img src={composeUrl(p?.src, '600')} alt="building" />
                    </GridListTile>
                  ))}
                </GridList>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card className={classes.card} style={{ height: 'calc(100% - 20px)' }}>
            <AgentInfo id={agent} />
          </Card>
        </Grid>
      </Grid>
      {
        openLightbox &&
        <Lightbox
          mainSrc={data.photo[photoIndex]?.src}
          nextSrc={data.photo[(photoIndex + 1) % data.photo.length]?.src}
          prevSrc={data.photo[(photoIndex + data.photo.length - 1) % data.photo.length]?.src}
          onCloseRequest={() => setOpenLightbox(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + data.photo.length - 1) % data.photo.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % data.photo.length)
          }
        />
      }
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h5">
            <Grid container>
              <Grid item xs={8}>
                <RoomType id={roomtype} />, {space_total} кв.м., {floor} / {buildingStages} этаж
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'right' }}>
                {Number(price).toLocaleString()} руб.
              </Grid>
              <Grid item xs={8}>

              </Grid>
              <Grid item xs={4} style={{ textAlign: 'right' }}>
                <Typography>{pm()}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>{address}</Typography>
              </Grid>
            </Grid>
          </Typography>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h5" gutterBottom>Описание</Typography>
          <Typography>{info}</Typography>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h5" gutterBottom>О квартире</Typography>
          <Grid container>
            <TextLine title="Комнатность" text={roomNums ?? ''} />
            <TextLine title="Общая площадь" text={`${space_total} кв.м.`} />
            <TextLine title="Высота потолков" text={`${height ?? ''} м.`} />
            <TextLine title="Ремонт" text={`${remontChoises.find(r => r.id === remontChoise)?.name}`} />
            <TextLine title="Санузел" text={sanuzel} />
            <TextLine title="Балкон" text={balkon} />
            <TextLine title="Остеклкние балкона" text={balkonGlassing} />
            <TextLine title="Солнечная сторона" text={sunSides} />
            <TextLine title="Счетчик воды" text={waterCalc} />
            <TextLine title="Железная дверь" text={ironDoor} />
            <TextLine title="Подключенные сервисы" text={services} />
          </Grid>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h5" gutterBottom>В квартире при продаже остается</Typography>
          <Typography style={{ color: 'grey' }}>{thingsRemains}</Typography>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h5" gutterBottom>О доме</Typography>
          <Grid container>
            <TextLine title="Этаж/Этажность" text={`${floor ?? 1} из ${buildingStages ?? 1}`} />
            <TextLine title="Год постройки" text={buildingDate} />
          </Grid>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h5" gutterBottom>Двор</Typography>
          <Grid container>
            <TextLine title="Двор" text={dvor} />
            <TextLine title="Парковка" text={parking} />
          </Grid>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <Typography variant="h5" gutterBottom>Расположение</Typography>
          {geo && <Map value={geo} name="" zoom="16" />}
        </CardContent>
      </Card>
      {/* <Typography>
        <Complex id={complex} />
      </Typography>
      <Typography>{address}</Typography>
      <Typography>
        Площадь: {space_total} | {space_kitchen} | {space_live} кв.м.
      </Typography>
      <Typography>
        Стоимость {Number(price).toLocaleString()} руб.
      </Typography>
      <Typography>
        Этаж: {floor} / {buildingStages}
      </Typography>
      <Typography>Описание:</Typography>
      <Typography>{info}</Typography>
      {geo && <Map value={geo} name="" zoom="16" />}
      <AgentInfo id={agent} /> */}
      {/* <Card>
        <CardContent>
          Room Info
          <div>{id}</div>
          <div>
            <pre>
              {JSON.stringify(data, null, ' ')}
            </pre>
          </div>
        </CardContent>
      </Card> */}
    </Container>
  );
}

export default RoomInfo;

function RoomType(props: any) {
  const { id } = props;
  const { data, loaded } = useQueryWithStore({
    type: 'getOne',
    resource: id ? 'roomtypes' : '',
    payload: { id }
  });

  if (!id || !loaded) {
    return null;
  }

  return <span>{data?.name}</span>;
}

function Complex(props: any) {
  const { id } = props;
  const { data, loaded } = useQueryWithStore({
    type: 'getOne',
    resource: id ? 'complexes' : '',
    payload: { id }
  });

  if (!id || !loaded) {
    return null;
  }

  return <span>ЖК {data?.name}</span>;
}


function AgentInfo(props: any) {
  const { id } = props;
  const { data, loaded } = useQueryWithStore({
    type: 'getOne',
    resource: id ? 'users' : '',
    payload: { id }
  });

  if (!id || !loaded) {
    return null;
  }

  // return <span>{data?.name} {data?.phone}</span>;
  return <>
    {
      data?.photo && <CardMedia style={{ textAlign: 'center' }}>
        <img src={data?.photo?.src} alt="avatar" style={{ maxWidth: '100%', maxHeight: 270, margin: '0 auto' }} />
      </CardMedia>
    }
    <CardContent>
      <Typography>Агент: {data?.name}</Typography>
      <Typography gutterBottom>тел.: {data?.phone}</Typography>
      <Button variant="contained" color="primary" >Сделать предложение</Button>
    </CardContent>
  </>
}

function TextLine(props: any) {
  const { title, text } = props;
  return <>
    <Grid item xs={4} style={{ color: 'grey' }}><Typography gutterBottom>{title}</Typography></Grid>
    <Grid item xs={8}><Typography gutterBottom>{text ?? ''}</Typography></Grid>
  </>
}

function OfficeInfo(props: any) {
  const { id } = props;
  const { data, loaded } = useQueryWithStore({
    type: 'getOne',
    resource: id ? 'offices' : '',
    payload: { id }
  });

  if (!id || !loaded) {
    return null;
  }

  const { realName, logo, name, address, phone, email } = data;

  return <Grid container>
    <Grid item xs={2}>
      <img src={logo?.src ?? "/logo.jpg"} alt="logo" style={{ maxHeight: 100 }} />
    </Grid>
    <Grid item xs={10}>
      <Typography>{realName ?? name}</Typography>
      <Typography>{address}</Typography>
      <Typography>тел. {phone}</Typography>
      <Typography>email: {email}</Typography>
    </Grid>
  </Grid>

  //return <span>{JSON.stringify(data)}</span>;
}