import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  NumberField,
  BooleanField
} from 'react-admin';

export const name = 'roomreserves';

export const list = (props: any) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="show">
      <TextField source="amo_id" />
      {/* <TextField source="complex_id" /> */}
      <ReferenceField source="data.type" reference="roomtypes" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="data.price" />
      <NumberField source="data.space" />
      <ReferenceField source="complex_id" reference="complexes" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <TextField source="data.buildingName" />
      <NumberField source="data.section" />
      <NumberField source="data.floor" />
      <NumberField source="data.number" />
      <TextField source="data.amo_user" />
      <BooleanField source="canceled" />
    </Datagrid>
  </List>
);

