import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Typography from '@material-ui/core/Typography';

import { City, District, SecondRooms, RoomTypes, PriceFrom, PriceTo, SpaceFrom, SpaceTo, ResetSearch } from './Controls';
// import { City, District, Complex, RoomTypes, PriceFrom, PriceTo, SpaceFrom, SpaceTo } from './Controls';
// import { useSelector } from 'react-redux';

import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    maxWidth: 200,
  },
  iconFormControl: {
    margin: theme.spacing(1),
    minWidth: 50,
    maxWidth: 50,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SearchControlsSecondRooms = () => {
  const classes = useStyles();
  // const { city } = useSelector((state: any) => state.search);
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <City />
      <SecondRooms />
      <District />
      {/* <Complex /> */}
      <RoomTypes />
      <PriceFrom />
      <PriceTo />
      <FormControl className={classes.iconFormControl}>
        <IconButton color="primary" aria-label="reset" component="span" title="Все фильтры" onClick={() => setOpen(true)} >
          <SearchIcon />
        </IconButton>
      </FormControl>
      <ResetSearch />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <AppBar style={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography>Фильтры</Typography>
          </Toolbar>
        </AppBar>
        <Card>
          <CardContent>
            <SpaceFrom />
            <SpaceTo />
          </CardContent>
        </Card>
      </Dialog>
    </>
  );
};

export default SearchControlsSecondRooms;
