import React from 'react';
import Lightbox from 'react-image-lightbox';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Card2 as Card } from './Card';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { composeUrl } from 'libs/parseHelper';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    // background: 'red',
    [theme.breakpoints.down('lg')]: {
      padding: 0,
      margin: '0 20px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      margin: 0
    }
  },
  card: {
    // margin: '10px 10px 10px 10px',
    margin: '10px 0',
  },
  photoPaper: {
    // marginRight: '10px',
  },
  buildingInfoContainer: {
    // margin: '0 10px 10px 10px',
  },
  photo: {
    height: 183,
  },
  floor: {
    display: 'flex',
    height: '32px',
    marginBottom: '2px',
  },
  floorNumber: {
    minWidth: '32px',
    height: '100%',
    flex: 0,
    textAlign: 'center',
  },
  singleSection: {
    // overflowY: 'auto'
    // margin: '10px 10px 10px',
  },
  room: {
    minWidth: '32px',
    width: '32px',
    marginRight: '2px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // cursor: 'pointer',
  },
  roomLink: {
    // width: '100%',
    // height: '100%',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'inherit',
    textDecoration: 'none'
  },
  sectionSelect: {
    margin: '10px 0 10px',
  },
  roomPhoto: {
    width: 250,
  },
  sectionButton: {
    marginRight: 5,
    marginBottom: 5,
  },
  photosRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  photosGridList: {
    width: '100%',
    // height: 450,
    height: 325
  },
  photosGridListSm: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)'
  },
  buildingDescContainer: {
    height: 446,
    margin: '10px 0 10px 5px',
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0',
    }
    // margin: '10px 10px 10px 10px',
  },
  sectionsRoot: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    overflowY: 'scroll',
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 350,
  },
  selectedRoom: {
    // background: 'red',
    overflow: 'hidden',
    width: 340,
    height: 520,
    marginTop: 140,
    boxShadow: '1px 6px 13px 3px rgb(0 0 0 / 12%)',
    borderRadius: 8
  },
  selectedRoomHeader: {
    height: 40,
    background: '#3F51B5'
  }
}));

function BuildingsPhotos(props: any) {
  const [openLightbox, setOpenLightbox] = React.useState(false);
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const classes = useStyles();
  const { statData } = props;

  return (
    <Card>
      <Typography variant="h5">Ход строительства</Typography>
      <div style={{ marginTop: 24 }} onClick={() => setOpenLightbox(true)}>
        <div className={classes.photosRoot}>
          {statData?.photos && (
            <GridList
              cellHeight={160}
              className={classes.photosGridList}
              cols={8}
            >
              {statData.photos.map((p: string, i: number) => (
                <GridListTile key={i}>
                  <img src={composeUrl(p, '600')} alt="building" />
                </GridListTile>
              ))}
            </GridList>
          )}
        </div>
      </div>
      {
        openLightbox &&
        <Lightbox
          mainSrc={statData.photos[photoIndex]}
          nextSrc={statData.photos[(photoIndex + 1) % statData.photos.length]}
          prevSrc={statData.photos[(photoIndex + statData.photos.length - 1) % statData.photos.length]}
          onCloseRequest={() => setOpenLightbox(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + statData.photos.length - 1) % statData.photos.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % statData.photos.length)
          }
        />
      }
    </Card>
  )
}

export default BuildingsPhotos;