import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { makeStyles } from '@material-ui/core/styles';

import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';

import Typography from '@material-ui/core/Typography';
import { Card2 as Card } from './Card';
import { BuildingContext } from './Context';
import { simpleRoomTypes } from 'search/SearchData';

import banksImage from '../images/banks.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    background: '#F5F5F5',
    width: '100vw',
    minHeight: '100vh',
  },
}));

function Rooms(props: any) {
  const { buildings, roomsStat } = props;
  const [building, setBuilding] = React.useContext(BuildingContext);

  const selectedBuildingObj = React.useMemo(() => {
    if (building) {
      const found = buildings?.find((b: any) => b.id === building) || {};
      found.endDate = '';
      try {
        found.endDate = format(parseISO(found.dateOfConstruction), 'Q кв. yyyy г.');
      } catch (ex) { }
      return found;
    }
  }, [buildings, building]);


  return (
    <Card>
      <Typography variant="h5">Квартиры</Typography>
      <div style={{ margin: '16px 0 24px' }}>
        <ButtonGroup color="primary" size="large">
          {
            buildings?.map((b: any, num: number) => <Button key={b.id} onClick={() => setBuilding(b.id)} variant={b.id === building ? 'contained' : 'outlined'}>ЛИТ: {num + 1}</Button>)
          }
        </ButtonGroup>
      </div>
      <div>
        <div style={{ display: 'inline-block', marginRight: 48 }}>
          <Typography variant="subtitle1" style={{ color: '#828282' }}>Литер:</Typography>
          <Typography variant="body1">{selectedBuildingObj?.name}</Typography>
        </div>
        <div style={{ display: 'inline-block' }}>
          <Typography variant="subtitle1" style={{ color: '#828282' }}>Срок сдачи:</Typography>
          <Typography variant="body1">{selectedBuildingObj?.endDate}</Typography>
        </div>
      </div>
      {
        roomsStat && <RoomsStat data={roomsStat} />
      }
      <Typography gutterBottom variant="body1">Ипотека</Typography>
      <img src={banksImage} alt="banks" />
    </Card>
  )
}

export default Rooms;

function RoomsStat(props: any) {
  const { data } = props;

  const getRoomStat = React.useCallback(
    (srt) => {
      const found = data[srt];
      const suff = isNaN(Number(srt)) ? '' : 'к';
      if (found) {
        return { type: `${srt}${suff}`, price: Number(found.p_min).toLocaleString(), space: Number(found.s_min).toFixed(2) };
      }
      return { type: `${srt}${suff}` };
    },
    [data]
  );

  return (
    <div style={{ display: 'flex', alignItems: 'top', margin: '24px 0' }}>
      {simpleRoomTypes.map((srt) => {
        const stat = getRoomStat(srt);
        if (!stat.price && !stat.space) {
          return (
            <div style={{ display: 'inline-block', marginRight: 48 }}>
              <Typography variant="subtitle1" style={{ color: '#828282' }}>{stat.type}</Typography>
              <Typography variant="subtitle1" style={{ color: '#828282' }}>нет в продаже</Typography>
            </div>
          )
        }
        return (
          <div style={{ display: 'inline-block', marginRight: 48 }}>
            <Typography variant="subtitle1" style={{ color: '#828282' }}>{stat.type}</Typography>
            <Typography variant="body1">от {stat.space} кв.м.</Typography>
            <Typography variant="body1">от {stat.price} руб</Typography>
          </div>
        )
      })}
    </div>
  );

}