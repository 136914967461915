import { Card2 } from './Card';
import Typography from '@material-ui/core/Typography';

function Genplan(props: any) {
  const { complex } = props;

  return (
    <Card2>
      <Typography gutterBottom variant="h5">Генплан</Typography>
      <div style={{ marginTop: 24 }}>
        <img src={complex?.genplan ? complex.genplan.src : '/genplan.jpg'} alt="genplan" style={{ maxWidth: '100%' }} />
      </div>
    </Card2>
  )
}

export default Genplan;