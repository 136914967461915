import { Card2 } from './Card';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import { SelectControl, TextControl } from '../Controls';

import vtb from '../images/bank_vtb.svg';
import gbp from '../images/bank_gpb.svg';
import sber from '../images/bank_sber.svg';
import alfa from '../images/bank_alfa.svg';

function Ipoteka(props: any) {

  return (
    <Card2>
      <Grid container>
        <Grid item xs={3}>
          <Typography gutterBottom variant="h5">Подбор Ипотеки</Typography>
          <div style={{ paddingTop: 16 }} />
          <SelectControl label="Банк" />
          <TextControl label="Стоимость недвижимости" />
          <TextControl label="Срок кредита" />
          <TextControl label="Первоначальный взнос" />
          <Button variant="contained" color="primary" style={{ margin: 8, width: '85%' }}>Рассчитать Ипотеку</Button>
        </Grid>
        <Grid item xs={9}>
          <Typography gutterBottom variant="h6">Подходящее предложение</Typography>
          <Typography gutterBottom variant="body2">Экономия до 0,5% на ставке</Typography>
          <Paper style={{ marginBottom: 16 }}>
            <div style={{ padding: 15, display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: 200 }}>
                <img src={vtb} alt="vtb" />
              </div>
              <Typography variant="body1">5.85%</Typography>
              <Typography variant="body1">20 385 Р</Typography>
              <div />
            </div>
          </Paper>
          <Paper style={{ marginBottom: 16 }}>
            <div style={{ padding: 15, display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: 200 }}>
                <img src={gbp} alt="gbp" />
              </div>
              <Typography variant="body1">5.85%</Typography>
              <Typography variant="body1">20 385 Р</Typography>
              <div />
            </div>
          </Paper>
          <Paper style={{ marginBottom: 16 }}>
            <div style={{ padding: 15, display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: 200 }}>
                <img src={sber} alt="sber" />
              </div>
              <Typography variant="body1">5.85%</Typography>
              <Typography variant="body1">20 385 Р</Typography>
              <div />
            </div>
          </Paper>
          <Paper style={{ marginBottom: 16 }}>
            <div style={{ padding: 15, display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: 200 }}>
                <img src={alfa} alt="alfa" />
              </div>
              <Typography variant="body1">5.85%</Typography>
              <Typography variant="body1">20 385 Р</Typography>
              <div />
            </div>
          </Paper>
        </Grid>
      </Grid>

    </Card2>
  )
}

export default Ipoteka;