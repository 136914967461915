import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import logoImage from './images/logo.svg';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';
import { usePermissions } from 'react-admin';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    background: '#fff',
    marginBottom: 32,
    borderRadius: '0 0 8px 8px',
    padding: '0 24px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {

  },
  menuItems: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 240
  },
  menuItem: {
    padding: '0px 16px',
    borderRight: '1px solid #ccc',
    color: '#10142D',
    fontSize: 16,
  },
  searchMenuItem: {
    padding: '0px 16px',
    color: '#10142D',
    fontSize: 16,
    marginLeft: 16,
    paddingBottom: 5
  },
  searchIcon: {
    color: '#3F51B5',
    position: 'relative',
    top: 5
    // padding: 5
  }

}))

function Header() {
  const classes = useStyles();
  const { permissions = {} } = usePermissions();
  const { email, role, user } = permissions;

  return (
    <div className={classes.container}>
      <div className={classes.logo}><img src={logoImage} alt="logo" /></div>
      <div className={classes.menuItems}>
        <Typography className={classes.menuItem}><Link to="/" style={{ textDecoration: 'none', color: '#354497' }}>Главная</Link></Typography>
        <Typography className={classes.menuItem}>Ипотечный калькулятор</Typography>
        <Typography className={classes.menuItem}>Сравнение</Typography>
        <Typography className={classes.menuItem}>Акции</Typography>
        <Typography className={classes.searchMenuItem}><SearchIcon className={classes.searchIcon} /> Поиск</Typography>
      </div>
      <div style={{ flex: 1 }} />
      <div style={{ display: 'flex' }}>
        <Avatar />
        <div style={{ margin: '0 16px' }}>
          <Typography variant="body1">{email}</Typography>
          <Typography variant="body2" style={{ color: '#7A7A7A' }}>{role}</Typography>
        </div>
      </div>
      <MoreVertIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
    </div>
  )
}

export default Header;