import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import endOfQuarter from 'date-fns/endOfQuarter';
import format from 'date-fns/format';
import add from 'date-fns/add'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100vh',
    position: 'fixed',
    background: '#F5F5F5',
    overflow: 'hidden',
  },
  header: {
    height: 44,
    background: '#FFFFFF',
    borderRadius: '0 0 10px 10px',
    margin: '0 24px 16px',
    padding: 16
  },
  main: {
    background: '#FFFFFF',
    borderRadius: '10px 10px 0 0',
    display: 'flex',
    position: 'relative',
    height: `calc(100% - ${44 + 2 * 16 + 16 + 16 + 10}px)`,
    margin: '0 24px',
    padding: '16px 16px 0',
  },
  filter: {
    width: 316,
    height: '100%',
  },
  cards: {
    width: 632,
    height: '100%',
    overflowY: 'scroll'
  },
  formControl: {
    margin: theme.spacing(1),
    width: '85%',
  },
  card: {
    position: 'relative',
    width: 278,
    height: 498,
    borderRadius: 8,
    // background: '#ccc',
    margin: '12px 12px 12px 24px',
    boxShadow: '2px 2px 9px 2px #999'
  }
}));

export function SelectControl(props: any) {
  const { label, data, value, onChange, multiple, allowClear = true, size = 'small', variant = 'outlined', disabled } = props;
  const classes = useStyles();

  const handleChange = React.useCallback((e) => {
    if (onChange) {
      const { value } = e.target;
      if (multiple) {
        onChange(value.includes('') ? [] : value);
      } else {
        onChange(value);
      }

    }
  }, [onChange, multiple]);

  return (
    <FormControl variant={variant} size={size} className={classes.formControl}>
      <InputLabel id="outlined-label">{label}</InputLabel>
      <Select
        labelId="outlined-label"
        id="select-outlined"
        label={label}
        value={value}
        onChange={handleChange}
        multiple={multiple}
        disabled={disabled}
      >
        {allowClear && (
          <MenuItem value="">
            <em>Нет</em>
          </MenuItem>
        )}
        {
          data?.map((d: any) => <MenuItem key={d.id} value={d.id}>{d.name}</MenuItem>)
        }
      </Select>
    </FormControl>
  )
}

export function TextControl(props: any) {
  const { label, value, onChange } = props;
  const classes = useStyles();

  const handleChange = React.useCallback((e) => {
    if (onChange) {
      const { value } = e.target;
      onChange(value);
    }
  }, [onChange]);

  return (
    <TextField label={label} value={value} onChange={handleChange} variant="outlined" size="small" className={classes.formControl} />
  )
}

export function getSellDates() {
  const ret = [];
  const date = new Date();
  for (let i = 0; i < 14; i++) {
    const q = add(endOfQuarter(date), { months: 3 * i });
    const name = i === 0 ? 'сдан' : format(q, 'до Q кв. yyyy г.');
    ret.push({ id: i + 1, q, name });
  }
  return ret;
}

export function SelectControlBroker(props: any) {
  const { data, value, onChange } = props;
  const classes = useStyles();

  const handleChange = React.useCallback((e) => {
    if (onChange) {
      const { value } = e.target;

      onChange(value);
    }
  }, [onChange]);

  return (
    <FormControl>
      <Select
        value={value}
        onChange={handleChange}
        style={{ fontSize: 14 }}
        disableUnderline
      >
        {
          data?.map((d: any) => <MenuItem key={d.id} value={d.id}>{d.name}</MenuItem>)
        }
      </Select>
    </FormControl>
  )
}