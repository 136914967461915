import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    marginBottom: 10
  },
  image: {
    backgroundColor: '#F5F5F5',
    marginRight: 8
  },
  title: {
    color: '#828282'
  }
}));

function CommonItem(props: any) {
  const {
    img,
    title,
    text
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Avatar className={classes.image}>
        <img src={img} alt="icon" />
      </Avatar>
      <div>
        <Typography variant="body2" className={classes.title}>{title}</Typography>
        <Typography variant="caption">{text}</Typography>
      </div>
    </div>
  )

}

export default CommonItem;

