import firebase from 'firebase/app';

// import endOfDay from 'date-fns/endOfDay';
// import startOfDay from 'date-fns/startOfDay';

// import {
//   CREATE,
//   DELETE,
//   DELETE_MANY,
//   // GET_LIST,
//   GET_MANY,
//   // GET_MANY_REFERENCE,
//   GET_ONE,
//   UPDATE,
//   UPDATE_MANY,
// } from 'react-admin';

const validUntil = (_duration: number = 60 * 24) => {
  const duration = _duration * 60 * 1000;
  const date = new Date();
  return date.setTime(date.getTime() + duration);
};

const customDataProvider = (fbDataProvider: any) => {
  return {
    ...fbDataProvider,
    getList: async (resource: string, params: any) => {
      if (!resource) {
        return { data: [], total: 0 };
      }
      // console.log('---getlist---', resource, params);
      // if (resource.startsWith('SEARCH_')) {
      //   const validResource = resource.replace('SEARCH_', '');
      //   return firebase_getListPerPage(validResource, params) as any;
      // }
      return firebase_getListPerPage(resource, params) as any;
    },
    getOne: async (resource: any, params: any) => {
      if (!resource || !params?.id) {
        return { data: { id: null } };
      }
      // console.log('---getOne---', resource, params);
      const data = await fbDataProvider.getOne(resource, params);
      //const data: any = await firebase_getOne(resource, params);
      data.validUntil = validUntil();
      return data;
    },
    // getOne: async (resource: any, params: any) => {
    //   if (!resource || !params?.id) {
    //     return { data: { id: null } };
    //   }
    //   // console.log('---getOne---', resource, params);
    //   const data = await fbDataProvider(GET_ONE, resource, params);
    //   data.validUntil = validUntil();
    //   return data;
    // },
    getMany: async (resource: any, params: any) => {
      if (!resource || !params?.ids) {
        return { data: [] };
      }
      console.log('---getMany---', resource, params);
      const data = await fbDataProvider.getMany(resource, params);
      data.validUntil = validUntil();
      return data;
    },
    // getMany: async (resource: any, params: any) => {
    //   if (!resource || !params?.ids) {
    //     return { data: [] };
    //   }
    //   // console.log('---getMany---', resource, params);
    //   const data = await fbDataProvider(GET_MANY, resource, params);
    //   data.validUntil = validUntil();
    //   return data;
    // },
    getManyReference: (resource: any, params: any) => {
      // console.log('---getManyReference---', resource, params);
      // try {
      //   return firebase_getManyReference(resource, params);
      // } catch (err) {
      //   console.error('---getManyReference---', err);
      // }
      return firebase_getManyReference(resource, params);
    },
    // create: (resource: any, params: any) => {
    //   // console.log('---create---', resource, params);
    //   return fbDataProvider(CREATE, resource, params);
    // },
    // update: (resource: any, params: any) => {
    //   // console.log('---update---', resource, params);
    //   return fbDataProvider(UPDATE, resource, params);
    // },
    // updateMany: (resource: any, params: any) => {
    //   // console.log('---updateMany---', resource, params);
    //   return fbDataProvider(UPDATE_MANY, resource, params);
    // },
    // delete: (resource: any, params: any) => {
    //   // console.log('---delete---', resource, params);
    //   return fbDataProvider(DELETE, resource, params);
    // },
    // deleteMany: (resource: any, params: any) => {
    //   // console.log('---deleteMany---', resource, params);
    //   return fbDataProvider(DELETE_MANY, resource, params);
    // },
  };
};

export default customDataProvider;

// const firebase_getList = async (collection: string, params: any) => {
//   if (!collection) {
//     return { data: [], total: 0 };
//   }
//   const { sort, filter } = params;
//   const db = firebase.firestore();
//   let ref: any = db.collection(collection);
//   if (Object.keys(filter).length) {
//     Object.entries(filter).forEach(([k, v]) => {
//       ref = ref.where(k, '==', v);
//     });
//   }
//   if (sort) {
//     const { field, order } = sort;
//     ref = ref.orderBy(field, order.toLowerCase());
//   }
//   const querySnapshot = await ref.get();
//   const data: any = [];
//   querySnapshot.forEach((doc: any) => {
//     data.push({ id: doc.id, ...doc.data() });
//   });

//   return { data, total: data.length, validUntil: validUntil() };
// };

const firebase_getOne = async (collection: string, params: any) => {
  const { id } = params;
  const db = firebase.firestore();
  const ref = db.collection(collection).doc(id);
  const querySnapshot = await ref.get();
  return { data: { ...querySnapshot.data(), id } };
};

const firebase_getManyReference = async (collection: string, params: any) => {
  if (!collection) {
    return { data: [], total: 0 };
  }
  const { target, filter, id, sort } = params;
  const db = firebase.firestore();
  let ref: any = db.collection(collection).where(target, '==', id);
  if (Object.keys(filter).length) {
    Object.entries(filter).forEach(([k, v]) => {
      ref = ref.where(k, '==', v);
    });
  }

  if (sort) {
    const { field, order } = sort;
    if (field === 'id') {
      ref = ref.orderBy(firebase.firestore.FieldPath.documentId());
    } else {
      ref = ref.orderBy(field, order.toLowerCase());
    }
  }

  const querySnapshot = await ref.get();
  const data: any = [];
  querySnapshot.forEach((doc: any) => {
    data.push({ id: doc.id, ...doc.data() });
  });
  data.sort((a: any, b: any) => `${a.name}`.localeCompare(`${b.name}`));
  return { data, total: data.length };
};

const arrayFields = ['attachedCity'];
const textFields = ['name', 'email'];

const pCache: any = {};

const firebase_getListPerPage = async (collection: string, params: any) => {
  if (!collection) {
    return { data: [], total: 0 };
  }
  const { sort, filter, pagination } = params;
  const { page, perPage } = pagination;

  const cacheKey = collection;
  let last = pCache[cacheKey];

  const db = firebase.firestore();
  let ref: any = db.collection(collection);

  let sortField;
  let sortOrder;
  // if (collection === 'ipotekalist') {
  //   const { list_kind, list_type } = filter;
  //   if (list_kind && list_kind !== 'all') {
  //     ref = ref.where('list_kind', '==', list_kind);
  //   }
  //   if (list_type === 'requests') {
  //     ref = ref.where('firstSendDate', '!=', null);
  //     sortField = 'firstSendDate';
  //     sortOrder = 'desc';
  //   }
  // }
  if (collection === 'lists') {
    if (!Object.keys(filter).length) {
      return { data: [], total: 0 };
    }
    const {
      date_type,
      date_lt,
      date_gt,
      offices,
      office,
      manager,
      list_kind,
    } = filter;

    if (!manager && !office && !(offices && offices.length)) {
      return { data: [], total: 0 };
    }

    if (list_kind && list_kind !== 'all') {
      ref = ref.where('list_kind', '==', list_kind);
    }

    if (offices && Array.isArray(offices)) {
      ref = ref.where('office', 'in', offices);
    }
    if (office && office !== 'all') {
      ref = ref.where('office', '==', office);
    }
    // if (!office && !offices) {
    //   ref = ref.where('office', 'in', []);
    // }
    if (manager) {
      ref = ref.where('manager', '==', manager);
    }
    if (date_type && date_lt && date_gt) {
      ref = ref.where(date_type, '>=', date_gt).where(date_type, '<=', date_lt);
    }
    sortField = date_type;
    sortOrder = 'desc';
  } else if (Object.keys(filter).length) {
    Object.entries(filter).forEach(([k, v]) => {
      if (k === 'list_kind') {
        if (v !== 'all') {
          ref = ref.where('list_kind', '==', v);
        }
      } else if (k === 'list_type') {
        if (v === 'requests') {
          ref = ref.where('firstSendDate', '!=', null);
          sortField = 'firstSendDate';
          sortOrder = 'desc';
        }
      } else if (v === undefined) {
      } else if (k.endsWith('date_gt')) {
        const date = firebase.firestore.Timestamp.fromDate(
          new Date(v as string)
        );
        ref = ref.where(k.replace('_gt', ''), '>=', date);
      } else if (k.endsWith('date_lt')) {
        const date = firebase.firestore.Timestamp.fromDate(
          new Date(v as string)
        );
        ref = ref.where(k.replace('_lt', ''), '<=', date);
      } else if (textFields.includes(k) && v) {
        const start = v as string;
        const end = start.replace(/.$/, (c) =>
          String.fromCharCode(c.charCodeAt(0) + 1)
        );
        ref = ref.where(k, '>=', start).where(k, '<', end);
        sortField = k;
      } else if (arrayFields.includes(k)) {
        ref = ref.where(k, 'array-contains', v);
      } else if (Array.isArray(v)) {
        ref = ref.where(k, 'in', v);
      } else {
        if (k === 'pipeline_id') {
          ref = ref.where(k, '==', `${v}`);
        } else {
          ref = ref.where(k, '==', v);
        }
        if (sort?.field === k) {
          sortField = 'id';
        }
      }
    });
  }
  if (sort) {
    const { field, order } = sort;
    if (sortField) {
      ref = ref.orderBy(sortField, sortOrder ?? 'desc');
    } else if (field === 'id') {
      ref = ref.orderBy(firebase.firestore.FieldPath.documentId());
    } else {
      ref = ref.orderBy(field, order.toLowerCase());
    }
  }

  if (page > 1 && last) {
    ref = ref.startAfter(last);
  }

  if (perPage) {
    ref = ref.limit(perPage);
  }

  const start = Date.now();
  // console.log('---start request---', collection);
  const querySnapshot = await ref.get();
  // console.log(
  //   '---end request---0-',
  //   collection,
  //   querySnapshot.docs.length,
  //   Date.now() - start
  // );
  pCache[cacheKey] = querySnapshot.docs[querySnapshot.docs.length - 1];

  const data: any = [];
  querySnapshot.forEach((doc: any) => {
    data.push({ id: doc.id, ...doc.data() });
  });
  // console.log('---end request---1-', collection, data.length);
  const total =
    data.length < perPage
      ? (page - 1) * perPage + data.length
      : page * perPage + 1;

  return { data, total: total, validUntil: validUntil() };
};
