import React from 'react';
import { Resource, usePermissions } from 'react-admin';
interface ResourcePermission {
  list: boolean;
  show: boolean;
  create: boolean;
  edit: boolean;
}

const defaultPermission: ResourcePermission = {
  list: false,
  show: true,
  create: false,
  edit: false,
};

const rolePermissions: Record<string, Record<string, ResourcePermission>> = {
  admin: {
    amo_pipelines: {
      ...defaultPermission,
      list: true
    },
    amo_users: {
      ...defaultPermission,
      list: true
    },
    dp_stat: {
      ...defaultPermission,
      list: true
    },
    roomreserves: {
      ...defaultPermission,
      list: true,
      create: false,
      edit: false,
    },
    iprograms: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    developers: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    cities: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    main_districts: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    districts: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    metro_lines: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    metro_stations: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    banks: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    complexes: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    buildings: {
      ...defaultPermission,
      create: true,
      edit: true,
    },
    entrances: {
      ...defaultPermission,
      show: true,
      create: true,
      edit: true,
    },
    users: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    offices: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    lists: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    list2: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    ipotekalist: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    secondroom: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    tasks: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    bankrequests: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    strahrequests: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    roomadds: {
      ...defaultPermission,
      edit: true,
    },
    roomtypes: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    compares: {
      ...defaultPermission,
      list: true,
      create: false,
      edit: false,
    },
    rooms: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    liftbrands: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    }
  },
  superofficemanager: {
    developers: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    cities: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    main_districts: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    districts: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    banks: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    complexes: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    buildings: {
      ...defaultPermission,
      create: true,
      edit: true,
    },
    entrances: {
      ...defaultPermission,
      show: true,
      create: true,
      edit: true,
    },
    secondroom: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    lists: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    roomtypes: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    compares: {
      ...defaultPermission,
      list: true,
      create: false,
      edit: false,
    },
    users: {
      ...defaultPermission,
      edit: true,
      show: false
    },
    rooms: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    metro_lines: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    metro_stations: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    iprograms: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    ipotekalist: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    bankrequests: {
      ...defaultPermission,
      list: false,
      create: true,
      edit: true,
    },
    strahrequests: {
      ...defaultPermission,
      list: false,
      create: true,
      edit: true,
    },
  },
  broker: {
    iprograms: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    ipotekalist: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    // list2: {
    //   ...defaultPermission,
    //   list: true,
    //   create: true,
    //   edit: true,
    // },
    // tasks: {
    //   ...defaultPermission,
    //   list: true,
    //   create: true,
    //   edit: true,
    // },
    bankrequests: {
      ...defaultPermission,
      list: false,
      create: true,
      edit: true,
    },
    strahrequests: {
      ...defaultPermission,
      list: false,
      create: true,
      edit: true,
    },
    // compares: {
    //   ...defaultPermission,
    //   list: true,
    //   create: false,
    //   edit: false,
    // },
    lists: {
      ...defaultPermission,
      list: true,
      create: false,
      edit: true,
    },
    users: {
      ...defaultPermission,
      edit: true,
      show: false
    },
  },
  superbroker: {
    iprograms: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    ipotekalist: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    lists: {
      ...defaultPermission,
      list: true,
      create: false,
      edit: true,
    },
    users: {
      ...defaultPermission,
      edit: true,
      show: false
    },
    banks: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    bankrequests: {
      ...defaultPermission,
      list: false,
      create: true,
      edit: true,
    },
    strahrequests: {
      ...defaultPermission,
      list: false,
      create: true,
      edit: true,
    },
    liftbrands: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    }
  },
  supermanager: {
    users: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    offices: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    lists: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    secondroom: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    compares: {
      ...defaultPermission,
      list: true,
      create: false,
      edit: false,
    },
    ipotekalist: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    banks: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    bankrequests: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    strahrequests: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    iprograms: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    liftbrands: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    }
  },
  manager: {
    ipotekalist: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    list2: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    lists: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    secondroom: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    tasks: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    roomadds: {
      ...defaultPermission,
      edit: true,
    },
    compares: {
      ...defaultPermission,
      list: true,
      create: false,
      edit: false,
    },
    users: {
      ...defaultPermission,
      edit: true,
      show: false
    },
  },
  marketolog: {},
  officehead: {
    lists: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    secondroom: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    users: {
      ...defaultPermission,
      edit: true,
      show: false
    },
    ipotekalist: {
      ...defaultPermission,
      list: true,
      create: false,
      edit: true,
    },
  },
  cityhead: {
    lists: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    secondroom: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    users: {
      ...defaultPermission,
      edit: true,
      show: false
    },
  },
  user: {
    lists: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    secondroom: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    compares: {
      ...defaultPermission,
      list: true,
      create: false,
      edit: false,
    },
    users: {
      ...defaultPermission,
      edit: true,
      show: false
    },
    ipotekalist: {
      ...defaultPermission,
      list: true,
      create: false,
      edit: true,
    },
  },
  guest: {
    lists: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: false,
    },
    secondroom: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: false,
    },
    ipotekalist: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: false,
    },
    compares: {
      ...defaultPermission,
      list: true,
      create: false,
      edit: false,
    },
    users: {
      ...defaultPermission,
      edit: false,
      show: false
    },
  },
  officemanager: {
    developers: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    complexes: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    buildings: {
      ...defaultPermission,
      show: true,
      create: true,
      edit: true,
    },
    entrances: {
      ...defaultPermission,
      show: true,
      create: true,
      edit: true,
    },
    secondroom: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    lists: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
    compares: {
      ...defaultPermission,
      list: true,
      create: false,
      edit: false,
    },
    users: {
      ...defaultPermission,
      edit: true,
      show: false
    },
    rooms: {
      ...defaultPermission,
      list: true,
      create: true,
      edit: true,
    },
  },
};

const ResourceWithPermissions = (props: any) => {
  const { name, list, create, edit, show, ...otherProps } = props;
  const { permissions } = usePermissions();
  const resourceProps = React.useMemo(() => {
    if (permissions) {
      const { role = 'guest', spr } = permissions;
      const role2 = spr ? `super${role}` : role;
      const p = (rolePermissions[role2] || {})[name] ?? defaultPermission;
      // console.log('---role---', role, name, p);
      return {
        name,
        list: p.list ? list : null,
        show: p.show ? show : null,
        create: p.create ? create : null,
        edit: p.edit ? edit : null,
      };
    }
    return { name };
  }, [permissions, list, create, edit, show, name]);

  return <Resource {...otherProps} {...resourceProps} />;
};

export default ResourceWithPermissions;
