import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// import Input from '@material-ui/core/Input';
// import TextField from '@material-ui/core/TextField';
// import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import { simpleRoomTypes, complexStat } from './SearchData';

import { useDispatch, useSelector } from 'react-redux';
import { setObject, setFilteredComplexes, setViewMap } from 'reducers/search-slice';
import { useQueryWithStore } from 'react-admin';
import { composeUrl } from 'libs/parseHelper';
import isBefore from 'date-fns/isBefore';
// import parseISO from 'date-fns/parseISO';

import { getSellDates } from 'search/Controls';

import SearchViewContext from './SearchViewContext';

const complexItemHeight = 550;

const complexItemHeight_list = 250;

const useStyles = makeStyles((theme) => ({
  container: {
    flex: '0 0 auto',
    // flex: 1,
    // width: 700,
    // width: '100%',
    background: 'silver',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      // background: 'red',
      height: '100vh',

    },
    [theme.breakpoints.up('md')]: {
      width: 350
    },
    [theme.breakpoints.up('lg')]: {
      width: 700
    }
  },
  listContainer: {
    flex: 1,
    background: 'silver'
  },
  complexItem: {
    // height: complexItemHeight,
    // cursor: 'pointer',
    margin: '0 5px 10px',
    // display: 'flex-inline',
    position: 'relative'
  },
  complexItem_list: {
    // height: complexItemHeight_list,
    // cursor: 'pointer',
    margin: '0 5px 10px',
    // display: 'flex-inline',
    position: 'relative'
  },
  geoIcon: {
    position: 'absolute',
    bottom: 5,
    right: 5,
    cursor: 'pointer'
  },
  media: {
    height: 250,
  },
  container0: {
    // paddingBottom: 10,
    // height: complexItemHeight + 10,
    // overflow: 'hidden',
  },
  container2: {
    height: complexItemHeight,
    width: 'calc(50% - 10px)',
    display: 'inline-block',
    // background: 'green',
    padding: '0 5px',
  },
  greyText: {
    color: 'grey',
  },
  blueText: {
    color: '#4682B4',
    textDecoration: 'none'
  },
  formControl: {
    // padding: '0 5px',
    margin: 5,
    // width: '100%',
    background: 'white',
  },
}));

const SearchDetails = React.memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { complexes: selectedComplexes, districts: selectedDistricts, priceFrom, priceTo, spaceFrom, spaceTo, roomTypes: selectedRoomTypes, sellDate } = useSelector((state: any) => state.search);
  const viewMode = React.useContext(SearchViewContext);
  const data = useSelector((state: any) => state.search.complexesData);

  const handleSelect = React.useCallback(
    (id) =>
      dispatch(setObject(id)),
    [dispatch]
  );

  const complexData = React.useMemo(() => {
    return data?.map((d: any) => complexStat(d)).filter((c: any) => Number(c.priceFrom) > 0);
    // return data?.map((d: any) => complexStat(d));
  }, [data]);

  const complexes = React.useMemo(() => {
    if (!complexData) {
      return null;
    }

    let filtered = complexData;

    if (sellDate) {
      const sellDates = getSellDates();
      const foundSellDate = sellDates.find(sd => sd.id === sellDate);
      // console.log('---foundSellDate---', foundSellDate);
      if (foundSellDate) {
        filtered = filtered.filter((c: any) => {
          const { dates } = c;
          if (dates) {
            // console.log('---complex---', dates, foundSellDate.q);
            return dates.some((d: any) => {
              const date1 = d;
              const date2 = foundSellDate.q;
              const before = isBefore(date1, date2);
              // console.log('---isBefore---', before, date1, date2);
              return before;
            });
          }
          // console.log('---complex---', dates, c);
          return false;
        });
      }
    }

    if (selectedDistricts && selectedDistricts.length) {
      filtered = filtered.filter((c: any) =>
        selectedDistricts.includes(c.district)
      );
    }

    if (selectedComplexes && selectedComplexes.length) {
      filtered = filtered.filter((c: any) =>
        selectedComplexes.includes(c.id)
      );
    }

    if (selectedRoomTypes && selectedRoomTypes.length) {
      filtered = filtered.filter((c: any) => {
        const { roomsStat } = c;
        const keys = Object.keys(roomsStat);
        const intersection = selectedRoomTypes.filter((x: any) => keys.includes(x));
        if (!intersection.length) {
          return false;
        }

        // console.log('---rooms stat---', intersection, roomsStat);
        if (!priceFrom && !priceTo && !spaceFrom && !spaceTo) {
          return true;
        }
        let passedArr: any = [];
        intersection.forEach((k: string) => {
          let passed: any = [];
          const { p_min, s_min } = roomsStat[k];
          if (priceFrom) {
            passed.push(Number(p_min) > Number(priceFrom));
          }
          if (priceTo) {
            passed.push(Number(p_min) < Number(priceTo));
          }
          if (spaceFrom) {
            passed.push(Number(s_min) > Number(spaceFrom));
          }
          if (spaceTo) {
            passed.push(Number(s_min) < Number(spaceTo));
          }
          passedArr.push(passed.every(Boolean));
        });
        return passedArr.some(Boolean);
        // return intersection.length;
      });
    } else {

      if (priceFrom) {
        filtered = filtered.filter((c: any) => Number(c.priceFrom) > Number(priceFrom));
      }

      if (priceTo) {
        filtered = filtered.filter((c: any) => Number(c.priceFrom) < Number(priceTo));
      }

      if (spaceFrom) {
        filtered = filtered.filter((c: any) => Number(c.spaceFrom) > Number(spaceFrom));
      }

      if (spaceTo) {
        filtered = filtered.filter((c: any) => Number(c.spaceFrom) < Number(spaceTo));
      }
    }

    return filtered;
  }, [
    complexData,
    selectedComplexes,
    selectedDistricts,
    selectedRoomTypes,
    priceFrom,
    priceTo,
    spaceFrom,
    spaceTo,
    sellDate
  ]);

  React.useEffect(() => {
    dispatch(setFilteredComplexes(complexes.map((c: any) => c.id)));
  }, [complexes, dispatch]);

  // return (
  //   <>
  //     <div>data: {data.length}</div>
  //     <div>ComplexData: {complexData.length}</div>
  //     <div>Complexes: {complexes.length}</div>
  //   </>
  // )



  return (
    <div className={viewMode === 'list' ? classes.listContainer : classes.container}>
      {
        (complexes) &&
        <ComplexesList complexes={complexes} onSelect={handleSelect} />
      }
    </div>
  );
});

const sortVariants = [
  { id: '0', name: 'По умолчанию' },
  { id: '1', name: 'По цене, сначала дешевые' },
  { id: '2', name: 'По цене, сначала дорогие' },
  { id: '3', name: 'По площади, сначала малые' },
  { id: '4', name: 'По площади, сначала большие' },
];

const ComplexesList = React.memo((props: any) => {
  const theme = useTheme();
  const is_lg = useMediaQuery(theme.breakpoints.up('lg'));
  const [sort, setSort] = React.useState('0');
  const { complexes: baseComplexes, onSelect } = props;
  const classes = useStyles();
  const viewMode = React.useContext(SearchViewContext);

  const complexes = React.useMemo(() => {
    if (sort === '0') {
      return baseComplexes;
    }
    const obj = [...baseComplexes];
    if (sort === '1') {
      obj.sort((a, b) => a.priceFrom - b.priceFrom);
    }
    if (sort === '2') {
      obj.sort((a, b) => b.priceFrom - a.priceFrom);
    }
    if (sort === '3') {
      obj.sort((a, b) => a.spaceFrom - b.spaceFrom);
    }
    if (sort === '4') {
      obj.sort((a, b) => b.spaceFrom - a.spaceFrom);
    }
    return obj;
  }, [sort, baseComplexes]);

  if (viewMode === 'list') {
    return (
      <>
        <FormControl className={classes.formControl} style={{ position: 'absolute', left: 0, top: 0, zIndex: 1 }}>
          <InputLabel>Сортировка</InputLabel>
          <Select
            value={sort}
            onChange={(event: any) => setSort(event.target.value)}
          >
            {sortVariants.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <AutoSizer>
          {({ height, width }) => (
            <List
              height={height}
              width={width}
              itemSize={complexItemHeight_list + 10}
              itemCount={Math.ceil(complexes.length)}
            >
              {({ index, style }) => (
                <div style={style}>
                  <ComplexItemList
                    data={complexes[index]}
                    onClick={onSelect}
                  />
                </div>
              )}
            </List>
          )}
        </AutoSizer>
      </>
    )
  }

  return (
    <>
      <FormControl className={classes.formControl} style={{ position: 'absolute', left: 0, top: 0, zIndex: 1 }}>
        <InputLabel>Сортировка</InputLabel>
        <Select
          value={sort}
          onChange={(event: any) => setSort(event.target.value)}
        >
          {sortVariants.map((c) => (
            <MenuItem key={c.id} value={c.id}>
              {c.name}
            </MenuItem>
          ))}
        </Select>
        {/* {complexes.length} === {baseComplexes.length} */}
      </FormControl>
      {
        !is_lg ? (
          <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                width={width}
                itemSize={complexItemHeight + 10}
                itemCount={Math.ceil(complexes.length)}
              >
                {({ index, style }) => (
                  <div style={style}>
                    <ComplexItem
                      data={complexes[index]}
                      onClick={onSelect}
                    />
                  </div>
                )}
              </List>
            )}
          </AutoSizer>
        ) : (
            <AutoSizer>
              {({ height, width }) => (
                <List
                  height={height}
                  width={width}
                  itemSize={complexItemHeight + 10}
                  itemCount={Math.ceil(complexes.length / 2)}
                >
                  {({ index, style }) => (
                    <div style={style}>
                      <Grid container>
                        <Grid item md={6}>
                          <ComplexItem
                            data={complexes[2 * index]}
                            onClick={onSelect}
                          />
                        </Grid>
                        <Grid item md={6}>
                          <ComplexItem
                            data={complexes[2 * index + 1]}
                            onClick={onSelect}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </List>
              )}
            </AutoSizer>
          )
      }

    </>
  );
});

export default SearchDetails;

export const ComplexItem = React.memo((props: any) => {
  const { data, onClick } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    name,
    photo,
    priceFrom,
    addr,
    min_date,
    max_date,
    roomsStat,
    totalRooms,
    district,
    common,
    geo
  } = data || {};

  const handleClick = React.useCallback(() => {
    if (geo) {
      // console.log('---selected complex---', data.id);
      dispatch(setViewMap(geo))
    }
  }, [dispatch, geo]);


  if (!data) {
    return null;
  }

  // return (
  //   <Card className={classes.complexItem} onClick={handleClick}>
  //     <pre>
  //       {JSON.stringify(data, null, ' ')}
  //     </pre>
  //   </Card>
  // );

  return (
    <Card className={classes.complexItem}>
      <CardMedia className={classes.media} image={composeUrl(photo, '600')} title={data.name} />
      <CardContent>
        <Typography gutterBottom variant="h6">
          {priceFrom !== Infinity ? (
            <b>от {Number(priceFrom).toLocaleString()} руб. </b>
          ) : (
              <br />
            )}
        </Typography>
        <Typography
          className={classes.greyText}
          variant="subtitle2"
          gutterBottom
        >
          Срок сдачи: {min_date} - {max_date}
        </Typography>
        <Typography gutterBottom>
          <Link to={{ pathname: '/complexinfo', search: `?id=${data.id}` }} target="_blank" className={classes.blueText}><b>ЖК {name}</b></Link>
        </Typography>
        <Typography variant="subtitle2">{addr && addr[0]}</Typography>
        <Typography variant="subtitle2" gutterBottom>
          {district && <District id={district} />}
        </Typography>
        <RoomsStat data={roomsStat} />
        {
          !common && (<Typography className={classes.greyText} variant="subtitle2">
            В продаже {totalRooms} квартир
          </Typography>)
        }

      </CardContent>
      {
        geo && <LocationOnIcon onClick={handleClick} className={classes.geoIcon} />
      }
    </Card>
    // </div>
  );
});

export const ComplexItemList = React.memo((props: any) => {
  const { data, onClick } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    name,
    photo,
    priceFrom,
    addr,
    min_date,
    max_date,
    roomsStat,
    totalRooms,
    district,
    common,
    geo
  } = data || {};

  const handleClick = React.useCallback(() => {
    if (geo) {
      // console.log('---selected complex---', data.id);
      dispatch(setViewMap(geo))
    }
  }, [dispatch, geo]);


  if (!data) {
    return null;
  }

  // return (
  //   <Card className={classes.complexItem} onClick={handleClick}>
  //     <pre>
  //       {JSON.stringify(data, null, ' ')}
  //     </pre>
  //   </Card>
  // );

  return (
    <Card className={classes.complexItem_list}>
      <Grid container>
        <Grid item sm={4} xs={12}>
          <CardMedia className={classes.media} image={composeUrl(photo, '600')} title={data.name} />
        </Grid>
        <Grid item sm={4} xs={12}>
          <CardContent>
            <RoomsStat data={roomsStat} />
            {
              !common && (<Typography className={classes.greyText} variant="subtitle2">
                В продаже {totalRooms} квартир
              </Typography>)
            }
          </CardContent>
        </Grid>
        <Grid item sm={4} xs={12}>
          <CardContent>
            <Typography gutterBottom variant="h6">
              {priceFrom !== Infinity ? (
                <b>от {Number(priceFrom).toLocaleString()} руб. </b>
              ) : (
                  <br />
                )}
            </Typography>
            <Typography
              className={classes.greyText}
              variant="subtitle2"
              gutterBottom
            >
              Срок сдачи: {min_date} - {max_date}
            </Typography>
            <Typography gutterBottom>
              <Link to={{ pathname: '/complexinfo', search: `?id=${data.id}` }} target="_blank" className={classes.blueText}><b>ЖК {name}</b></Link>
            </Typography>
            <Typography variant="subtitle2">{addr && addr[0]}</Typography>
            <Typography variant="subtitle2" gutterBottom>
              {district && <District id={district} />}
            </Typography>
          </CardContent>
        </Grid>
      </Grid>
      {/* <CardMedia className={classes.media} image={composeUrl(photo, '600')} title={data.name} /> */}
      {/* <CardContent> */}
      {/* <Typography gutterBottom variant="h6">
          {priceFrom !== Infinity ? (
            <b>от {Number(priceFrom).toLocaleString()} руб. </b>
          ) : (
              <br />
            )}
        </Typography>
        <Typography
          className={classes.greyText}
          variant="subtitle2"
          gutterBottom
        >
          Срок сдачи: {min_date} - {max_date}
        </Typography>
        <Typography gutterBottom>
          <Link to={{ pathname: '/complexinfo', search: `?id=${data.id}` }} target="_blank" className={classes.blueText}><b>ЖК {name}</b></Link>
        </Typography>
        <Typography variant="subtitle2">{addr && addr[0]}</Typography>
        <Typography variant="subtitle2" gutterBottom>
          {district && <District id={district} />}
        </Typography>
        <RoomsStat data={roomsStat} />
        {
          !common && (<Typography className={classes.greyText} variant="subtitle2">
            В продаже {totalRooms} квартир
          </Typography>)
        } */}

      {/* </CardContent> */}
      {
        geo && <LocationOnIcon onClick={handleClick} className={classes.geoIcon} />
      }
    </Card>
    // </div>
  );
});

function RoomsStat(props: any) {
  const { data } = props;
  const { roomTypes } = useSelector((state: any) => state.search);

  const getRoomStat = React.useCallback(
    (srt) => {
      const found = (data || {})[srt];
      const suff = isNaN(Number(srt)) ? '' : 'к';
      if (found) {
        return `${srt}${suff} - от ${Number(found.s_min).toFixed(
          2
        )} кв.м. от ${Number(found.p_min).toLocaleString()} Руб.`;
      }
      return `${srt}${suff} - нет в продаже`;
    },
    [data]
  );

  const getStyle = React.useCallback((srt) => {
    if (Array.isArray(roomTypes) && roomTypes.length && roomTypes.includes(srt)) {
      return { color: 'green', fontWeight: 'bold' };
    }
    return {};
  }, [roomTypes]);

  return (
    <div>
      {simpleRoomTypes.map((srt) => (
        <Typography key={srt} variant="subtitle2" style={getStyle(srt) as any}>
          {getRoomStat(srt)}
        </Typography>
      ))}
    </div>
  );
}


function District(props: any) {
  // const { } = useSelector((state: any) => )
  const { id } = props;
  const { data, loaded } = useQueryWithStore({
    type: 'getOne',
    resource: 'districts',
    payload: { id }
  });

  if (!id || !loaded) {
    return null;
  }

  return <span>{data?.name}</span>;
  // return <span />
}