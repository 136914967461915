import React, { Suspense } from 'react';

const Component = React.lazy(() => import('./IpotekaBanksReport'));

function Loader() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Component />
    </Suspense>
  )
}

export default Loader;