import * as React from 'react';
import { Route } from 'react-router-dom';
import Calendar from 'calendar';
import ComplexInfo from 'search/ComplexInfo';
import RoomInfo from 'search/RoomInfo';
import SecondRoomInfo from 'search/SecondRoomInfo';
import ListReports from 'components/ListReports';
import ExpertReport from 'components/ExpertReport';
import DeveloperReport from 'components/DeveloperReport';
import Compare from 'search/Compare';
import IpotekaBrokersReport from 'components/IpotekaReports/LoaderIpotekaBrokersReport';
import IpotekaOfficesReport from 'components/IpotekaReports/LoaderIpotekaOfficesReport';
import IpotekaBanksReport from 'components/IpotekaReports/LoaderIpotekaBanksReport';
import IpotekaSignedReport from 'components/IpotekaReports/LoaderIpotekaSignedReport';
import IpotekaPropagationReport from 'components/IpotekaReports/LoaderIpotekaPropagationReport';
import KPI from 'KPI';
import Search2 from 'Search2';


const routes = [
  <Route exact path="/calendar" component={Calendar} />,
  <Route exact path="/complexinfo" component={ComplexInfo} noLayout />,
  <Route exact path="/roominfo" component={RoomInfo} noLayout />,
  <Route exact path="/secondroominfo" component={SecondRoomInfo} noLayout />,
  <Route exact path="/listreports" component={ListReports} />,
  <Route exact path="/expertreport" component={ExpertReport} />,
  <Route exact path="/developerreport" component={DeveloperReport} />,
  <Route exact path="/compare" component={Compare} noLayout />,
  <Route exact path="/ipoteka_brokers_report" component={IpotekaBrokersReport} />,
  <Route exact path="/ipoteka_offices_report" component={IpotekaOfficesReport} />,
  <Route exact path="/ipoteka_banks_report" component={IpotekaBanksReport} />,
  <Route exact path="/ipoteka_signed_report" component={IpotekaSignedReport} />,
  <Route exact path="/ipoteka_propagation_report" component={IpotekaPropagationReport} />,
  <Route exact path="/kpi" component={KPI} />,
  <Route exact path="/search2" component={Search2} noLayout />
];

export default routes;
