import React from 'react';
import {
  useListContext,
  TextInput,
  DateInput,
  RadioButtonGroupInput,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
  ReferenceArrayInput,
  SelectArrayInput
} from 'react-admin';

import ContentFilter from "@material-ui/icons/FilterList";
import SearchIcon from '@material-ui/icons/Search';
import { Box, Button, InputAdornment } from '@material-ui/core';

import { Form } from 'react-final-form';

// const ListFilterButton = () => {
//   const { showFilter } = useListContext();

//   return (
//     <Button size="small" color="primary" onClick={() => showFilter('main', [])} startIcon={<ContentFilter />}>Filter</Button>
//   )
// }

export const dateChoices = [
  { id: 'reservedate', name: 'по дате бронирования' },
  { id: 'contractdate', name: 'по дате заключения договора' },
  { id: 'kvpaiddate', name: 'по дате оплаты КВ' },
  { id: 'developerpaiddate', name: 'по дате оплаты квартиры застройщику' }
]

const dateChoices2 = [...dateChoices, { id: 'createdAt', name: 'по дате создания' }];

const listKindChoices = [
  { id: 'active', name: 'Активные' },
  { id: 'success', name: 'Успешные' },
  { id: 'reject', name: 'Отказ от работы' },
  { id: 'all', name: 'Все' }
]

const ListFilterForm = (props: any) => {
  const { role, ...otherProps } = props;
  const { filterValues, setFilters, hideFilter, loading } = useListContext();
  // console.log('---other props---', otherProps);
  const onSubmit = (values: any) => {
    // console.log('---list filter---', values);
    if (Object.keys(values).length > 0) {
      setFilters(values, []);
    }
  }

  // const resetFilter = () => {
  //   setFilters({}, []);
  // }

  return (
    <div>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
        {
          ({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              {
                ['supermanager', 'admin'].includes(role) && ([
                  <Box component="span" mr={2} key="city">
                    <ReferenceInput {...otherProps} source="city" reference="cities" filter={{ active: true }} sort={{ field: 'name', order: 'ASC' }} perPage={500} label="Город">
                      <SelectInput optionText="name" />
                    </ReferenceInput>
                  </Box>,
                  <Box component="span" mr={2} minWidth={150} key="office">
                    <FormDataConsumer>
                      {
                        ({ formData }) => (
                          formData?.city ?
                            <ReferenceArrayInput {...otherProps} source="offices" reference="offices" filter={{ active: true, city: formData.city }} sort={{ field: 'name', order: 'ASC' }} perPage={500} label="Офисы" allowEmpty={false}>
                              <SelectArrayInput optionText="name" />
                            </ReferenceArrayInput> :
                            <TextInput {...otherProps} source="office" disabled />
                        )
                      }
                    </FormDataConsumer>
                  </Box>
                ])
              }
              {
                ['cityhead', 'broker'].includes(role) && ([
                  <Box component="span" mr={2} minWidth={150}>
                    <FormDataConsumer>
                      {
                        ({ formData }) => (
                          formData?.city ?
                            <ReferenceArrayInput {...otherProps} source="offices" reference="offices" filter={{ active: true, city: formData.city }} sort={{ field: 'name', order: 'ASC' }} perPage={500} label="Офисы" allowEmpty={false}>
                              <SelectArrayInput optionText="name" />
                            </ReferenceArrayInput> :
                            <TextInput {...otherProps} source="office" disabled />
                        )
                      }
                    </FormDataConsumer>
                  </Box>
                ])
              }
              <Box display="flex" alignItems="flex-end">
                <Box component="span" mr={2}>
                  <SelectInput source="list_kind" label="Статус документа" choices={listKindChoices} />
                </Box>
                <Box component="span" mr={2}>
                  <SelectInput source="date_type" label="Выборка по" choices={dateChoices2} />
                </Box>
                <Box component="span" mr={2}>
                  <DateInput source="date_gt" label="Период с" />
                </Box>
                <Box component="span" mr={2}>
                  <DateInput source="date_lt" label="Период по" />
                </Box>
                <Box component="span" mr={2} mb={3.5}>
                  <Button variant="outlined" color="primary" type="submit">Загрузить</Button>
                </Box>
              </Box>
            </form>
          )
        }
      </Form>
    </div>
  )

}

const ListFilter = (props: any) => {
  // return props.context === 'button' ? (
  //   <ListFilterButton {...props} />
  // ) : (
  //     <ListFilterForm {...props} />
  //   )
  // return <ListFilterForm {...props} />
  return props.context === 'button' ? null : (
    <ListFilterForm {...props} />
  )
}

export default ListFilter;