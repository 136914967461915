import React from 'react';
import DG from '2gis-maps';
import { Portal } from 'react-portal';


// import CancelIcon from '@material-ui/icons/Cancel';
import { useDispatch, useSelector } from 'react-redux';
import { setCity, setObject } from 'reducers/search-slice';


const defaultIcon = DG.divIcon({
  className: 'map-icon',
  iconSize: new DG.Point(15, 15),
});

const defaultView = {
  center: [54.98, 82.89],
  zoom: 4,
};

const SearchMap = (props: any) => {
  const dispatch = useDispatch();
  const [complexIcons, setComplexIcons] = React.useState<any>([]);
  const { city: selectedCity, viewMap } = useSelector((state: any) => state.search);

  const cities = useSelector((state: any) => state.search.citiesData);

  const complexes = useSelector((state: any) => state.search.complexesData);

  const ref = React.useRef(null);
  const [map, setMap] = React.useState<any>(null);
  const citiesLayer = React.useRef<any>(null);
  const complexesLayer = React.useRef<any>(null);

  React.useEffect(() => {
    const mapObj = DG.map(ref.current, defaultView);
    mapObj.on('zoomend', (e: any) => {
      if (e.target.getZoom() < 7) {
        dispatch(setCity(''));
        citiesLayer.current?.addTo(mapObj);
        complexesLayer.current?.remove();
      }
    });
    setMap(mapObj);
    return () => {
      mapObj.remove();
    };
  }, [dispatch]);

  // city markers
  React.useEffect(() => {
    if (map && cities && cities.length) {
      const layer = new DG.LayerGroup();
      cities.forEach((c: any) => {
        if (c.geo) {
          const { lat, lng } = c.geo;
          const marker = DG.marker([lat, lng], {
            title: c.name,
            icon: defaultIcon,
          });
          marker.on('click', () => {
            dispatch(setCity(c.id));
          });
          marker.addTo(layer);
        }
      });
      citiesLayer.current = layer;
      layer.addTo(map);
    }
  }, [map, cities, dispatch]);

  // zoom to selected city
  React.useEffect(() => {
    if (map) {
      if (!selectedCity) {
        map.setView(defaultView.center, defaultView.zoom);
      } else if (cities) {
        const city: any = cities.find((c: any) => c.id === selectedCity);
        complexesLayer.current?.remove();
        if (city && city.geo) {
          const { lat, lng } = city.geo;
          map.setView([lat, lng], 12);
          citiesLayer.current?.remove();
        }
      }
    }
  }, [map, cities, selectedCity]);

  // complexes
  React.useEffect(() => {
    if (map && complexes && complexes.length) {
      complexesLayer.current?.remove();
      const layer = new DG.LayerGroup();
      const iconsData: any = [];
      complexes.forEach((c: any) => {
        if (c.geo) {
          try {
            const { lat, lng } = c.geo;
            const complexIcon = DG.divIcon({
              // className: 'map-icon',
              className: 'map-icon-zero',
              iconSize: new DG.Point(15, 15),
              // html: `<div class="complex-name" id="c_${c.id}">${c.name}</div>`
              html: `<div id="c_${c.id}"/>`
            })
            const marker = DG.marker([lat, lng], {
              title: c.name,
              icon: complexIcon,
            }); //.bindPopup(c.name);
            marker.on('click', () => {
              dispatch(setObject(c.id));
            });
            marker.addTo(layer);
            iconsData.push(<ComplexIcon key={c.id} data={c} />);
          } catch (ex) { }
        }
      });
      complexesLayer.current = layer;
      layer.addTo(map);
      setComplexIcons(iconsData);
    }
  }, [map, complexes, dispatch]);

  React.useEffect(() => {
    if (map && viewMap) {
      const { lat, lng } = viewMap || {};
      if (lat && lng) {
        map.setView([lat, lng], 16);
      }
    }
  }, [viewMap, map]);

  return <div style={{ flex: 1, position: 'relative', borderRadius: '10px 10px 0 0' }} ref={ref}>
    {/* <SelectedComplexInfo /> */}
    {complexIcons}
  </div>;
};

export default SearchMap;

// export function SelectedComplexInfo(props: any) {
//   const { selected, complexesData } = useSelector((state: any) => state.search);
//   const dispatch = useDispatch();

//   const selectedComplexData = React.useMemo(() => {
//     if (selected && complexesData) {
//       const found = complexesData.find((c: any) => c.id === selected);
//       if (found) {
//         return complexStat(found);
//       }
//     }
//     return null;
//   }, [selected, complexesData]);

//   if (!selected || !selectedComplexData) {
//     return null;
//   }

//   return (
//     <div style={{ position: 'absolute', top: 0, left: 0, width: 340, background: 'transparent', paddingTop: 10, zIndex: 10000, transform: 'scaleY(0.7) scaleX(0.7)', transformOrigin: 'left top' }}>
//       <CancelIcon style={{ position: 'absolute', top: 15, right: 10, cursor: 'pointer', zIndex: 1 }} onClick={() => dispatch(setObject(''))} />
//       <ComplexItem data={selectedComplexData} />

//     </div>
//   )
// }

export function ComplexIcon(props: any) {
  const { filteredComplexes } = useSelector((state: any) => state.search);
  const { data = {} } = props;
  const { id, name } = data;

  const isFiltered = React.useMemo(() => {
    if (!filteredComplexes.length) {
      return true;
    }
    return filteredComplexes.includes(id);
  }, [filteredComplexes, id]);


  return (
    <Portal node={document && document.getElementById(`c_${data.id}`)}>
      {
        isFiltered ? (
          <div className='map-icon' style={{ opacity: 1, background: 'red' }}>
            <div className="complex-name" >
              {name}
            </div>
          </div>
        ) : (
          <div className='map-icon' style={{ opacity: 0.8, background: 'silver' }}>
            <div className="complex-name" style={{ opacity: 0.8, background: 'silver' }}>
              {name}
            </div>
          </div>
        )
      }

    </Portal>
  )
}