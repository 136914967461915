import { Card2 } from './Card';
import Typography from '@material-ui/core/Typography';

import RoomPriceLoader from 'search/RoomPriceLoader';

function PricesGraph(props: any) {
  const { buildings, building } = props;

  return (
    <Card2>
      <Typography gutterBottom variant="h5">Динамика Цен</Typography>
      <div style={{ marginTop: 24 }}>
        <RoomPriceLoader buildingsData={buildings} selectedBuilding={building} />
      </div>
    </Card2>
  )
}

export default PricesGraph;