import * as React from 'react';
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
import { parse } from 'query-string';
import SearchDetailsRoomInfo from 'search/SearchDetailsRoomInfo';

const RoomInfo = (props: any) => {
  const { id } = parse(props.location.search);
  if (!id) {
    return null
  }

  return <SearchDetailsRoomInfo selected={id} />

  // return (
  //   <Card>
  //     <CardContent>
  //       Room Info
  //       {/* <div>{JSON.stringify(props)}</div> */}
  //       <div>{id}</div>
  //     </CardContent>
  //   </Card>
  // );
}

export default RoomInfo;