import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import ObjectViewContext from 'ObjectViewContext';

export const ObjectStartSelectButton = (props: any) => {
  const { record } = props;
  const { id, name } = record;
  const { setList } = React.useContext(ObjectViewContext);

  const handleClick = () => {
    setList({ id, name });
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleClick}
      component={Link as any}
      to="/"
    >
      Подбор Объектов
    </Button>
  );
};
