import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { RoomType } from './roomtypes';
import Room, { InfoRoom } from './Room';

interface FloorProps {
  floor: number;
  rooms: Array<RoomType>;
  onSelectRoom?: any;
  onSelectFloor?: any;
  selectedRooms?: any;
}

interface InfoFloorProps extends FloorProps {
  rpf: number;
  disabled: boolean;
}

const useStyles = makeStyles((theme) => ({
  floor: {
    display: 'flex',
    height: 50,
    marginBottom: theme.spacing(1)
  },
  floorNumber: {
    minWidth: 30,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  }
}));

const Floor = (props: FloorProps) => {
  const { floor, rooms, onSelectRoom, onSelectFloor, selectedRooms } = props;
  const classes = useStyles();
  const floorRooms = React.useMemo(() => rooms.filter(r => r.floor === floor), [rooms, floor]);
  return (
    <div className={classes.floor}>
      <Typography variant="caption" className={classes.floorNumber} onClick={() => onSelectFloor && onSelectFloor(floor)}>{floor}</Typography>
      {
        floorRooms.map(r => <Room key={r.rpf} data={r} onSelectRoom={onSelectRoom} selectedRooms={selectedRooms} />)
      }
    </div>
  )
}

export default Floor;

export const InfoFloor = (props: InfoFloorProps) => {
  const { rooms = [], rpf, onSelectRoom, onSelectFloor, disabled } = props;
  const classes = useStyles();

  const floorRooms = React.useMemo(() => {
    const ret: Array<RoomType> = [];
    for (let i = 1; i <= rpf; i++) {
      const foundRoom = rooms.find(r => r.floor === 0 && r.rpf === i) || { floor: 0, rpf: i } as RoomType;
      ret.push({ ...foundRoom });
    }
    return ret;
  }, [rpf, rooms]);
  return (
    <div className={classes.floor}>
      <div className={classes.floorNumber} onClick={() => onSelectFloor && onSelectFloor(0)} />
      {
        !disabled && floorRooms.map(r => <InfoRoom key={r.rpf} data={r} onSelectRoom={onSelectRoom} />)
      }
    </div>
  )
}