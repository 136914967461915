import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  ReferenceManyField,
  SingleFieldList,
  ChipField,
  ReferenceArrayField,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  ImageField,
  BooleanField,
  FileField,
  UrlField,
  BooleanInput,
  FileInput,
  ReferenceArrayInput,
  ImageInput,
  SelectArrayInput,
  SelectInput
  // Pagination
} from 'react-admin';

import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

// import ListPagination from 'components/ListPagination';

export const name = 'developers';

const isAdmin = (role: string) => ['admin', 'supermanager', 'superofficemanager'].includes(role);

const DeveloperListFilter = (props: any) => (
  <Filter {...props}>
    <BooleanInput source="active" alwaysOn />
    {/* <ReferenceInput
      source="attachedCity"
      reference="cities"
      filterToQuery={(txt: string) => ({ name: txt })}
      alwaysOn
      allowEmpty={false}
      sort={{ field: 'name', order: 'ASC' }}
      filter={{ active: true }}
      perPage={100}
    >
      <AutocompleteInput optionText="name" allowEmpty={false} />
    </ReferenceInput> */}
    <ReferenceInput
      source="attachedCity"
      reference="cities"
      alwaysOn
      sort={{ field: 'name', order: 'ASC' }}
      allowEmpty={false}
      perPage={1000}
    >
      <SelectInput source="name" />
    </ReferenceInput>
    <TextInput source="name" />
  </Filter>
);

const UserDeveloperListFilter = (props: any) => (
  <Filter {...props}>
    <BooleanInput source="active" alwaysOn />
  </Filter>
);

// const PostPagination = (props: any) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

export const list = (props: any) => {
  const { permissions = {} } = props;
  const { city, role } = permissions;
  if (!city) {
    return null;
  }

  if (isAdmin(role)) {
    return (
      <List
        {...props}
        bulkActionButtons={false}
        filters={<DeveloperListFilter />}
        filterDefaultValues={{ active: true, attachedCity: city }}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <Datagrid rowClick="show">
          <BooleanField source="active" />
          <TextField source="name" />
          <ReferenceArrayField reference="cities" source="attachedCity">
            <SingleFieldList linkType="show">
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
        </Datagrid>
      </List>
    );
  }

  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<UserDeveloperListFilter />}
      filterDefaultValues={{ active: true }}
      filter={{ attachedCity: city }}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <Datagrid rowClick="show">
        <BooleanField source="active" />
        <TextField source="name" />
        <ReferenceArrayField reference="cities" source="attachedCity">
          <SingleFieldList linkType="show">
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
      </Datagrid>
    </List>
  );
};

export const show = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <BooleanField source="active" />
      <TextField source="name" />
      <TextField source="info" />
      <ImageField source="logo.src" />
      <TextField source="contactPhone" />
      <TextField source="contactName" />
      <TextField source="contactRole" />
      <TextField source="contactEmail" />
      <TextField source="buhPhone" />
      <TextField source="buhName" />
      <TextField source="buhRole" />
      <TextField source="buhEmail" />
      <TextField source="mainManPhone" />
      <TextField source="mainManName" />
      <TextField source="mainManRole" />
      <TextField source="mainManEmail" />
      <TextField source="managerPhone" />
      <TextField source="officialName" />
      <TextField source="inn" />
      <TextField source="ogrn" />
      <TextField source="realAddress" />
      <TextField source="officialAddress" />
      <TextField source="officeAddress" />
      <TextField source="bankAccount" />
      <TextField source="BIK" />
      <TextField source="bankName" />
      <TextField source="contractNumber" />
      <TextField source="contractDate" />
      <TextField source="contractPeriod" />
      <TextField source="contractProlongation" />
      <TextField source="commissionAmount" />
      <TextField source="comissionConditions" />
      <TextField source="commissionPayDay" />
      <UrlField source="site" />
      <FileField source="docs" src="src" title="title" target="_blank" />
      <ReferenceArrayField reference="cities" source="attachedCity">
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceManyField reference="complexes" target="developer" label="ЖК">
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceManyField>
      <CreateNewComplexButton />
    </SimpleShowLayout>
  </Show>
);

export const create = (props: any) => {
  const { permissions = {} } = props;
  const { city, role } = permissions;

  if (!city) {
    return null;
  }

  return (
    <Create {...props}>
      <SimpleForm initialValues={{ attachedCity: [city] }}>
        <BooleanInput source="active" />
        <TextInput source="name" />
        <TextInput source="info" fullWidth multiline />
        <ImageInput source="logo">
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput source="contactPhone" />
        <TextInput source="contactName" />
        <TextInput source="contactRole" />
        <TextInput source="contactEmail" />
        <TextInput source="buhPhone" />
        <TextInput source="buhName" />
        <TextInput source="buhRole" />
        <TextInput source="buhEmail" />
        <TextInput source="mainManPhone" />
        <TextInput source="mainManName" />
        <TextInput source="mainManRole" />
        <TextInput source="mainManEmail" />
        <TextInput source="managerPhone" />
        <TextInput source="officialName" />
        <TextInput source="inn" />
        <TextInput source="ogrn" />
        <TextInput source="realAddress" />
        <TextInput source="officialAddress" />
        <TextInput source="officeAddress" />
        <TextInput source="bankAccount" />
        <TextInput source="BIK" />
        <TextInput source="bankName" />
        <TextInput source="contractNumber" />
        <TextInput source="contractDate" />
        <TextInput source="contractPeriod" />
        <TextInput source="contractProlongation" />
        <TextInput source="commissionAmount" />
        <TextInput source="comissionConditions" />
        <TextInput source="commissionPayDay" />
        <TextInput source="site" />
        <FileInput source="docs" multiple>
          <FileField source="src" title="title" />
        </FileInput>
        {
          isAdmin(role) && (
            <ReferenceArrayInput
              reference="cities"
              source="attachedCity"
              perPage={100}
            >
              <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
          )
        }
      </SimpleForm>
    </Create>
  )
};

export const edit = (props: any) => {
  const { permissions = {} } = props;
  const { city, role } = permissions;

  if (!city) {
    return null;
  }
  return (
    <Edit {...props}>
      <SimpleForm>
        <BooleanInput source="active" />
        <TextInput source="name" />
        <TextInput source="info" fullWidth multiline />
        <ImageInput source="logo">
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput source="contactPhone" />
        <TextInput source="contactName" />
        <TextInput source="contactRole" />
        <TextInput source="contactEmail" />
        <TextInput source="buhPhone" />
        <TextInput source="buhName" />
        <TextInput source="buhRole" />
        <TextInput source="buhEmail" />
        <TextInput source="mainManPhone" />
        <TextInput source="mainManName" />
        <TextInput source="mainManRole" />
        <TextInput source="mainManEmail" />
        <TextInput source="managerPhone" />
        <TextInput source="officialName" />
        <TextInput source="inn" />
        <TextInput source="ogrn" />
        <TextInput source="realAddress" />
        <TextInput source="officialAddress" />
        <TextInput source="officeAddress" />
        <TextInput source="bankAccount" />
        <TextInput source="BIK" />
        <TextInput source="bankName" />
        <TextInput source="contractNumber" />
        <TextInput source="contractDate" />
        <TextInput source="contractPeriod" />
        <TextInput source="contractProlongation" />
        <TextInput source="commissionAmount" />
        <TextInput source="comissionConditions" />
        <TextInput source="commissionPayDay" />
        <TextInput source="site" />
        <FileInput source="docs" multiple>
          <FileField source="src" title="title" />
        </FileInput>
        {/* <ReferenceArrayInput
        reference="cities"
        source="attachedCity"
        perPage={100}
      >
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput> */}
        {
          isAdmin(role) && (
            <ReferenceArrayInput
              reference="cities"
              source="attachedCity"
              perPage={100}
            >
              <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
          )
        }
      </SimpleForm>
    </Edit>
  )
};


function CreateNewComplexButton(props: any) {
  const { record } = props;
  const { id } = record;

  return (
    <Button
      variant="contained"
      color="primary"
      component={Link as any}
      to={`/complexes/create?developer=${id}`}
    >
      Добавить ЖК
    </Button>
  )
}