import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    background: '#FFF',
    padding: '12px 24px',
    borderRadius: 10,
    marginBottom: theme.spacing(3)
  },
  container2: {
    background: '#FFF',
    padding: '24px',
    borderRadius: 10,
    marginBottom: theme.spacing(3)
  },
}));

function Card(props: any) {
  const classes = useStyles();
  return <div {...props} className={classes.container} />
}

export default Card;

export function Card2(props: any) {
  const classes = useStyles();
  return <div {...props} className={classes.container2} />
}