import firebase from 'firebase/app';
// import 'firebase/database';
// import 'firebase/firebase';

import parseISO from 'date-fns/parseISO';
import minDate from 'date-fns/min';
import maxDate from 'date-fns/max';
import format from 'date-fns/format';

export const composedRoomTypes: any = {
  '56cd7e5b4fd597460a9064c8': 'C',
  '56cd7e6b4fd597460a9064c9': '2',
  '56cd7e7e4fd597460a9064ca': '1',
  '56cd7e7e4fd597460a9064cb': '3',
  '56cd7ea64fd597460a9064cc': '2',
  '56cd7eb74fd597460a9064cd': '4+',
  '56e92e3e3dc8563c233f052f': '4+',
  '56e92e673dc8563c233f0531': '4+',
  '571f4db896184abb65a10eff': '3',
  '573c5b6a8951b74668103b96': '4+',
  '573c5ba08951b74668103b99': '4+',
  '577bab74d0348f6056d7a1fc': '4+',
  '577bab9dd0348f6056d7a1fe': 'КН',
  '57bd589b9d13c28818a9d2a6': '4+',
  '57ff4c01cc382759671d4a4d': '1',
  '57ff4c2acc382759671d4a4f': '3',
  '57ff4c49cc382759671d4a51': '4+',
  '5802976ccc382759671d55d4': 'ОФ',
  '580297a9cc382759671d55d6': 'П',
  '58f4747ff45a9a1815c855d8': '4+',
  VdfvBRE80iLDiDm9OPWU: '4+',
  Vmdsy3owaTkTd6ns8z8R: '4+',
  '8GpGeaSUtgFok6vQjlhz': '2',
  B82RaeEy1JpBQrj76GnR: '1',
  DPtd1mktiWnnGBNuNVML: 'НЖ',
};

export const simpleRoomTypes = ['C', '1', '2', '3', '4+'];
export const sortOrder: any = { C: 1, '1': 2, '2': 3, '3': 4, '4': 5, '4+': 6 };

export const fetchCitiesData = async () => {
  const db = firebase.firestore();
  const querySnapshot = await db
    .collection('cities')
    .where('active', '==', true)
    .orderBy('name', 'asc')
    .get();

  const data: any = [];
  querySnapshot.forEach((doc) => {
    data.push({ id: doc.id, ...doc.data() });
  });
  return data;
};

export const fetchComplexesData = async (cityId: string) => {
  const db = firebase.firestore();
  const querySnapshot = await db
    .collection('rooms_search')
    .where('city', '==', cityId)
    .get();
  console.log('---fetch rooms_search---');
  const data: any = [];
  querySnapshot.forEach((doc) => {
    data.push(complexStat({ id: doc.id, ...doc.data() }));
  });
  return data;
};

export const fetchDeveloperData = async (id: string) => {
  const db = firebase.firestore();
  const doc = await db.collection('developers').doc(id).get();

  if (doc.exists) {
    return { id, ...doc.data() };
  }
  return { id };
};

export const fetchComplexData = async (id: string) => {
  const db = firebase.firestore();
  const doc = await db.collection('complexes').doc(id).get();

  if (doc.exists) {
    return { id, ...doc.data() };
  }
  return { id };
};

export const fetchBuildingData = async (id: string) => {
  const db = firebase.firestore();
  const doc = await db.collection('buildings').doc(id).get();

  if (doc.exists) {
    return { id, ...doc.data() };
  }
  return { id };
};

export const fetchRoomtypesData = async () => {
  // console.log('---fetch rooms---');
  const db = firebase.firestore();
  const querySnapshot = await db
    .collection('roomtypes')
    .where('active', '==', true)
    .orderBy('short', 'asc')
    .get();

  const data: any = [];
  querySnapshot.forEach((doc) => {
    // console.log('---roomtypes---', doc.id, doc.data());
    const roomType = { id: doc.id, ...doc.data() };
    data.push(roomType);
  });
  return data;
};

export const fetchSectionsData = async (buildingId: string) => {
  const db = firebase.firestore();
  const data: any = [];
  const querySnapshot = await db
    .collection('entrances')
    .where('active', '==', true)
    .where('building', '==', buildingId)
    .orderBy('number', 'asc')
    .get();

  querySnapshot.forEach((doc) => {
    const section = { id: doc.id, ...doc.data() };
    data.push(section);
  });

  return data;
};

export const fetchDistrictsData = async (cityId: string) => {
  const db = firebase.firestore();
  const querySnapshot = await db
    .collection('districts')
    .where('active', '==', true)
    .where('city', '==', cityId)
    .orderBy('name', 'asc')
    .get();

  const data: any = [];
  querySnapshot.forEach((doc) => {
    data.push({ id: doc.id, ...doc.data() });
  });
  return data;
};

export const fetchBuildingsData = async (complexId: string) => {
  const db = firebase.firestore();
  const querySnapshot = await db
    .collection('buildings')
    .where('active', '==', true)
    .where('complex', '==', complexId)
    .orderBy('name', 'asc')
    .get();

  const data: any = [];
  querySnapshot.forEach((doc) => {
    data.push({ id: doc.id, ...doc.data() });
  });
  return data;
};

export const fetchEventsData = async (user: string) => {
  const db = firebase.firestore();
  if (!user) {
    return [];
  }
  const querySnapshot = await db
    .collection('tasks')
    .where('user', '==', user)
    .get();

  const data: any = [];
  querySnapshot.forEach((doc) => {
    data.push({ id: doc.id, ...doc.data() });
  });
  return data;
};

function filterObject(obj: any) {
  return Object.entries(obj).reduce(
    (m: any, [k, v]) => (v !== undefined && v !== null ? { ...m, [k]: v } : m),
    {}
  );
}

export const addRooms = async (params: any) => {
  const db = firebase.firestore();
  return db.collection('roomadds').add(filterObject(params));
};

export function complexStat(_data: any, dealtype?: any) {
  if (!_data) {
    return {};
  }
  const data = JSON.parse(JSON.stringify(_data));
  const photos = Object.entries(data).reduce((m: any, [k, v]: any) => {
    if (`${k}`.startsWith('b_') && v) {
      const building_photos = v.photo.map((p: any) => p.src);
      return [...m, ...building_photos];
    }
    return m;
  }, []);

  const dates = Object.entries(data).reduce((m: any, [k, v]: any) => {
    if (`${k}`.startsWith('b_') && v) {
      return [...m, parseISO(v.date)];
    }
    return m;
  }, []);

  let min_date = '';
  let max_date = '';

  try {
    min_date = format(minDate(dates), 'Q кв. yyyy г.');
    max_date = format(maxDate(dates), 'Q кв. yyyy г.');
  } catch (ex) {}

  const addr = Object.entries(data)
    .reduce((m: any, [k, v]: any) => {
      if (`${k}`.startsWith('b_') && v) {
        return [...m, v.address ?? ''];
      }
      return m;
    }, [])
    .filter(Boolean);

  const m1: any = {};

  Object.entries(data).forEach(([kk, vv]: any) => {
    if (`${kk}`.startsWith('e_') && vv) {
      const obj = ![2, 3].includes(dealtype) ? vv : vv[`byDealtype${dealtype}`];
      if (obj && Object.keys(obj)) {
        Object.entries(obj).forEach(([k, v]: any) => {
          if (`${k}`.startsWith('r_') && !`${k}`.includes('_unknown')) {
            const rt = composedRoomTypes[`${k}`.replace('r_', '')] || '0';
            if (!m1[rt]) {
              m1[rt] = v;
            } else {
              const r = m1[rt];
              r.num += v.num || 0;
              r.p_min = r.p_min < v.p_min ? r.p_min : v.p_min;
              r.s_min = r.s_min < v.s_min ? r.s_min : v.s_min;
              r.p_max = r.p_max > v.p_max ? r.p_max : v.p_max;
              r.s_max = r.s_max > v.s_max ? r.s_max : v.s_max;
            }
          }

          // if (`${k}`.startsWith('byDealtype') && Object.keys(v).length) {
          //   const dealtype = k.replace('byDealtype', '');
          //   m1[`dealtype${dealtype}`] = true;
          // }
        });
      }
    }
  });

  const m2: any = {};

  Object.entries(data).forEach(([k, v]: any) => {
    if (`${k}`.startsWith('rs_') && v) {
      const rt = composedRoomTypes[v?.type] || '0';
      if (!m2[rt]) {
        m2[rt] = v;
      } else {
        const r = m2[rt];
        r.num += v.num || 0;
        r.p_min = r.p_min < v.p_min ? r.p_min : v.p_min;
        r.s_min = r.s_min < v.s_min ? r.s_min : v.s_min;
        r.p_max = r.p_max > v.p_max ? r.p_max : v.p_max;
        r.s_max = r.s_max > v.s_max ? r.s_max : v.s_max;
      }
    }
  });

  const m = Object.keys(m1).length || dealtype !== undefined ? m1 : m2;

  const priceFrom = Math.min(
    ...Object.values(m)
      .filter((v: any) => v.p_min > 0)
      .map((v: any) => Number(v.p_min))
  );

  const priceTo = Math.max(
    ...Object.values(m)
      .filter((v: any) => v.p_max > 0)
      .map((v: any) => Number(v.p_max))
  );

  const spaceFrom = Math.min(
    ...Object.values(m)
      .filter((v: any) => v.s_min > 0)
      .map((v: any) => Number(v.s_min))
  );

  const totalRooms = Object.values(m).reduce(
    (m, v: any) => (Number(v?.num) ? m + v.num : m),
    0
  );

  const extObj: any = {
    photos,
    photo: photos[0] ?? '',
    dates,
    min_date,
    max_date,
    roomsStat: m,
    priceFrom: priceFrom === Infinity ? 0 : priceFrom,
    priceTo: priceTo === Infinity ? 0 : priceTo,
    spaceFrom: spaceFrom === Infinity ? 0 : spaceFrom,
    totalRooms,
    addr,
    common: !!!Object.keys(m1).length,
  };

  return { ...data, ...extObj };
}
