import React from 'react';

import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import { DataGrid } from '@material-ui/data-grid';

import { makeStyles } from '@material-ui/core/styles';

import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';

import Card from './Card';
import { SelectControl } from '../Controls';
import { simpleRoomTypes } from 'search/SearchData';

import { FiltersContext, ViewContext, viewModes } from './Context';

import { useQueryWithStore } from 'react-admin';
import { roomSides } from 'components/RoomsGrid/Room';
import { composedRoomTypes } from 'search/SearchData';
import { composeUrl } from 'libs/parseHelper';

import Icon0 from '../images/selectedroomicon0.svg';
import Icon1 from '../images/selectedroomicon1.svg';
import Icon2 from '../images/selectedroomicon2.svg';

const useStyles = makeStyles((theme) => ({
  floor: {
    display: 'flex',
    minHeight: '32px',
    marginBottom: '2px',
  },
  floorNumber: {
    minWidth: '32px',
    height: '100%',
    flex: 0,
    textAlign: 'center',
  },
  singleSection: {
    marginRight: 24
    // overflowY: 'auto'
    // margin: '10px 10px 10px',
  },
  room: {
    minWidth: '32px',
    width: '32px',
    marginRight: '2px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 2
    // cursor: 'pointer',
  },
  room1: {
    minWidth: '147px',
    width: '147px',
    marginRight: '2px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderRadius: 2,
    background: '#F5F5F5',
    // cursor: 'pointer',
  },
  sectionsRoot: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    overflowY: 'scroll',
    backgroundColor: theme.palette.background.paper,
    marginTop: 24,
    flex: 1
  },
  selectedRoom: {
    overflow: 'hidden',
    width: 340,
    // height: 520,
    marginTop: 30,
    boxShadow: '1px 6px 13px 3px rgb(0 0 0 / 12%)',
    borderRadius: 8,
    flex: '0 0 auto',
    alignSelf: 'flex-start'
  },
  selectedRoomHeader: {
    height: 40,
    background: '#3F51B5'
  }
}));

const ownerChoices = [
  { id: '0', name: 'Застройщик' },
  { id: '2', name: 'Подрядчик' },
  { id: '3', name: 'Уступка СЗ' },
]

const gridModes = [
  { id: '0', name: 'По умолчанию' },
  { id: '1', name: 'Расширенный' },
  { id: '2', name: 'Список' }
]

const roomTypes = simpleRoomTypes.map(d => ({ id: d, name: d }));

const GridModeContext = React.createContext<any>('');

function RoomsGrid(props: any) {
  const { building } = props;
  const classes = useStyles();
  const [viewMode, setViewMode] = React.useState('0');
  const [roomsFilter, setRoomsFilter] = React.useState<string[]>([]);
  const [ownerFilter, setOwnerFilter] = React.useState<string[]>([]);
  const [selectedRoom, setSelectedRoom] = React.useState();

  const { data: sections } = useQueryWithStore({
    type: 'getList',
    resource: building ? 'entrances' : '',
    payload: {
      pagination: { page: 1, perPage: 1000 },
      filter: { active: true, building },
      sort: { field: 'number', order: 'ASC' }
    }
  });

  return (
    <FiltersContext.Provider value={{ roomsFilter, ownerFilter }}>
      <Card>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'inline-block', width: 180 }}>
            <SelectControl data={roomTypes} value={roomsFilter} onChange={setRoomsFilter} label="Вид комнат" multiple />
          </div>
          <div style={{ display: 'inline-block', width: 180 }}>
            <SelectControl data={ownerChoices} value={ownerFilter} onChange={setOwnerFilter} label="Вид сделки" multiple />
          </div>
          <div style={{ flex: 1 }} />
          <div style={{ display: 'inline-block', width: 180 }}>
            <SelectControl data={gridModes} value={viewMode} onChange={setViewMode} label="Вид отображения" allowClear={false} />
          </div>
        </div>
        {
          viewMode !== '2' && <Legend />
        }
        <div style={{ display: 'flex' }}>
          <GridModeContext.Provider value={viewMode}>
            {
              viewMode === '2' ? (
                <div style={{ height: 650, width: '100%', marginTop: 24 }}>
                  <RoomsList sections={sections} />
                </div>
              ) : (
                <div className={classes.sectionsRoot}>
                  {sections?.map((s: any) => (
                    <SingleSection key={s.id} data={s} onSelectRoom={setSelectedRoom} />
                  ))}
                </div>
              )
            }

          </GridModeContext.Provider>
          {
            Boolean(selectedRoom && viewMode !== '2') && (
              <div className={classes.selectedRoom}>
                <div className={classes.selectedRoomHeader}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100%', padding: '0 24px' }}>
                    <div style={{ marginRight: 8 }}><img src={Icon0} alt="icon0" /></div>
                    <div><img src={Icon1} alt="icon1" /></div>
                    <div style={{ flex: 1 }} />
                    <div><img src={Icon2} alt="icon2" /></div>
                  </div>
                </div>
                <SelectedRoom data={selectedRoom} />
              </div>
            )
          }
        </div>
      </Card>
    </FiltersContext.Provider>
  )
}

export default RoomsGrid;

const legendData = [
  { id: '0', name: 'Свободные', color: '#519872' },
  { id: '1', name: 'Забронированные', color: '#EFA00B' },
  { id: '2', name: 'Проданные', color: '#E0E0E0' },
  { id: '3', name: 'Недоступно', color: '#E42535' }
]

function Legend() {
  return (
    <div style={{ marginTop: 24 }}>
      {
        legendData.map(l => {
          return (
            <div style={{ display: 'inline-flex', marginRight: 24 }}>
              <div style={{ width: 24, height: 24, background: l.color, borderRadius: 4, marginRight: 8 }} />
              <Typography variant="subtitle1">{l.name}</Typography>
            </div>
          )
        })
      }
    </div>
  )
}
const range = (n: number) => Array.from(Array(n).keys());

function SingleSection(props: any) {
  const { data, onSelectRoom, allowLink } = props;
  const classes = useStyles();
  const { floorsnum, number, id, lastupdate, updatedAt } = data;
  const rooms = React.useMemo(() => calcRooms(data), [data]);

  const updated = React.useMemo(() => {
    try {
      if (lastupdate) {
        return format(lastupdate?.toDate(), 'dd.MM.yyyy HH:mm');
      }
      if (updatedAt) {
        return format(parseISO(updatedAt), 'dd.MM.yyyy HH:mm');
      }
    } catch (ex) { }
    return '';
  }, [lastupdate, updatedAt]);

  return (
    <div className={classes.singleSection}>
      <Typography variant="body2">Секция {number}</Typography>
      <Typography variant="caption" style={{ color: '#7A7A7A', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Дата обновления: {updated}</Typography>
      <InfoFloor data={data} />
      {range(floorsnum).map((n) => {
        const floor = floorsnum - n;
        return (
          <Floor
            key={n}
            floor={floor}
            rooms={rooms.filter((r) => r.floor === floor)}
            onSelectRoom={onSelectRoom}
          />
        );
      })}
    </div>
  );
}

function InfoFloor(props: any) {
  const classes = useStyles();
  const { data } = props;
  const { rooms: allRooms = [] } = data;

  const rooms = React.useMemo(() => {
    if (allRooms) {
      return allRooms.filter((ar: any) => ar.floor === 0);
    }
    return [];
  }, [allRooms]);

  return (
    <div className={classes.floor}>
      <div className={classes.floorNumber}></div>
      {rooms.map((r: any) => (
        <InfoRoom key={r.rpf} data={r} />
      ))}
    </div>
  );
}

function InfoRoom(props: any) {
  const { data } = props;
  const { space, sides = [] } = data;
  const classes = useStyles();
  const style: any = { background: 'white', flexDirection: 'column' };
  const gridMode = React.useContext(GridModeContext);

  const sidesNamed = React.useMemo(() => {
    return sides.map((s: any) => roomSides.find((rs: any) => Number(rs.id) === Number(s))?.name).join(',')
  }, [sides]);

  return (
    <div className={gridMode === '0' ? classes.room : classes.room1} style={style}>
      <Typography variant="caption">{sidesNamed}</Typography>
      <Typography variant="caption">{space}</Typography>
    </div>
  );
}

function Floor(props: any) {
  const classes = useStyles();
  const { floor, rooms, onSelectRoom } = props;
  return (
    <div className={classes.floor}>
      <div className={classes.floorNumber}>
        <Typography variant="caption">{floor}</Typography>
      </div>
      {rooms.map((r: any) => (
        <Room key={r.rpf} data={r} onSelectRoom={onSelectRoom} />
      ))}
    </div>
  );
}

function Room(props: any) {
  const gridMode = React.useContext(GridModeContext);
  const { data, onSelectRoom } = props;
  const { roomsFilter, ownerFilter } = React.useContext(FiltersContext);
  const { type, price, space, status, floor, number, dealtype, id, photo, info } = data;
  const classes = useStyles();
  const roomType = composedRoomTypes[type];
  const style: any = { cursor: 'pointer' };

  if (Number(status) === 3) {
    style.background = '#E42535';
    style.color = '#fff';
  }

  if (Number(status) === 2) {
    style.background = '#E0E0E0';
    style.color = '#E0E0E0';
  }

  if (Number(status) === 1) {
    style.background = '#EFA00B';
    style.color = '#fff';
  }

  if (Number(status) === 0) {
    style.background = '#519872';
    style.color = '#fff';
  }

  if (roomsFilter && roomsFilter.length) {
    if (!roomsFilter.includes(roomType)) {
      style.opacity = 0.1;
    }
  }

  if (ownerFilter && ownerFilter.length) {
    if (!ownerFilter.includes(`${dealtype}`)) {
      style.opacity = 0.1;
    }
  }

  const handleClick = () => {
    if (onSelectRoom && data) {
      onSelectRoom(data)
    }
  }

  const showPrice = React.useMemo(() => {
    if (Number(status) === 2) {
      return 'Продано';
    }
    if (Number(status) === 3) {
      return 'Недоступно';
    }
    if (Number(status) === 1) {
      return 'Бронь';
    }
    return `${Number(price).toLocaleString()} р`;
  }, [status, price]);


  if (gridMode === '0') {
    return (
      <div
        className={classes.room}
        style={style}
        onClick={handleClick}
      >
        <Typography variant="caption">{roomType}</Typography>
      </div>
    );
  }

  style.width = 32;
  style.height = 32;
  style.marginLeft = 5;

  return (
    <div
      className={classes.room1}
      onClick={handleClick}
    >
      <Avatar style={style}>
        <Typography variant="caption">{roomType}</Typography>
      </Avatar>
      <div style={{ flex: 1, marginLeft: 8 }}>
        <Typography variant="body2">{Number(status) === 2 ? 'Продано' : showPrice}</Typography>
        <Typography variant="caption" style={{ color: '#7A7A7A' }}>{space} кв.м.</Typography>
      </div>
    </div>
  );

}

function filterObject(obj: any) {
  return Object.entries(obj).reduce(
    (m: any, [k, v]) => (v !== undefined && v !== null ? { ...m, [k]: v } : m),
    {}
  );
}

function calcRooms(props: any) {
  const result = [];
  const { floors = [], rooms = [], building, number, id } = props;
  const { floorsnum, roomsFromFloor = 1, roomsFromNumber = 1 } = props;
  const roofFloor = floors.find((f: any) => f.number === 0) || {};

  let roomNumber = roomsFromNumber - 1;

  for (let floor = 1; floor <= floorsnum; floor++) {
    const foundFloor = floors.find((f: any) => f.number === floor);
    const roomsPerFloor =
      foundFloor?.roomsPerFloor !== undefined
        ? foundFloor.roomsPerFloor
        : props.roomsPerFloor;
    for (let rpf = 1; rpf <= roomsPerFloor; rpf++) {
      const roof = rooms.find((r: any) => r.floor === 0 && r.rpf === rpf) || {};
      const foundRoom =
        rooms.find((r: any) => r.floor === floor && r.rpf === rpf) || {};
      const room = { ...filterObject(roof), floor, ...filterObject(foundRoom) };

      if (floor >= roomsFromFloor) {
        roomNumber++;
        room.number = roomNumber;
      }

      if (room.space && room.price && Number(room.price) < 200000) {
        room.price = Number(room.price) * Number(room.space);
      }
      if (!room.pricepm && room.price && room.space) {
        room.pricepm = Number(room.price) / Number(room.space);
      }

      result.push({
        rpf: room.rpf,
        floor: room.floor,
        number: room.number,
        floorPhoto: foundFloor?.photo || roofFloor?.photo || {},
        photo: room.photo,
        type: room.type || 'unknown',
        price: Number(room.price) || 0,
        space: Number(room.space) || 0,
        status: room.status,
        pricepm: room.pricepm,
        dealtype: room.dealtype || 0,
        state: room.state,
        rt: composedRoomTypes[room.type || 'unknown'],
        id: `${building}_${id}_${room.floor}_${room.rpf}`,
        section: number,
        info: room.info
      });
    }
  }
  return result;
}

function SelectedRoom(props: any) {
  const { data } = props;
  const [userMode] = React.useContext(ViewContext);

  if (!data) {
    return null;
  }

  const { type, price, space, status, floor, number, dealtype, id, photo, info } = data;

  const roomType = composedRoomTypes[type];

  return (
    <div style={{ padding: '16px 24px' }}>
      <SelectedRoomRow text="Этаж" value={floor} />
      <SelectedRoomRow text="Вид" value={`${roomType}к`} />
      <SelectedRoomRow text="Номер Квартиры" value={number} />
      <SelectedRoomRow text="Стоимость" value={`${Number(price).toLocaleString()} руб`} />
      <SelectedRoomRow text="Площадь" value={`${space} кв.м.`} />
      {
        userMode === 'broker' && <SelectedRoomRow text="Вознаграждение" value={`${Number(price * 0.02).toLocaleString()} руб`} />
      }
      <div style={{ width: 250 }}>
        <img src={composeUrl(photo?.src, '600')} alt="room" style={{ maxWidth: '100%', height: 'auto', maxHeight: 250 }} />
      </div>
      <Button size="small" variant="contained" color="primary" component={Link} to={{ pathname: '/roominfo', search: `?id=${id}` }} target="_blank">Сформировать презентацию</Button>
    </div>
  )
}

function SelectedRoomRow(props: any) {
  const { text, value } = props;

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #E0E0E0', padding: '8px 0' }}>
      <Typography variant="body2">{text}:</Typography>
      <Typography variant="body2">{value}</Typography>
    </div>
  )
}

const columns: any = [
  { field: 'id', hide: true },
  {
    field: 'photo', headerName: 'Планировка', width: 150, renderCell: (params: any) => (
      <img src={composeUrl(params.value?.src, '600')} style={{ maxHeight: 50 }} />
      // <span>{JSON.stringify(params.value)}</span>
    )
  },
  { field: 'section', headerName: 'Секция', width: 150 },
  { field: 'rt', headerName: 'Тип', width: 150 },
  { field: 'number', headerName: 'Номер', width: 150 },
  { field: 'floor', headerName: 'Этаж', width: 150 },
  { field: 'space', headerName: 'Площадь', width: 150 },
  { field: 'price', headerName: 'Стоимость', width: 150 },
];

function RoomsList(props: any) {
  const { sections } = props;
  const classes = useStyles();
  const { roomsFilter, ownerFilter } = React.useContext(FiltersContext);

  const rows = React.useMemo(() => {
    let rooms: any = [];
    if (sections) {
      sections.forEach((s: any) => {
        const calc = calcRooms(s).filter((r) => Number(r.status) === 0);
        rooms = [...rooms, ...calc];
      });
    }
    if (roomsFilter.length) {
      rooms = rooms.filter((r: any) => roomsFilter.includes(r.rt))
    }
    if (ownerFilter.length) {
      rooms = rooms.filter((r: any) => ownerFilter.includes(r.dealtype))
    }
    return rooms;
  }, [sections, roomsFilter, ownerFilter]);

  if (!rows.length) {
    return null;
  }

  return <DataGrid rows={rows} columns={columns} />
}