import { Card2 } from './Card';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Icon0 from '../images/propsicon0.svg';
import Icon1 from '../images/propsicon1.svg';
import Icon2 from '../images/propsicon2.svg';
import Icon3 from '../images/propsicon3.svg';


import CommonItem from './CommonItem';

function Advantages(props: any) {
  const { building } = props;

  return (
    <Card2>
      <Typography gutterBottom variant="h5">Преимущества</Typography>
      <div style={{ marginTop: 24 }}>
        <Grid container>
          <Grid item xs={3}>
            <CommonItem img={Icon0} title="Технология строительства" text={building?.buildingTechnology} />
            <CommonItem img={Icon1} title="Количество этажей" text={building?.floors} />
            <CommonItem img={Icon2} title="Высота потолков" text={building?.ceilingHeight} />
            <CommonItem img={Icon3} title="Состояние на момент сдачи" text={building?.buildingState} />
          </Grid>
          <Grid item xs={3}>
            <CommonItem img={Icon0} title="Количество лифтов" text={building?.buildingTechnology} />
            <CommonItem img={Icon1} title="Количество квартир на этаже" text={building?.floors} />
            <CommonItem img={Icon2} title="Консьерж" text={building?.ceilingHeight} />
            <CommonItem img={Icon3} title="Остекление балконов" text={building?.buildingState} />
          </Grid>
          <Grid item xs={3}>
            <CommonItem img={Icon0} title="Класс дома" text={building?.buildingTechnology} />
          </Grid>
        </Grid>
      </div>
    </Card2>
  )
}

export default Advantages;