import React from 'react';
import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
import { Title, usePermissions } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
// import Dialog from '@material-ui/core/Dialog';
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
// import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';
// import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import SearchControls from './SearchControls';
import SearchControlsSecondRooms from './SearchControlsSecondRooms';
import Map from './SearchMap';
import MapSecondRooms from './SearchMapSecondRooms';
import Details from './SearchDetails';
import DetailsSecondsRooms from './SearchDetailsSecondRooms';
// import SearchDetailsObject2 from './SearchDetailsObject2';
// import SearchDetailsObject3 from './SearchDetailsObject3';

// import SearchContext, { reducer, initialValues } from 'search/SearchContext';

import { useDispatch } from 'react-redux';
import { setCity } from 'reducers/search-slice';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import MapIcon from '@material-ui/icons/Map';
import ViewListIcon from '@material-ui/icons/ViewList';

import SearchViewContext from './SearchViewContext';

// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 'calc(100vh - 50px)',
    [theme.breakpoints.up('sm')]: {
      minHeight: 'calc(100vh - 70px)',
    },
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  searchTop: {
    flex: '0 0 auto',
  },
  middleContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },
}));


const Search = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [expanded, setExpanded] = React.useState(true);
  const theme = useTheme();
  const is_sm = useMediaQuery(theme.breakpoints.up('sm'));
  const [viewMode, setViewMode] = React.useState('map');

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    event.preventDefault();
    event.stopPropagation();
    setValue(newValue);
  };
  // const { selected } = useSelector((state: any) => state.search);

  const { permissions = {}, loaded } = usePermissions();
  const { city } = permissions;

  React.useEffect(() => {
    if (city) {
      dispatch(setCity(city));
    }
  }, [city, dispatch]);

  if (!loaded) {
    return null;
  }

  return (
    <SearchViewContext.Provider value={viewMode}>
      <Card className={classes.container}>
        <Title title="Поиск" />
        {/* <Accordion expanded={expanded} square onChange={() => setExpanded(state => !state)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Новостройки" />
              <Tab label="Вторичная" />
              <Tab label="Коммерческая" disabled />
              <Tab label="Загородная" disabled />
            </Tabs>
          </AccordionSummary>
          <AccordionDetails>
            {
              value === 0 && <SearchControls />
            }
            {
              value === 1 && <SearchControlsSecondRooms />
            }
          </AccordionDetails>
        </Accordion> */}
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Новостройки" />
          <Tab label="Вторичная" />
          {
            is_sm && (
              <>
                <Tab label="Коммерческая" disabled />
                <Tab label="Загородная" disabled />
              </>
            )
          }
          {/* <Tab label="Коммерческая" disabled />
          <Tab label="Загородная" disabled /> */}
        </Tabs>
        <div>
          {
            value === 0 && <SearchControls />
          }
          {
            value === 1 && <SearchControlsSecondRooms />
          }
        </div>

        {
          value === 0 && (
            <div className={classes.middleContainer}>
              {
                (is_sm && viewMode === 'map') &&
                <Map />
              }
              <Details />
            </div>
          )
        }
        {
          value === 1 && (
            <div className={classes.middleContainer}>
              {
                (is_sm && viewMode === 'map') &&
                <MapSecondRooms />
              }
              <DetailsSecondsRooms />
            </div>
          )
        }
        {
          is_sm && (
            <div style={{ position: 'absolute', top: 0, right: 0 }}>
              <MapIcon onClick={() => setViewMode('map')} style={{ cursor: 'pointer', color: viewMode === 'map' ? 'silver' : 'black' }} />
              <ViewListIcon onClick={() => setViewMode('list')} style={{ cursor: 'pointer', color: viewMode === 'list' ? 'silver' : 'black' }} />
            </div>
          )
        }
      </Card>
    </SearchViewContext.Provider>
  );
};

export default Search;

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}