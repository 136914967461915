import React from 'react';
import { useField } from 'react-final-form';
import DG from '2gis-maps';

const defaultView = {
  center: [54.98, 82.89],
  zoom: 4,
};

// const zooms = {
//   country: 4,
//   city: 12,
//   complex: 16
// }

export const MapView = (props: any) => {
  const { source, record, zoom = 4 } = props;
  const value = record[source];
  const name = record['name'];

  const ref = React.useRef(null);
  const [map, setMap] = React.useState<any>(null);

  React.useEffect(() => {
    const mapObj = DG.map(ref.current, defaultView);
    setMap(mapObj);
    return () => {
      mapObj.remove();
    };
  }, []);

  React.useEffect(() => {
    if (map && value) {
      const { lat, lng } = value;
      if (lat && lng) {
        const marker = DG.marker([lat, lng], {
          title: name,
          draggable: false,
        });
        marker.addTo(map);
        map.setView([lat, lng], zoom);
      }
    }
  }, [map, value, name, zoom]);

  return (
    <div ref={ref} style={{ height: 400 }}>
      Map View
    </div>
  );
};

export const MapEdit = (props: any) => {
  const { source, record, zoom = 4 } = props;
  const name = record['name'];
  const { input } = useField(source);
  const { onChange, value } = input;

  const ref = React.useRef(null);
  const [map, setMap] = React.useState<any>(null);
  const markerRef = React.useRef(null);

  React.useEffect(() => {
    const mapObj = DG.map(ref.current, defaultView);
    setMap(mapObj);
    return () => {
      mapObj.remove();
    };
  }, []);

  React.useEffect(() => {
    if (map && !markerRef.current) {
      let { lat, lng } = value || {};

      if (!lat || !lng) {
        lat = defaultView.center[0];
        lng = defaultView.center[1];
      }
      const marker = DG.marker([lat, lng], {
        title: name,
        draggable: true,
      });
      marker.addTo(map);
      marker.on('moveend', function (e: any) {
        const { lat, lng } = e.target._latlng;
        onChange({ lat, lng });
      });
      map.setView([lat, lng], zoom);
      markerRef.current = marker;
    }
  }, [map, value, name, onChange, zoom]);

  return (
    <div ref={ref} style={{ height: 400 }}>
      Map View
    </div>
  );
};
