import React from 'react';

import { parse } from 'query-string';

import {
  List,
  Datagrid,
  TextField,
  Show,
  Create,
  TextInput,
  Edit,
  FileInput,
  FileField,
  SingleFieldList,
  ChipField,
  SimpleShowLayout,
  BooleanField,
  NumberField,
  ReferenceField,
  UrlField,
  ReferenceArrayField,
  SimpleForm,
  BooleanInput,
  NumberInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceInput,
  SelectInput,
  Filter,
  AutocompleteInput,
  ReferenceManyField,
  ImageInput,
  ImageField,
  TabbedForm,
  FormTab,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  Toolbar,
  SaveButton,
  RichTextField,
} from 'react-admin';

import RichTextInput from 'ra-input-rich-text';


import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import { MapEdit, MapView } from 'components/map';

export const name = 'complexes';

const isAdmin = (role: string) => ['admin', 'supermanager', 'superofficemanager'].includes(role);

const ComplexListFilter = (props: any) => (
  <Filter {...props}>
    <BooleanInput source="active" alwaysOn />
    <ReferenceInput
      source="city"
      reference="cities"
      alwaysOn
      sort={{ field: 'name', order: 'ASC' }}
      allowEmpty={false}
      perPage={1000}
    >
      <SelectInput source="name" />
    </ReferenceInput>
    <TextInput source="name" alwaysOn />
  </Filter>
);


export const list = (props: any) => {
  const { permissions = {} } = props;
  const { city, role } = permissions;
  if (!city) {
    return null;
  }

  if (isAdmin(role)) {
    return (
      <List
        {...props}
        bulkActionButtons={false}
        filters={<ComplexListFilter />}
        filterDefaultValues={{ active: true, city }}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <Datagrid rowClick="show">
          <BooleanField source="active" />
          <ReferenceField source="city" reference="cities" link="show">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="developer" reference="developers" link="show">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="name" />
          <TextField source="geo" />
        </Datagrid>
      </List>
    )
  }

  return (
    <List
      {...props}
      bulkActionButtons={false}
      filter={{ city }}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <Datagrid rowClick="show">
        <BooleanField source="active" />
        <ReferenceField source="city" reference="cities" link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="developer" reference="developers" link="show">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="name" />
        <TextField source="geo" />
      </Datagrid>
    </List>
  )

};

export const show = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <BooleanField source="active" />
      <TextField source="name" />
      <ReferenceField source="developer" reference="developers">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="city" reference="cities">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="district" reference="districts">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="buildings" />
      <BooleanField source="landOwn" />
      <BooleanField source="fencedArea" />
      <TextField source="parkingType" />
      <BooleanField source="paidParking" />
      <BooleanField source="deferredPayment" />
      <TextField source="deferredPaymentText" />
      <BooleanField source="paidReserve" />
      <TextField source="reserveText" />
      <UrlField source="site" />
      <MapView source="geo" zoom="16" />
      <UrlField source="video" />
      <FileField source="docs" src="src" title="title" target="_blank" />
      <BooleanField source="top" />
      <RichTextField source="info" />
      <NumberField source="topnumber" />
      <NumberField source="rating" />
      <ImageField source="genplan.src" />
      <ReferenceManyField reference="buildings" target="complex" label="Литеры">
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceManyField>
      <CreateNewBuildingButton />
    </SimpleShowLayout>
  </Show>
);

export const create = (props: any) => {

  const { developer } = parse(props.location.search);
  const redirect = developer ? `/developers/${developer}/show` : 'show';

  const { permissions = {} } = props;
  const { city, role } = permissions;

  if (!city) {
    return null;
  }


  return (
    <Create {...props} transform={transform}>
      <TabbedForm redirect="show" initialValues={{ city }}>
        <FormTab label="Информация">
          <BooleanInput source="active" />
          <TextInput source="name" />
          <FormDataConsumer>
            {({ formData, ...otherProps }) => (
              formData.city ?
                <ReferenceInput {...otherProps} source="developer" reference="developers" filter={{ attachedCity: formData.city, active: true }} sort={{ field: 'name', order: 'ASC' }} perPage={1000}>
                  <SelectInput source="name" />
                </ReferenceInput>
                :
                <SelectInput source="developer" disabled />
            )}
          </FormDataConsumer>
          {
            isAdmin(role) && (
              <ReferenceInput source="city" reference="cities" perPage={100}>
                <SelectInput optionText="name" />
              </ReferenceInput>
            )
          }

          <FormDataConsumer>
            {({ formData, ...otherProps }) => (
              formData.city ?
                <ReferenceInput {...otherProps} source="district" reference="districts" filter={{ city: formData.city }} sort={{ field: 'name', order: 'ASC' }} perPage={500}>
                  <SelectInput source="name" />
                </ReferenceInput>
                :
                <SelectInput {...otherProps} source="district" disabled />
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...otherProps }) => (
              formData.city ?
                <ReferenceInput {...otherProps} source="main_district" reference="main_districts" filter={{ city: formData.city }} sort={{ field: 'name', order: 'ASC' }} perPage={500}>
                  <SelectInput source="name" />
                </ReferenceInput>
                :
                <SelectInput {...otherProps} source="main_district" disabled />
            )}
          </FormDataConsumer>
          <NumberInput source="buildings" />
          <BooleanInput source="landOwn" />
          <BooleanInput source="fencedArea" />
          <TextInput source="parkingType" />
          <BooleanInput source="paidParking" />
          <BooleanInput source="deferredPayment" />
          <TextInput source="deferredPaymentText" />
          <BooleanInput source="paidReserve" />
          <TextInput source="reserveText" />
          <TextInput source="site" />
          <MapEdit source="geo" zoom="16" />
          <TextInput source="video" />
          <FileInput source="docs" multiple>
            <FileField source="src" title="title" />
          </FileInput>
          <BooleanInput source="top" />
          <RichTextInput source="info" fullWidth />
          <NumberInput source="topnumber" />
          <NumberInput source="rating" />
          <ImageInput source="genplan">
            <ImageField source="src" title="title" />
          </ImageInput>
          <BooleanInput source="apartments" />
          <FormDataConsumer>
            {({ formData, ...otherProps }) => (
              formData.city ?
                // <ReferenceInput {...otherProps} source="metro_line" reference="metro_lines" filter={{ city: formData.city }} sort={{ field: 'name', order: 'ASC' }} perPage={100}>
                //   <SelectInput source="name" />
                // </ReferenceInput>
                <ReferenceArrayInput
                  {...otherProps}
                  reference="metro_lines"
                  source="metro_line_arr"
                  filter={{ city: formData.city }}
                  sort={{ field: 'name', order: 'ASC' }}
                  perPage={500}
                  fullWidth
                >
                  <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>
                :
                <SelectInput source="metro_line_arr" disabled />
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...otherProps }) => (
              Boolean(formData.city && formData.metro_line_arr) ?
                // <ReferenceInput {...otherProps} source="metro_station" reference="metro_stations" filter={{ city: formData.city, metro_line: formData.metro_line_arr }} sort={{ field: 'name', order: 'ASC' }} perPage={100}>
                //   <SelectInput source="name" />
                // </ReferenceInput>
                <ReferenceArrayInput
                  {...otherProps}
                  reference="metro_stations"
                  source="metro_station_arr"
                  filter={{ city: formData.city, metro_line: formData.metro_line_arr }}
                  sort={{ field: 'name', order: 'ASC' }}
                  perPage={500}
                  fullWidth
                >
                  <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>
                :
                <SelectInput {...otherProps} source="metro_station" disabled />
            )}
          </FormDataConsumer>
        </FormTab>
        <FormTab label="Преимущества">
          <ArrayInput source="advantages" label="">
            <SimpleFormIterator>
              <NumberInput source="sort" label="Сортировка" />
              <RichTextInput source="text" label="" fullWidth />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Выгоды">
          <ArrayInput source="benefits" label="">
            <SimpleFormIterator>
              <NumberInput source="sort" label="Сортировка" />
              <RichTextInput source="text" label="" fullWidth />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Акции">
          <ArrayInput source="actions" label="">
            <SimpleFormIterator>
              <NumberInput source="sort" label="Сортировка" />
              <RichTextInput source="text" label="" fullWidth />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Ипотека, аккредитация">
          <ArrayInput source="ipoteka_accreditation" label="">
            <SimpleFormIterator>
              <ReferenceInput source="bank" reference="banks" label="Банк" filter={{ active: true }} sort={{ field: 'name', order: 'ASC' }} perPage={1000}>
                <SelectInput source="name" />
              </ReferenceInput>
              <TextInput source="info" label="Информация" fullWidth multiline />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Create>
  )
};


const CustomToolbar = (props: any) => {
  return (
    <Toolbar {...props} >
      <SaveButton />
    </Toolbar>
  )
};

export const edit = (props: any) => {
  const { permissions = {} } = props;
  const { city, role } = permissions;

  if (!city) {
    return null;
  }

  return (
    <Edit {...props} transform={transform}>
      <TabbedForm redirect="show" toolbar={<CustomToolbar />}>
        <FormTab label="Информация">
          <BooleanInput source="active" />
          <TextInput source="name" />
          <FormDataConsumer>
            {({ formData, ...otherProps }) => (
              formData.city ?
                <ReferenceInput {...otherProps} source="developer" reference="developers" filter={{ attachedCity: formData.city, active: true }} sort={{ field: 'name', order: 'ASC' }} perPage={1000}>
                  <SelectInput source="name" />
                </ReferenceInput>
                :
                <SelectInput source="developer" disabled />
            )}
          </FormDataConsumer>
          {
            isAdmin(role) && (
              <ReferenceInput source="city" reference="cities" perPage={100}>
                <SelectInput optionText="name" />
              </ReferenceInput>
            )
          }

          <FormDataConsumer>
            {({ formData, ...otherProps }) => (
              formData.city ?
                <ReferenceInput {...otherProps} source="district" reference="districts" filter={{ city: formData.city }} sort={{ field: 'name', order: 'ASC' }} perPage={500}>
                  <SelectInput source="name" />
                </ReferenceInput>
                :
                <SelectInput {...otherProps} source="district" disabled />
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...otherProps }) => (
              formData.city ?
                <ReferenceInput {...otherProps} source="main_district" reference="main_districts" filter={{ city: formData.city }} sort={{ field: 'name', order: 'ASC' }} perPage={500}>
                  <SelectInput source="name" />
                </ReferenceInput>
                :
                <SelectInput {...otherProps} source="main_district" disabled />
            )}
          </FormDataConsumer>
          <NumberInput source="buildings" />
          <BooleanInput source="landOwn" />
          <BooleanInput source="fencedArea" />
          <TextInput source="parkingType" />
          <BooleanInput source="paidParking" />
          <BooleanInput source="deferredPayment" />
          <TextInput source="deferredPaymentText" />
          <BooleanInput source="paidReserve" />
          <TextInput source="reserveText" />
          <TextInput source="site" />
          <MapEdit source="geo" zoom="16" />
          <TextInput source="video" />
          <FileInput source="docs" multiple>
            <FileField source="src" title="title" />
          </FileInput>
          <BooleanInput source="top" />
          <RichTextInput source="info" fullWidth />
          <NumberInput source="topnumber" />
          <NumberInput source="rating" />
          <ImageInput source="genplan">
            <ImageField source="src" title="title" />
          </ImageInput>
          <BooleanInput source="apartments" />
          <FormDataConsumer>
            {({ formData, ...otherProps }) => (
              formData.city ?
                // <ReferenceInput {...otherProps} source="metro_line" reference="metro_lines" filter={{ city: formData.city }} sort={{ field: 'name', order: 'ASC' }} perPage={100}>
                //   <SelectInput source="name" />
                // </ReferenceInput>
                <ReferenceArrayInput
                  {...otherProps}
                  reference="metro_lines"
                  source="metro_line_arr"
                  filter={{ city: formData.city }}
                  sort={{ field: 'name', order: 'ASC' }}
                  perPage={500}
                  fullWidth
                >
                  <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>
                :
                <SelectInput {...otherProps} source="metro_line_arr" disabled />
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...otherProps }) => (
              Boolean(formData.city && formData.metro_line_arr) ?
                // <ReferenceInput {...otherProps} source="metro_station" reference="metro_stations" filter={{ city: formData.city, metro_line: formData.metro_line_arr }} sort={{ field: 'name', order: 'ASC' }} perPage={100}>
                //   <SelectInput source="name" />
                // </ReferenceInput>
                <ReferenceArrayInput
                  {...otherProps}
                  reference="metro_stations"
                  source="metro_station_arr"
                  filter={{ city: formData.city, metro_line: formData.metro_line_arr }}
                  sort={{ field: 'name', order: 'ASC' }}
                  perPage={500}
                  fullWidth
                >
                  <SelectArrayInput optionText="name" fullWidth />
                </ReferenceArrayInput>
                :
                <SelectInput {...otherProps} source="metro_station" disabled fullWidth />
            )}
          </FormDataConsumer>
        </FormTab>
        <FormTab label="Преимущества">
          <ArrayInput source="advantages" label="">
            <SimpleFormIterator>
              <NumberInput source="sort" label="Сортировка" />
              <RichTextInput source="text" label="" fullWidth />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Выгоды">
          <ArrayInput source="benefits" label="">
            <SimpleFormIterator>
              <NumberInput source="sort" label="Сортировка" />
              <RichTextInput source="text" label="" fullWidth />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Акции">
          <ArrayInput source="actions" label="">
            <SimpleFormIterator>
              <NumberInput source="sort" label="Сортировка" />
              <RichTextInput source="text" label="" fullWidth />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Ипотека, аккредитация">
          <ArrayInput source="ipoteka_accreditation" label="">
            <SimpleFormIterator>
              <ReferenceInput source="bank" reference="banks" label="Банк" filter={{ active: true }} sort={{ field: 'name', order: 'ASC' }} perPage={1000}>
                <SelectInput source="name" />
              </ReferenceInput>
              <TextInput source="info" label="Информация" fullWidth multiline />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
};

function CreateNewBuildingButton(props: any) {
  const { record } = props;
  const { id } = record;

  return (
    <Button
      variant="contained"
      color="primary"
      component={Link as any}
      to={`/buildings/create?complex=${id}`}
    >
      Добавить Литер
    </Button>
  )
}

function sortFn(a: any, b: any) {
  return (a.sort ?? 1) - (b.sort ?? 1);
}

function transform(data: any) {

  if (Array.isArray(data?.advantages)) {
    data.advantages.sort(sortFn);
  }
  if (Array.isArray(data?.benefits)) {
    data.benefits.sort(sortFn);
  }
  if (Array.isArray(data?.actions)) {
    data.actions.sort(sortFn);
  }
  return data;
}