import React from 'react';
import { useQueryWithStore, usePermissions } from 'react-admin';
import { complexStat, composedRoomTypes, simpleRoomTypes } from './SearchData';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Rating from '@material-ui/lab/Rating';
import { Link } from 'react-router-dom';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Map from './SearchDeatilsMap';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import { DataGrid } from '@material-ui/data-grid';
import Lightbox from 'react-image-lightbox';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


import { composeUrl } from 'libs/parseHelper';
import { roomSides } from 'components/RoomsGrid/Room';
// import SearchDetailsRoomInfo from './SearchDetailsRoomInfo';
import { buildingClassChoises } from 'resources/building-res';
import Container from '@material-ui/core/Container';


import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import PresentToAllIcon from '@material-ui/icons/PresentToAll';

import RoomPriceLoader from './RoomPriceLoader';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  container: {
    // background: 'red',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      margin: 0
    }
  },
  card: {
    // margin: '10px 10px 10px 10px',
    margin: '10px 0',
  },
  photoPaper: {
    // marginRight: '10px',
  },
  buildingInfoContainer: {
    // margin: '0 10px 10px 10px',
  },
  photo: {
    height: 183,
  },
  floor: {
    display: 'flex',
    height: '50px',
    marginBottom: '2px',
  },
  floorNumber: {
    minWidth: '30px',
    height: '100%',
    flex: 0,
    textAlign: 'center',
  },
  singleSection: {
    overflowY: 'auto'
    // margin: '10px 10px 10px',
  },
  room: {
    minWidth: '50px',
    width: '50px',
    marginRight: '2px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // cursor: 'pointer',
  },
  roomLink: {
    // width: '100%',
    // height: '100%',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'inherit',
    textDecoration: 'none'
  },
  sectionSelect: {
    margin: '10px 0 10px',
  },
  roomPhoto: {
    width: 250,
  },
  sectionButton: {
    marginRight: 5,
    marginBottom: 5,
  },
  photosRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  photosGridList: {
    width: '100%',
    height: 450,
  },
  photosGridListSm: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)'
  },
  buildingDescContainer: {
    height: 446,
    margin: '10px 0 10px 5px',
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0',
    }
    // margin: '10px 10px 10px 10px',
  },
  sectionsRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 350,
  },
}));

const SearchDetailsObject3 = (props: any) => {
  const { selected } = props;
  const classes = useStyles();
  const [selectedBuilding, setSelectedBuilding] = React.useState<string>('');
  const [openLightbox, setOpenLightbox] = React.useState(false);
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const { permissions } = usePermissions();
  const theme = useTheme();
  const is_sm = useMediaQuery(theme.breakpoints.up('sm'));

  const { data: statInfo, loaded: statLoaded } = useQueryWithStore({
    type: 'getOne',
    resource: selected ? 'rooms_search' : '',
    payload: { id: selected }
  });

  const { data: complexInfo, loaded: complexLoaded } = useQueryWithStore({
    type: 'getOne',
    resource: statLoaded && selected ? 'complexes' : '',
    payload: { id: selected }
  });

  const { data: developerInfo, loaded: developerLoaded } = useQueryWithStore({
    type: 'getOne',
    resource: complexInfo?.developer ? 'developers' : '',
    payload: { id: complexInfo?.developer }
  });

  const { data: buildingsData, loaded: buildingsDataLoaded } = useQueryWithStore({
    type: 'getList',
    resource: complexInfo?.id ? 'buildings' : '',
    payload: {
      pagination: { page: 1, perPage: 1000 },
      filter: { active: true, complex: complexInfo?.id },
      sort: { field: 'name', order: 'ASC' }
    }
  });

  const selectedBuildingObj = React.useMemo(() => {
    if (selectedBuilding) {
      const found = buildingsData?.find((b: any) => b.id === selectedBuilding) || {};
      found.endDate = '';
      try {
        found.endDate = format(parseISO(found.dateOfConstruction), 'Q кв. yyyy г.');
      } catch (ex) { }
      return found;
    }
  }, [buildingsData, selectedBuilding]);

  const statData = React.useMemo(() => statLoaded && complexStat(statInfo), [statInfo, statLoaded]);

  const roomsStat = React.useMemo(() => {
    if (selectedBuilding) {
      const buildingId = `${selectedBuilding}`.replace('b_', '');
      const m: any = {};
      if (!statData.common) {
        Object.entries(statData).forEach(([kk, vv]: any) => {
          if (`${kk}`.startsWith('e_') && vv?.building === buildingId) {
            Object.entries(vv).forEach(([k, v]: any) => {
              if (`${k}`.startsWith('r_') && !`${k}`.includes('_unknown')) {
                const rt = composedRoomTypes[`${k}`.replace('r_', '')] || '0';
                if (!m[rt]) {
                  m[rt] = v;
                } else {
                  const r = m[rt];
                  r.num += v.num || 0;
                  r.p_min = r.p_min < v.p_min ? r.p_min : v.p_min;
                  r.s_min = r.s_min < v.s_min ? r.s_min : v.s_min;
                  r.p_max = r.p_max > v.p_max ? r.p_max : v.p_max;
                  r.s_max = r.s_max > v.s_max ? r.s_max : v.s_max;
                }
              }
            });
          }
        });
      } else {
        Object.entries(statData).forEach(([k, v]: any) => {
          if (`${k}`.startsWith('rs_') && v?.building === buildingId) {
            // if (`${k}`.startsWith('rs_')) {
            const rt = composedRoomTypes[v?.type] || '0';
            if (!m[rt]) {
              m[rt] = v;
            } else {
              const r = m[rt];
              r.num += v.num || 0;
              r.p_min = r.p_min < v.p_min ? r.p_min : v.p_min;
              r.s_min = r.s_min < v.s_min ? r.s_min : v.s_min;
              r.p_max = r.p_max > v.p_max ? r.p_max : v.p_max;
              r.s_max = r.s_max > v.s_max ? r.s_max : v.s_max;
            }
          }
        });
      }
      return m;
    }
    return {};
  }, [statData, selectedBuilding]);

  React.useEffect(() => {
    if (buildingsData && buildingsData.length && !selectedBuilding) {
      setSelectedBuilding(buildingsData[0]?.id);
    }
  }, [buildingsData, selectedBuilding]);

  const loading = !(statLoaded && complexLoaded && developerLoaded && buildingsDataLoaded);

  const allowLink = React.useMemo(() => {
    if (permissions && complexInfo) {
      const { city, role } = permissions;
      if (['admin', 'superofficemanager'].includes(role)) {
        return true;
      }
      if (['officemanager'].includes(role)) {
        return complexInfo?.city === city;
      }
    }
    return false;
  }, [permissions, complexInfo])

  if (!permissions) {
    return null;
  }

  return (
    <Container fixed className={classes.container}>
      <Card className={classes.card}>
        <CardContent>
          <Grid container>
            <Grid item sm={1} xs={12}>
              <img src="/logo.jpg" alt="logo" style={{ maxHeight: 50, maxWidth: 110 }} /><br />
              <img src={developerInfo?.logo?.src} alt="logo" style={{ maxHeight: 50, maxWidth: 90 }} />
            </Grid>
            <Grid item sm={7} xs={12}>
              <Typography>Застройщик: {developerInfo?.name}</Typography>
              <AllowedLink to={`/complexes/${complexInfo?.id}/show`} target="_blank" allow={allowLink}>ЖК {statData?.name}</AllowedLink>
              <Typography>Адрес: {statData?.addr && statData?.addr[0]}</Typography>
              <Typography>
                Рейтинг: <Rating value={Number(complexInfo?.rating)} name="rating" />
              </Typography>
              <Typography>
                Топ: {complexInfo?.topnumber}
              </Typography>
              <Typography>Сайт: <a href={complexInfo?.site} target="_blank" rel="noreferrer">{complexInfo?.site}</a></Typography>
            </Grid>
            <Grid style={{ textAlign: 'right' }} item sm={4} xs={12}>
              <Typography>от {Number(statData?.priceFrom).toLocaleString()} р</Typography>
              {
                !statData?.common && (
                  <Typography variant="body2">
                    квартир в продаже: {statData?.totalRooms}
                  </Typography>
                )
              }
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <div>
        <Grid container>
          <Grid item sm={6} xs={12}>
            <Card className={classes.card} onClick={() => setOpenLightbox(true)}>
              {
                is_sm && (
                  <div className={classes.photosRoot}>
                    {statData?.photos && (
                      <GridList
                        cellHeight={160}
                        className={classes.photosGridList}
                        cols={4}
                      >
                        {statData.photos.map((p: string, i: number) => (
                          <GridListTile key={i}>
                            <img src={composeUrl(p, '600')} alt="building" />
                          </GridListTile>
                        ))}
                      </GridList>
                    )}
                  </div>
                )
              }
              {
                !is_sm && (
                  <div className={classes.photosRoot}>
                    {statData?.photos && (
                      <GridList
                        className={classes.photosGridListSm}
                        cols={1}
                      >
                        {statData.photos.map((p: string, i: number) => (
                          <GridListTile key={i}>
                            <img src={composeUrl(p, '600')} alt="building" />
                          </GridListTile>
                        ))}
                      </GridList>
                    )}
                  </div>
                )
              }
            </Card>
            {
              openLightbox &&
              <Lightbox
                mainSrc={statData.photos[photoIndex]}
                nextSrc={statData.photos[(photoIndex + 1) % statData.photos.length]}
                prevSrc={statData.photos[(photoIndex + statData.photos.length - 1) % statData.photos.length]}
                onCloseRequest={() => setOpenLightbox(false)}
                onMovePrevRequest={() =>
                  setPhotoIndex((photoIndex + statData.photos.length - 1) % statData.photos.length)
                }
                onMoveNextRequest={() =>
                  setPhotoIndex((photoIndex + 1) % statData.photos.length)
                }
              />
            }
          </Grid>
          <Grid item sm={6} xs={12}>
            <Card className={classes.buildingDescContainer}>
              <CardContent>
                <AllowedLink to={`/buildings/${selectedBuilding}/show`} target="_blank" allow={allowLink}>Литер: {selectedBuildingObj?.name}</AllowedLink>
                <Typography>
                  Срок сдачи: {selectedBuildingObj?.endDate}
                </Typography>
              </CardContent>
              <CardContent>
                <Typography> Адрес: {selectedBuildingObj?.address}</Typography>
              </CardContent>
              <CardContent>
                <RoomsStat data={roomsStat} />
              </CardContent>
              <CardContent>
                <Typography>
                  Ипотека, аккредитация
                </Typography>
                {
                  complexInfo?.ipoteka_accreditation?.map((i: any) => (
                    <Typography><Bank id={i.bank} /> {i.info}</Typography>
                  ))
                }
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>

      <Card className={classes.card}>
        <CardContent>
          {buildingsData?.map((b: any) => (
            <Button
              key={b.id}
              color="primary"
              variant="contained"
              className={classes.sectionButton}
              onClick={() => setSelectedBuilding(b.id)}
              disabled={b.id === selectedBuilding}
            >
              Литер: {b.name}
            </Button>
          ))}
        </CardContent>
      </Card>
      {
        !statData?.common ? <Sections building={selectedBuilding} allowLink={allowLink} /> : <Rooms building={selectedBuilding} endDate={selectedBuildingObj?.endDate} complex={complexInfo?.id} allowLink={allowLink} />
      }
      <Card className={classes.card}>
        <CardContent>
          <Typography>Генплан</Typography>
        </CardContent>
        <CardMedia>
          <img src={complexInfo?.genplan ? complexInfo.genplan.src : '/genplan.jpg'} alt="genplan" style={{ maxWidth: '100%' }} />
        </CardMedia>
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <Typography><b>Динамика Цен:</b></Typography>
          <RoomPriceLoader buildingsData={buildingsData} selectedBuilding={selectedBuilding} />
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <Grid container>
            <Grid item sm={4} xs={12}>
              <Typography><b>Преимущества:</b></Typography>
              <List>
                {
                  complexInfo?.advantages?.map((adv: any, i: number) => (
                    <ListItem key={i}>
                      <ListItemIcon>
                        <AssignmentTurnedInIcon />
                      </ListItemIcon>
                      <ListItemText primary={<div dangerouslySetInnerHTML={{ __html: adv.text }} />} />
                    </ListItem>
                  ))
                }
              </List>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography><b>Выгоды:</b></Typography>
              <List>
                {
                  complexInfo?.benefits?.map((ben: any, i: number) => (
                    <ListItem key={i}>
                      <ListItemIcon>
                        <AssignmentTurnedInIcon style={{ color: 'green' }} />
                      </ListItemIcon>
                      <ListItemText primary={<div dangerouslySetInnerHTML={{ __html: ben.text }} />} />
                    </ListItem>
                  ))
                }
              </List>
              <Typography><b>Акции:</b></Typography>
              <List>
                {
                  complexInfo?.actions?.map((act: any, i: number) => (
                    <ListItem key={i}>
                      <ListItemIcon>
                        <AssignmentTurnedInIcon style={{ color: 'red' }} />
                      </ListItemIcon>
                      <ListItemText primary={<div dangerouslySetInnerHTML={{ __html: act.text }} />} />
                    </ListItem>
                  ))
                }
              </List>
            </Grid>
            <Grid item sm={4} xs={12}>
              <Typography><b>Характеристики:</b></Typography>
              <List>
                <ListItem>
                  <Typography>
                    Технология строительства: <BuildingTechnology id={selectedBuildingObj?.buildingTechnology} />
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    Количество этажей: {selectedBuildingObj?.floors}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    Высота потолков: {selectedBuildingObj?.ceilingHeight}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    Состояние на момент сдачи: <BuildingState id={selectedBuildingObj?.buildingState} />
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    Количество лифтов: {selectedBuildingObj?.passengerLift}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    Количество квартир на этаже: {selectedBuildingObj?.roomNumbersPerFloor}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    Количество подъездов: {selectedBuildingObj?.entrancesNumber}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    Консьерж: {selectedBuildingObj?.concierge ? 'Да' : 'Нет'}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    Остекление балконов: {selectedBuildingObj?.glassing ? 'Да' : 'Нет'}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    Класс дома: <BuildingClass id={selectedBuildingObj?.buildingClass} />
                  </Typography>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card className={classes.card}>
        {complexInfo?.geo && (
          <Card>
            <Map value={complexInfo?.geo} name={complexInfo?.name} id={complexInfo?.id} zoom="16" />
          </Card>
        )}
      </Card>

      <Card className={classes.card}>
        {complexInfo?.info && (
          <Card>
            <CardContent>
              <Typography dangerouslySetInnerHTML={{ __html: complexInfo.info }} />
            </CardContent>
          </Card>
        )}
      </Card>

      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
    </Container>
  )
}

export default SearchDetailsObject3;

function RoomsStat(props: any) {
  const { data } = props;

  const getRoomStat = React.useCallback(
    (srt) => {
      const found = data[srt];
      const suff = isNaN(Number(srt)) ? '' : 'к';
      if (found) {
        return `${srt}${suff} - от ${Number(found.s_min).toFixed(
          2
        )} кв.м. от ${Number(found.p_min).toLocaleString()} Руб.`;
      }
      return `${srt}${suff} - нет в продаже`;
    },
    [data]
  );

  return (
    <div>
      {simpleRoomTypes.map((srt) => (
        <Typography key={srt}>{getRoomStat(srt)}</Typography>
      ))}
    </div>
  );
}

interface FiltersContextProps {
  roomsFilter: string[];
  ownerFilter: string[];
}

const FiltersContext = React.createContext<FiltersContextProps>({
  roomsFilter: [],
  ownerFilter: [],
});

const Sections = (props: any) => {
  const { building, allowLink } = props;
  const classes = useStyles();

  const theme = useTheme();
  const is_sm = useMediaQuery(theme.breakpoints.up('sm'));

  const [roomsFilter, setRoomsFilter] = React.useState<string[]>([]);
  const [ownerFilter, setOwnerFilter] = React.useState<string[]>([]);

  const { data: sections } = useQueryWithStore({
    type: 'getList',
    resource: building ? 'entrances' : '',
    payload: {
      pagination: { page: 1, perPage: 1000 },
      filter: { active: true, building },
      sort: { field: 'number', order: 'ASC' }
    }
  });

  const handleRoomsFilter = (ev: any, newFilters: string[]) =>
    setRoomsFilter(newFilters);

  const handleOwnerFilter = (ev: any, newFilters: string[]) =>
    setOwnerFilter(newFilters);

  return (
    <FiltersContext.Provider value={{ roomsFilter, ownerFilter }}>
      <Card className={classes.card}>
        <CardContent>
          <div style={{ display: 'inline-block', marginRight: 10 }}>
            <ToggleButtonGroup value={roomsFilter} onChange={handleRoomsFilter}>
              <ToggleButton value="C">C</ToggleButton>
              <ToggleButton value="1">1k</ToggleButton>
              <ToggleButton value="2">2k</ToggleButton>
              <ToggleButton value="3">3k</ToggleButton>
              <ToggleButton value="4+">4k+</ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div style={{ display: 'inline-block' }}>
            {
              is_sm ? (
                <ToggleButtonGroup value={ownerFilter} onChange={handleOwnerFilter}>
                  <ToggleButton value="0">Застройщик</ToggleButton>
                  {/* <ToggleButton value="1">Дольщик</ToggleButton> */}
                  <ToggleButton value="2">Подрядчик</ToggleButton>
                  <ToggleButton value="3">Уступка СЗ</ToggleButton>
                </ToggleButtonGroup>
              ) : (
                <ToggleButtonGroup value={ownerFilter} onChange={handleOwnerFilter}>
                  <ToggleButton value="0">З</ToggleButton>
                  {/* <ToggleButton value="1">Д</ToggleButton> */}
                  <ToggleButton value="2">П</ToggleButton>
                  <ToggleButton value="3">УСЗ</ToggleButton>
                </ToggleButtonGroup>
              )
            }
          </div>
        </CardContent>
        <CardContent>
          <div className={classes.sectionsRoot}>
            {sections?.map((s: any) => (
              <SingleSection key={s.id} data={s} allowLink={allowLink} />
            ))}
          </div>
        </CardContent>
      </Card>
      <RoomsList sections={sections} />
    </FiltersContext.Provider>
  );
};

const range = (n: number) => Array.from(Array(n).keys());

function SingleSection(props: any) {
  const { data, onSelectRoom, allowLink } = props;
  const classes = useStyles();
  const { floorsnum, number, id, lastupdate, updatedAt } = data;
  const rooms = React.useMemo(() => calcRooms(data), [data]);

  const updated = React.useMemo(() => {
    try {
      if (lastupdate) {
        return format(lastupdate?.toDate(), 'dd.MM.yyyy HH:mm');
      }
      if (updatedAt) {
        return format(parseISO(updatedAt), 'dd.MM.yyyy HH:mm');
      }
    } catch (ex) { }
    return '';
  }, [lastupdate, updatedAt]);

  return (
    <div className={classes.singleSection}>
      {/* <Typography>Секция {number}</Typography> */}
      <AllowedLink to={`/entrances/${id}/show`} target="_blank" allow={allowLink}>Секция {number}</AllowedLink>
      <Typography variant="caption">Дата обновления: {updated}</Typography>
      <InfoFloor data={data} />
      {range(floorsnum).map((n) => {
        const floor = floorsnum - n;
        return (
          <Floor
            key={n}
            floor={floor}
            rooms={rooms.filter((r) => r.floor === floor)}
            onSelectRoom={onSelectRoom}
          />
        );
      })}
    </div>
  );
}

function InfoFloor(props: any) {
  const classes = useStyles();
  const { data } = props;
  const { rooms: allRooms = [] } = data;

  const rooms = React.useMemo(() => {
    if (allRooms) {
      return allRooms.filter((ar: any) => ar.floor === 0);
    }
    return [];
  }, [allRooms]);

  return (
    <div className={classes.floor}>
      <div className={classes.floorNumber}></div>
      {rooms.map((r: any) => (
        <InfoRoom key={r.rpf} data={r} />
      ))}
    </div>
  );
}

function InfoRoom(props: any) {
  const { data } = props;
  const { space, sides = [] } = data;
  const classes = useStyles();
  const style: any = { background: 'white', flexDirection: 'column' };

  const sidesNamed = React.useMemo(() => {
    return sides.map((s: any) => roomSides.find((rs: any) => Number(rs.id) === Number(s))?.name).join(',')
  }, [sides]);

  return (
    <Paper className={classes.room} style={style}>
      <Typography variant="caption">{sidesNamed}</Typography>
      <Typography variant="caption">{space}</Typography>
    </Paper>
  );
}

function Floor(props: any) {
  const classes = useStyles();
  const { floor, rooms, onSelectRoom } = props;
  return (
    <div className={classes.floor}>
      <div className={classes.floorNumber}>
        <Typography variant="caption">{floor}</Typography>
      </div>
      {rooms.map((r: any) => (
        <Room key={r.rpf} data={r} onSelectRoom={onSelectRoom} />
      ))}
    </div>
  );
}

function Room(props: any) {
  const { data } = props;
  const { roomsFilter, ownerFilter } = React.useContext(FiltersContext);
  const { type, price, space, status, floor, number, dealtype, id, photo, info } = data;
  const classes = useStyles();
  const roomType = composedRoomTypes[type];
  const style: any = {};

  if (Number(status) === 3) {
    style.background = 'red';
  }

  if (Number(status) === 2) {
    style.background = 'black';
  }

  if (Number(status) === 1) {
    style.background = 'yellow';
  }

  if (Number(status) === 0) {
    style.background = 'darkcyan';
  }

  if (roomsFilter && roomsFilter.length) {
    if (!roomsFilter.includes(roomType)) {
      style.opacity = 0.1;
    }
  }

  if (ownerFilter && ownerFilter.length) {
    if (!ownerFilter.includes(`${dealtype}`)) {
      style.opacity = 0.1;
    }
  }

  return (
    <Tooltip
      title={
        <>
          <Typography>Вид: {roomType}к</Typography>
          <Typography>Номер квартиры: {number}</Typography>
          <Typography>Этаж: {floor}</Typography>
          <Typography>
            Стоимость: {Number(price).toLocaleString()} руб.
          </Typography>
          <Typography>Площадь: {space} кв.м.</Typography>
          {
            info && (
              <Typography>Инфо: {info}</Typography>
            )
          }
          <div style={{ width: 250 }}>
            <img src={composeUrl(photo?.src, '600')} alt="room" style={{ maxWidth: '100%', height: 'auto', maxHeight: 250 }} />
          </div>
        </>
      }
      interactive={false}
      placement="right-end"
    >
      <Paper
        className={classes.room}
        style={style}
      >
        <Link to={{ pathname: '/roominfo', search: `?id=${id}` }} className={classes.roomLink} target="_blank">
          <Typography variant="caption">{roomType}</Typography>
        </Link>
      </Paper>

    </Tooltip>
  );
}

function filterObject(obj: any) {
  return Object.entries(obj).reduce(
    (m: any, [k, v]) => (v !== undefined && v !== null ? { ...m, [k]: v } : m),
    {}
  );
}

function calcRooms(props: any) {
  const result = [];
  const { floors = [], rooms = [], building, number, id } = props;
  const { floorsnum, roomsFromFloor = 1, roomsFromNumber = 1 } = props;
  const roofFloor = floors.find((f: any) => f.number === 0) || {};

  let roomNumber = roomsFromNumber - 1;

  for (let floor = 1; floor <= floorsnum; floor++) {
    const foundFloor = floors.find((f: any) => f.number === floor);
    const roomsPerFloor =
      foundFloor?.roomsPerFloor !== undefined
        ? foundFloor.roomsPerFloor
        : props.roomsPerFloor;
    for (let rpf = 1; rpf <= roomsPerFloor; rpf++) {
      const roof = rooms.find((r: any) => r.floor === 0 && r.rpf === rpf) || {};
      const foundRoom =
        rooms.find((r: any) => r.floor === floor && r.rpf === rpf) || {};
      const room = { ...filterObject(roof), floor, ...filterObject(foundRoom) };

      if (floor >= roomsFromFloor) {
        roomNumber++;
        room.number = roomNumber;
      }

      if (room.space && room.price && Number(room.price) < 200000) {
        room.price = Number(room.price) * Number(room.space);
      }
      if (!room.pricepm && room.price && room.space) {
        room.pricepm = Number(room.price) / Number(room.space);
      }

      result.push({
        rpf: room.rpf,
        floor: room.floor,
        number: room.number,
        floorPhoto: foundFloor?.photo || roofFloor?.photo || {},
        photo: room.photo,
        type: room.type || 'unknown',
        price: Number(room.price) || 0,
        space: Number(room.space) || 0,
        status: room.status,
        pricepm: room.pricepm,
        dealtype: room.dealtype || 0,
        state: room.state,
        rt: composedRoomTypes[room.type || 'unknown'],
        id: `${building}_${id}_${room.floor}_${room.rpf}`,
        section: number,
        info: room.info
      });
    }
  }
  return result;
}

const columns: any = [
  { field: 'id', hide: true },
  { field: 'section', headerName: 'Секция', width: 150 },
  { field: 'rt', headerName: 'Тип', width: 150 },
  { field: 'number', headerName: 'Номер', width: 150 },
  { field: 'floor', headerName: 'Этаж', width: 150 },
  { field: 'space', headerName: 'Площадь', width: 150 },
  { field: 'price', headerName: 'Стоимость', width: 150 },
  // {field: 'status', headerName: 'Статус', width: 150 },
];

function RoomsList(props: any) {
  const { sections } = props;
  const classes = useStyles();

  const rows = React.useMemo(() => {
    let rooms: any = [];
    if (sections) {
      sections.forEach((s: any) => {
        const calc = calcRooms(s).filter((r) => Number(r.status) === 0);

        rooms = [...rooms, ...calc];
      });
    }
    return rooms;
  }, [sections]);

  if (!rows.length) {
    return null;
  }

  return (
    <Card className={classes.card}>
      <CardContent>
        <div style={{ height: 450, width: '100%' }}>
          <DataGrid rows={rows} columns={columns} />
          {/* {
            <pre>
              {JSON.stringify(rows, null, ' ')}
            </pre>
          } */}
        </div>
      </CardContent>
    </Card>
  );
}

function BuildingTechnology(props: any) {
  // return <span></span>;
  const { id } = props;
  const { data, loaded } = useQueryWithStore({
    type: 'getOne',
    resource: id ? 'buildingtechnologies' : '',
    payload: { id }
  });

  if (!id || !loaded) {
    return null;
  }

  return <span>{data?.name}</span>;
}

function BuildingState(props: any) {
  // return <span></span>;
  const { id } = props;
  const { data, loaded } = useQueryWithStore({
    type: 'getMany',
    resource: id ? 'buildingstates' : '',
    payload: { ids: id }
  });

  if (!id || !loaded) {
    return null;
  }

  const names = Array.isArray(data) ? data.map((d: any) => d.name).join(', ') : '';

  return <span>{names}</span>;
}

function Bank(props: any) {
  const { id } = props;
  const { data, loaded } = useQueryWithStore({
    type: 'getOne',
    resource: id ? 'banks' : '',
    payload: { id }
  });

  if (!id || !loaded) {
    return null;
  }

  return <span>{data?.name}</span>;
}

function BuildingClass(props: any) {
  const { id } = props;
  if (!id) {
    return null;
  }
  const found = buildingClassChoises.find(b => b.id === id);

  if (!found) {
    return null;
  }

  return <span>{found?.name}</span>
}


function AllowedLink(props: any) {
  const { allow, children, ...otherProps } = props;
  if (!allow) {
    return <Typography>{children}</Typography>
  }

  return <Typography><Link {...otherProps}>{children}</Link></Typography>
}

const Rooms = (props: any) => {
  const { building, allowLink, complex, endDate } = props;
  const classes = useStyles();

  const { data: rooms } = useQueryWithStore({
    type: 'getList',
    resource: building ? 'rooms' : '',
    payload: {
      pagination: { page: 1, perPage: 1000 },
      filter: { active: true, building },
      sort: { field: 'priceFrom', order: 'ASC' }
    }
  });

  // return (
  //   <Card className={classes.card}>
  //     <CardContent>
  //       <TableContainer component={Paper}>
  //         <Table>
  //           <TableHead>
  //             <TableRow>
  //               <TableCell>Вид квартиры</TableCell>
  //               <TableCell>Площадь кв.м.</TableCell>
  //               <TableCell>Стоимость руб.</TableCell>
  //               <TableCell>План</TableCell>
  //             </TableRow>
  //           </TableHead>
  //           <TableBody>
  //             {
  //               rooms?.map((row: any) => (
  //                 <TableRow key={row.id}>
  //                   <TableCell>
  //                     <Link to={{ pathname: '/roominfo', search: `?id=${building}_0_0_0_${row.id}` }} target="_blank"><PresentToAllIcon /></Link>
  //                     <AllowedLink to={`/rooms/${row?.id}`} target="_blank" allow={allowLink}><RoomType id={row.type} /></AllowedLink>
  //                   </TableCell>
  //                   <TableCell><RangeNumberField data1={row?.spaceFrom} data2={row?.spaceTo} /></TableCell>
  //                   <TableCell><RangeNumberField data1={row?.priceFrom} data2={row?.priceTo} /> </TableCell>
  //                   <TableCell><RoomsPhoto data={row} /></TableCell>
  //                 </TableRow>
  //               ))
  //             }
  //           </TableBody>
  //         </Table>
  //       </TableContainer>
  //     </CardContent>
  //   </Card>
  // );
  return (
    <Card className={classes.card}>
      <CardContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Тип квартиры</TableCell>
                <TableCell>Планировка</TableCell>
                <TableCell>Площадь кв.м.</TableCell>
                <TableCell>Стоимость руб.</TableCell>
                <TableCell>Дата сдачи</TableCell>
                <TableCell>Фото</TableCell>
                <TableCell>План этажа</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                rooms?.map((row: any) => (
                  <TableRow key={row.id}>
                    <TableCell style={{ position: 'relative', textAlign: 'center' }}>
                      <Link to={{ pathname: '/roominfo', search: `?id=${building}_0_0_0_${row.id}` }} target="_blank"><PresentToAllIcon style={{ position: 'absolute', top: 5, left: 5 }} /></Link>
                      <AllowedLink to={`/rooms/${row?.id}`} target="_blank" allow={allowLink}><RoomType id={row.type} /></AllowedLink>
                    </TableCell>
                    <TableCell><RoomsPlanPhoto data={row} /></TableCell>
                    <TableCell><RangeNumberField data1={row?.spaceFrom} data2={row?.spaceTo} /></TableCell>
                    <TableCell><RangeNumberField data1={row?.priceFrom} data2={row?.priceTo} /> </TableCell>
                    <TableCell>{endDate}</TableCell>
                    <TableCell><RoomsPhoto data={row} /></TableCell>
                    <TableCell><RoomsFloorPlanPhoto data={row} /></TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};


function RoomType(props: any) {
  const { id } = props;
  // const { data, loaded } = useQueryWithStore({
  //   type: 'getOne',
  //   resource: id ? 'roomtypes' : '',
  //   payload: { id }
  // });

  // if (!id || !loaded) {
  //   return null;
  // }

  // return <span>{data?.name}</span>;
  return <span>{composedRoomTypes[id]}</span>
}

function NumberField(props: any) {
  const { data } = props;
  if (!data) {
    return null;
  }
  return <span>{Number(data).toLocaleString()}</span>;
}

function RangeNumberField(props: any) {
  const { data1, data2 } = props;
  if (!data1 && !data2) {
    return null;
  }

  if (data1 === data2) {
    return <span><NumberField data={data1} /></span>
  }

  return <span><NumberField data={data1} /> - <NumberField data={data2} /></span>;
}

function RoomsPhoto(props: any) {
  const { data } = props;
  const [openLightbox, setOpenLightbox] = React.useState(false);
  const [photoIndex, setPhotoIndex] = React.useState(0);

  const photos = React.useMemo(() => {
    if (data) {
      // const { roomPhoto = [], planPhoto = [] } = data;
      // return [...roomPhoto, ...planPhoto];
      const { roomPhoto = [] } = data;
      return [...roomPhoto];
    }
    return null;
  }, [data]);

  if (!data || !photos) {
    return null;
  }

  return (
    <div>
      <div style={{ width: 120, height: 100, display: 'flex', flexDirection: 'row', overflowY: 'scroll' }}>
        {
          photos.map((p: any, num) => <img src={composeUrl(p.src, '600')} style={{ maxWidth: 100, maxHeight: 100, cursor: 'pointer' }} alt={p.title} onClick={() => setOpenLightbox(true)} />)
        }
      </div>
      {
        openLightbox &&
        <Lightbox
          mainSrc={photos[photoIndex].src}
          nextSrc={photos[(photoIndex + 1) % photos.length].src}
          prevSrc={photos[(photoIndex + photos.length - 1) % photos.length].src}
          onCloseRequest={() => setOpenLightbox(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % photos.length)
          }
        />
      }
    </div>
  )

}

function RoomsPlanPhoto(props: any) {
  const { data } = props;
  const [openLightbox, setOpenLightbox] = React.useState(false);
  const [photoIndex, setPhotoIndex] = React.useState(0);

  const photos = React.useMemo(() => {
    if (data) {
      const { planPhoto = [] } = data;
      return [...planPhoto];
    }
    return null;
  }, [data]);

  if (!data || !photos) {
    return null;
  }

  return (
    <div>
      <div style={{ width: 120, height: 100, display: 'flex', flexDirection: 'row', overflowY: 'scroll' }}>
        {
          photos.map((p: any, num) => <img src={composeUrl(p.src, '600')} style={{ maxWidth: 100, maxHeight: 100, cursor: 'pointer' }} alt={p.title} onClick={() => setOpenLightbox(true)} />)
        }
      </div>
      {
        openLightbox &&
        <Lightbox
          mainSrc={photos[photoIndex].src}
          nextSrc={photos[(photoIndex + 1) % photos.length].src}
          prevSrc={photos[(photoIndex + photos.length - 1) % photos.length].src}
          onCloseRequest={() => setOpenLightbox(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % photos.length)
          }
        />
      }
    </div>
  )

}

function RoomsFloorPlanPhoto(props: any) {
  const { data } = props;
  const [openLightbox, setOpenLightbox] = React.useState(false);
  const [photoIndex, setPhotoIndex] = React.useState(0);

  const photos = React.useMemo(() => {
    if (data) {
      const { floorPlanPhoto = [] } = data;
      return [...floorPlanPhoto];
    }
    return null;
  }, [data]);

  if (!data || !photos) {
    return null;
  }

  return (
    <div>
      <div style={{ width: 120, height: 100, display: 'flex', flexDirection: 'row', overflowY: 'scroll' }}>
        {
          photos.map((p: any, num) => <img src={composeUrl(p.src, '600')} style={{ maxWidth: 100, maxHeight: 100, cursor: 'pointer' }} alt={p.title} onClick={() => setOpenLightbox(true)} />)
        }
      </div>
      {
        openLightbox &&
        <Lightbox
          mainSrc={photos[photoIndex].src}
          nextSrc={photos[(photoIndex + 1) % photos.length].src}
          prevSrc={photos[(photoIndex + photos.length - 1) % photos.length].src}
          onCloseRequest={() => setOpenLightbox(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % photos.length)
          }
        />
      }
    </div>
  )

}