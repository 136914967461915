import React from 'react';
import DG from '2gis-maps';

import { useDispatch } from 'react-redux';
import { ComplexIcon, SelectedComplexInfo } from './SearchMap';
import { setObject, setComplexesData } from 'reducers/search-slice';

const defaultView = {
  center: [54.98, 82.89],
  zoom: 4,
};

export const Map = (props: any) => {
  const { value, name, zoom = 4, height, id } = props;
  const dispatch = useDispatch();
  const ref = React.useRef(null);
  const [map, setMap] = React.useState<any>(null);
  const [complexIcons, setComplexIcons] = React.useState<any>([]);

  React.useEffect(() => {
    const mapObj = DG.map(ref.current, defaultView);
    setMap(mapObj);
    return () => {
      mapObj.remove();
    };
  }, []);

  React.useEffect(() => {
    if (map && value) {
      const { lat, lng } = value;
      if (lat && lng) {
        const marker = DG.marker([lat, lng], {
          title: name,
          draggable: false,
        });
        marker.addTo(map);
        map.setView([lat, lng], zoom);
      }
    }
  }, [map, value, zoom, name]);

  React.useEffect(() => {
    if (map && id) {
      try {
        const complexes = JSON.parse(localStorage.getItem('szpro::complex-search-data') || '');
        dispatch(setComplexesData(complexes));
        const layer = new DG.LayerGroup();
        const iconsData: any = [];
        complexes.forEach((c: any) => {
          if (c.geo && c.id !== id) {
            try {
              const { lat, lng } = c.geo;
              const complexIcon = DG.divIcon({
                className: 'map-icon-zero',
                iconSize: new DG.Point(15, 15),
                html: `<div id="c_${c.id}"/>`
              })
              const marker = DG.marker([lat, lng], {
                title: c.name,
                icon: complexIcon,
              }); //.bindPopup(c.name);
              marker.on('click', () => {
                dispatch(setObject(c.id));
              });
              marker.addTo(layer);
              iconsData.push(<ComplexIcon key={c.id} data={c} />);
            } catch (ex) { }
          }
        });
        layer.addTo(map);
        setComplexIcons(iconsData);
      } catch (err) {
        console.log('---error read search cache---', err);
      }
    }
  }, [map, id]);

  return (
    <div ref={ref} style={{ height: height ?? 400, position: 'relative' }}>
      <SelectedComplexInfo />
      {complexIcons}
    </div>
  );
};

export default Map;
