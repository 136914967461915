import React from 'react';
import { RoomType } from './roomtypes';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  room: {
    width: 50,
    marginRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  }
}));

export const roomSides = [
  { id: 0, name: 'с' },
  { id: 1, name: 'св' },
  { id: 2, name: 'в' },
  { id: 3, name: 'юв' },
  { id: 4, name: 'ю' },
  { id: 5, name: 'юз' },
  { id: 6, name: 'з' },
  { id: 7, name: 'сз' },
];

const Room = (props: { data: RoomType; onSelectRoom: any, selectedRooms: any }) => {
  const { data, onSelectRoom, selectedRooms } = props;
  const { number, status, rt, floor, price, space, pricepm, rpf, info, euro } = data;
  const classes = useStyles();
  const style: any = { userSelect: 'none' };

  const selected = React.useMemo(() => {
    if (Array.isArray(selectedRooms) && floor && rpf) {
      return selectedRooms.find(s => s.rpf === rpf && s.floor === floor);
    }
    return false;
  }, [floor, rpf, selectedRooms]);

  if (Number(status) === 3) {
    style.background = 'red';
  }

  if (Number(status) === 2) {
    style.background = 'black';
  }

  if (Number(status) === 1) {
    style.background = 'yellow';
  }

  if (Number(status) === 0) {
    style.background = 'darkcyan';
  }

  if (selected) {
    style.background = 'blue';
  }

  const handleClick = React.useCallback((event) => {
    // console.log('---data---', data);
    const { rpf, floor } = data;
    if (onSelectRoom) {
      onSelectRoom({ rpf, floor }, event)
    }
  }, [data, onSelectRoom])

  return (
    <Tooltip
      title={
        <>
          <Typography>Вид: {rt}к</Typography>
          <Typography>Номер квартиры: {number}</Typography>
          <Typography>Этаж: {floor}</Typography>
          <Typography>
            Стоимость: {Number(price).toLocaleString()} руб.
          </Typography>
          <Typography>
            Стоимость за кв.м.: {Number(pricepm).toLocaleString()} руб.
          </Typography>
          <Typography>Площадь: {space} кв.м.</Typography>
          {
            info && (
              <Typography>Инфо: {info}</Typography>
            )
          }

        </>
      }
      interactive={false}
      placement="right-end"
    >
      <Paper className={classes.room} style={style} onClick={() => handleClick('click')} onMouseDown={() => handleClick('down')} onMouseUp={() => handleClick('up')} onMouseOut={() => handleClick('over')} elevation={selected ? 0 : 3}><Typography variant="caption">{rt} {euro ? 'e' : ''}</Typography></Paper>
    </Tooltip>
  )
}

export default Room;

export const InfoRoom = (props: { data: RoomType; onSelectRoom: any }) => {
  const { data, onSelectRoom } = props;
  const { space, sides = [] } = data;
  const classes = useStyles();
  const style: any = { background: 'white', flexDirection: 'column' };

  const sidesNamed = React.useMemo(() => {
    return sides.map(s => roomSides.find(rs => Number(rs.id) === Number(s))?.name).join(',')
  }, [sides]);

  const handleClick = React.useCallback((event) => {
    const { rpf } = data;
    if (onSelectRoom) {
      onSelectRoom({ rpf, floor: 0 }, event)
    }
  }, [data, onSelectRoom])

  return (
    <Paper className={classes.room} style={style} onClick={() => handleClick('click')}>
      <Typography variant="caption">{sidesNamed}</Typography>
      <Typography variant="caption">{space}</Typography>
    </Paper>
  );
}