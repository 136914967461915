const listFields = {
  info: 'Комментарии',
  name: 'Наименование',
  reject: 'Отказ от работы',
  manager: 'Эксперт',
  liststatus: 'Статус сделки',
  broker: 'Брокер',
  selltype: 'Вид оплаты',
  office: 'Офис',
  contractnum: 'Номер договора',
  reservedate: 'Дата бронирования',
  contractdate: 'Дата заключения договора',
  developerpaiddate: 'Дата оплаты квартиры застройщику',
  dopkvData: 'Дополнительный КВ, дата',
  contractdate2: 'Дата отправки на договор',
  regdate: 'Дата регистрации',
  invoicedate: 'Дата выставления счета застройщику',
  estimateDealDate: 'Ожидаемая дата заключения договора',
  estimateClientPaidDate: 'Ожидаемая дата оплаты клиентом',
  estimateKVPaidDate: 'Ожидаемая дата оплаты КВ',
  kvpaiddate: 'Дата оплаты КВ',
  bonusCoefficient: 'Коэффициент премии',
  field_3_7: 'Итого ЗП',
  salaryValue: 'Начисленная ЗП',
  salary: 'выплаченная Зарплата',
  comm_pj: 'комиссия партнеру/юристу',
  salaryDate: 'дата выплаты Зарплаты',
  field_3_1: 'Коэффициент премии страховка',
  field_3_3: 'Коэффициент премии платные банки',
  field_3_2: 'Начисленная ЗП страховка',
  field_3_4: 'Начисленная ЗП платные банки',
  field_3_5: 'Коэффициент премии от суммы КВ',
  contragentsurname: 'Фамилия контрагента',
  contragentname: 'Имя контрагента',
  email: 'Email клиента',
  kv_buyer_fix: 'Фикс размер КВ с покупателя',
  contragentforename: 'Отчество контрагента',
  source: 'Источник',
  kv_buyer: 'КВ с покупателя',
  sellersurname: ' Фамилия продавца',
  sellerphone: 'Телефон продавца',
  kv_seller_pr: '% КВ с продавца',
  sellername: 'Имя продавца',
  selleremail: 'Email продавца',
  kv_seller_fix: 'Фикс размер КВ с продавца',
  sellerforename: 'Отчество продавца',
  sellersource: 'Источник продавца',
  kv_seller: 'КВ с продавца',
  complex: 'ЖК',
  address: 'адрес',
  building: 'Дом',
  roomnum: 'Номер квартиры',
  roomsquare: 'Площадь квартиры',
  kvpr: 'Процент КВ',
  docs1: 'Направление',
  stage: 'Этаж',
  roomcode: 'Код квартиры',
  sell: 'Сумма продажи',
  priceperm: 'Цена за кв метр',
  docs2: 'ДДУ',
  roomtype: 'Вид квартиры',
  action: 'Акция',
  kv: 'Размер КВ',
  dopkv: 'Дополнительный КВ, %',
  bank: 'Банк',
  strahRef: 'Страховая',
  ouripoteka: 'Ипотека через нас',
  indkv: 'Индивидуальный расчет КВ',
  creditSum: 'Сумма кредита',
  strahSum: 'Сумма страхования',
  strahDateStrat: 'Дата заключения договора страхования',
  bankKvPercent: 'КВ банка, %',
  strahKv: 'КВ Страховка, %',
  strahDateEnd: 'Дата окончания договора страхования',
  bankKvSum: 'КВ банка, сумма',
  strahKvSum: 'КВ Страховка, сумма',
  ipoteka: 'Ипотека',
  contragent: 'Контрагент',
  city: 'Город',
  developer: 'Застройщик',
  phone: 'Телефон',
  dateOfConstruction: 'Дата сдачи',
  realDateOfConstruction: 'Дата сдачи по декларации',
  dopkvSum: 'Доп размер КВ',
  sumkvItog: 'Общая сумма (итого) КВ',
  dopkvZp: 'ЗП по доп КВ',
  sumZpItog: 'Общая сумма ЗП',
  rejectDate: 'Дата отказа',
  kv_buyer_pr: '% КВ с покупателя',
  id: 'id',
  list_kind: 'Статус документа',
  createdAt: 'Создан',
  creditIpotekaDateStart: 'Дата заключения кредитного договора',
};

const ipotekaFieldsList = {
  id: 'ID',
  status: 'Статус',
  office: 'Офис',
  manager: 'Эксперт',
  broker: 'Ипотечный брокер',
  name: 'ФИО',
  sum: 'Стоимость',
  first_sum: 'Первоначальный взнос',
  credit_sum: 'Сумма кредита',
  credit_period: 'Срок кредита',
  borrower: 'Заемщик или созаемщик',
  credit_program: 'Программа кредитования',
  object_type: 'Тип недвижимости',
  source_money: 'Источник первоначального взноса',
  address_fact: 'Адрес фактического проживания',
  family_status: 'Семейное положение',
  education_status: 'Образование',
  phone: 'Телефон',
  work_place: 'Место работы',
  work_phone: 'Рабочий телефон',
  stag_on_last_work_place: 'Стаж на последнем месте',
  stag_months: 'Стаж в месяцах',
  work_role: 'Должность',
  inn: 'ИНН',
  work_address_fact: 'Фактический адрес места работы',
  surname_changed: 'Менялась ли фамилия',
  surname_prev: 'Предыдущая фамилия',
  children: 'Дети',
  actives: 'Активы',
  info: 'Комментарии',
  createdate: 'Создан',
  lastupdate: 'Обновлен',
  expert: 'Эксперт',
  requestDateBank: 'Дата первого запроса в банк',
  requestDateStrah: 'Дата первого запроса в страховую',
  liststatus: 'Этап ОП',
  documents: 'Документы',
  docsname: 'Наименование',
  comment: 'Комментарий',
  docs: 'Файлы',
  strahstatus: 'Статус Страхования',
  bank: 'Банк',
  firstSendDate: 'Дата подачи (первая)',
  approveDate: 'Дата одобрения (первая)',
  list_kind: 'Состояние документа',
  managerOffice: 'Офис эксперта',
};

const messages = {
  custom: {
    price: 'Стоимость',
    space: 'Площадь',
    pricepm: 'Стоимость кв.м.',
    space_k: 'Площадь кухни',
    space_r: 'Площадь комнат',
    roomStatus: 'Статус',
    roomState: 'Отделка',
    roomDealtype: 'Тип сделки',
    roomType: 'Тип квартиры',
    roomPhoto: 'Фото',
    roomSides: 'Стороны света',
    rpf: 'Квартир на этаже',
    floorPhoto: 'Планировка этажа',
    contragent: 'Контрагент',
    info: 'Информация',
    euro: 'Европланировка',
  },
  list: listFields,
  ilist: ipotekaFieldsList,
  resources: {
    countries: {
      name: 'Страна |||| Страны',
    },
    regions: {
      name: 'Регион |||| Регионы',
      fields: {
        name: 'Наименование',
        country: 'Страна',
      },
    },
    cities: {
      name: 'Город |||| Города',
      fields: {
        name: 'Наименование',
        region: 'Регион',
        geo: 'Координаты',
      },
    },
    districts: {
      name: 'Район городов |||| Районы города',
      fields: {
        name: 'Наименование',
        city: 'Город',
        main_district: 'Округ',
      },
    },
    developers: {
      name: 'Застройщик |||| Застройщики',
      fields: {
        active: 'Активен',
        name: 'Наименование',
        info: 'Описание',
        logo: {
          src: 'Логотип',
        },
        contactPhone: 'Контактный номер для экспертов',
        contactName: 'ФИО Контактного лица',
        contactRole: 'Должность Контактного лица',
        contactEmail: 'Электронные адреса Контактного лица',
        buhPhone: 'Контактны номер бухгалтерия',
        buhName: 'ФИО Бухгалтера',
        buhRole: 'Должность Бухгалтера',
        buhEmail: 'Электронные адреса Бухгалтерии',
        mainManPhone: 'Контактный номер лица принимающего решения',
        mainManName: 'ФИО',
        mainManRole: 'Должность',
        mainManEmail: 'Электронные адреса',
        managerPhone: 'Контактный номер мненеджера на объекте',
        officialName: 'Наименование юридического лица',
        inn: 'ИНН',
        ogrn: 'ОГРН',
        realAddress: 'Фактический адрес',
        officialAddress: 'Юридичекий адрес',
        officeAddress: 'Адрес офиса проода',
        bankAccount: 'Расчетный счет',
        BIK: 'БИК',
        bankName: 'Банк',
        contractNumber: 'Номер договора',
        contractDate: 'Дата договора',
        contractPeriod: 'Срок действия договора',
        contractProlongation: 'Возможность пролонгации договора',
        commissionAmount: 'Размер КВ',
        comissionConditions: 'Условия оплаты КВ',
        commissionPayDay: 'Срок оплаты КВ',
        site: 'Адрес Сайта',
        attachedCity: 'Город',
        docs: 'Документы',
      },
    },
    complexes: {
      name: 'ЖК',
      fields: {
        active: 'Активен',
        name: 'Наименование',
        developer: 'Застройщик',
        city: 'Город',
        district: 'Район',
        buildings: 'Количество зданий',
        landOwn: 'Земля в собственности',
        fencedArea: 'Огороженная территория',
        parkingType: 'Парковка',
        paidParking: 'Платные стоянки в округе',
        banksAccreditation: 'Аккредитация',
        deferredPayment: 'Рассрочка',
        deferredPaymentText: 'Условия рассрочки',
        paidReserve: 'Платный резерв',
        reserveText: 'Стоимость и срок резерва',
        site: 'Сайт',
        geo: 'Координаты',
        video: 'Cссылка на видео',
        info: 'Описание',
        accreditation: 'Ипотека',
        docs: 'Документы',
        top: 'Топ ЖК',
        topnumber: 'Топ 1/5/10',
        rating: 'Рейтинг',
        genplan: 'Генплан',
        advantages: {
          text: 'Преимущество',
        },
        apartments: 'Апартаменты',
        main_district: 'Округ',
        metro_line: 'Линия метро',
        metro_line_arr: 'Линии метро',
        metro_station: 'Станция метро',
        metro_station_arr: 'Станции метро',
      },
    },
    banks: {
      name: 'Банк |||| Банки',
      fields: {
        name: 'Наименование',
        active: 'Активен',
        commission: 'Комиссия',
        contact1Name: 'Ответственно лицо за прием заявок',
        contact1Role: 'должность',
        contact1Email: 'email',
        contact1Phone: 'телефон',
        contact2Name: 'Ответственно лицо за партнерское развитие ',
        contact2Role: 'должность',
        contact2Email: 'email',
        contact2Phone: 'телефон',
        contact3Name: 'Руководитель ипотечного отдела',
        contact3Role: 'должность',
        contact3Email: 'email',
        contact3Phone: 'телефон',
        attachedCity: 'Город',
        top3: 'Топ 3',
        logo: {
          src: 'Логотип',
        },
      },
    },
    accreditations: {
      name: 'Ипотечная программа |||| Ипотечные программы',
      fields: {
        name: 'Наименование',
        active: 'Активен',
        bank: 'Банк',
        minRateWithAgree:
          'минимальная процентная ставка с подтверждением доходов',
        minRateWithoutAgree:
          'минимальная процентная ставка без подтверждения доходов',
        minPaymentWithAgree:
          'минимальная первоначальный взнос с подтверждением доходов',
        minPaymentWithoutAgree:
          'минимальная первоначальный взнос без подтверждением доходов',
        bankFormAgree: 'Подтверждение по форме банка',
        motherCapital: 'Материнский капитал',
        military: 'Военная ипотека',
        twoDocs: 'По двум документам',
        workWithIP: 'Работа с ИП',
        govSupport: 'Господдержка',
      },
    },
    materials: {
      name: 'Материал |||| Материалы',
      fields: {
        name: 'Наименование',
      },
    },
    heatings: {
      name: 'Отопление',
      fields: {
        name: 'Наименование',
      },
    },
    canalizations: {
      name: 'Канализация',
      fields: {
        name: 'Наименование',
      },
    },
    buildingstates: {
      name: 'Варианты отделки',
      fields: {
        name: 'Наименование',
      },
    },
    buildingtechnologies: {
      name: 'Технология строительства',
      fields: {
        name: 'Наименование',
      },
    },
    liftbrands: {
      name: 'Лифт',
      fields: {
        name: 'Наименование',
      },
    },
    buildings: {
      name: 'Здание |||| Здания',
      fields: {
        name: 'Наименование',
        info: 'Описание',
        complex: 'ЖК',
        active: 'Активен',
        dateOfConstruction: 'Дата сдачи',
        realDateOfConstruction: 'Дата сдачи по декларации',
        photo: 'Фото',
        materials: 'Материалы',
        heating: 'Система отопления',
        canalization: 'Система канализации',
        buildingState: 'Состояние на момент сдачи',
        buildingTechnology: 'Технология строительства',
        floors: 'Количество этажей',
        ceilingHeight: 'Высота потолков',
        liftBrand: 'Марка лифта',
        freightLift: 'Количество грузовых лифтов',
        passengerLift: 'Количество пассажирских лифтов',
        address: 'Адрес',
        buildingWallWidth: 'Толщина внешних стен',
        roomWallWidth: 'Толщина межкомнатных стен',
        feature: 'Особенность',
        roomNumbersPerFloor: 'Количество квартир на этаже',
        entrancesNumber: 'Количество подъездов',
        technicalFloor: 'Наличие технического этажа',
        concierge: 'Консьерж',
        glassing: 'Остекление',
        geo: 'Координаты',
        docs: 'Документы',
        done: 'Сдан',
        readypercent: 'Процент готовности',
        buildingClass: 'Класс дома',
        date: 'Дата',
        prices: 'Цены',
        c: 'Студии',
        '1k': '1 комнатные',
        '2k': '2 комнатные',
        '3k': '3 комнатные',
        '4+': '4 комнатные и больше',
        cSells: 'Продано Студии',
        '1kSells': 'Продано 1 комнатные',
        '2kSells': 'Продано 2 комнатные',
        '3kSells': 'Продано 3 комнатные',
        '4+Sells': 'Продано 4 комнатные и больше',
      },
    },
    roomtypes: {
      name: 'Тип помещения',
      fields: {
        name: 'Наименование',
      },
    },
    entrances: {
      name: 'Секция |||| Секции',
      fields: {
        name: 'Наименование',
        building: 'Здание',
        number: 'Номер',
        floorsnum: 'Количество этажей',
        roomsPerFloor: 'Количество квартир на этаже',
        roomsFromFloor: 'Квартиры с этажа',
        roomsFromNumber: 'Нумерация Квартир с',
        info: 'Информация',
        price: 'Стоимость',
        rooms: {
          price: 'Стоимость',
          space: 'Площадь',
        },
      },
    },
    offices: {
      name: 'Офис |||| Офисы',
      fields: {
        name: 'Наименование',
        city: 'Город',
        active: 'Активен',
        address: 'Адрес',
        officeSquare: 'Площадь офиса',
        headPhone: 'Телефон директора',
        realName: 'Реальное наименование',
        info: 'Информация',
        headEmail: 'email Директора',
        dob: 'Дата образования',
        region: 'Регион',
        phone: 'Телефон',
        use_amo: 'Использовать Интеграцию AMOCRM',
        amo_pipeline: 'Воронка AMOCRM',
        amo_pipeline2: 'Доп. Воронка AMOCRM',
      },
    },
    users: {
      name: 'Пользователь |||| Пользователи',
      fields: {
        name: 'Имя',
        role: 'Права',
        office: 'Офис',
        active: 'Активен',
        fb_active: 'Активен',
        phone: 'Телефон',
        notInManagerReportsFrom: 'Не виден в отчетах с даты',
        photo: 'Фото',
        amo: 'Пользователь amocrm (email)',
        amo2: 'Доп. Пользователь amocrm (email)',
      },
    },
    actions: {
      name: 'Акция |||| Акции',
      fields: {
        name: 'Наименование',
      },
    },
    ipotekastatuses: {
      name: 'Статус ипотеки |||| Статусы ипотеки',
      fields: {
        name: 'Наименование',
      },
    },
    liststatuses: {
      name: 'Статус РЛ |||| Статусы РЛ',
      fields: {
        name: 'Наименование',
      },
    },
    listsources: {
      name: 'Источник РЛ |||| Источники РЛ',
      fields: {
        name: 'Наименование',
      },
    },
    selltypes: {
      name: 'Вид продажи |||| Виды продаж',
      fields: {
        name: 'Наименование',
      },
    },
    strahs: {
      name: 'СК',
      fields: {
        name: 'Наименование',
      },
    },
    stats: {
      name: 'Статистика',
      fields: {
        name: 'Наименование',
      },
    },
    lists: {
      name: 'Сделки |||| Сделки',
      fields: listFields,
    },
    rooms: {
      name: 'Квартира |||| Квартиры ',
      fields: {
        name: 'Наименование',
        price: 'Стоимость',
        active: 'Активен',
        building: 'Здание',
        type: 'Тип помещения',
        priceFrom: 'Цена от',
        priceTo: 'Цена до',
        spaceFrom: 'Площадь от',
        spaceTo: 'Площадь до',
        roomPhoto: 'Фото квартиры',
        planPhoto: 'Фото планировки',
        floor: 'Этаж',
        entrance: 'Подъезд',
        number: 'Номер',
        numberOnFloor: 'Номер на этаже',
        typical: 'Типовая',
        info: 'Информация',
        floorPlanPhoto: 'Фото планировки этажа',
      },
    },
    rooms2: {
      name: 'База поиска',
      fields: {
        name: 'Наименование',
        city: 'Город',
        complex: 'ЖК',
        type: 'Тип',
        dateOfConstruction: 'Дата окончания',
        space: 'Площадь',
        price: 'Цена',
        floor: 'Этаж',
      },
    },
    ipotekalist: {
      name: 'РЛ Ипотека',
      fields: ipotekaFieldsList,
    },
    list2: {
      name: 'РЛ Эксперт',
      fields: {
        status: 'Статус',
        manager: 'Эксперт',
        office: 'Офис',
        broker: 'Ипотечный брокер',
        name: 'ФИО',
        info: 'Инфрмация',
        buyChoise: 'Цель покупки:',
        money: 'Бюджет',
        moneyChoise: 'Способ оплаты',
        ipotekaChoise: 'Ипотека',
        bank: 'Банк',
        startMoney: 'Первоначальный взнос',
        timesChoise: 'Срок приобритения',
        needSaleChoise: 'Требуется продажа своего жилья',
        secondRoomChoise: 'Рассматривает ли покупатель вторичное жильё',
        districtChoise: 'Район',
        otherDistrict: 'Другой район',
        roomChoise: 'Количество комнат',
        spaceFrom: 'Площадь от',
        spaceTo: 'Площадь до',
        floorFrom: 'Этаж от',
        floorTo: 'Этаж до',
        roomStateChoise: 'Отделка квартиры',
        endDate: 'Срок сдачи',
        innerInfrastructure: 'Инфраструктура внутренняя',
        outerInfrastructure: 'Инфраструктура внешняя',
        lastupdate: 'Обновлен',
        createdate: 'Создан',
        expert: 'Эксперт',
        phone: 'Телефон',
      },
    },
    secondroom: {
      name: 'Вторичная недвижимость',
      fields: {
        active: 'Активен',
        address: 'Адрес',
        complex: 'ЖК',
        office: 'Офис',
        buildingStages: 'Этажность дома',
        agent: 'Агент',
        dogovorChoise: 'Договор',
        info: 'Комментарии',
        roomtype: 'Тип квартиры',
        price: 'Стоимость',
        space_total: 'Площадь Квартиры',
        space_kitchen: 'Площадь кухни',
        space_live: 'Жилая площадь',
        typeChoise: 'Тип планировки',
        floor: 'Этаж',
        number: 'Номер',
        photo: 'Фото',
        docs: 'Документы',
        geo: 'Геокоординаты',
        height: 'Высота потолков',
        remontChoise: 'Состояние ремонта',
        ipotekaChoise: 'Под ипотеку',
        roomIpotekaChoise: 'Квартира в ипотеке',
        matcapitalusedChoise: 'Мат капитал использовался',
        city: 'Город',
        district: 'Район',
        buidingType: 'Тип дома',
        buildingDate: 'Год постройки',
        client_phone: 'Телефон клиента',
        client_info: 'Условия продажи',
        pricepm: 'Стоимость кв.м.',
        roomNums: 'Количество комнат',
        sanuzel: 'Санузел',
        balkon: 'Балкон',
        balkonGlassing: 'Остеклкние балкона',
        sunSides: 'Солнечная сторона',
        waterCalc: 'Счетчик воды',
        ironDoor: 'Железная дверь',
        services: 'Подключенные сервисы',
        thingsRemains: 'В квартире при продаже остается',
        dvor: 'Двор',
        parking: 'Парковка',
        createdate: 'Дата создания'
      },
    },
    tasks: {
      name: 'Задачи',
      fields: {
        active: 'Активен',
        name: 'Наименование',
        info: 'Информация',
        type: 'Тип',
        status: 'Статус',
        date: 'Дата',
        office: 'Офис',
        user: 'Пользователь',
      },
    },
    bankrequests: {
      name: 'Запрос в банк |||| Запросы в банк',
      fields: {
        status: 'Статус',
        office: 'Офис',
        bank: 'Банк',
        manager: 'Эксперт',
        broker: 'Ипотечный брокер',
        name: 'ФИО',
        sum: 'Сумма кредита',
        first_sum: 'Первоначальный взнос',
        credit_sum: 'Сумма кредита',
        credit_period: 'Срок кредита',
        borrower: 'Заемщик или созаемщик',
        credit_program: 'Программа кредитования',
        object_type: 'Тип недвижимости',
        source_money: 'Источник первоначального взноса',
        address_fact: 'Адрес фактического проживания',
        family_status: 'Семейное положение',
        education_status: 'Образование',
        phone: 'Телефон',
        work_place: 'Место работы',
        work_phone: 'Рабочий телефон',
        stag_on_last_work_place: 'Стаж на последнем месте',
        stag_months: 'Стаж в месяцах',
        work_role: 'Должность',
        inn: 'ИНН',
        work_address_fact: 'Фактический адрес места работы',
        surname_changed: 'Менялась ли фамилия',
        surname_prev: 'Предыдущая фамилия',
        children: 'Дети',
        actives: 'Активы',
        info: 'Комментарии',
        createdate: 'Создан',
        lastupdate: 'Обновлен',
        requestDate: 'Дата запроса',
        requestStatusDate: 'Дата изменения статуса запроса',
        list: 'РЛ',
        bankIntegration: 'Интеграция с банком',
      },
    },
    roomadds: {
      name: 'Объект',
      fields: {
        status: 'Статус',
        showDate: 'Дата показа',
        showResult: 'Результат показа',
      },
    },
    compares: {
      name: 'Мои подборки',
      fields: {
        name: 'Нименование',
        createdate: 'Создан',
      },
    },
    strahrequests: {
      name: 'Запросы в СК',
      fields: {
        strah: 'Страховая',
        live: 'Жизнь',
        things: 'Имущество',
        titul: 'Титул',
        dop: 'Доп',
        info: 'Комментарии',
        docs: 'Документы',
        requestDate: 'Дата запроса',
      },
    },
    iprograms: {
      name: 'Ипотечные программы',
      fields: {
        bank: 'Банк',
        program: 'Программа',
        pv: 'ПВ',
        stavka: 'ставка',
        srok: 'срок',
        stazh: 'Стаж на последнем месте',
        vozrast: 'Возраст',
        twodocs: '2 документа',
        twodocs_text: '2 документа',
        other: 'Прочее',
      },
    },
    roomreserves: {
      name: 'Бронирование',
      fields: {
        amo_id: 'Сделка amocrm',
        complex_id: 'ЖК',
        data: {
          number: 'Номер',
          price: 'Стоимость',
          space: 'Площадь',
          floor: 'Этаж',
          type: 'Вид квартиры',
          buildingName: 'Литер',
          section: 'Секция',
          amo_user: 'amouser',
        },
        canceled: 'Отмена брони',
      },
    },
    main_districts: {
      name: 'Округ |||| Округа',
      fields: {
        name: 'Наименование',
        city: 'Город',
      },
    },
    metro_lines: {
      name: 'Линия метро |||| Линии Метро',
      fields: {
        name: 'Наименование',
        city: 'Город',
      },
    },
    metro_stations: {
      name: 'Станция метро |||| Станции Метро',
      fields: {
        name: 'Наименование',
        city: 'Город',
        metro_line: 'Линия метро',
      },
    },
    dp_stat: {
      name: 'AMOCRM stat',
    },
    amo_users: {
      name: 'AMOCRM users',
    },
    amo_pipelines: {
      name: 'AMOCRM pipelines',
    },
  },
};

export default messages;
