import React from 'react';

import { parse } from 'query-string';

import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  // BooleanInput,
  NumberInput,
  SelectField,
  SelectInput,
  FilterList,
  FilterListItem,
  NumberField,
  // BooleanField,
  DateField,
  ReferenceInput,
  ReferenceField,
  DateInput,
  Toolbar,
  SaveButton,
  required,
  minLength,
  maxLength,
  number,
  email,
  FileInput,
  FileField,
  BooleanInput,
  BooleanField,
  // useMutation,
  useDataProvider,
  ArrayField,
  FunctionField,
  UrlField,
  useRefresh,
  usePermissions,
  Filter,
  FormDataConsumer
} from 'react-admin';

import { Button, makeStyles, Drawer, Typography, Grid, Box } from '@material-ui/core';
import { Form } from 'react-final-form';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import masterCikIdChoices from './vtb.json';

const apiURL = window.location.hostname === 'localhost1' ? 'http://localhost:5001/rooms-232b0/europe-west3/api/vtb' : 'https://europe-west3-rooms-232b0.cloudfunctions.net/api/vtb';
const apiIpotekaSMTPURL = window.location.hostname === 'localhost1' ? 'http://localhost:5001/rooms-232b0/europe-west3/api/ipoteka_smtp' : 'https://europe-west3-rooms-232b0.cloudfunctions.net/api/ipoteka_smtp';



const useStyles = makeStyles({
  formDrawer: {
    width: '560px',
    position: 'relative',
    overflow: 'auto',
    padding: 5
  },
  button: {
    margin: 5,
    width: 320
  }
});

// import { Card as MuiCard, CardContent, withStyles } from '@material-ui/core';

export const name = 'bankrequests';

// export const status = [
//   { id: 1, name: 'Создан' },
//   { id: 3, name: 'Подача Документов' },
//   { id: 5, name: 'Одобрение банка' },
//   { id: 8, name: 'Отказ банка' },
// ];

export const status = [
  { id: 1, name: 'Кредитный анализ' },
  { id: 2, name: 'Одобрение' },
  { id: 3, name: 'Отказ' },
  { id: 4, name: 'Готовим Подписание' },
  { id: 5, name: 'Подписан' }
];

const vtb_StateId = [
  { id: 'cm.LoanApplicationStatus.ApplicationFilling', name: 'Черновик' },
  { id: 'cm.LoanApplicationStatus.DecisionProcessing', name: 'Принято в работу' },
  { id: 'cm.LoanApplicationStatus.Approved', name: 'Одобрено' },
  { id: 'tsc.AppStatus.ObjectInProgress', name: 'Объект на согласовании' },
  { id: 'tsc.AppStatus.ObjectApproved', name: 'Объект согласован' },
  { id: 'cm.LoanApplicationStatus.Issued', name: 'Кредит выдан' },
  { id: 'tsc.AppStatus.ReworkObject', name: 'Уточнениепо объекту' },
  { id: 'tsc.AppStatus.Reject', name: 'Отказ банка' },
  { id: 'tsc.AppStatus.ClientsReject', name: 'Отказ клиента' },
  { id: 'tsc.AppStatus.ReworkClient', name: 'Уточнение по клиенту' }
];

const vtb_ReworkReasons = [
  { id: 'tsc.ReasonForRework.IncorrectInfo', name: 'Некорректные данные в анкете' },
  { id: 'tsc.ReasonForRework.IncorrectScan', name: 'Нечитаемый/неверный скан документа' },
  { id: 'tsc.ReasonForRework.ObjectFailure', name: 'Отказ по объекту недвижимости' },
  { id: 'tsc.ReasonForRework.NeedDocs', name: 'Запрос недостающих документов' },
];

// const masterCikIdChoices = [
//   { id: '003', name: 'Москва' },
//   { id: '022', name: 'Ростов-на-Дону' },
//   { id: '018', name: 'Краснодар' },
//   { id: '016', name: 'Воронеж' },
// ];


const bankIntegrationChoises = [
  { id: 'vtb', name: 'ВТБ' },
  { id: 'email', name: 'Стандартная' },
  // { id: 'uralsib', name: 'Уралсиб', type: 'smtp' },
  // { id: 'gazprombank', name: 'Газпромбанк', type: 'smtp' },
  // { id: 'psb', name: 'Промсвязьбанк', type: 'smtp' },
  // { id: 'tkb', name: 'Транскапитал', type: 'smtp' },
  // { id: 'sovcombank', name: 'Совкомбанк', type: 'smtp' },
  // { id: 'open', name: 'Открытие', type: 'smtp' },
  // { id: 'raiffeisen', name: 'Райффайзен', type: 'smtp' },
  // { id: 'unicredit', name: 'Юникредит', type: 'smtp' }
];

const emailChoices = [
  { id: 'ipoteka@rov.uralsib.ru', name: 'Уралсиб (ipoteka@rov.uralsib.ru) Юлия' },
  { id: '007-partner@gazprombank.ru', name: 'Газпромбанк (007-partner@gazprombank.ru) Кристина' },
  { id: 'gyurdzhinyanng@rostov.psbank.ru', name: 'Промсвязьбанк (gyurdzhinyanng@rostov.psbank.ru) Нелли' },
  { id: 'tkb@balance-pl.ru', name: 'Транскапитал (tkb@balance-pl.ru) Манана' },
  { id: 'bazarovasi@sovcombank.ru', name: 'Совкомбанк (bazarovasi@sovcombank.ru) Игорь' },
  { id: 'lyudmila.evkina@open.ru', name: 'Открытие (lyudmila.evkina@open.ru) Людмила' },
  { id: 'elena.y.kaverina@raiffeisen.ru', name: 'Райффайзен (elena.y.kaverina@raiffeisen.ru) Елена' },
  { id: 'vborovenskaya.external@unicredit.ru', name: 'Юникредит (vborovenskaya.external@unicredit.ru) Виктория' }
];

// const Card = withStyles((theme) => ({
//   root: {
//     [theme.breakpoints.up('sm')]: {
//       order: -1, // display on the left rather than on the right of the list
//       width: '15em',
//       marginRight: '1em',
//     },
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
// }))(MuiCard);

// const StatusFilter = () => (
//   <FilterList label="Статус" icon="">
//     {status.map((s) => (
//       <FilterListItem label={s.name} key={s.id} value={{ status: s.id }} />
//     ))}
//   </FilterList>
// );

// const FilterSidebar = () => (
//   <Card>
//     <CardContent>
//       <StatusFilter />
//     </CardContent>
//   </Card>
// );

const BankRequestListFilter = (props: any) => (
  <Filter {...props}>
    <SelectInput source="status" choices={status} alwaysOn />
    <ReferenceInput source="bank" reference="banks" alwaysOn filter={{ active: true }} sort={{ field: 'name', order: 'ASC' }} perPage={1000}>
      <SelectInput source="name" />
    </ReferenceInput>
  </Filter>
);

export const list = (props: any) => (
  <List {...props} bulkActionButtons={false} filters={<BankRequestListFilter />}>
    <Datagrid rowClick="show">
      <SelectField source="status" choices={status} />
      <ReferenceField source="bank" reference="banks" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="list" reference="ipotekalist">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="sum" />
      <TextField source="info" fullWidth />
      <SelectField source="bankIntegration" choices={bankIntegrationChoises} />
    </Datagrid>
  </List>
);

export const show = (props: any) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <ReferenceField source="list" reference="ipotekalist" link="show">
          <TextField source="name" />
        </ReferenceField>
        {/* <DateField source="vtb_anketa_sent" /> */}
        <SelectField source="status" choices={status} />
        <ReferenceField source="bank" reference="banks">
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="sum" />
        <TextField source="info" fullWidth />
        <VTBIntegration />
        {/* <ArrayField source="vtb_files">
          <Datagrid>
            <FunctionField label="file" render={(record: any) => <a href={record.file.src} target="_blank" rel="noreferrer">{record.file.title}</a>} />
            <TextField source="file_id" label="file ID" />
          </Datagrid>
        </ArrayField>
        <VTBSendAnketa />
        <VTBSendFile />
        <VTBSubmit />
        <VTBStatus /> */}
        <EmailIntegration />
      </SimpleShowLayout>
    </Show>
  )
};


const CustomToolbar = (props: any) => {
  const { record, role } = props;

  return (
    <Toolbar {...props} >
      <SaveButton />
    </Toolbar>
  )
};

const ResCreate = (props: any) => {

  const { list } = parse(props.location.search);
  const redirect = list ? `/ipotekalist/${list}` : 'show';

  return (
    <Create {...props}>
      <SimpleForm initialValues={{ list }} redirect={redirect} toolbar={<CustomToolbar />}>
        {/* <TextInput source="list" /> */}
        {/* <DateInput source="requestDate" />
        <SelectInput source="status" choices={status} required />
        <DateInput source="requestStatusDate" />
        <ReferenceInput source="bank" reference="banks" perPage={100} filter={{ active: true }}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="sum" />
        <TextInput source="info" />
        <SelectInput source="bankIntegration" choices={bankIntegrationChoises} allowEmpty /> */}
        <Grid container style={{ width: '100%' }} spacing={1}>
          <Grid item xs={6}>
            <DateInput source="requestDate" fullWidth resource="bankrequests" />
          </Grid>
          <Grid item xs={6}>
          </Grid>
          <Grid item xs={6}>
            <SelectInput source="status" choices={status} required fullWidth resource="bankrequests" />
            <ReferenceInput source="bank" reference="banks" perPage={100} filter={{ active: true }} resource="bankrequests">
              <SelectInput optionText="name" fullWidth />
            </ReferenceInput>
            <TextInput source="info" fullWidth resource="bankrequests" />
          </Grid>
          <Grid item xs={6}>
            <DateInput source="requestStatusDate" fullWidth resource="bankrequests" />
            <NumberInput source="sum" fullWidth resource="bankrequests" />
            <SelectInput source="bankIntegration" choices={bankIntegrationChoises} allowEmpty emptyText="Без интеграции" fullWidth resource="bankrequests" />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export const ResEdit = (props: any) => {
  const redirect = (basePath: string, id: string, data: any) => `/ipotekalist/${data.list}`;
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<CustomToolbar />} redirect={redirect}>
        {/* <BooleanInput source="vtb_anketa_sent" /> */}
        <ReferenceField source="list" reference="ipotekalist" link="edit">
          <TextField source="name" />
        </ReferenceField>
        <Grid container style={{ width: '100%' }} spacing={1}>
          <Grid item xs={6}>
            <DateInput source="requestDate" fullWidth resource="bankrequests" />
          </Grid>
          <Grid item xs={6}>
          </Grid>
          <Grid item xs={6}>
            <SelectInput source="status" choices={status} required fullWidth resource="bankrequests" />
            <ReferenceInput source="bank" reference="banks" perPage={100} filter={{ active: true }} resource="bankrequests">
              <SelectInput optionText="name" fullWidth />
            </ReferenceInput>
            <TextInput source="info" fullWidth resource="bankrequests" />
          </Grid>
          <Grid item xs={6}>
            <DateInput source="requestStatusDate" fullWidth resource="bankrequests" />
            <NumberInput source="sum" fullWidth resource="bankrequests" />
            <SelectInput source="bankIntegration" choices={bankIntegrationChoises} allowEmpty emptyText="Без интеграции" fullWidth resource="bankrequests" />
          </Grid>
        </Grid>
        <FormDataConsumer>
          {({ formData }) => (
            <VTBIntegration record={formData} />
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData }) => (
            <EmailIntegration record={formData} />
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  )
};

export { ResCreate as create, ResEdit as edit };


function VTBSendAnketa(props: any) {
  const classes = useStyles();
  const { record } = props;
  const { vtb_anketa_sent, bankIntegration, list } = record;
  const [showDrawer, setShowDrawer] = React.useState(false);
  const dataProvider = useDataProvider();
  const [json, setJSON] = React.useState();
  const refresh = useRefresh();
  const { permissions, loading } = usePermissions();
  const [userData, setUserData] = React.useState<null | Record<string, unknown>>(null);
  const [listData, setListData] = React.useState<null | Record<string, unknown>>(null);

  const handleClick = () => {
    setShowDrawer(true);
  }

  const onSubmit = async (values: any) => {
    // return console.log('---submit---', values);

    values.masterCikId = masterCikIdChoices.find(m => m.id === values.masterCikId)?.masterCikId;

    const response = await fetch(`${apiURL}/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    });
    const json = await response.json();
    setJSON(json);

    if (json?.acceptanceResult !== "Success") {
      return;
    }

    return dataProvider.update('bankrequests',
      {
        id: record.id,
        data: {
          ...record,
          vtb_anketa_sent: Date.now(),
          vtb_anketa: values,
          vtb_anketa_response: json
        }, previousData: record
      })
      .then(response => {
        // setShowDrawer(false);
      });
  }

  const handleClose = () => {
    setShowDrawer(false);
    // refresh();
  }

  React.useEffect(() => {
    const getUserData = async () => {
      const resp = await dataProvider.getOne('users', { id: permissions.user });
      setUserData(resp.data);
    }
    if (!loading && permissions.user) {
      getUserData();
    }
  }, [permissions, loading, dataProvider]);

  React.useEffect(() => {
    const getListData = async () => {
      const resp = await dataProvider.getOne('ipotekalist', { id: list });
      setListData(resp.data);
    }
    if (list) {
      getListData();
    }
  }, [dataProvider, list]);

  const partnerName = (userData?.name as string ?? '').split(' ').filter(Boolean);
  const clientName = (listData?.name as string ?? '').split(' ').filter(Boolean);

  if (vtb_anketa_sent || !userData) {
    return null;
  }

  return <>
    <Button
      variant="contained"
      color="primary"
      onClick={handleClick}
      className={classes.button}
    >
      Заполнить Анкету ВТБ
    </Button>
    {/* <Drawer anchor="right" open={showDrawer} onClose={handleClose}>
      <div className={classes.formDrawer}>
        <Form onSubmit={onSubmit} initialValues={{
          test: true,
          id: record.id,
          partnerLastName: partnerName[0] ?? '',
          partnerFirstName: partnerName[1] ?? '',
          partnerMiddleNames: partnerName[2] ?? '',
          partnerPhone: userData?.phone ?? '',
          partnerEmail: `${userData?.email ?? ''}`.slice(-10)
        }}
        >
          {
            ({ handleSubmit, valid, submitting }) => (
              <form onSubmit={handleSubmit}>
                <TextInput source="clientEmail" label="E-mail клиента" fullWidth validate={email()} />
                <TextInput source="clientFirstName" label="Имя клиента" fullWidth validate={required()} />
                <TextInput source="clientLastName" label="Фамилия Клиента" fullWidth validate={required()} />
                <TextInput source="clientMiddleNames" label="Отчество клиента" fullWidth />
                <TextInput source="clientPhone" label="Телефон Клиента" fullWidth validate={[required(), number(), minLength(10), maxLength(10)]} />
                <SelectInput source="masterCikId" label="Город подписания кредитной документации" choices={masterCikIdChoices} fullWidth validate={required()} />
                <TextInput source="partnerEmail" label="E-mail менеджера партнера" fullWidth validate={email()} />
                <TextInput source="partnerFirstName" label="Имя менеджера партнера" fullWidth />
                <TextInput source="partnerLastName" label="Фамилия менеджера партнера" fullWidth />
                <TextInput source="partnerMiddleNames" label="Отчество менеджера партнера" fullWidth />
                <TextInput source="partnerPhone" label="Телефон менеджера партнера" fullWidth validate={[number(), minLength(10), maxLength(10)]} />
                <NumberInput source="requestedAmount" label="Запрошенная сумма кредита" fullWidth validate={[required(), number()]} />
                <BooleanInput source="test" label="Тест" />
                <TextInput source="comment" label="Комментарии" fullWidth multiline />
                <Button variant="outlined" color="primary" type="submit" disabled={!valid || submitting}>Отправить</Button>
              </form>
            )
          }
        </Form>
        <DecodedResponse response={json} />
      </div>
    </Drawer> */}
    <Dialog open={showDrawer} onClose={handleClose}>
      <DialogTitle>Заполнить Анкету ВТБ</DialogTitle>
      <div className={classes.formDrawer}>
        <Form onSubmit={onSubmit} initialValues={{
          test: false,
          id: record.id,
          partnerLastName: partnerName[0] ?? '',
          partnerFirstName: partnerName[1] ?? '',
          partnerMiddleNames: partnerName[2] ?? '',
          partnerPhone: `${userData?.phone ?? ''}`.slice(-10),
          partnerEmail: userData?.email ?? '',
          clientLastName: clientName[0] ?? '',
          clientFirstName: clientName[1] ?? '',
          clientMiddleNames: clientName[2] ?? '',
          clientPhone: `${listData?.phone ?? ''}`.slice(-10),
          clientEmail: listData?.email ?? '',
          requestedAmount: listData?.credit_sum ?? 0
        }}
        >
          {
            ({ handleSubmit, valid, submitting }) => (
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <TextInput source="clientEmail" label="E-mail клиента" fullWidth validate={email()} />
                  <TextInput source="clientFirstName" label="Имя клиента" fullWidth validate={required()} />
                  <TextInput source="clientLastName" label="Фамилия Клиента" fullWidth validate={required()} />
                  <TextInput source="clientMiddleNames" label="Отчество клиента" fullWidth />
                  <TextInput source="clientPhone" label="Телефон Клиента" fullWidth validate={[required(), number(), minLength(10), maxLength(10)]} />
                  <SelectInput source="masterCikId" label="Город подписания кредитной документации" choices={masterCikIdChoices} fullWidth validate={required()} optionText="city" />
                  <TextInput source="partnerEmail" label="E-mail менеджера партнера" fullWidth validate={email()} />
                  <TextInput source="partnerFirstName" label="Имя менеджера партнера" fullWidth />
                  <TextInput source="partnerLastName" label="Фамилия менеджера партнера" fullWidth />
                  <TextInput source="partnerMiddleNames" label="Отчество менеджера партнера" fullWidth />
                  <TextInput source="partnerPhone" label="Телефон менеджера партнера" fullWidth validate={[number(), minLength(10), maxLength(10)]} />
                  <NumberInput source="requestedAmount" label="Запрошенная сумма кредита" fullWidth validate={[required(), number()]} />
                  {/* <BooleanInput source="test" label="Тест" /> */}
                  <TextInput source="comment" label="Комментарии" fullWidth multiline />
                  <DecodedResponse response={json} />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">Закрыть</Button>
                  <Button variant="outlined" color="primary" type="submit" disabled={!valid || submitting}>Отправить</Button>
                </DialogActions>
              </form>
            )
          }
        </Form>
      </div>
    </Dialog>
  </>
}

// function sleep(ms: number) {
//   return new Promise(r => setTimeout(r, ms));
// }

function VTBSendFile(props: any) {
  const classes = useStyles();
  const { record } = props;
  const [showDrawer, setShowDrawer] = React.useState(false);
  const dataProvider = useDataProvider();
  const [json, setJSON] = React.useState<any>([]);
  const refresh = useRefresh();

  const handleClick = () => {
    setShowDrawer(true);
  }

  const onSubmit = async (values: any) => {
    const { file_id, files, comment } = values;
    const vtb_files: any = [];

    // console.log('---upload---', file_id, files);

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const formData = new FormData();
      formData.append('file', file.rawFile);
      const response = await fetch(`${apiURL}/upload/${record.id}/${file_id}_${i}`, {
        method: 'POST',
        body: formData
      });
      const json = await response.json();
      setJSON((s: any) => ([...s, json]));
      vtb_files.push({
        file_id: `${file_id}_${i}`,
        comment,
        response: json,
        file
      });
    }

    return await dataProvider.update('bankrequests',
      {
        id: record.id,
        data: {
          ...record,
          vtb_files: [...(record.vtb_files ?? []), ...vtb_files]
        }, previousData: record
      })

    // const formData = new FormData();
    // formData.append('file', file.rawFile);
    // const response = await fetch(`${apiURL}/upload/${record.id}/${file_id}`, {
    //   method: 'POST',
    //   body: formData
    // });
    // const json = await response.json();    
    // setJSON(json);

    // console.log('---submit---', values);
    // if (json?.acceptanceResult !== "Success") {
    //   return;
    // }

    // return dataProvider.update('bankrequests',
    //   {
    //     id: record.id,
    //     data: {
    //       ...record,
    //       vtb_files: [...(record.vtb_files ?? []), { ...values, response: json }]
    //     }, previousData: record
    //   })
    //   .then(response => {
    //     // setShowDrawer(false);
    //   });
  }

  const handleClose = () => {
    setJSON([]);
    setShowDrawer(false);
    refresh();
  }

  return <>
    <Button
      variant="contained"
      color="primary"
      onClick={handleClick}
      className={classes.button}
    >
      Загрузить документ в ВТБ
    </Button>
    {/* <Drawer anchor="right" open={showDrawer} onClose={handleClose}>
      <div className={classes.formDrawer}>
        <Form onSubmit={onSubmit}>
          {
            ({ handleSubmit, valid, submitting }) => (
              <form onSubmit={handleSubmit}>
                <TextInput source="file_id" label="ID файла" fullWidth validate={required()} />
                <FileInput source="files" label="Файл" multiple maxSize={10000000} accept=".jpg, .jpeg, .tiff, .tif, .pdf, .png, .heic, .gif, .doc, .docx, .rtf, .xls, .xlsx" validate={required()}>
                  <FileField source="src" title="title" />
                </FileInput>
                <TextInput source="comment" label="Комментарий" fullWidth multiline />
                <Button variant="outlined" color="primary" type="submit" disabled={!valid || submitting}>Отправить</Button>
              </form>
            )
          }
        </Form>
      </div>
      {
        json.map((r: any, ind: any) => <DecodedResponse key={`${ind}`} response={r} />)
      }
    </Drawer> */}
    <Dialog open={showDrawer} onClose={handleClose}>
      <DialogTitle>Загрузить документ в ВТБ</DialogTitle>
      <div className={classes.formDrawer}>
        <Form onSubmit={onSubmit}>
          {
            ({ handleSubmit, valid, submitting }) => (
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <TextInput source="file_id" label="ID файла" fullWidth validate={required()} />
                  <FileInput source="files" label="Файл" multiple maxSize={10000000} accept=".jpg, .jpeg, .tiff, .tif, .pdf, .png, .heic, .gif, .doc, .docx, .rtf, .xls, .xlsx" validate={required()}>
                    <FileField source="src" title="title" />
                  </FileInput>
                  <TextInput source="comment" label="Комментарий" fullWidth multiline />
                  {
                    json.map((r: any, ind: any) => <DecodedResponse key={`${ind}`} response={r} />)
                  }
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">Закрыть</Button>
                  <Button variant="outlined" color="primary" type="submit" disabled={!valid || submitting}>Отправить</Button>
                </DialogActions>
              </form>
            )
          }
        </Form>
      </div>
    </Dialog>
  </>
}

function VTBSubmit(props: any) {
  const classes = useStyles();
  const { record } = props;
  const [showDrawer, setShowDrawer] = React.useState(false);
  const dataProvider = useDataProvider();
  const [json, setJSON] = React.useState();
  const refresh = useRefresh();

  const onSubmit = async (values: any) => {
    const response = await fetch(`${apiURL}/submit`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id: record.id })
    });
    const json = await response.json();
    setJSON(json);

    // console.log('---submit---', values);
    if (json?.acceptanceResult !== "Success") {
      return;
    }

    return dataProvider.update('bankrequests',
      {
        id: record.id,
        data: {
          ...record,
          vtb_submitted: Date.now(),
          vtb_submitted_response: json
        }, previousData: record
      })
      .then(response => {
        // setShowDrawer(false);
      });
  }

  const handleClick = () => {
    setShowDrawer(true);
    onSubmit(null);
  }

  const handleClose = () => {
    setJSON(undefined);
    setShowDrawer(false);
    // refresh();
  }

  // if (record.vtb_submitted) {
  //   return null;
  // }

  return <>
    <Button
      variant="contained"
      color="primary"
      onClick={handleClick}
      className={classes.button}
    >
      Отправить в банк
    </Button>
    <Dialog open={showDrawer} onClose={handleClose}>
      <DialogTitle>Отправить в банк</DialogTitle>
      <div className={classes.formDrawer}>
        <DialogContent>
          <DecodedResponse response={json} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Закрыть</Button>
        </DialogActions>
      </div>
    </Dialog>
    {/* <Drawer anchor="right" open={showDrawer} onClose={handleClose}>
      <div className={classes.formDrawer}>
        <DecodedResponse response={json} />
      </div>
    </Drawer> */}
  </>
}

function VTBStatus(props: any) {
  const classes = useStyles();
  const { record } = props;
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [json, setJSON] = React.useState();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();


  const onSubmit = async (values: any) => {
    // console.log('---submit---', record.id);
    const response = await fetch(`${apiURL}/${record.id}`);
    const json = await response.json();
    setJSON(json);
    if (json?.acceptanceResult !== "Success") {
      return;
    }
    return dataProvider.update('bankrequests',
      {
        id: record.id,
        data: {
          ...record,
          vtb_status: json
        }, previousData: record
      })
      .then(response => {
        // setShowDrawer(false);
      });
  }

  const handleClick = () => {
    setJSON(undefined);
    setShowDrawer(true);
    onSubmit(null);
  }

  const handleClose = () => {
    setJSON(undefined);
    setShowDrawer(false);
    // refresh();
  }

  return <>
    <Button
      variant="contained"
      color="primary"
      onClick={handleClick}
      className={classes.button}
    >
      Получить статус
    </Button>
    {/* <Drawer anchor="right" open={showDrawer} onClose={handleClose}>
      <div className={classes.formDrawer}>
        <DecodedResponse response={json} />
      </div>
    </Drawer> */}
    <Dialog open={showDrawer} onClose={handleClose}>
      <DialogTitle>Получить статус</DialogTitle>
      <div className={classes.formDrawer}>
        <DialogContent>
          <DecodedResponse response={json} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Закрыть</Button>
        </DialogActions>
      </div>
    </Dialog>
  </>
}

function DebugResponse(props: any) {
  const { response } = props;
  return <div>
    <pre>
      {JSON.stringify(response, null, ' ')}
    </pre>
  </div>
}

function DecodedResponse(props: any) {
  const { response } = props;

  const { acceptanceResult, value } = response || {};

  return (
    <>
      {
        (acceptanceResult && acceptanceResult === "Success") && (
          <Typography>Обмен завершен успешно</Typography>
        )
      }
      {
        (acceptanceResult && acceptanceResult !== "Success") && (
          <Typography>Удаленный сервер не принял данные, подробности внизу</Typography>
        )
      }
      {
        (acceptanceResult && acceptanceResult === "Success" && value?.stateId) && (
          <Typography>Статус Заявки: {vtb_StateId.find(v => v.id === value.stateId)?.name}</Typography>
        )
      }
      {
        (acceptanceResult && acceptanceResult === "Success" && value?.reworkReason) && (
          <Typography>Причина возврата: {vtb_ReworkReasons.find(v => v.id === value.reworkReason)?.name}</Typography>
        )
      }
      <DebugResponse response={response} />
    </>
  )
}


function VTBIntegration(props: any) {
  const { record } = props;
  if (record?.bankIntegration !== 'vtb') {
    return null;
  }
  return (
    <>
      <ArrayField source="vtb_files" {...props}>
        <Datagrid>
          <TextField source="file_id" label="ID файла" />
          {/* <FunctionField label="Файл" render={(record: any) => <span>{JSON.stringify(record)}</span>} /> */}
          <FunctionField label="Файл" render={(record: any) => <a href={record.file?.src} target="_blank" rel="noreferrer">{record.file?.title}</a>} />
          <TextField source="comment" label="Комментарий" />
        </Datagrid>
      </ArrayField>
      <VTBSendAnketa {...props} />
      <VTBSendFile {...props} />
      <VTBSubmit {...props} />
      <VTBStatus {...props} />
      <Typography>Переданная Анкета</Typography>
      <div>
        <pre>
          {JSON.stringify(record?.vtb_anketa, null, ' ')}
        </pre>
      </div>
      <Typography>Послений статус заявки ВТБ</Typography>
      {/* <div>
        <pre>
          {JSON.stringify(record?.vtb_status, null, ' ')}
        </pre>
      </div> */}
      <DecodedResponse response={record?.vtb_status} />
    </>
  )
}

function EmailIntegration(props: any) {
  const { record } = props;
  const { list } = record;
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [json, setJSON] = React.useState();
  const [listObject, setListObject] = React.useState<any>();
  const [userData, setUserData] = React.useState<any>();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const { permissions, loading } = usePermissions();

  React.useEffect(() => {
    const getUserData = async () => {
      const resp = await dataProvider.getOne('users', { id: permissions.user });
      setUserData(resp.data);
    }
    if (!loading && permissions.user) {
      getUserData();
    }
  }, [permissions, loading, dataProvider]);

  const handleClick = () => {
    setJSON(undefined);
    setShowDrawer(true);
  }

  const onSubmit = async (values: any) => {
    const { files, ...otherValues } = values;
    const attachments = listObject?.documents[files]?.docs?.map((f: any) => ({ filename: f.title, path: f.src }));
    const sendObj = { ...otherValues, attachments };

    // return console.log('---send smtp---', values, sendObj);
    const response = await fetch(`${apiIpotekaSMTPURL}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(sendObj)
    });
    const json = await response.json();
    setJSON(json);

    if (json?.message !== "success") {
      return;
    }

    // return dataProvider.update('bankrequests',
    //   {
    //     id: record.id,
    //     data: {
    //       ...record,
    //       vtb_anketa_sent: Date.now(),
    //       vtb_anketa: values,
    //       vtb_anketa_response: json
    //     }, previousData: record
    //   })
    //   .then(response => {
    //     // setShowDrawer(false);
    //   });
  }

  const handleClose = () => {
    setShowDrawer(false);
    // refresh();
  }

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await dataProvider.getOne('ipotekalist', { id: list });
      setListObject(response?.data);
    }

    if (list) {
      fetchData();
    }
  }, [list, dataProvider])

  const fileChoices = React.useMemo(() => {
    if (listObject?.documents) {
      return listObject?.documents.map((l: any, num: number) => ({ id: num, name: `${l.docsname ?? ''} ${l.comments ?? ''}` }))
    }
    return [];
  }, [listObject]);

  if (loading || record?.bankIntegration !== 'email') {
    return null;
  }
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
        className={classes.button}
      >
        Отправить пакет документов
      </Button>
      <Dialog open={showDrawer} onClose={handleClose}>
        <DialogTitle>Отправить пакет документов</DialogTitle>
        <div className={classes.formDrawer}>
          <Form onSubmit={onSubmit} initialValues={{ from: permissions.email, brokerName: userData?.name, brokerPhoen: userData?.phone, subject: `${listObject?.name ?? ''}` }}>
            {
              ({ handleSubmit, valid, submitting }) => (
                <form onSubmit={handleSubmit}>
                  <DialogContent>
                    <TextInput source="from" label="от кого" fullWidth validate={[required(), email()]} />
                    <SelectInput source="to" choices={emailChoices} fullWidth label="кому" />

                    <TextInput source="subject" label="тема" fullWidth validate={required()} />
                    <SelectInput source="files" choices={fileChoices} fullWidth label="Файлы" />
                    {
                      json && <pre>{JSON.stringify(json, null, ' ')}</pre>
                    }
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">Закрыть</Button>
                    <Button variant="outlined" color="primary" type="submit" disabled={!valid || submitting}>Отправить</Button>
                  </DialogActions>
                </form>
              )
            }
          </Form>
        </div>
      </Dialog>
      {/* <Drawer anchor="right" open={showDrawer} onClose={handleClose}>
        <div className={classes.formDrawer}>
          <Form onSubmit={onSubmit} initialValues={{ from: permissions.email, subject: `${listObject?.name ?? ''}` }}>
            {
              ({ handleSubmit, valid, submitting }) => (
                <form onSubmit={handleSubmit}>
                  <TextInput source="from" label="от кого" fullWidth validate={[required(), email()]} />
                  <SelectInput source="to" choices={emailChoices} fullWidth label="кому" />

                  <TextInput source="subject" label="тема" fullWidth validate={required()} />
                  <SelectInput source="files" choices={fileChoices} fullWidth label="Файлы" />
                  <Button variant="outlined" color="primary" type="submit" disabled={!valid || submitting}>Отправить</Button>
                </form>
              )
            }
          </Form>
          {
            json && <pre>{JSON.stringify(json, null, ' ')}</pre>
          }
        </div>
      </Drawer> */}
    </>
  )
}

// ipoteka_box@souz-zastroi.ru 
// Ipoteka2020
// lazeoksgvdegizgy


// адрес почтового сервера — smtp.yandex.ru;
// защита соединения — SSL;
// порт — 465.