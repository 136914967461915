import React, { Suspense } from 'react';
import { Loading } from 'react-admin';


const IpotekaListDataGrid = React.lazy(() => import('./IpotekaListDataGrid'));

function IpotekaListDataGridLoader() {
  return (
    <Suspense fallback={<Loading />}>
      <IpotekaListDataGrid />
    </Suspense>
  )
}


export default IpotekaListDataGridLoader;
