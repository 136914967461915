import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Typography from '@material-ui/core/Typography';

import { City, District, Complex, RoomTypes, PriceFrom, PriceTo, SpaceFrom, SpaceTo, ResetSearch, SellDate, MainDistrict, Apartments, MetroLine, MetroStation } from './Controls';
import { useSelector } from 'react-redux';

import SearchIcon from '@material-ui/icons/Search';


import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    maxWidth: 200,
  },
  iconFormControl: {
    margin: theme.spacing(1),
    minWidth: 50,
    maxWidth: 50,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

// const sellDates = [
//   { id: 1, name: 'Сдан' },
//   { id: 2, name: 'до 3 кв. 2020' },
//   { id: 3, name: 'до 4 кв. 2020' },
//   { id: 4, name: 'до 1 кв. 2021' },
//   { id: 5, name: 'до 2 кв. 2021' },
//   { id: 6, name: 'до 3 кв. 2021' },
//   { id: 7, name: 'до 4 кв. 2021' },
//   { id: 8, name: 'до 1 кв. 2022' },
//   { id: 9, name: 'до 2 кв. 2022' },
//   { id: 10, name: 'до 3 кв. 2022' },
//   { id: 11, name: 'до 4 кв. 2022' },
//   { id: 12, name: 'до 1 кв. 2023' },
//   { id: 13, name: 'до 2 кв. 2023' },
//   { id: 14, name: 'до 3 кв. 2023' },
//   { id: 15, name: 'до 4 кв. 2023' },
// ];

// const sellStart = [
//   { id: 1, name: 'июль 2020' },
//   { id: 2, name: 'июнь 2020' },
//   { id: 3, name: 'май 2020' },
//   { id: 4, name: 'апрель 2020' },
//   { id: 5, name: 'март 2020' },
//   { id: 6, name: 'февраль 2020' },
//   { id: 7, name: 'январь 2020' },
//   { id: 8, name: 'декабрь 2019' },
//   { id: 9, name: 'ноябрь 2019' },
// ];

// const tualets = [
//   { id: 1, name: 'Совмещенный' },
//   { id: 2, name: 'Раздельный' },
//   { id: 3, name: '2 и более' },
// ];

// const paidTypes = [
//   { id: 1, name: 'Ипотека' },
//   { id: 2, name: 'Военная ипотека' },
//   { id: 3, name: 'Рассрочка' },
//   { id: 4, name: 'Субсидии' },
// ];

// const decor = [
//   { id: 1, name: 'Без отделки' },
//   { id: 2, name: 'Подчистовая' },
//   { id: 3, name: 'Чистовая' },
//   { id: 4, name: 'С ремонтом' },
//   { id: 5, name: 'С мебелью' },
// ];

// const metro = [
//   { id: 1, name: 'до 5 минут пешком' },
//   { id: 2, name: 'до 10 минут пешком' },
//   { id: 3, name: 'до 15 минут пешком' },
//   { id: 4, name: 'до 20 минут пешком' },
//   { id: 5, name: 'до 10 минут транспортом' },
//   { id: 6, name: 'до 20 минут транспортом' },
//   { id: 7, name: 'до 30 минут транспортом' },
//   { id: 8, name: 'до 40 минут транспортом' },
//   { id: 9, name: 'до 50 минут транспортом' },
// ];

const SearchControls = () => {
  const classes = useStyles();
  const { city } = useSelector((state: any) => state.search);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const is_sm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      <City />
      {
        is_sm && (
          <>
            <District />
            <Complex />
            <RoomTypes />
            <PriceFrom />
            <PriceTo />
          </>
        )
      }
      {
        !is_sm && <div style={{ top: -1000, position: 'absolute' }}><Complex /></div>
      }
      <FormControl className={classes.iconFormControl}>
        <IconButton color="primary" aria-label="reset" component="span" title="Все фильтры" onClick={() => setOpen(true)} >
          <SearchIcon />
        </IconButton>
      </FormControl>
      <ResetSearch />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <AppBar style={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography>Фильтры</Typography>
          </Toolbar>
        </AppBar>
        <Card>
          {
            !is_sm && (
              <>
                <District />
                <Complex />
                <RoomTypes />
                <PriceFrom />
                <PriceTo />
              </>
            )
          }
          <CardContent>
            <SpaceFrom />
            <SpaceTo />
          </CardContent>
          <CardContent>
            <SellDate />
            <MainDistrict />
          </CardContent>
          <CardContent>
            <Apartments />
          </CardContent>
          <CardContent>
            <MetroLine />
            <MetroStation />
          </CardContent>
          {/* <CardContent>
            <FormControl className={classes.formControl}>
              <InputLabel>Срок Сдачи</InputLabel>
              <Select input={<Input />}>
                {sellDates.map((c) => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel>Старт продаж</InputLabel>
              <Select input={<Input />}>
                {sellStart.map((c) => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </CardContent>
          <CardContent>
            <FormControl className={classes.formControl}>
              <InputLabel>Способы оплаты</InputLabel>
              <Select input={<Input />}>
                {paidTypes.map((c) => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel>Отделка</InputLabel>
              <Select input={<Input />}>
                {decor.map((c) => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </CardContent>
          <CardContent>
            <FormControl className={classes.formControl}>
              <InputLabel>До метро</InputLabel>
              <Select input={<Input />}>
                {metro.map((c) => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel>Санузел</InputLabel>
              <Select input={<Input />}>
                {tualets.map((c) => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </CardContent> */}
        </Card>
      </Dialog>
    </>
  );
};

export default SearchControls;
