import React from 'react';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';

import { ViewContext } from './Context';

import Card from './Card';

import Icon0 from '../images/emptyHeart.svg';
import Icon1 from '../images/emptyBookmark.svg';
import ArrowImg from '../images/arrowToRight.svg'

function ComplexInfoSmall(props: any) {
  const { complex, developer } = props;
  const [viewMode] = React.useContext(ViewContext)

  return (
    <Card style={{ marginLeft: 24 }}>
      <div>
        <div style={{ height: 30, borderBottom: '1px solid #E0E0E0', marginBottom: 24, display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: 8 }}><img src={Icon0} /></div>
          <div><img src={Icon1} /></div>
          <div style={{ flex: 1 }} />
          {
            viewMode === 'broker' && (
              <>
                <Typography variant="caption">Сформировать презентацию</Typography>
                <div><img src={ArrowImg} /></div>
              </>
            )
          }
        </div>
        <Typography gutterBottom variant="h5">ЖК {complex?.name}</Typography>
        <Typography gutterBottom variant="body1">
          Топ {complex?.topnumber}
        </Typography>
        <div>
          <Rating value={Number(complex?.rating)} name="rating" />
        </div>
        <Typography gutterBottom variant="caption"><a href={complex?.site} target="_blank" rel="noreferrer">{complex?.site}</a></Typography>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Typography variant="body1">{developer?.name}</Typography>
            <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Проекты застройщика</Typography>
          </div>
          <div>
            <img src={developer?.logo?.src} alt="logo" style={{ maxHeight: 50, maxWidth: 90 }} />
          </div>
        </div>
      </div>
    </Card>
  )
}

export default ComplexInfoSmall;