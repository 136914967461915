import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  BooleanField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  Filter,
  AutocompleteInput,
} from 'react-admin';

export const name = 'accreditations';

const ComplexListFilter = (props: any) => (
  <Filter {...props}>
    <BooleanInput source="active" alwaysOn />
    <ReferenceInput
      source="bank"
      reference="banks"
      filterToQuery={(txt: string) => ({ name: txt })}
      alwaysOn
      allowEmpty={true}
      sort={{ field: 'name', order: 'ASC' }}
      filter={{ active: true }}
    >
      <AutocompleteInput optionText="name" allowEmpty={true} />
    </ReferenceInput>
    <TextInput source="name" />
  </Filter>
);

export const list = (props: any) => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={<ComplexListFilter />}
    filterDefaultValues={{ active: true }}
    sort={{ field: 'name', order: 'ASC' }}
  >
    <Datagrid rowClick="show">
      <BooleanField source="active" />
      <TextField source="name" />
      <ReferenceField source="bank" reference="banks" link="show">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="minRateWithAgree" />
      <TextField source="minRateWithoutAgree" />
      <TextField source="minPaymentWithAgree" />
      <TextField source="minPaymentWithoutAgree" />
      <BooleanField source="bankFormAgree" />
      <BooleanField source="motherCapital" />
      <BooleanField source="military" />
      <BooleanField source="twoDocs" />
      <BooleanField source="workWithIP" />
      <BooleanField source="govSupport" />
    </Datagrid>
  </List>
);

export const show = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <BooleanField source="active" />
      <TextField source="name" />
      <ReferenceField source="bank" reference="banks" link="show">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="minRateWithAgree" />
      <TextField source="minRateWithoutAgree" />
      <TextField source="minPaymentWithAgree" />
      <TextField source="minPaymentWithoutAgree" />
      <BooleanField source="bankFormAgree" />
      <BooleanField source="motherCapital" />
      <BooleanField source="military" />
      <BooleanField source="twoDocs" />
      <BooleanField source="workWithIP" />
      <BooleanField source="govSupport" />
    </SimpleShowLayout>
  </Show>
);

export const create = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput source="active" />
      <TextInput source="name" />
      <ReferenceInput source="bank" reference="banks" perPage={100}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="minRateWithAgree" />
      <TextInput source="minRateWithoutAgree" />
      <TextInput source="minPaymentWithAgree" />
      <TextInput source="minPaymentWithoutAgree" />
      <BooleanInput source="bankFormAgree" />
      <BooleanInput source="motherCapital" />
      <BooleanInput source="military" />
      <BooleanInput source="twoDocs" />
      <BooleanInput source="workWithIP" />
      <BooleanInput source="govSupport" />
    </SimpleForm>
  </Create>
);

export const edit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <BooleanInput source="active" />
      <TextInput source="name" />
      <ReferenceInput source="bank" reference="banks" perPage={100}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="minRateWithAgree" />
      <TextInput source="minRateWithoutAgree" />
      <TextInput source="minPaymentWithAgree" />
      <TextInput source="minPaymentWithoutAgree" />
      <BooleanInput source="bankFormAgree" />
      <BooleanInput source="motherCapital" />
      <BooleanInput source="military" />
      <BooleanInput source="twoDocs" />
      <BooleanInput source="workWithIP" />
      <BooleanInput source="govSupport" />
    </SimpleForm>
  </Edit>
);
