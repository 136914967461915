import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  BooleanInput,
  BooleanField,
  ImageField,
  EmailField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ImageInput,
} from 'react-admin';

export const name = 'banks';

const BankListFilter = (props: any) => {
  return (
    <Filter {...props}>
      <BooleanInput source="active" alwaysOn />
      {/* <ReferenceInput
        source="attachedCity"
        reference="cities"
        filterToQuery={(txt: string) => ({ name: txt })}
        alwaysOn
        allowEmpty={false}
        sort={{ field: 'name', order: 'ASC' }}
        filter={{ active: true }}
      >
        <AutocompleteInput optionText="name" allowEmpty={false} />
      </ReferenceInput> */}
      {/* <TextInput source="name" /> */}
    </Filter>
  );
};

export const list = (props: any) => {
  const { permissions = {} } = props;
  const { city } = permissions;
  if (!city) {
    return null;
  }

  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<BankListFilter />}
      filterDefaultValues={{ active: true }}
      sort={{ field: 'name', order: 'ASC' }}
    >
      <Datagrid rowClick="show">
        <BooleanField source="active" />
        <BooleanField source="top3" />
        <TextField source="name" />
        <ImageField source="logo.src" title="logo.title" />
      </Datagrid>
    </List>
  );
};

export const show = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <BooleanField source="active" />
      <TextField source="name" />
      <ImageField source="logo.src" title="logo.title" />
      {/* <TextField source="commission" />
      <TextField source="contact1Name" />
      <EmailField source="contact1Email" />
      <TextField source="contact1Phone" />
      <TextField source="contact2Name" />
      <EmailField source="contact2Email" />
      <TextField source="contact2Phone" />
      <TextField source="contact3Name" />
      <EmailField source="contact3Email" />
      <TextField source="contact3Phone" />
      <ImageField source="logo.src" />
      <ReferenceArrayField reference="cities" source="attachedCity">
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField> */}
    </SimpleShowLayout>
  </Show>
);

export const create = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput source="active" />
      <BooleanInput source="top3" />
      <TextInput source="name" />
      {/* <TextInput source="commission" />
      <TextInput source="contact1Name" />
      <TextInput source="contact1Email" />
      <TextInput source="contact1Phone" />
      <TextInput source="contact2Name" />
      <TextInput source="contact2Email" />
      <TextInput source="contact2Phone" />
      <TextInput source="contact3Name" />
      <TextInput source="contact3Email" />
      <TextInput source="contact3Phone" /> */}
      <ImageInput source="logo">
        <ImageField source="src" title="title" />
      </ImageInput>
      {/* <ReferenceArrayInput
        reference="cities"
        source="attachedCity"
        perPage={100}
        filter={{ active: true }}
      >
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput> */}
    </SimpleForm>
  </Create>
);

export const edit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <BooleanInput source="active" />
      <BooleanInput source="top3" />
      <TextInput source="name" />
      {/* <TextInput source="commission" />
      <TextInput source="contact1Name" />
      <TextInput source="contact1Email" />
      <TextInput source="contact1Phone" />
      <TextInput source="contact2Name" />
      <TextInput source="contact2Email" />
      <TextInput source="contact2Phone" />
      <TextInput source="contact3Name" />
      <TextInput source="contact3Email" />
      <TextInput source="contact3Phone" /> */}
      <ImageInput source="logo">
        <ImageField source="src" title="title" />
      </ImageInput>
      {/* <ReferenceArrayInput
        label="Cities"
        reference="cities"
        source="attachedCity"
        perPage={100}
        filter={{ active: true }}
      >
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput> */}
    </SimpleForm>
  </Edit>
);
