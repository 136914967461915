import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  Filter,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  BooleanInput,
} from 'react-admin';

export const name = 'regions';

const RegionFilter = (props: any) => (
  <Filter {...props}>
    <BooleanInput source="active" alwaysOn />
    <TextInput source="name" alwaysOn resettable />
    <ReferenceInput source="country" reference="countries">
      <SelectInput source="name" resettable />
    </ReferenceInput>
  </Filter>
);

export const list = (props: any) => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={<RegionFilter />}
    filterDefaultValues={{ active: true }}
  >
    <Datagrid rowClick="show">
      <TextField source="name" />
      <ReferenceField source="country" reference="countries" link="show">
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export const show = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <ReferenceField source="country" reference="countries" link="show">
        <TextField source="name" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);

export const create = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <ReferenceInput source="country" reference="countries" link="show">
        <SelectInput source="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export const edit = (props: any) => (
  <Edit {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" />
      <ReferenceInput source="country" reference="countries" link="show">
        <SelectInput source="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
