import React from 'react';

import startOfMonth from 'date-fns/startOfMonth';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import createDecorator from 'final-form-calculate';

import {
  List,
  Datagrid,
  TextField,
  // Show,
  // SimpleShowLayout,
  Create,
  // SimpleForm,
  TextInput,
  Edit,
  // ListGuesser,
  NumberField,
  BooleanField,
  DateField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  NumberInput,
  BooleanInput,
  DateInput,
  FormWithRedirect,
  SaveButton,
  FileInput,
  FileField,
  FormDataConsumer,
  FunctionField,
  Filter,
  Pagination
  // AutocompleteInput
} from 'react-admin';

// import CustomizableDatagrid from 'ra-customizable-datagrid';
import ListDataGrid from 'list';

import ListFilter from 'list/ListFilter';

import {
  // Card as MuiCard,
  // CardContent,
  // withStyles,
  // Typography,
  Box,
  Toolbar,
  // Paper,
} from '@material-ui/core';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import { TabPanelProps } from '@material-ui/lab/TabPanel/TabPanel';

export const name = 'lists';

const contragent = (record: any) => {
  const { contragentsurname, contragentname, contragentforename } = record;
  return record ? `${contragentsurname ?? ''} ${contragentname ?? ''} ${contragentforename ?? ''}` : '';
}

const localStatuses = [
  { "id": "57eb4c2f5a5c874560271f82", "name": "Резерв", "sort": 10 },
  { "id": "57eb4c245a5c874560271f81", "name": "Бронь", "sort": 20 },
  { "id": "57eb4cc05a5c874560271f88", "name": "Платная бронь", "sort": 30 },
  { "id": "580fb4b5be68cc09c27b2962", "name": "Договор задатка", "sort": 40 },
  { "id": "57eb4c525a5c874560271f83", "name": "ДКП предварительный подписан", "sort": 50 },
  { "id": "58029aa9cc382759671d55e2", "name": "ДДУ в наборе", "sort": 60 },
  { "id": "57eb4c115a5c874560271f80", "name": "ДДУ подписан", "sort": 70 },
  { "id": "59f865461412c64cd69e22ff", "name": "ДКП подписан", "sort": 75 },
  { "id": "57eb4c685a5c874560271f84", "name": "ДДУ в юстиции", "sort": 80 },
  { "id": "57eb4c745a5c874560271f86", "name": "ДДУ забрали из юстиции", "sort": 90 },
  { "id": "587881f75cebba8f41c1917d", "name": "Клиент частично оплатил", "sort": 95 },
  { "id": "57eb4bfd5a5c874560271f7f", "name": "Клиент оплатил", "sort": 100 },
  { "id": "579b00024107fdee7fdfb110", "name": "Выставлен счет", "sort": 110 },
  { "id": "57eb4bdf5a5c874560271f7e", "name": "Застройщик частично оплатил", "sort": 120 },
  { "id": "579afff24107fdee7fdfb10f", "name": "КВ Получено", "sort": 130 }
]
// const ListFilter = (props: any) => {
//   const { office, role, ...otherProps } = props;

//   if (['user', 'broker'].includes(role)) {
//     return null;
//   }

//   return (
//     <Filter {...otherProps}>
//       {/* <ReferenceInput
//       source="office"
//       reference="offices"
//       filterToQuery={(txt: string) => ({ name: txt })}
//       alwaysOn
//       allowEmpty={true}
//       sort={{ field: 'name', order: 'ASC' }}
//       filter={{ active: true }}
//     >
//       <AutocompleteInput optionText="name" allowEmpty={true} />
//     </ReferenceInput> */}
//       <ReferenceInput
//         source="manager"
//         reference="users"
//         alwaysOn
//         sort={{ field: 'name', order: 'ASC' }}
//         filter={{ office }}
//         perPage={1000}
//       >
//         <SelectInput source="name" />
//       </ReferenceInput>
//       {/* <FormDataConsumer>
//       {({ formData }) => (
//         formData.office ?
//           <ReferenceInput source="manager" reference="users" filter={{ office: formData.office }} sort={{ field: 'name', order: 'ASC' }}>
//             <SelectInput source="name" />
//           </ReferenceInput>
//           :
//           <SelectInput source="manager" disabled />
//       )}
//     </FormDataConsumer> */}
//     </Filter>
//   )
// };

const ListPagination = (props: any) => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200, 500, 1000, 2000]} {...props} />

export const ListRes = (props: any) => {
  const { permissions = {} } = props;
  const { role, office, city, user } = permissions;

  const defValues: any = {
    list_kind: 'active',
    date_type: 'contractdate',
    date_lt: endOfDay(Date.now()).toISOString(),
    date_gt: startOfMonth(Date.now()).toISOString(),
  }

  if (role && ['admin', 'supermanager', 'cityhead', 'broker'].includes(role)) {
    defValues.city = city;
    defValues.offices = [office];
  } else if (role && ['officehead', 'superofficemanager'].includes(role)) {
    defValues.office = office;
  } else {
    defValues.manager = user;
  }

  if (!office || !city || !role) {
    return null;
  }

  if (!['admin', 'supermanager'].includes(role) && !user) {
    return null;
  }

  return (
    <List {...props}
      pagination={<ListPagination />}
      exporter={false}
      perPage={200}
      filters={<ListFilter role={role} />}
      filterDefaultValues={defValues}
    >
      <ListDataGrid />
    </List>
  )
}

export { ListRes as list };

// export const list = (props: any) => {
//   const { permissions = {} } = props;
//   const { role, office, user } = permissions;
//   // const { role, user } = permissions;

//   if (!role) {
//     return null;
//   }

//   const filter: any = { reject: false };
//   if (['admin', 'supermanager']) {

//   } else if (['manager', 'officehead'].includes(role)) {
//     filter.office = office;
//   } else if (['broker']) {
//     filter.broker = user;
//   } else {
//     filter.manager = user;
//   }
//   // if (['admin', 'officehead'].includes(role)) {
//   //   filter.office = office;
//   // } else {
//   //   filter.manager = user;
//   // }

//   return (
//     <List {...props}
//       bulkActionButtons={false}
//       filter={filter}
//       filters={<ListFilter office={office} role={role} />}
//       sort={{ field: 'contractdate', order: 'DESC' }}
//     >
//       <CustomizableDatagrid rowClick="edit">
//         <FunctionField label="custom.contragent" render={contragent} />
//         <TextField source="phone" />
//         <TextField source="email" />
//         <ReferenceField source="manager" reference="users" link={false}>
//           <TextField source="name" />
//         </ReferenceField>
//         <ReferenceField source="office" reference="offices" link={false}>
//           <TextField source="name" />
//         </ReferenceField>
//         <ReferenceField source="source" reference="listsources" link={false}>
//           <TextField source="name" />
//         </ReferenceField>
//         <ReferenceField source="complex" reference="complexes" link={false}>
//           <TextField source="name" />
//         </ReferenceField>
//         <ReferenceField source="building" reference="buildings" link={false}>
//           <TextField source="name" />
//         </ReferenceField>
//         <ReferenceField source="roomtype" reference="roomtypes" link={false}>
//           <TextField source="name" />
//         </ReferenceField>
//         <TextField source="roomnum" />
//         <TextField source="stage" />
//         <NumberField source="sell" />
//         <ReferenceField source="liststatus" reference="liststatuses" link={false}>
//           <TextField source="name" />
//         </ReferenceField>
//         <ReferenceField source="selltype" reference="selltypes" link={false}>
//           <TextField source="name" />
//         </ReferenceField>
//         <DateField source="reservedate" />
//         <DateField source="contractdate" />
//         <DateField source="developerpaiddate" />
//         <DateField source="invoicedate" />
//         <ReferenceField source="ipoteka" reference="ipotekastatuses" link={false}>
//           <TextField source="name" />
//         </ReferenceField>
//         <DateField source="kvpaiddate" />
//         <DateField source="contractdate2" />
//         <DateField source="regdate" />
//         <ReferenceField source="action" reference="actions" link={false}>
//           <TextField source="name" />
//         </ReferenceField>
//         <NumberField source="roomsquare" />
//         <BooleanField source="indkv" />
//         <BooleanField source="ouripoteka" />
//         <NumberField source="kv" />
//         <TextField source="roomcode" />
//         <TextField source="kvpr" />
//         <TextField source="priceperm" />
//         <TextField source="contractnum" />
//         <NumberField source="salaryValue" />
//         <NumberField source="salary" />
//         <DateField source="salaryDate" />
//         <DateField source="estimateClientPaidDate" />
//         <DateField source="estimateKVPaidDate" />
//         <TextField source="bonusCoefficient" />
//         <ReferenceField source="banks" reference="banks" link={false}>
//           <TextField source="name" />
//         </ReferenceField>
//         <ReferenceField source="strahRef" reference="strahs" link={false}>
//           <TextField source="name" />
//         </ReferenceField>
//         <DateField source="dateOfConstruction" />
//         <DateField source="realDateOfConstruction" />
//         <DateField source="estimateDealDate" />
//         <ReferenceField source="broker" reference="users" link={false}>
//           <TextField source="name" />
//         </ReferenceField>
//         <NumberField source="creditSum" />
//         <NumberField source="dopkv" />
//         <NumberField source="dopkvSum" />
//         <DateField source="dopkvData" />
//         <NumberField source="sumkvItog" />
//         <NumberField source="dopkvZp" />
//         <NumberField source="sumZpItog" />
//         <DateField source="rejectDate" />
//         <NumberField source="kv_buyer_pr" />
//         <NumberField source="kv_buyer_fix" />
//         <NumberField source="kv_buyer" />
//         <TextField source="sellersurname" />
//         <TextField source="sellername" />
//         <TextField source="sellerforename" />
//         <TextField source="sellerphone" />
//         <TextField source="selleremail" />
//         <NumberField source="kv_seller_pr" />
//         <NumberField source="kv_seller" />
//         <TextField source="address" />
//         <NumberField source="comm_pj" />
//         <ReferenceField source="sellersource" reference="listsources" link={false}>
//           <TextField source="name" />
//         </ReferenceField>
//       </CustomizableDatagrid>
//     </List>
//   )
// };

const transform = async (data: any) => {

  const recalcBank = () => {
    if (data.creditSum && data.bankKvPercent) {
      data.bankKvSum =
        (Number(data.creditSum) * Number(data.bankKvPercent)) / 100;
    }
  };

  const recalcStrah = () => {
    if (data.strahSum && data.strahKv) {
      data.strahKvSum =
        (Number(data.strahSum) * Number(data.strahKv)) / 100;
    }
  };

  const recalc3 = () => {
    data.field_3_2 =
      Number((data.field_3_1 * data.strahKvSum) / 100) || 0;
    data.field_3_4 =
      Number((data.field_3_3 * data.bankKvSum) / 100) || 0;
    data.field_3_6 = Number((data.field_3_5 * data.kv) / 100) || 0;
    data.field_3_7 =
      Math.round(
        Number(data.field_3_2 + data.field_3_4 + data.field_3_6) *
        100
      ) / 100 || 0;
  };

  const reculcSalary = () => {
    data.salaryValue = 0;
    if (Number(data.bonusCoefficient)) {
      if (Number(data.kv_seller)) {
        data.salaryValue += Number(data.kv_seller);
      }
      if (Number(data.kv_buyer)) {
        data.salaryValue += Number(data.kv_buyer);
      }
      if (Number(data.comm_pj)) {
        data.salaryValue += Number(data.comm_pj);
      }
      data.salaryValue *= Number(data.bonusCoefficient);
    }
    data.salaryValue = Number(data.salaryValue).toFixed(2);
  };
  const reculcBuyerKV = () => {
    if (Number(data.sell)) {
      if (Number(data.kv_buyer_pr)) {
        data.kv_buyer = (
          (Number(data.sell) * Number(data.kv_buyer_pr)) /
          100
        ).toFixed(2);
      } else if (Number(data.kv_buyer_fix)) {
        data.kv_buyer = Number(data.kv_buyer_fix);
      }
    }
    reculcSalary();
  };

  const reculcSellerKV = function () {
    if (Number(data.sell)) {
      if (Number(data.kv_seller_pr)) {
        data.kv_seller = (
          (Number(data.sell) * Number(data.kv_seller_pr)) /
          100
        ).toFixed(2);
      } else if (Number(data.kv_seller_fix)) {
        data.kv_seller = Number(data.kv_seller_fix);
      }
    }
    reculcSalary();
  };

  const recalcKV = () => {
    if (!data.indkv) {
      if (!data.priceperm) {
        data.kv = (Number(data.sell) * Number(data.kvpr)) / 100;
      } else {
        data.kv = Number(data.roomsquare) * Number(data.priceperm);
      }
    }
    if (Number(data.sell)) {
      reculcSellerKV();
      reculcBuyerKV();
    }
  }

  if (data.reject && !data.rejectDate) {
    data.rejectDate = startOfDay(Date.now()).toISOString();
  } else {
    data.reject = false;
  }

  try {
    if (!data.createdAt) {
      if (data.createdate) {
        data.createdAt = data.createdate.toDate().toISOString()
      } else {
        data.createdAt = startOfDay(Date.now()).toISOString();
      }
    }
  } catch (ex) { }

  if (data.liststatus === '579afff24107fdee7fdfb10f') {
    data.list_kind = 'success';
  } else if (data.reject === true) {
    data.list_kind = 'reject';
  } else {
    data.list_kind = 'active';
  }

  recalcBank();
  recalc3();
  recalcStrah();

  recalcKV();
  return data;
}

const validateListForm = (values: any) => {
  const errors: any = {};

  if (!values.contractdate) {
    errors.contractdate = ['Required'];
  }

  if (!values.liststatus) {
    errors.liststatus = ['Required'];
  }

  return errors;
};

const ListCreate = (props: any) => {
  const { permissions = {} } = props;
  const { office, user } = permissions;

  if (!office) {
    return null;
  }

  return (
    <Create {...props} transform={transform}>
      <ListForm
        permissions={props.permissions}
        defaultValue={{ office, manager: user }}
        validate={validateListForm}
      />
    </Create>
  );
};

export { ListCreate as create };


export const edit = (props: any) => (
  <Edit {...props} transform={transform}>
    <ListForm permissions={props.permissions} validate={validateListForm} />
  </Edit>
);

// function CreateListForm(props: any) {
//   return <FormWithRedirect {...props} render={(formProps: any) => (
//     <form>
//       <DateInput source="contractdate" fullWidth resource="lists" />
//       <ReferenceInput source="manager" reference="users" fullWidth resource="lists">
//         <SelectInput source="name" />
//       </ReferenceInput>
//       <SaveButton
//         saving={formProps.saving}
//         handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
//       />
//     </form>
//   )} />
// }

const calc = createDecorator({
  field: /roomsquare|sell|kvpr|priceperm|dopkv|kv_seller_pr|kv_seller_fix|kv_buyer_pr|kv_buyer_fi|creditSum|bankKvPercent|strahSum|strahKv|field_3_1|field_3_3|field_3_5|bonusCoefficient|comm_pj/,
  updates: (value, name, allValues) => transform({ ...allValues, [name]: value })
});

function ListForm(props: any) {
  const { permissions = {} } = props;
  const { office, city, role } = permissions;
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  if (!office) {
    return null;
  }

  const canChangeOffice = ['admin', 'supermanager', 'cityhead'].includes(role);

  const isAdmin = ['admin', 'supermanager', 'officehead', 'superofficemanager'].includes(role);

  const allowReject = city === '56cd7da14fd597460a9064c1' ? isAdmin : true;

  const filterOffices: any = { active: true };

  if (['cityhead'].includes(role)) {
    filterOffices.city = city;
  }

  const validateListStatus = (value: any, allValues: any) => {
    if (!value) {
      return 'required';
    }
    const foundStatus = localStatuses.find(ls => ls.id === value);
    if (foundStatus && !isAdmin && foundStatus.sort >= 110) {
      return `Нельзя изменить статус, код ${foundStatus.sort}`;
    }
    return undefined;
  }

  return (
    <FormWithRedirect
      {...props}
      decorators={[calc]}
      render={(formProps: any) => (
        <form>
          <Box p="1em">
            {
              allowReject && <BooleanInput source="reject" resource="lists" />
            }
            <Box display="flex">
              <Box flex={1} mr="1em">
                <ReferenceInput source="manager" reference="users" fullWidth resource="lists" filter={{ office, fb_active: true }} sort={{ field: 'name', order: 'ASC' }} perPage={100} disabled={!isAdmin}>
                  <SelectInput source="name" />
                </ReferenceInput>
                <ReferenceInput source="liststatus" reference="liststatuses" fullWidth resource="lists" sort={{ field: 'sort', order: 'ASC' }} perPage={100} validate={validateListStatus}>
                  <SelectInput source="name" />
                </ReferenceInput>
              </Box>
              <Box flex={1} mr="1em">
                <ReferenceInput source="broker" reference="users" fullWidth resource="lists" filter={{ fb_active: true, role: 'broker' }} perPage={1000}>
                  <SelectInput source="name" />
                </ReferenceInput>
                <ReferenceInput source="selltype" reference="selltypes" fullWidth resource="lists" >
                  <SelectInput source="name" />
                </ReferenceInput>
              </Box>
              <Box flex={1}>
                {
                  canChangeOffice && (
                    <ReferenceInput source="office" reference="offices" fullWidth resource="lists" disabled={!canChangeOffice} filter={filterOffices} sort={{ field: 'name', order: 'ASC' }} perPage={1000}>
                      <SelectInput source="name" />
                    </ReferenceInput>)
                }
                <TextInput source="contractnum" fullWidth resource="lists" />
              </Box>
            </Box>
            <TextInput source="info" fullWidth multiline resource="lists" />
            <Box>
              <Tabs value={value} onChange={handleChange}>
                <Tab label="Сделка" />
                <Tab label="Зарплата" />
                <Tab label="Покупатель" />
                <Tab label="Продавец" />
                <Tab label="Объект" />
                <Tab label="Ипотека" />
              </Tabs>
            </Box>
            <Box>
              <TabPanel value={value} index={0}>
                <Box display="flex">
                  <Box flex={1} mr="1em">
                    <DateInput source="reservedate" fullWidth resource="lists" />
                    <DateInput source="contractdate" fullWidth resource="lists" />
                    <DateInput source="developerpaiddate" fullWidth resource="lists" />
                    <DateInput source="dopkvData" fullWidth resource="lists" />
                  </Box>
                  <Box flex={1} mr="1em">
                    <DateInput source="contractdate2" fullWidth resource="lists" />
                    <DateInput source="regdate" fullWidth resource="lists" />
                    <DateInput source="invoicedate" fullWidth resource="lists" />
                  </Box>
                  <Box flex={1}>
                    <DateInput source="estimateDealDate" fullWidth resource="lists" />
                    <DateInput source="estimateClientPaidDate" fullWidth resource="lists" />
                    <DateInput source="estimateKVPaidDate" fullWidth resource="lists" />
                    <DateInput source="kvpaiddate" fullWidth resource="lists" />
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Box display="flex">
                  <Box flex={1} mr="1em">
                    <NumberInput source="bonusCoefficient" fullWidth resource="lists" disabled={!isAdmin} />
                    <NumberInput source="field_3_7" fullWidth resource="lists" />
                  </Box>
                  <Box flex={1} mr="1em">
                    <NumberInput source="salaryValue" fullWidth resource="lists" disabled={!isAdmin} />
                    <NumberInput source="salary" fullWidth resource="lists" disabled={!isAdmin} />
                  </Box>
                  <Box flex={1}>
                    <NumberInput source="comm_pj" fullWidth resource="lists" disabled={!isAdmin} />
                    <DateInput source="salaryDate" fullWidth resource="lists" disabled={!isAdmin} />
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={1} mr="1em">
                    <NumberInput source="field_3_1" fullWidth resource="lists" />
                    <NumberInput source="field_3_3" fullWidth resource="lists" />
                  </Box>
                  <Box flex={1} mr="1em">
                    <NumberInput source="field_3_2" fullWidth resource="lists" disabled />
                    <NumberInput source="field_3_4" fullWidth resource="lists" disabled />
                  </Box>
                  <Box flex={1}>
                    <NumberInput source="field_3_5" fullWidth resource="lists" />
                  </Box>
                </Box>

              </TabPanel>
              <TabPanel value={value} index={2}>
                <Box display="flex">
                  <Box flex={1} mr="1em">
                    <TextInput source="contragentsurname" fullWidth resource="lists" />
                    <TextInput source="phone" fullWidth resource="lists" />
                    <NumberInput source="kv_buyer_pr" fullWidth resource="lists" />
                  </Box>
                  <Box flex={1} mr="1em">
                    <TextInput source="contragentname" fullWidth resource="lists" />
                    <TextInput source="email" fullWidth resource="lists" />
                    <NumberInput source="kv_buyer_fix" fullWidth resource="lists" />
                  </Box>
                  <Box flex={1}>
                    <TextInput source="contragentforename" fullWidth resource="lists" />
                    <ReferenceInput source="source" reference="listsources" fullWidth resource="lists" >
                      <SelectInput source="name" />
                    </ReferenceInput>
                    <NumberInput source="kv_buyer" resource="lists" fullWidth />
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Box display="flex">
                  <Box flex={1} mr="1em">
                    <TextInput source="sellersurname" fullWidth resource="lists" />
                    <TextInput source="sellerphone" fullWidth resource="lists" />
                    <NumberInput source="kv_seller_pr" fullWidth resource="lists" />
                  </Box>
                  <Box flex={1} mr="1em">
                    <TextInput source="sellername" fullWidth resource="lists" />
                    <TextInput source="selleremail" fullWidth resource="lists" />
                    <NumberInput source="kv_seller_fix" fullWidth resource="lists" />
                  </Box>
                  <Box flex={1}>
                    <TextInput source="sellerforename" fullWidth resource="lists" />
                    <ReferenceInput source="sellersource" reference="listsources" fullWidth resource="lists" >
                      <SelectInput source="name" />
                    </ReferenceInput>
                    <NumberInput source="kv_seller" fullWidth resource="lists" />
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={4}>
                <Box display="flex">
                  <Box flex={1} mr="1em">
                    <ReferenceInput source="complex" reference="complexes" fullWidth resource="lists" filter={{ city }} sort={{ field: 'name', order: 'ASC' }} perPage={500}>
                      <SelectInput source="name" />
                    </ReferenceInput>
                  </Box>
                  <Box flex={2}>
                    <TextInput source="address" fullWidth multiline resource="lists" />
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={1} mr="1em">
                    <FormDataConsumer>
                      {({ formData }) => (
                        formData.complex ?
                          <ReferenceInput source="building" reference="buildings" fullWidth resource="lists" filter={{ complex: formData.complex }} sort={{ field: 'name', order: 'ASC' }} perPage={500}>
                            <SelectInput source="name" />
                          </ReferenceInput>
                          :
                          <SelectInput source="building" resource="lists" fullWidth disabled />
                      )}
                    </FormDataConsumer>
                    <TextInput source="roomnum" fullWidth resource="lists" />
                    <NumberInput source="roomsquare" fullWidth resource="lists" />
                    <NumberInput source="kvpr" fullWidth resource="lists" />
                    <FileInput source="docs1" multiple resource="lists" >
                      <FileField source="src" title="title" />
                    </FileInput>
                  </Box>
                  <Box flex={1} mr="1em">
                    <TextInput source="stage" fullWidth resource="lists" />
                    <TextInput source="roomcode" fullWidth resource="lists" />
                    <NumberInput source="sell" fullWidth resource="lists" />
                    <NumberInput source="priceperm" fullWidth resource="lists" />
                    <FileInput source="docs2" multiple resource="lists" >
                      <FileField source="src" title="title" />
                    </FileInput>
                  </Box>
                  <Box flex={1}>
                    <ReferenceInput source="roomtype" reference="roomtypes" fullWidth resource="lists" >
                      <SelectInput source="name" />
                    </ReferenceInput>
                    <ReferenceInput source="action" reference="actions" fullWidth resource="lists" >
                      <SelectInput source="name" />
                    </ReferenceInput>
                    <NumberInput source="kv" fullWidth resource="lists" />
                    <NumberInput source="dopkv" fullWidth resource="lists" />
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={5}>
                <Box display="flex">
                  <Box flex={1} mr="1em">
                    <ReferenceInput source="bank" reference="banks" fullWidth resource="lists" filter={{ active: true }} sort={{ field: 'name', order: 'ASC' }} perPage={1000}>
                      <SelectInput source="name" />
                    </ReferenceInput>
                    <ReferenceInput source="strahRef" reference="strahs" fullWidth resource="lists" >
                      <SelectInput source="name" />
                    </ReferenceInput>
                  </Box>
                  <Box flex={1} mr="1em">
                    <BooleanInput source="ouripoteka" resource="lists" />
                    <ReferenceInput source="ipoteka" reference="ipotekastatuses" fullWidth resource="lists" >
                      <SelectInput source="name" />
                    </ReferenceInput>
                  </Box>
                  <Box flex={1}>
                    <BooleanInput source="indkv" resource="lists" />
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={1} mr="1em">
                    <NumberInput source="creditSum" fullWidth resource="lists" />
                    <NumberInput source="strahSum" fullWidth resource="lists" />
                    <DateInput source="strahDateStrat" fullWidth resource="lists" />
                  </Box>
                  <Box flex={1} mr="1em">
                    <NumberInput source="bankKvPercent" fullWidth resource="lists" />
                    <NumberInput source="strahKv" fullWidth resource="lists" />
                    <DateInput source="strahDateEnd" fullWidth resource="lists" />
                  </Box>
                  <Box flex={1}>
                    <NumberInput source="bankKvSum" fullWidth resource="lists" />
                    <NumberInput source="strahKvSum" fullWidth resource="lists" />
                    <DateInput source="creditIpotekaDateStart" fullWidth resource="lists" />
                  </Box>
                </Box>
              </TabPanel>
            </Box>
          </Box>
          <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%">
              {/* <SaveButton
                saving={formProps.saving}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              /> */}
              <FormDataConsumer>
                {({ formData }) => {
                  if (!isAdmin && formData.list_kind === 'success') {
                    return null
                  }
                  return (
                    <SaveButton
                      disabled={!role || role === 'guest'}
                      saving={formProps.saving}
                      handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                    />
                  )
                }}
              </FormDataConsumer>
            </Box>
          </Toolbar>
        </form>
      )}
    />
  )
}

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

// function bankName(record: any) {
//   return record?.name;
// }