import React, { Suspense } from 'react';

const KPI = React.lazy(() => import('./KPI'));

function KPILoader() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <KPI />
    </Suspense>
  )
}

export default KPILoader;
