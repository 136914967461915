import * as React from "react";
import Card from '@material-ui/core/Card';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import { Title, DateInput, SelectInput, useDataProvider, usePermissions, ReferenceInput, FormDataConsumer, ReferenceArrayInput, TextInput, SelectArrayInput } from 'react-admin';
import { Link } from "react-router-dom";
import { Form } from 'react-final-form';
import { Box } from '@material-ui/core';
import startOfMonth from 'date-fns/startOfMonth';
import endOfDay from 'date-fns/endOfDay';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  cell: {
    fontSize: 12,
    borderLeft: '1px solid #ccc',
    padding: 5
  },
  headCell: {
    fontSize: 12,
    borderLeft: '1px solid #ccc',
    fontWeight: 'bold',
    padding: 5,
    borderTop: '1px solid #ccc',
  },
}));

const ReportFilter = (props: any) => {
  const { setFilter, filterValues, role, disabled } = props;

  const onSubmit = (values: any) => {
    if (setFilter) {
      setFilter(values);
    }
  }

  return (
    <div>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
        {
          ({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Box display="fex" alignItems="flex-end">
                <Box component="span" mr={2}>
                  <DateInput source="date_gt" label="Период с" />
                </Box>
                <Box component="span" mr={2}>
                  <DateInput source="date_lt" label="Период по" />
                </Box>
                {
                  ['supermanager', 'admin'].includes(role) && ([
                    <Box component="span" mr={2} key="city">
                      <ReferenceInput source="city" reference="cities" filter={{ active: true }} sort={{ field: 'name', order: 'ASC' }} perPage={500} label="Город">
                        <SelectInput optionText="name" />
                      </ReferenceInput>
                    </Box>,
                    <Box component="span" mr={2} minWidth={150} key="office">
                      <FormDataConsumer>
                        {
                          ({ formData }) => (
                            formData?.city ?
                              <ReferenceArrayInput basePath="/developerreport" source="offices" reference="offices" resource="lists" filter={{ active: true, city: formData.city }} sort={{ field: 'name', order: 'ASC' }} perPage={500} label="Офисы">
                                <SelectArrayInput optionText="name" />
                              </ReferenceArrayInput> :
                              <TextInput source="office" disabled />
                          )
                        }
                      </FormDataConsumer>
                    </Box>
                  ])
                }
                {
                  ['cityhead'].includes(role) && ([
                    <Box component="span" mr={2} minWidth={150} key="office">
                      <FormDataConsumer>
                        {
                          ({ formData }) => (
                            formData?.city ?
                              <ReferenceArrayInput basePath="/developerreport" source="offices" reference="offices" resource="lists" filter={{ active: true, city: formData.city }} sort={{ field: 'name', order: 'ASC' }} perPage={500} label="Офисы">
                                <SelectArrayInput optionText="name" />
                              </ReferenceArrayInput> :
                              <TextInput source="office" disabled />
                          )
                        }
                      </FormDataConsumer>
                    </Box>
                  ])
                }
                <Box component="span" mr={2}>
                  <Button variant="outlined" color="primary" type="submit" disabled={disabled}>Сформировать</Button>
                </Box>
              </Box>
            </form>
          )
        }
      </Form>
    </div>
  )
}

const DeveloperReport = (props: any) => {
  const [filter, setFilter] = React.useState<Record<string, any>>({});
  const [listData, setListData] = React.useState<any>([]);
  const [parsing, setParsing] = React.useState(false);
  const dataProvider = useDataProvider();
  const { permissions, loaded } = usePermissions();

  const initialFilterValues = React.useMemo(() => {
    const { role, city, office } = permissions || {};
    const ret: any = {
      date_lt: endOfDay(Date.now()).toISOString(),
      date_gt: startOfMonth(Date.now()).toISOString(),
    };
    if (role) {
      if (['admin', 'supermanager', 'cityhead'].includes(role)) {
        ret.city = city;
        ret.offices = [office];
      } else if (['officehead'].includes(role)) {
        ret.office = office;
      }
    }
    return ret;
  }, [permissions]);

  React.useEffect(() => {
    const fetchListData = async (date_type: string, offices: string[], date_gt: any, date_lt: any) => {
      const resp = await dataProvider.getList('lists',
        {
          pagination: { page: 1, perPage: 10000 },
          filter: { list_kind: 'all', offices, date_gt, date_lt, date_type },
          sort: { field: 'id', order: 'ASC' }
        });
      return resp.data.filter(d => !d.reject);
    }

    const fetchOffice = async (id: string) => {
      const resp = await dataProvider.getOne('offices', { id });
      return resp.data;
    }

    const fetchDevelopers = async (city: string) => {
      const resp = await dataProvider.getList('developers', {
        pagination: { page: 1, perPage: 1000 },
        filter: { attachedCity: city, active: true },
        sort: { field: 'name', order: 'ASC' }
      });
      return resp.data;
    }

    const fetchComplexes = async (developer: string) => {
      const resp = await dataProvider.getList('complexes', {
        pagination: { page: 1, perPage: 1000 },
        filter: { developer, active: true },
        sort: { field: 'name', order: 'ASC' }
      });
      return resp.data;
    }

    const fillData = async (offices: string[], date_gt: any, date_lt: any) => {
      let officesData: any = [];
      for (let i = 0; i < offices.length; i++) {
        const resp = await fetchOffice(offices[i]);
        officesData = [...officesData, resp];
      }
      const cities = officesData.reduce((m: any, d: any) => m.includes(d.city) ? m : [...m, d.city], []);
      let developersData: any = [];
      for (let i = 0; i < cities.length; i++) {
        const resp = await fetchDevelopers(cities[i]);
        developersData = [...developersData, ...resp];
      }

      const byContract = await fetchListData('contractdate', offices, date_gt, date_lt);
      const byDevPaid = await fetchListData('developerpaiddate', offices, date_gt, date_lt);

      let table: any = [];

      for (let i = 0; i < developersData.length; i++) {
        const developer = developersData[i];
        const complexes = await fetchComplexes(developer.id);
        const _table: any = [];
        complexes.forEach((c, ind) => {

          const filtered1 = byContract.filter(d => d.complex === c.id);
          const filtered2 = byDevPaid.filter(d => d.complex === c.id);

          _table.push({
            developer: ind === 0 ? developer.name : '',
            complex: c.name,
            ddu: filtered1.length,
            sumDdu: filtered1.reduce((m, d) => m + Number(d.sell ?? 0), 0),
            kv: filtered1.reduce((m, d) => m + Number(d.kv ?? 0), 0),
            rooms: filtered2.length,
            sumSells: filtered2.reduce((m, d) => m + Number(d.sell ?? 0), 0),
            sumKv: filtered1.reduce((m, d) => m + Number(d.kv ?? 0), 0),
          });
        });
        if (!complexes.length) {
          _table.push({
            developer: developer.name,
            complex: '',
            ddu: 0,
            sumDdu: 0,
            kv: 0,
            rooms: 0,
            sumSells: 0,
            sumKv: 0,
          });
        }
        _table.push({
          type: 'sum',
          developer: 'Итого',
          ddu: _table.reduce((m: any, d: any) => m + d.ddu, 0),
          sumDdu: _table.reduce((m: any, d: any) => m + d.sumDdu, 0),
          kv: _table.reduce((m: any, d: any) => m + d.kv, 0),
          rooms: _table.reduce((m: any, d: any) => m + d.rooms, 0),
          sumSells: _table.reduce((m: any, d: any) => m + d.sumSells, 0),
          sumKv: _table.reduce((m: any, d: any) => m + d.sumKv, 0),
        })
        // console.log('---table---', _table);
        Array.prototype.push.apply(table, _table);
      }

      return table;
    }



    if (Object.keys(filter).length > 0) {
      const offices = filter.office ? [filter.office] : filter.offices;
      setParsing(true);
      fillData(offices, filter.date_gt, filter.date_lt).then(data => setListData(data)).finally(() => setParsing(false));
    }
  }, [filter, dataProvider])

  return (
    <Card>
      <Title title="Отчеты по сделкам" />
      <CardContent>
        <ButtonGroup>
          <Button component={Link} to="/listreports">СПРАВКА</Button>
          <Button component={Link} to="/expertreport">Рейтинг Экспертов</Button>
          <Button disabled>Работа с застройщиками</Button>
        </ButtonGroup>
        <ReportFilter setFilter={setFilter} filterValues={initialFilterValues} role={permissions?.role} disabled={parsing} />
        <Table1 data={listData} parsing={parsing} setParsing={setParsing} />
        {/* <div>
          filter
          <pre>
            {JSON.stringify(filter, null, ' ')}
          </pre>
          data
          <pre>
            {JSON.stringify(listData, null, ' ')}
          </pre>
        </div> */}
      </CardContent>
    </Card>
  )
}

export default DeveloperReport;

function Table1(props: any) {
  const { data, parsing, setParsing } = props;
  // const [data, setData] = React.useState([]);
  // const timer = React.useRef<any>();
  // const dataProvider = useDataProvider();
  const classes = useStyles();


  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.headCell}>Застройщик</TableCell>
            <TableCell className={classes.headCell}>ЖК</TableCell>
            <TableCell className={classes.headCell}>Кол-во подписанных дду</TableCell>
            <TableCell className={classes.headCell}>Сумма подписанных дду</TableCell>
            <TableCell className={classes.headCell}>Сумма подписанных кв</TableCell>
            <TableCell className={classes.headCell}>Кол-во оплаченных квартир</TableCell>
            <TableCell className={classes.headCell}>Сумма продажи</TableCell>
            <TableCell className={classes.headCell}>Сумма оплат</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            parsing && <TableRow><TableCell colSpan={14}>Обработка данных</TableCell></TableRow>
          }
          {
            !parsing && data.map((row: any, ind: number) => (
              <TableRow key={row.id} style={{ background: row.type === 'sum' ? '#ccc' : '' }}>
                <TableCell size="small" className={classes.cell}>{row.developer}</TableCell>
                <TableCell size="small" className={classes.cell}>{row.complex}</TableCell>
                <TableCell size="small" className={classes.cell} align="right"><NumberField data={row.ddu} /></TableCell>
                <TableCell size="small" className={classes.cell} align="right"><NumberField data={row.sumDdu} /></TableCell>
                <TableCell size="small" className={classes.cell} align="right"><NumberField data={row.kv} /></TableCell>
                <TableCell size="small" className={classes.cell} align="right"><NumberField data={row.rooms} /></TableCell>
                <TableCell size="small" className={classes.cell} align="right"><NumberField data={row.sumSells} /></TableCell>
                <TableCell size="small" className={classes.cell} align="right"><NumberField data={row.sumKv} /></TableCell>
              </TableRow>
            ))
          }
          <TableRow style={{ background: '#ccc' }}>
            <TableCell size="small" className={classes.cell}>ОБЩИЙ ИТОГ</TableCell>
            <TableCell size="small" className={classes.cell}></TableCell>
            <TableCell size="small" className={classes.cell} align="right"><TotalNumberField data={data} source="ddu" /></TableCell>
            <TableCell size="small" className={classes.cell} align="right"><TotalNumberField data={data} source="sumDdu" /></TableCell>
            <TableCell size="small" className={classes.cell} align="right"><TotalNumberField data={data} source="kv" /></TableCell>
            <TableCell size="small" className={classes.cell} align="right"><TotalNumberField data={data} source="rooms" /></TableCell>
            <TableCell size="small" className={classes.cell} align="right"><TotalNumberField data={data} source="sumSells" /></TableCell>
            <TableCell size="small" className={classes.cell} align="right"><TotalNumberField data={data} source="sumKv" /></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function NumberField(props: any) {
  const { data } = props;
  if (!data) {
    return null;
  }
  return <span>{Number(data).toLocaleString()}</span>;
}

function TotalNumberField(props: any) {
  const { data, source } = props;

  const sum = React.useMemo(() => {
    if (data && source) {
      return data.filter((d: any) => d.type === 'sum').reduce((m: number, d: any) => Number(d[source]) ? m + Number(d[source]) : m, 0);
    }
    return undefined;
  }, [data, source]);

  if (sum === undefined) {
    return null;
  }

  return <span>{Number(sum).toLocaleString()}</span>;
}