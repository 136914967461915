import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  ReferenceField,
  SelectInput,
} from 'react-admin';

// import Map, { MapView } from 'components/Map';
import { MapView, MapEdit } from 'components/map';

export const name = 'cities';

const CityListFilter = (props: any) => (
  <Filter {...props}>
    <TextInput source="name" />
    <ReferenceInput
      source="region"
      reference="regions"
      filterToQuery={(txt: string) => ({ name: txt })}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <BooleanInput source="active" />
  </Filter>
);

export const list = (props: any) => (
  <List {...props} bulkActionButtons={false} filters={<CityListFilter />}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <ReferenceField source="region" reference="regions" link="show">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="geo" />
      <BooleanField source="active" />
    </Datagrid>
  </List>
);

export const show = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <BooleanField source="active" />
      <TextField source="name" />
      <ReferenceField source="region" reference="regions" link="show">
        <TextField source="name" />
      </ReferenceField>
      <MapView source="geo" />
    </SimpleShowLayout>
  </Show>
);

export const create = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <BooleanInput source="active" />
      <TextInput source="name" />
      <ReferenceInput source="region" reference="regions" link="show">
        <SelectInput source="name" />
      </ReferenceInput>
      {/* <Map source="geo" /> */}
    </SimpleForm>
  </Create>
);

export const edit = (props: any) => (
  <Edit {...props}>
    <SimpleForm redirect="list">
      <BooleanInput source="active" />
      <TextInput source="name" />
      <ReferenceInput source="region" reference="regions" link="show">
        <SelectInput source="name" />
      </ReferenceInput>
      <MapEdit source="geo" />
    </SimpleForm>
  </Edit>
);
