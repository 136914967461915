import firebase from 'firebase/app';
import 'firebase/auth';
import React from 'react';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export const FbUsersResetPassword = (props: any) => {
  const { record } = props;
  const { fb_uid, email, fb_active } = record;
  const [resetting, setResetting] = React.useState(false);
  const classes = useStyles();

  const handleClick = React.useCallback(async () => {
    try {
      setResetting(true);
      await firebase.auth().sendPasswordResetEmail(record.email);
      // console.log('---reset password success---', record);
    } catch (ex) {
      console.log('---reset password failed---', record, ex);
    }
    setResetting(false);
  }, [record]);

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleClick}
      disabled={!fb_uid || !fb_active || !email}
    >
      Сбросить пароль
      <Backdrop open={resetting} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
    </Button>
  );
};
