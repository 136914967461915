import React from 'react';

// import Button from '@material-ui/core/Button';
// import { Link } from 'react-router-dom';

import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  Create,
  // SimpleForm,
  TextInput,
  Edit,
  // BooleanInput,
  NumberInput,
  // SelectField,
  SelectInput,
  // FilterList,
  // FilterListItem,
  // NumberField,
  // BooleanField,
  DateField,
  ReferenceInput,
  ReferenceManyField,
  // SingleFieldList,
  // ChipField,
  ReferenceField,
  FormWithRedirect,
  RadioButtonGroupInput,
  DateInput,
  SaveButton,
} from 'react-admin';

import {
  // Card as MuiCard,
  // CardContent,
  // withStyles,
  Typography,
  Box,
  Toolbar,
  // Paper,
} from '@material-ui/core';

import { ObjectStartSelectButton } from 'components/ObjectButton';

export const name = 'list2';

// const status = [
//   { id: '1', name: 'Новый Клиент' },
//   { id: '2', name: 'Презентация' },
//   { id: '3', name: 'Показ' },
//   { id: '4', name: 'Бронь' },
//   { id: '5', name: 'Сделка' },
// ];

// const Card = withStyles((theme) => ({
//   root: {
//     [theme.breakpoints.up('sm')]: {
//       order: -1, // display on the left rather than on the right of the list
//       width: '15em',
//       marginRight: '1em',
//     },
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
// }))(MuiCard);

// const StatusFilter = () => (
//   <FilterList label="Статус" icon="">
//     {status.map((s) => (
//       <FilterListItem label={s.name} key={s.id} value={{ status: s.id }} />
//     ))}
//   </FilterList>
// );

// const FilterSidebar = () => (
//   <Card>
//     <CardContent>
//       <StatusFilter />
//     </CardContent>
//   </Card>
// );

export const list = (props: any) => {
  const { permissions = {} } = props;
  const { role, office, user } = permissions;

  if (!role) {
    return null;
  }

  const filter: any = {};
  if (['admin', 'officehead'].includes(role)) {
    filter.office = office;
  } else {
    filter.manager = user;
  }
  return (
    // <List {...props} bulkActionButtons={false} aside={<FilterSidebar />} filter={filter} > 
    <List {...props} bulkActionButtons={false} filter={filter} >
      <Datagrid rowClick="show">
        {/* <SelectField source="status" choices={status} /> */}
        <ReferenceField source="office" reference="offices" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="manager" reference="users" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="name" />
        <DateField source="createdate" />
        <DateField source="lastupdate" />
      </Datagrid>
    </List>
  )
};

export const show = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      {/* <SelectField source="status" choices={status} /> */}
      <TextField source="name" />
      <ReferenceManyField
        label="Презентация"
        reference="roomadds"
        target="list"
        filter={{ status: '1' }}
      >
        <Datagrid rowClick="show">
          <ReferenceField
            source="complex"
            reference="complexes"
            link="show"
            label="ЖК"
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="price" label="Стоимость" />
          <TextField source="space" label="Площадь" />
          <TextField source="rt" label="Комнат" />
          <TextField source="floor" label="Этаж" />
          <TextField source="section" label="Секция" />
          <TextField source="number" label="Номер кв." />
          <DateField source="date" label="Дата презентации" />
        </Datagrid>
      </ReferenceManyField>
      <ReferenceManyField
        label="Показ"
        reference="roomadds"
        target="list"
        filter={{ status: '2' }}
      >
        <Datagrid rowClick="show">
          <ReferenceField
            source="complex"
            reference="complexes"
            link="show"
            label="ЖК"
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="price" label="Стоимость" />
          <TextField source="space" label="Площадь" />
          <TextField source="rt" label="Комнат" />
          <TextField source="floor" label="Этаж" />
          <TextField source="section" label="Секция" />
          <TextField source="number" label="Номер кв." />
          <DateField source="showDate" label="Дата показа" />
        </Datagrid>
      </ReferenceManyField>
      <ObjectStartSelectButton />
    </SimpleShowLayout>
  </Show>
);

const ResCreate = (props: any) => {
  const { permissions = {} } = props;
  const { office, user } = permissions;

  if (!office) {
    return null;
  }

  return (
    <Create {...props}>
      <ListForm
        permissions={props.permissions}
        defaultValue={{ office, manager: user, status: '1' }}
      />
    </Create>
  );
};

export const edit = (props: any) => (
  <Edit {...props}>
    <ListForm permissions={props.permissions} />
  </Edit>
);

export { ResCreate as create };

const placeChoises = [
  { id: '1', name: 'Ростов-на-Дону' },
  { id: '2', name: 'Область' },
  { id: '3', name: 'Регионы' },
];

const buyChoises = [
  { id: '1', name: 'Для проживания' },
  { id: '2', name: 'Для сдачи в аренду' },
  { id: '3', name: 'Для проживания детей' },
  { id: '4', name: 'Для инвестирования' },
  { id: '5', name: 'В целях сохранения денежных средств' },
];

const moneyChoises = [
  { id: '1', name: 'Наличные деньги' },
  { id: '2', name: 'Военная ипотека' },
  { id: '3', name: 'Рассрочка' },
  { id: '4', name: 'Материнский капитал' },
  { id: '5', name: 'Ипотека' },
  { id: '6', name: 'АЖП' },
];

const ipotekaChoises = [
  { id: '1', name: 'Наша' },
  { id: '2', name: 'Не наша' },
];

const timesChoises = [
  { id: '1', name: '1 месяц' },
  { id: '2', name: '< 3' },
  { id: '3', name: '> 3' },
];

const yesnoChoises = [
  { id: '1', name: 'Да' },
  { id: '2', name: 'Нет' },
];

const districtChoises = [
  { id: '1', name: 'Центр' },
  { id: '2', name: 'ЗЖМ' },
  { id: '3', name: 'Александровка' },
  { id: '4', name: 'Батайск' },
  { id: '5', name: 'Аксай' },
  { id: '6', name: 'СЖМ' },
  { id: '7', name: 'Сельмаш' },
  { id: '8', name: 'Суворовский' },
  { id: '9', name: 'Другой' },
];

const roomChoises = [
  { id: '1', name: 'Студия' },
  { id: '2', name: '1' },
  { id: '3', name: '2' },
  { id: '4', name: '3' },
  { id: '5', name: '4' },
];

const roomStateChoises = [
  { id: '1', name: 'Стройвариант' },
  { id: '2', name: 'Под чистовую' },
  { id: '3', name: '"Под ключ"' },
];

const validateListForm = (values: any) => {
  const errors: any = {};

  if (!values.name) {
    errors.name = ['Required'];
  }

  if (!values.phone) {
    errors.phone = ['Required'];
  }

  // if (!values.email) {
  //   errors.email = ['Required'];
  // }

  if (!values.manager) {
    errors.manager = ['Required'];
  }

  return errors;
};

function ListForm(props: any) {
  const { permissions = {} } = props;
  const { office, city } = permissions;

  if (!office) {
    return null;
  }

  return (
    <FormWithRedirect
      validate={validateListForm}
      redirect="list"
      {...props}
      render={(formProps: any) => (
        <form>
          <Box p="1em">
            {/* <SelectInput
              source="status"
              resource="list2"
              choices={status}
              required
            /> */}
            <Typography gutterBottom>Контактные данные:</Typography>
            <Box display="flex" style={{ borderBottom: '1px solid black' }}>
              <Box flex={2} mr="1em">
                <TextInput source="name" resource="list2" fullWidth />
                <TextInput source="phone" resource="list2" fullWidth />
                <TextInput source="email" resource="list2" fullWidth />
                {/* <TextInput source="manager" resource="list2" fullWidth /> */}
                <ReferenceInput
                  source="manager"
                  resource="list2"
                  reference="users"
                  perPage={100}
                  filter={{ office }}
                  fullWidth
                  disabled
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
                {/* <TextInput source="broker" resource="list2" fullWidth /> */}
                <ReferenceInput
                  source="broker"
                  resource="list2"
                  reference="users"
                  perPage={100}
                  filter={{ office, role: 'broker' }}
                  fullWidth
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
              </Box>
              <Box flex={1} mr="1em">
                <ReferenceInput
                  source="office"
                  resource="list2"
                  reference="offices"
                  filter={{ city }}
                  fullWidth
                  disabled
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
                <RadioButtonGroupInput
                  label=""
                  source="placeChoise"
                  resource="list2"
                  choices={placeChoises}
                  row={false}
                />
              </Box>
            </Box>
            <Box style={{ borderBottom: '1px solid black' }}>
              <RadioButtonGroupInput
                source="buyChoise"
                resource="list2"
                choices={buyChoises}
              />
            </Box>
            <Box display="flex" style={{ borderBottom: '1px solid black' }}>
              <Box
                flex={2}
                mr="0.4em"
                style={{ borderRight: '1px solid black' }}
              >
                <NumberInput source="money" resource="list2" fullWidth />
                <RadioButtonGroupInput
                  source="moneyChoise"
                  resource="list2"
                  choices={moneyChoises}
                />
              </Box>
              <Box flex={1}>
                <RadioButtonGroupInput
                  source="ipotekaChoise"
                  resource="list2"
                  choices={ipotekaChoises}
                />
                <TextInput source="bank" resource="list2" fullWidth />
                <NumberInput source="startMoney" resource="list2" fullWidth />
              </Box>
            </Box>
            <Box display="flex" style={{ borderBottom: '1px solid black' }}>
              <Box mr="1em" flex={1} style={{ borderRight: '1px solid black' }}>
                <RadioButtonGroupInput
                  source="timesChoise"
                  resource="list2"
                  choices={timesChoises}
                />
              </Box>
              <Box mr="1em" flex={1} style={{ borderRight: '1px solid black' }}>
                <RadioButtonGroupInput
                  source="needSaleChoise"
                  resource="list2"
                  choices={yesnoChoises}
                />
              </Box>
              <Box flex={1}>
                <RadioButtonGroupInput
                  source="secondRoomChoise"
                  resource="list2"
                  choices={yesnoChoises}
                />
              </Box>
            </Box>
            <Box style={{ borderBottom: '1px solid black' }}>
              <RadioButtonGroupInput
                source="districtChoise"
                resource="list2"
                choices={districtChoises}
              />
              <TextInput source="otherDistrict" resource="list2" fullWidth />
            </Box>
            <Box display="flex" style={{ borderBottom: '1px solid black' }}>
              <Box flex={1} style={{ borderRight: '1px solid black' }}>
                <RadioButtonGroupInput
                  source="roomChoise"
                  resource="list2"
                  choices={roomChoises}
                />
              </Box>
              <Box flex={1}>
                <Box display="flex">
                  <Box flex={1}>
                    <NumberInput
                      source="spaceFrom"
                      resource="list2"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1}>
                    <NumberInput source="spaceTo" resource="list2" fullWidth />
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={1}>
                    <NumberInput
                      source="floorFrom"
                      resource="list2"
                      fullWidth
                    />
                  </Box>
                  <Box flex={1}>
                    <NumberInput source="floorTo" resource="list2" fullWidth />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box display="flex" style={{ borderBottom: '1px solid black' }}>
              <Box flex={1} style={{ borderRight: '1px solid black' }}>
                <RadioButtonGroupInput
                  source="roomStateChoise"
                  resource="list2"
                  choices={roomStateChoises}
                />
              </Box>
              <Box flex={1}>
                <DateInput source="endDate" resource="list2" />
              </Box>
            </Box>
            <Typography gutterBottom>Дополнительные пожелания:</Typography>
            <TextInput
              source="innerInfrastructure"
              resource="list2"
              fullWidth
            />
            <TextInput
              source="outerInfrastructure"
              resource="list2"
              fullWidth
            />
          </Box>
          <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%">
              <SaveButton
                saving={formProps.saving}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              />
              {/* <DeleteButton record={formProps.record} /> */}
            </Box>
          </Toolbar>
        </form>
      )}
    />
  );
}
