import React from 'react';

import { useQueryWithStore, usePermissions } from 'react-admin';
import { useReactToPrint } from 'react-to-print';
// import TagBox from 'devextreme-react/tag-box';
// import Pdf from 'react-to-pdf';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

import Lightbox from 'react-image-lightbox';
import { Link } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import FormControl from '@material-ui/core/FormControl';
import Badge from '@material-ui/core/Badge';

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';

import calcRooms from 'components/RoomsGrid/calcrooms';
import { composeUrl } from 'libs/parseHelper';

import Map from './SearchDeatilsMap';
import { buildingClassChoises } from 'resources/building-res';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { composedRoomTypes } from 'search/SearchData';

export const compareKey = 'szpro::compare';


const visibileElementsData = [
  { id: 1, name: 'Информация об Офисе' },
  { id: 2, name: 'Информация об Эксперте' },
  { id: 3, name: 'Слоган' },
  { id: 4, name: 'Информация о ЖК' },
  { id: 5, name: 'Адрес' },
  { id: 6, name: 'Застройщик' },
  { id: 7, name: 'Стоимость' },
  { id: 8, name: 'Карта' },
  { id: 9, name: 'План этажа' },
  { id: 10, name: 'Характеристики' }
];

const useStyles = makeStyles((theme) => ({

  photosRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  photosGridList: {
    width: '100%',
    height: 250,
    overflow: 'hidden'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 350,
  },
}));

const SearchDetailsRoomInfo = (props: any) => {
  const { permissions } = usePermissions();
  const { office, user } = permissions || {};
  // const { onClose } = props;
  const classes = useStyles();
  const printRef = React.useRef<any>();
  // const dispatch = useDispatch();
  // const { selectedRoom } = useSelector((state: any) => state.search);
  const { selected: selectedRoom } = props;
  // const { list } = React.useContext(ObjectViewContext);
  const [openLightbox, setOpenLightbox] = React.useState(false);
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const [compareCount, setCompareCount] = React.useState(0);

  const [visibleElements, setVisibleElements] = React.useState<any>(visibileElementsData.map((el) => el.id));

  const theme = useTheme();
  const is_sm = useMediaQuery(theme.breakpoints.up('sm'));

  const handlePrint = useReactToPrint({
    content: () => printRef?.current,
  });

  // const handlePDF = async () => {
  //   const canvas = await html2canvas(printRef.current, {
  //     windowWidth: printRef.current.scrollWidth,
  //     windowHeight: printRef.current.scrollHeight
  //   });
  //   const imgData = canvas.toDataURL('image/png');
  //   const pdf = new jsPDF();
  //   pdf.addImage(imgData, 'PNG', 0, 0);
  //   pdf.save('room.pdf');
  // }

  const [building, section, floor, rpf, room_id] = (selectedRoom || '').split('_');

  const { data: userData } = useQueryWithStore({
    type: 'getOne',
    resource: user ? 'users' : '',
    payload: { id: user }
  });

  const { data: officeData } = useQueryWithStore({
    type: 'getOne',
    resource: office ? 'offices' : '',
    payload: { id: office }
  });

  const { data: officeCityData } = useQueryWithStore({
    type: 'getOne',
    resource: officeData?.city ? 'cities' : '',
    payload: { id: officeData?.city }
  });

  const { data: buildingData } = useQueryWithStore({
    type: 'getOne',
    resource: building ? 'buildings' : '',
    payload: { id: building }
  });

  const { data: sectionData } = useQueryWithStore({
    type: 'getOne',
    resource: section && section !== '0' ? 'entrances' : '',
    payload: { id: section }
  });

  const { data: complexData } = useQueryWithStore({
    type: 'getOne',
    resource: section ? 'complexes' : '',
    payload: { id: buildingData?.complex }
  });

  const { data: districtData } = useQueryWithStore({
    type: 'getOne',
    resource: complexData?.district ? 'districts' : '',
    payload: { id: complexData?.district }
  });

  const { data: developerData } = useQueryWithStore({
    type: 'getOne',
    resource: complexData?.developer ? 'developers' : '',
    payload: { id: complexData?.developer }
  });

  const { data: singleRoomData } = useQueryWithStore({
    type: 'getOne',
    resource: room_id ? 'rooms' : '',
    payload: { id: room_id }
  });

  const endDate = React.useMemo(() => {
    try {
      return format(parseISO(buildingData?.dateOfConstruction), 'Q кв. yyyy г.');
    } catch (err) {

    }
    return '';
  }, [buildingData]);

  const buildingPhotos = React.useMemo(() => {
    if (buildingData && Array.isArray(buildingData.photo)) {
      return { f: buildingData.photo[0]?.src, s: buildingData.photo[1]?.src };
    }
    return {};
  }, [buildingData])

  const roomInfo: any = React.useMemo(() => {
    // console.log('---roomInfo calc---', sectionData, singleRoomData);
    if (sectionData && sectionData?.id) {
      const rooms = calcRooms(sectionData);
      return rooms.find(r => Number(r.floor) === Number(floor) && Number(r.rpf) === Number(rpf)) || {};
    } else if (singleRoomData) {
      let photos: any = [];
      // if (singleRoomData.roomPhoto) {
      //   photos = [...photos, ...singleRoomData.roomPhoto];
      // }
      if (singleRoomData.planPhoto) {
        photos = [...photos, ...singleRoomData.planPhoto];
      }
      let price = singleRoomData.priceFrom;
      if (singleRoomData.priceTo && singleRoomData.priceFrom !== singleRoomData.priceTo) {
        price = `${Number(singleRoomData.priceFrom).toLocaleString()} - ${Number(singleRoomData.priceTo).toLocaleString()}`
      } else {
        price = `${Number(singleRoomData.priceFrom).toLocaleString()}`;
      }

      let space = singleRoomData.spaceFrom;
      if (singleRoomData.priceTo && singleRoomData.spaceFrom !== singleRoomData.spaceTo) {
        space = `${singleRoomData.spaceFrom} - ${singleRoomData.spaceTo}`
      } else {
        space = `${singleRoomData.spaceFrom}`;
      }

      return {
        rpf: 0,
        floor: 0,
        number: 0,
        floorPhoto: null,
        photo: photos[0],
        type: singleRoomData.type,
        price: singleRoomData.priceFrom,
        space: singleRoomData.spaceFrom,
        priceRange: price,
        spaceRange: space,
        rt: composedRoomTypes[singleRoomData.type || 'unknown'],
      }
    }
    return {};
  }, [sectionData, floor, rpf, singleRoomData]);

  const [ipotekaFirst, setIpoteksFirst] = React.useState('');
  const [ipotekaPercent, setIpotekaPercent] = React.useState('');
  const [ipotekaMonths, setIpotekaMonths] = React.useState('');

  const handleFirst = (ev: any) => {
    setIpoteksFirst(ev.target.value);
  };

  const handlePercent = (ev: any) => {
    setIpotekaPercent(ev.target.value);
  };

  const handleMonths = (ev: any) => {
    setIpotekaMonths(ev.target.value);
  };

  const ipotekaPerMonth = React.useMemo(() => {
    const s = Number(roomInfo?.price) - Number(ipotekaFirst);
    const i = Number(ipotekaPercent) / 12 / 100;
    const n = Number(ipotekaMonths);
    const k = (i * Math.pow(1 + i, n)) / (Math.pow(1 + i, n) - 1);
    const a = k * s;
    if (!a || isNaN(a) || a === Infinity) {
      return '';
    }
    return a;
  }, [roomInfo, ipotekaFirst, ipotekaPercent, ipotekaMonths]);

  const allPhotos = React.useMemo(() => {
    let ret: any = [];
    if (roomInfo?.photo) {
      ret = [...ret, roomInfo?.photo];
    }
    if (roomInfo?.floorPhoto) {
      ret = [...ret, roomInfo?.floorPhoto];
    }
    if (buildingData && Array.isArray(buildingData.photo)) {
      ret = [...ret, ...buildingData.photo]
    }
    return ret.map((p: any) => p.src);
  }, [buildingData, roomInfo]);

  const handleAddComparison = () => {
    const obj = { room: selectedRoom, ipoteka: { ipotekaFirst, ipotekaPercent, ipotekaMonths, ipotekaPerMonth } };
    let storedObj: any = {};
    try {
      const stored = localStorage.getItem(compareKey);
      storedObj = JSON.parse(stored ?? '');
    } catch (ex) { }

    storedObj[selectedRoom] = obj;
    localStorage.setItem(compareKey, JSON.stringify(storedObj));
  }

  React.useEffect(() => {
    let timer: any;
    const checkStorage = () => {
      try {
        const data = localStorage.getItem(compareKey);
        const parsed = JSON.parse(data ?? '');
        setCompareCount(Object.keys(parsed).length);
      } catch (ex) {
        setCompareCount(0);
      }
      timer = setTimeout(() => {
        checkStorage();
      }, 2000);
    }

    checkStorage();

    return () => {
      clearTimeout(timer);
    }
  }, []);

  return (
    <div>
      {
        is_sm && (
          <Card style={{ margin: '10px 0' }}>
            <CardContent>
              <Typography>Ипотечный калькулятор:</Typography>
              <FormControl className={classes.formControl}>
                <TextField
                  label="Первый взнос, руб"
                  onChange={handleFirst}
                  value={ipotekaFirst}
                />
              </FormControl>
              {/* <FormControl className={classes.formControl}>
                <TextField
                  label="Процентная ставка, %"
                  onChange={handlePercent}
                  value={ipotekaPercent}
                  type="number"
                />
              </FormControl> */}
              <FormControl className={classes.formControl}>
                <TextField
                  label="Срок кредитования, мес"
                  onChange={handleMonths}
                  value={ipotekaMonths}
                />
              </FormControl>
            </CardContent>
          </Card>
        )
      }
      {
        is_sm && (
          <Card style={{ marginBottom: 10 }}>
            <CardContent>
              <Button variant="contained" color="primary" onClick={handlePrint} style={{ marginRight: 5 }}>
                Печать
              </Button>
              <Button variant="contained" color="primary" onClick={handleAddComparison} style={{ marginRight: 5 }}>
                Добавить к сравнению
              </Button>
              <Badge badgeContent={compareCount} color="secondary">
                <Button variant="contained" component={Link} to="/compare" target="_blank" color="primary" style={{ marginRight: 5 }} disabled={compareCount === 0}>
                  Сравнение
              </Button>
              </Badge>
              <a href={`${process.env.REACT_APP_VIEW_URL}/roominfo?id=${selectedRoom}::${office}::${user ?? ''}::${btoa(JSON.stringify(visibleElements))}`} target="_blank" rel="noreferrer">Ссылка</a>
              <a href={`${process.env.REACT_APP_PDF_URL}/pdf_api?url=${process.env.REACT_APP_VIEW_URL}/roominfo?id=${selectedRoom}::${office}::${user ?? ''}::${btoa(JSON.stringify(visibleElements))}`} target="_blank" rel="noreferrer" style={{ marginLeft: 5 }}>PDF</a>
              <div>
                <FormControl style={{ maxWidth: '100%' }}>
                  <InputLabel>Видимые элементы</InputLabel>
                  <Select
                    value={visibleElements}
                    onChange={(ev: any) => setVisibleElements(ev.target.value)}
                    input={<Input />}
                    multiple
                  >
                    {visibileElementsData?.map((d: any) => (
                      <MenuItem key={d.id} value={d.id}>
                        {d.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </CardContent>
          </Card>
        )
      }
      <div ref={printRef}>
        <Card
          className="sheet"
          style={{ overflowY: 'scroll', boxShadow: 'none', borderRadius: 0 }}
        >
          <CardContent>
            <Grid container>
              <Grid item xs={5}>
                <Typography>
                  Адрес: {officeCityData?.name}
                </Typography>
                <Typography>
                  {officeData?.address}
                </Typography>
                <Typography>
                  Телефон: {officeData?.phone}
                </Typography>
                <Typography>
                  Email: {officeData?.email}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <img src={officeData?.logo?.src ?? "/logo.jpg"} alt="logo" style={{ maxHeight: 100 }} />
              </Grid>
              <Grid item xs={5}>
                <Typography>Эксперт по недвижимости</Typography>
                <Typography>{userData?.name}</Typography>
                <Typography>телефон: {userData?.phone}</Typography>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>Федеральный оператор жилой недвижимости</Typography>
                <Typography variant="h5" style={{ fontWeight: 'bold' }}>СОЮЗ ЗАСТРОЙЩИКОВ</Typography>
                <Typography variant="h5" style={{ fontWeight: 'bold' }}>№1</Typography>
              </Grid>
            </Grid>
          </CardContent>
          <Typography style={{ textAlign: 'center' }}>Презентация подготовлена - {format(Date.now(), 'dd.MM.yyyy')}</Typography>
          <Typography style={{ textAlign: 'left', fontWeight: 'bold', marginLeft: 20 }}>Информация о ЖК</Typography>
          <CardContent>
            <Grid container>
              <Grid item xs={6}>
                <div className={classes.photosRoot}
                  onClick={() => {
                    setPhotoIndex(2);
                    setOpenLightbox(true)
                  }}
                >
                  <GridList
                    cellHeight={250}
                    className={classes.photosGridList}
                    cols={2}
                  >
                    <GridListTile>
                      {
                        buildingPhotos.f &&
                        <img src={composeUrl(buildingPhotos.f, '600')} alt="f" />
                      }
                    </GridListTile>
                    <GridListTile>
                      {
                        buildingPhotos.s &&
                        <img src={composeUrl(buildingPhotos.s, '600')} alt="s" />
                      }
                    </GridListTile>
                  </GridList>
                </div>
              </Grid>
              <Grid item xs={6}>
                <List>
                  <ListItem>
                    <Typography>
                      <b>Застройщик:</b> {developerData?.name}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      <b>ЖК:</b> {complexData?.name}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      <b>Район:</b> {districtData?.name}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      <b>Адрес:</b> {buildingData?.address}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      <b>Литер:</b> {buildingData?.name}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>
                      <b>Срок сдачи:</b> {endDate}
                    </Typography>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </CardContent>
          <Typography style={{ textAlign: 'left', fontWeight: 'bold', marginLeft: 20 }}>Информация о квартире</Typography>
          <CardContent>
            <Grid container>
              <Grid item xs={6}>
                {/* <div className={classes.photosRoot}>
                <GridList
                  cellHeight={300}
                  className={classes.photosGridList}
                  cols={1}
                >
                  <GridListTile>
                    {
                      roomInfo?.photo?.src &&
                      <img src={roomInfo?.photo?.src} alt="room" />
                    }
                  </GridListTile>
                  <GridListTile>
                    {
                      roomInfo?.floorPhoto?.src &&
                      <img src={roomInfo?.floorPhoto?.src} alt="floor" />
                    }
                  </GridListTile>
                </GridList>
              </div> */}
                <CardMedia onClick={() => {
                  setPhotoIndex(0);
                  setOpenLightbox(true)
                }}>
                  {
                    roomInfo?.photo?.src &&
                    <img src={composeUrl(roomInfo?.photo?.src, '600')} alt="room" style={{ maxWidth: '100%', maxHeight: 300 }} />
                  }
                </CardMedia>
                {/* {
                roomInfo?.photo?.src &&
                <img src={composeUrl(roomInfo?.photo?.src, '600')} alt="room" style={{ maxWidth: '100%', maxHeight: 300 }} />
              } */}
                {/* <img src="https://firebasestorage.googleapis.com/v0/b/rooms-232b0.appspot.com/o/migrated%2F088ysYURVUMUYHTDTWVx-Ro0.png?alt=media" alt="room" width="100" height="100" /> */}
              </Grid>
              <Grid item xs={6}>
                {
                  room_id ? (
                    <List>
                      <ListItem>
                        <Typography>
                          <b>Количество комнат:</b> {roomInfo?.rt}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          <b>Площадь:</b> {roomInfo?.spaceRange} кв.м.
                        </Typography>
                      </ListItem>
                    </List>
                  ) : (
                    <List>
                      <ListItem>
                        <Typography>
                          <b>Секция:</b> {sectionData?.number}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          <b>Этаж:</b> {floor}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          <b>Номер квартиры:</b> {roomInfo?.number}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          <b>Количество комнат:</b> {roomInfo?.rt}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          <b>Площадь:</b> {roomInfo?.space} кв.м.
                  </Typography>
                      </ListItem>
                    </List>
                  )
                }
              </Grid>
            </Grid>
          </CardContent>
          <CardContent>
            <Grid container>
              <Grid item xs={6}>
                {
                  room_id ? (
                    <List>
                      <ListItem>
                        <Typography>
                          <b>Стоимость:</b> {roomInfo.priceRange} руб.
                  </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          <b>Цена за кв.м.:</b> от  {roomInfo?.space && Number(roomInfo?.price / roomInfo?.space).toLocaleString()} руб/кв.м.
                  </Typography>
                      </ListItem>
                    </List>
                  ) : (
                    <List>
                      <ListItem>
                        <Typography>
                          <b>Стоимость:</b> {Number(roomInfo?.price).toLocaleString()} руб.
                  </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          <b>Цена за кв.м.:</b> {roomInfo?.space && Number(roomInfo?.price / roomInfo?.space).toLocaleString()} руб/кв.м.
                  </Typography>
                      </ListItem>
                    </List>
                  )
                }

              </Grid>
              <Grid item xs={6}>
                <Typography>
                  Ипотека, аккредитация
                </Typography>
                {
                  Boolean(ipotekaFirst && ipotekaMonths) && (
                    <>
                      <ListItem>
                        <Typography>
                          <b>Первоначальный взнос:</b>{' '}
                          <span>
                            {Number(ipotekaFirst).toLocaleString()} руб.
                      </span>
                        </Typography>
                      </ListItem>
                      <IpotekaTop3 ipotekaFirst={ipotekaFirst} roomInfo={roomInfo} ipotekaMonths={ipotekaMonths} />
                    </>
                  )
                }

                {/* <Typography>{complexData?.banksAccreditation}</Typography> */}
                {/* {ipotekaPerMonth && (
                  <>
                    <ListItem>
                      <Typography>
                        <b>Первоначальный взнос:</b>{' '}
                        <span>
                          {Number(ipotekaFirst).toLocaleString()} руб.
                        </span>
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        <b>Ежемесячный платеж:</b>{' '}
                        <span>
                          {Number(ipotekaPerMonth.toFixed(2)).toLocaleString()}{' '}
                          руб.
                        </span>
                      </Typography>
                    </ListItem>
                  </>
                )} */}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card
          className="sheet"
          style={{ overflowY: 'scroll', boxShadow: 'none', borderRadius: 0 }}
        >
          <CardContent>
            <Typography style={{ textAlign: 'left', fontWeight: 'bold' }}>Адрес Объекта</Typography>
            {complexData?.geo && <Map value={complexData?.geo} name="" zoom="16" height={200} />}
          </CardContent>
          <CardContent onClick={() => {
            setPhotoIndex(1);
            setOpenLightbox(true)
          }}>
            <Typography style={{ textAlign: 'left', fontWeight: 'bold' }}>План этажа</Typography>
            {
              roomInfo?.floorPhoto?.src &&
              <img src={composeUrl(roomInfo?.floorPhoto?.src, '600')} alt="floor" style={{ maxWidth: '100%', maxHeight: 300 }} />
            }
          </CardContent>
          <CardContent>
            <Typography><b>Характеристики:</b></Typography>
            <List>
              <ListItem>
                <Typography>
                  Технология строительства: <BuildingTechnology id={buildingData?.buildingTechnology} />
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  Количество этажей: {buildingData?.floors}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  Высота потолков: {buildingData?.ceilingHeight}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  Состояние на момент сдачи: <BuildingState id={buildingData?.buildingState} />
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  Количество лифтов: {buildingData?.passengerLift}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  Количество квартир на этаже: {buildingData?.roomNumbersPerFloor}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  Количество подъездов: {buildingData?.entrancesNumber}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  Консьерж: {buildingData?.concierge ? 'Да' : 'Нет'}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  Остекление балконов: {buildingData?.glassing ? 'Да' : 'Нет'}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  Класс дома: <BuildingClass id={buildingData?.buildingClass} />
                </Typography>
              </ListItem>
            </List>
            {/* <div>
              <pre>
                {JSON.stringify(singleRoomData, null, ' ')}
              </pre>
            </div>
            <div>
              <pre>
                {JSON.stringify(roomInfo, null, ' ')}
              </pre>
            </div> */}
          </CardContent>
        </Card>
      </div>
      {
        openLightbox &&
        <Lightbox
          mainSrc={allPhotos[photoIndex]}
          nextSrc={allPhotos[(photoIndex + 1) % allPhotos.length]}
          prevSrc={allPhotos[(photoIndex + allPhotos.length - 1) % allPhotos.length]}
          onCloseRequest={() => setOpenLightbox(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + allPhotos.length - 1) % allPhotos.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % allPhotos.length)
          }
        />
      }
    </div>
  )
}

export default SearchDetailsRoomInfo;


function BuildingTechnology(props: any) {
  // return <span></span>;
  const { id } = props;
  const { data, loaded } = useQueryWithStore({
    type: 'getOne',
    resource: id ? 'buildingtechnologies' : '',
    payload: { id }
  });

  if (!id || !loaded) {
    return null;
  }

  return <span>{data?.name}</span>;
}

function BuildingState(props: any) {
  // return <span></span>;
  const { id } = props;
  const { data, loaded } = useQueryWithStore({
    type: 'getMany',
    resource: id ? 'buildingstates' : '',
    payload: { ids: id }
  });

  if (!id || !loaded) {
    return null;
  }

  const names = Array.isArray(data) ? data.map((d: any) => d.name).join(', ') : '';

  return <span>{names}</span>;
}

function BuildingClass(props: any) {
  const { id } = props;
  if (!id) {
    return null;
  }
  const found = buildingClassChoises.find(b => b.id === id);

  if (!found) {
    return null;
  }

  return <span>{found?.name}</span>
}

function IpotekaTop3(props: any) {
  const { data: banksData } = useQueryWithStore({
    type: 'getList',
    resource: 'banks',
    payload: { pagination: { page: 1, perPage: 1000 }, filter: { top3: true }, sort: { field: 'id', order: 'ASC' } }
  });

  const { data: iprogramData } = useQueryWithStore({
    type: 'getList',
    resource: 'iprograms',
    payload: { pagination: { page: 1, perPage: 1000 }, filter: { program: 1 }, sort: { field: 'id', order: 'ASC' } }
  });

  if (!banksData || !iprogramData) {
    return null;
  }

  return (
    <>
      <Typography>
        <b>
          <Grid container>
            <Grid item xs={4}>
              Банк
        </Grid>
            <Grid item xs={2}>
              %
        </Grid>
            <Grid item xs={3}>
              срок, мес.
        </Grid>
            <Grid item xs={3}>
              руб в мес.
        </Grid>
          </Grid>
        </b>
      </Typography>
      {
        banksData.map((b: any) => <IpotekaBank key={b.id} {...b} {...props} iprogramData={iprogramData} />)
      }
    </>
  )
}

function IpotekaBank(props: any) {
  const { ipotekaFirst, roomInfo, ipotekaMonths, id, name, logo, iprogramData } = props;

  const program = React.useMemo(() => iprogramData?.find((p: any) => p.bank === id), [iprogramData, id]);

  const percent = React.useMemo(() => Number(`${program?.stavka}`.replace(',', '.')), [program]);

  const ipotekaPerMonth = React.useMemo(() => {
    const s = Number(roomInfo?.price) - Number(ipotekaFirst);
    const i = Number(percent) / 12 / 100;
    const n = Number(ipotekaMonths);
    const k = (i * Math.pow(1 + i, n)) / (Math.pow(1 + i, n) - 1);
    const a = k * s;
    if (!a || isNaN(a) || a === Infinity) {
      return '';
    }
    // return a;
    return Number(a.toFixed(2)).toLocaleString();
  }, [roomInfo, ipotekaFirst, ipotekaMonths, percent]);

  return (
    //  <div>{name} - {percent} - {ipotekaPerMonth}</div>
    <Typography>
      <Grid container>
        <Grid item xs={4}>
          {name}
        </Grid>
        <Grid item xs={2}>
          {percent}
        </Grid>
        <Grid item xs={3}>
          {ipotekaMonths}
        </Grid>
        <Grid item xs={3}>
          {ipotekaPerMonth}
        </Grid>
      </Grid>
    </Typography>
  )
}