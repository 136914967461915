import firebase from 'firebase/app';
import 'firebase/firestore';

export const impersonalKey = 'szpro::impersonal';

export const extKey = 'ra::rooms::extKey';
const storage = localStorage;

let cache: any;

const customAuthProvider = (fbAuthProvider: any) => {
  return {
    ...fbAuthProvider,
    login: async ({ username, password }: any) => {
      const result: any = await fbAuthProvider.login({ username, password });
      // const { user } = result;

      // let extObj = {};
      // const db = firebase.firestore();
      // let qUser;
      // const userDocs = await db
      //   .collection('users')
      //   .where('fb_uid', '==', user.uid)
      //   .get();
      // userDocs.forEach((doc) => {
      //   qUser = { ...doc.data(), id: doc.id };
      // });

      // if (qUser) {
      //   const { office, id, name: userName } = qUser;
      //   extObj = { ...extObj, office, user: id, userName };
      //   if (office) {
      //     const officeDoc = await db.collection(`offices`).doc(office).get();
      //     if (officeDoc) {
      //       const officeData: any = officeDoc.data();
      //       const { city, attachedCity, blockedCity, name: officeName } =
      //         officeData || {};
      //       extObj = {
      //         ...extObj,
      //         office,
      //         city,
      //         attachedCity,
      //         blockedCity,
      //         officeName,
      //       };
      //     }
      //   }
      // }
      // storage.setItem(extKey, JSON.stringify(extObj));
      Promise.resolve(result);
    },
    logout: (params: any) => {
      storage.removeItem(extKey);
      cache = undefined;
      return fbAuthProvider.logout(params);
    },
    getPermissions: async (params: any) => {
      const permissions = await fbAuthProvider.getPermissions(params);
      const { email, user_id, role } = permissions;
      // console.log('---permissions---', permissions);
      if (!user_id) {
        return Promise.reject(null);
      }
      let impersonalId;
      if (role === 'admin' || email === 'andrei.bakaev@gmail.com') {
        try {
          impersonalId = localStorage.getItem(impersonalKey);
        } catch (ex) {}
      }

      let extData: any = {};
      if (!cache) {
        let extObj: any = {};
        const db = firebase.firestore();
        let qUser: any;
        if (impersonalId) {
          const doc = await db.collection('users').doc(impersonalId).get();
          qUser = { ...doc.data(), id: doc.id };
          // console.log('---impersonal---', qUser);
        } else {
          const userDocs = await db
            .collection('users')
            .where('fb_uid', '==', user_id)
            .get();
          userDocs.forEach((doc) => {
            qUser = { ...doc.data(), id: doc.id };
          });
        }

        if (qUser) {
          const { office, id, name: userName, spr } = qUser;
          extObj = { ...extObj, office, user: id, userName };
          if (office) {
            const officeDoc = await db.collection(`offices`).doc(office).get();
            if (officeDoc) {
              const officeData: any = officeDoc.data();
              const { city, attachedCity, blockedCity, name: officeName } =
                officeData || {};
              extObj = {
                ...extObj,
                office,
                city,
                attachedCity,
                blockedCity,
                officeName,
                spr,
              };
            }
          }
          if (impersonalId) {
            extObj.role = qUser.role;
            extObj.email = qUser.email;
          }
        }
        cache = extObj;
      } else {
        // console.log('---cached---', cache);
      }

      extData = { ...extData, ...cache };

      if (!impersonalId) {
        if (email === 'andrei.bakaev@gmail.com') {
          extData.role = 'admin';
          extData.office = '56efa528631ec81c2d74ad40';
        }

        if (email === 'admin@office2.szpro.ru') {
          extData.role = 'guest';
          extData.office = '56efa528631ec81c2d74ad40';
          extData.user = '5afa9200072748299739a044';
        }

        // if (email === 'admin@office2.szpro.ru') {
        //   extData.role = 'user';
        //   extData.office = '56efa528631ec81c2d74ad40';
        //   extData.user = '5afa9200072748299739a044';
        // }

        // if (email === 'admin@office2.szpro.ru') {
        //   extData.role = 'cityhead';
        //   extData.city = '56cd7dd94fd597460a9064c5';
        //   extData.office = '56f00191631ec81c2d74ad82';
        //   extData.user = '56f41e4e631ec81c2d74aeac';
        // }

        if (!extData.city) {
          extData.city = '56cd7da14fd597460a9064c1';
        }
        if (!extData.office) {
          extData.office = '56efa528631ec81c2d74ad40';
        }
      }
      // console.log('---extData---', extData);
      const authObj = { ...permissions, ...extData };
      return Promise.resolve(authObj);

      // let extData: any = {};
      // try {
      //   extData = JSON.parse(storage.getItem(extKey) ?? '');
      // } catch (ex) {}
      // const permissions = await fbAuthProvider.getPermissions(params);
      // const { email } = permissions;
      // if (email === 'andrei.bakaev@gmail.com') {
      //   extData.role = 'admin';
      //   extData.office = '56efa528631ec81c2d74ad40';
      // }

      // if (email === 'admin@office2.szpro.ru') {
      //   extData.role = 'user';
      //   extData.office = '56efa528631ec81c2d74ad40';
      //   extData.user = '5afa9200072748299739a044';
      // }

      // if (!extData.city) {
      //   extData.city = '56cd7da14fd597460a9064c1';
      // }
      // if (!extData.office) {
      //   extData.office = '56efa528631ec81c2d74ad40';
      // }
      // const authObj = { ...permissions, ...extData };
      // return Promise.resolve(authObj);
    },
    getIdentity: () => {
      // try {
      //   const obj = storage.getItem(extKey);
      //   const json = JSON.parse(obj || '');
      //   const { user, email, role } = json;
      //   if (!email || !role) {
      //     return { id: '', fullName: '', avatar: null };
      //   }
      //   return { id: user, fullName: `${email} (${role})` };
      // } catch (ex) {}

      return { id: '', fullName: '', avatar: null };
    },
  };
};

export default customAuthProvider;

// expert39.z@souz-zastroi.ru
