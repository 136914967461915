import React from 'react';

import { parse } from 'query-string';

// import { parseArray } from 'libs/parseHelper';

import {
  List,
  Datagrid,
  TextField,
  Show,
  Create,
  TextInput,
  Edit,
  DateInput,
  DateField,
  ImageField,
  ImageInput,
  SingleFieldList,
  ChipField,
  SimpleShowLayout,
  NumberField,
  BooleanField,
  ReferenceField,
  ReferenceArrayField,
  SimpleForm,
  NumberInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  FileField,
  FileInput,
  ReferenceManyField,
  TabbedForm,
  FormTab,
  ArrayInput,
  SimpleFormIterator
} from 'react-admin';

import { Box } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import { MapEdit, MapView } from 'components/map';

export const name = 'buildings';

export const buildingClassChoises = [
  { id: '1', name: 'Бизнес' },
  { id: '2', name: 'Многоквартирный' },
  { id: '3', name: 'Элит' },
  { id: '4', name: 'Премьер' },
  { id: '5', name: 'Комфорт' },
];

export const list = (props: any) => (
  <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <ReferenceField source="complex" reference="complexes" link="show">
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export const show = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <BooleanField source="active" />
      <BooleanField source="done" />
      <NumberField source="readypercent" />
      <TextField source="name" />
      <ReferenceField source="complex" reference="complexes" link="show">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="dateOfConstruction" />
      <DateField source="realDateOfConstruction" />
      <ImageField source="photo" src="src" title="title" />
      <ReferenceArrayField reference="materials" source="materials">
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceField source="heating" reference="heatings">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="canalization" reference="canalizations">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceArrayField reference="buildingstates" source="buildingState">
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceField
        source="buildingTechnology"
        reference="buildingtechnologies"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="floors" />
      <TextField source="ceilingHeight" />
      <ReferenceField source="liftBrand" reference="liftbrands">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="freightLift" />
      <NumberField source="passengerLift" />
      <TextField source="address" />
      <NumberField source="buildingWallWidth" />
      <NumberField source="roomWallWidth" />
      <TextField source="feature" />
      <NumberField source="roomNumbersPerFloor" />
      <NumberField source="entrancesNumber" />
      <BooleanField source="technicalFloor" />
      <BooleanField source="concierge" />
      <BooleanField source="glassing" />
      <MapView source="geo" zoom="16" />
      <FileField source="docs" src="src" title="title" />
      <TextField source="info" />
      <ReferenceManyField reference="entrances" target="building" label="Секции">
        <SingleFieldList linkType="show">
          <ChipField source="number" />
        </SingleFieldList>
      </ReferenceManyField>
      <CreateNewEntranceButton />
      <ReferenceManyField reference="rooms" target="building" label="Квартиры" sort={{ field: 'spaceFrom', order: 'ASC' }}>
        <Datagrid rowClick="edit">
          <BooleanField source="active" />
          <ReferenceField source="type" reference="roomtypes" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <NumberField source="priceFrom" />
          <NumberField source="priceTo" />
          <NumberField source="spaceFrom" />
          <NumberField source="spaceTo" />
          <TextField source="floor" />
          <TextField source="entrance" />
          <TextField source="number" />
          <TextField source="numberOnFloor" />
          <BooleanField source="typical" />
        </Datagrid>
      </ReferenceManyField>
      <CreateNewRoomButton />
    </SimpleShowLayout>
  </Show>
);

export const create = (props: any) => {

  const { complex } = parse(props.location.search);
  const redirect = complex ? `/complexes/${complex}/show` : 'show';

  return (
    <Create {...props}>
      <SimpleForm defaultValue={{ complex }} redirect={redirect}>
        <BooleanInput source="active" />
        <BooleanInput source="done" />
        <NumberInput source="readypercent" />
        <TextInput source="name" />
        <ReferenceInput source="complex" reference="complexes" perPage={100}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <DateInput source="dateOfConstruction" />
        <DateInput source="realDateOfConstruction" />
        <ImageInput source="photo" multiple>
          <ImageField source="src" title="title" />
        </ImageInput>
        <ReferenceArrayInput
          reference="materials"
          source="materials"
          perPage={100}
          fullWidth
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceInput source="heating" reference="heatings" perPage={100}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          source="canalization"
          reference="canalizations"
          perPage={100}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceArrayInput
          reference="buildingstates"
          source="buildingState"
          perPage={100}
          fullWidth
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceInput
          source="buildingTechnology"
          reference="buildingtechnologies"
          perPage={100}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="floors" />
        <TextInput source="ceilingHeight" />
        <ReferenceInput source="liftBrand" reference="liftbrands" perPage={100}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="freightLift" />
        <NumberInput source="passengerLift" />
        <TextInput source="address" />
        <NumberInput source="buildingWallWidth" />
        <NumberInput source="roomWallWidth" />
        <TextInput source="feature" />
        <NumberInput source="roomNumbersPerFloor" />
        <NumberInput source="entrancesNumber" />
        <BooleanInput source="technicalFloor" />
        <BooleanInput source="concierge" />
        <BooleanInput source="glassing" />
        <MapEdit source="geo" zoom="16" />
        <FileInput source="docs" multiple>
          <FileField source="src" title="title" />
        </FileInput>
        <TextInput source="info" fullWidth multiline />
        <SelectInput source="buildingClass" choices={buildingClassChoises} />
      </SimpleForm>
    </Create>
  )
};


export const BuildingEdit = (props: any) => {
  const transform = async (data: any) => {
    // await parseArray(data.photo);
    // await parseArray(data.docs);
    return data;
  }

  const { hasShow, ...otherProps } = props;

  return (
    <Edit {...otherProps} transform={transform}>
      <TabbedForm redirect="show">
        <FormTab label="Информация">
          <BooleanInput source="active" />
          <BooleanInput source="done" />
          <NumberInput source="readypercent" />
          <TextInput source="name" />
          <ReferenceInput source="complex" reference="complexes" perPage={100}>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <DateInput source="dateOfConstruction" />
          <DateInput source="realDateOfConstruction" />
          <ImageInput source="photo" multiple>
            <ImageField source="src" title="title" />
          </ImageInput>
          <ReferenceArrayInput
            reference="materials"
            source="materials"
            perPage={100}
            fullWidth
          >
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
          <ReferenceInput source="heating" reference="heatings" perPage={100}>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput
            source="canalization"
            reference="canalizations"
            perPage={100}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceArrayInput
            reference="buildingstates"
            source="buildingState"
            perPage={100}
            fullWidth
          >
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
          <ReferenceInput
            source="buildingTechnology"
            reference="buildingtechnologies"
            perPage={100}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput source="floors" />
          <TextInput source="ceilingHeight" />
          <ReferenceInput source="liftBrand" reference="liftbrands" perPage={100}>
            <SelectInput optionText="name" />
          </ReferenceInput>
          <NumberInput source="freightLift" />
          <NumberInput source="passengerLift" />
          <TextInput source="address" />
          <NumberInput source="buildingWallWidth" />
          <NumberInput source="roomWallWidth" />
          <TextInput source="feature" />
          <NumberInput source="roomNumbersPerFloor" />
          <NumberInput source="entrancesNumber" />
          <BooleanInput source="technicalFloor" />
          <BooleanInput source="concierge" />
          <BooleanInput source="glassing" />
          <MapEdit source="geo" zoom="16" />
          <FileInput source="docs" multiple>
            <FileField source="src" title="title" />
          </FileInput>
          <TextInput source="info" fullWidth multiline />
          <SelectInput source="buildingClass" choices={buildingClassChoises} />
        </FormTab>
        <FormTab label="Цены">
          <ArrayInput source="prices" label="">
            <SimpleFormIterator>
              <DateInput source="date" />
              <NumberInput source="c" />
              <NumberInput source="cSells" />
              <NumberInput source="1k" />
              <NumberInput source="1kSells" />
              <NumberInput source="2k" />
              <NumberInput source="2kSells" />
              <NumberInput source="3k" />
              <NumberInput source="3kSells" />
              <NumberInput source="4+" />
              <NumberInput source="4+Sells" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
};

export { BuildingEdit as edit };

function CreateNewEntranceButton(props: any) {
  const { record } = props;
  const { id } = record;

  return (
    <Button
      variant="contained"
      color="primary"
      component={Link as any}
      to={`/entrances/create?building=${id}`}
    >
      Добавить Секцию
    </Button>
  )
}

function CreateNewRoomButton(props: any) {
  const { record } = props;
  const { id } = record;

  return (
    <Button
      variant="contained"
      color="primary"
      component={Link as any}
      to={`/rooms/create?building=${id}`}
    >
      Добавить Квартиру
    </Button>
  )
}

// function ThumbImages(props: any) {
//   const { record } = props;
//   const { photo } = record;
//   return <div>
//     {
//       // Array.isArray(photo) && photo.map(p => <div key={p.src}>{composeUrl(p.src, '200')}</div>)
//       Array.isArray(photo) && photo.map(p => <img key={p.src} src={composeUrl(p.src, '300')} alt={p.title} style={{ height: 300 }} />)
//     }

//   </div>
// }

// function composeUrl(src: string, thumb: string) {
//   const sep = '%2F';
//   const arr0 = src.split('?');
//   const arr1 = arr0[0].split(sep);
//   const index = arr1.pop();
//   arr1.push('thumbs');
//   arr1.push(`${index}_${thumb}x${thumb}`);
//   arr0[0] = arr1.join(sep);
//   return arr0.join('?');
// }
