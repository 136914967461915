// import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  Filter,
  ReferenceInput,
  BooleanInput,
  SelectInput,
  FunctionField
} from 'react-admin';

export const name = 'amo_pipelines';



const ResList = (props: any) => {
  const { permissions } = props;
  const { city } = permissions || {};

  if (!city) {
    return null;
  }

  return (
    <List
      {...props}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="show">
        {/* <TextField source="id" /> */}
        <TextField source="name" />
        <FunctionField render={stages} />
      </Datagrid>
    </List>
  );
};

export {
  ResList as list
};

function stages(record: any) {
  const { _embedded: { statuses } } = record;

  const data = statuses.sort((s: any) => s.sort).map((s: any) => `(${s.id}) ${s.name}`);
  return <span>{data.join(', ')}</span>;
}
