import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  Create,
  SimpleForm,
  TextInput,
  Edit,
} from 'react-admin';

// import useUserInfo from 'components/use-user-info';

export const name = 'countries';

const ResList = (props: any) => {
  // const { permissions } = props;

  // React.useEffect(() => {
  //   console.log('---123444---', permissions);
  // }, [permissions]);

  return (
    <List {...props} bulkActionButtons={false}>
      <Datagrid rowClick="show">
        <TextField source="name" />
      </Datagrid>
    </List>
  );
};

const ResShow = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
    </SimpleShowLayout>
  </Show>
);

const ResCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);

const ResEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);

export {
  ResList as list,
  ResShow as show,
  ResCreate as create,
  ResEdit as edit,
};
