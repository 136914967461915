import { composedRoomTypes } from 'search/SearchData';

import { EntranceType, FloorType } from './roomtypes';

function filterObject(obj: any) {
  return Object.entries(obj).reduce(
    (m: any, [k, v]) => (v !== undefined && v !== null ? { ...m, [k]: v } : m),
    {}
  );
}

function calcRooms(props: EntranceType) {
  const result = [];
  const { floors = [], rooms = [], building, number, id } = props;
  const { floorsnum, roomsFromFloor = 1, roomsFromNumber = 1 } = props;
  const roofFloor =
    floors.find((f: any) => f.number === 0) || ({} as FloorType);

  let roomNumber = roomsFromNumber - 1;

  for (let floor = 1; floor <= floorsnum; floor++) {
    const foundFloor = floors.find((f: any) => f.number === floor);
    const roomsPerFloor =
      foundFloor?.roomsPerFloor !== undefined
        ? foundFloor.roomsPerFloor
        : props.roomsPerFloor;
    for (let rpf = 1; rpf <= roomsPerFloor; rpf++) {
      const roof = rooms.find((r: any) => r.floor === 0 && r.rpf === rpf) || {};
      const foundRoom =
        rooms.find((r: any) => r.floor === floor && r.rpf === rpf) || {};
      const room = { ...filterObject(roof), floor, ...filterObject(foundRoom) };

      if (floor >= roomsFromFloor) {
        roomNumber++;
        room.number = roomNumber;
      } else {
        continue;
      }

      if (room.space && room.price && Number(room.price) < 200000) {
        room.price = Number(room.price) * Number(room.space);
      }
      if (!room.pricepm && room.price && room.space) {
        room.pricepm = Number(room.price) / Number(room.space);
      }

      result.push({
        rpf: room.rpf || rpf,
        floor: room.floor || floor,
        number: room.number,
        floorPhoto: foundFloor?.photo || roofFloor?.photo || {},
        photo: room.photo,
        type: room.type || 'unknown',
        price: Number(room.price) || 0,
        space: Number(room.space) || 0,
        status: room.status,
        pricepm: room.pricepm,
        dealtype: room.dealtype || 0,
        state: room.state,
        rt: composedRoomTypes[room.type || 'unknown'],
        id: `${building}_${id}_${room.floor}_${room.rpf}`,
        section: number,
        sides: [],
        info: room.info ?? '',
        euro: room.euro ?? false,
      });
    }
  }
  return result;
}

export default calcRooms;
