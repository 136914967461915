import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  ReferenceField,
  SelectInput,
  FunctionField
} from 'react-admin';

export const name = 'dp_stat';

const StatFilter = (props: any) => (
  <Filter {...props}>
    <ReferenceInput
      source="pipeline_id"
      reference="amo_pipelines"
      alwaysOn
      sort={{ field: 'sort', order: 'ASC' }}
      allowEmpty={false}
      perPage={1000}
    >
      <SelectInput source="name" />
    </ReferenceInput>
  </Filter>
);

const ResList = (props: any) => {
  const { permissions } = props;
  const { city } = permissions || {};

  if (!city) {
    return null;
  }

  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<StatFilter />}
    // filterDefaultValues={{ active: true, city }}
    >
      <Datagrid rowClick="show">
        {/* <TextField source="id" /> */}
        {/* <TextField source="account_id" /> */}
        <TextField source="date_key" />
        <ReferenceField source="pipeline_id" reference="amo_pipelines" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="user_key" reference="amo_users" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <FunctionField render={stat} />
        {/* <TextField source="pipeline_id" /> */}
        {/* <TextField source="user_key" /> */}
      </Datagrid>
    </List>
  );
};

export {
  ResList as list
};

function stat(record: any) {
  const { s } = record;

  const data = Object.entries(s).map(([k, v]: any) => {
    return {
      id: k,
      num: v.length,
      sum: v.reduce((m: any, o: any) => m + Number(0), 0)
    }
  });

  // return <span>{JSON.stringify(s)}</span>;
  return <span>{JSON.stringify(data)}</span>;
}
