import * as React from 'react';

import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, getResources, useTranslate, usePermissions } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
// import LabelIcon from '@material-ui/icons/Label';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import ListAltIcon from '@material-ui/icons/ListAlt';

const Menu = ({ onMenuClick, logout }: any) => {
  const isXSmall = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));
  const open = useSelector((state: any) => state.admin.ui.sidebarOpen);
  const translate = useTranslate();
  const resources = useSelector(getResources);
  const { permissions, loaded } = usePermissions();

  if (!loaded) {
    return null;
  }

  return (
    <div style={{ marginTop: '1.5em' }}>
      <MenuItemLink
        to="/"
        primaryText="Поиск"
        leftIcon={<HomeWorkIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        to="/search2"
        primaryText="Поиск(v2)"
        leftIcon={<HomeWorkIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      {
        ['admin', 'supermanager', 'officehead', 'cityhead'].includes(permissions?.role) &&
        <MenuItemLink
          to="/listreports"
          primaryText="Статистика и рейтинг"
          leftIcon={<ListAltIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      }
      {
        Boolean(['admin', 'supermanager'].includes(permissions?.role) || permissions?.spr) &&
        <MenuItemLink
          to="/ipoteka_brokers_report"
          primaryText="Ипотека, Аналитика"
          leftIcon={<ListAltIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      }
      {
        ['admin', 'supermanager', 'officehead', 'cityhead'].includes(permissions?.role) &&
        <MenuItemLink
          to="/kpi"
          primaryText="KPI"
          leftIcon={<ListAltIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      }
      {resources.map((resource: any) => (
        resource.hasList &&
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={translate(`resources.${resource.name}.name`, 2)}
          leftIcon={
            resource.icon ? <resource.icon /> : <DefaultIcon />
          }
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      ))}
      {isXSmall && logout}
    </div>
  );
};

export default Menu;