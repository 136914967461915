import * as React from "react";
import Card from '@material-ui/core/Card';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import { Title, DateInput, SelectInput, useDataProvider, usePermissions, ReferenceInput, FormDataConsumer, ReferenceArrayInput, TextInput, SelectArrayInput, BooleanInput } from 'react-admin';
import { Link } from "react-router-dom";
import { Form } from 'react-final-form';
import { Box } from '@material-ui/core';
import isBefore from 'date-fns/isBefore'
import startOfMonth from 'date-fns/startOfMonth';
import endOfDay from 'date-fns/endOfDay';
import parseISO from 'date-fns/parseISO';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  cell: {
    fontSize: 12,
    borderLeft: '1px solid #ccc',
    padding: 5
  },
  headCell: {
    fontSize: 12,
    borderLeft: '1px solid #ccc',
    fontWeight: 'bold',
    padding: 5,
    borderTop: '1px solid #ccc',
  },
}));

const ReportFilter = (props: any) => {
  const { setFilter, filterValues, role, disabled } = props;

  const onSubmit = (values: any) => {
    if (setFilter) {
      setFilter(values);
    }
  }

  return (
    <div>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
        {
          ({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Box display="fex" alignItems="flex-end">
                <Box component="span" mr={2}>
                  <DateInput source="date_gt" label="Период с" />
                </Box>
                <Box component="span" mr={2}>
                  <DateInput source="date_lt" label="Период по" />
                </Box>
                {
                  ['supermanager', 'admin'].includes(role) && ([
                    <Box component="span" mr={2} key="city">
                      <ReferenceInput source="city" reference="cities" filter={{ active: true }} sort={{ field: 'name', order: 'ASC' }} perPage={500} label="Город">
                        <SelectInput optionText="name" />
                      </ReferenceInput>
                    </Box>,
                    <Box component="span" mr={2} minWidth={150} key="office">
                      <FormDataConsumer>
                        {
                          ({ formData }) => (
                            formData?.city ?
                              <ReferenceArrayInput basePath="/expertreport" source="offices" reference="offices" resource="lists" filter={{ active: true, city: formData.city }} sort={{ field: 'name', order: 'ASC' }} perPage={500} label="Офисы">
                                <SelectArrayInput optionText="name" />
                              </ReferenceArrayInput> :
                              <TextInput source="office" disabled />
                          )
                        }
                      </FormDataConsumer>
                    </Box>
                  ])
                }
                {
                  ['cityhead'].includes(role) && ([
                    <Box component="span" mr={2} minWidth={150} key="office">
                      <FormDataConsumer>
                        {
                          ({ formData }) => (
                            formData?.city ?
                              <ReferenceArrayInput basePath="/expertreport" source="offices" reference="offices" resource="lists" filter={{ active: true, city: formData.city }} sort={{ field: 'name', order: 'ASC' }} perPage={500} label="Офисы">
                                <SelectArrayInput optionText="name" />
                              </ReferenceArrayInput> :
                              <TextInput source="office" disabled />
                          )
                        }
                      </FormDataConsumer>
                    </Box>
                  ])
                }
                <Box component="div" mr={2}>
                  <BooleanInput source="showStat" label="Запросить статистику" />
                </Box>
                <Box component="span" mr={2}>
                  <Button variant="outlined" color="primary" type="submit" disabled={disabled}>Сформировать</Button>
                </Box>
              </Box>
            </form>
          )
        }
      </Form>
    </div >
  )
}

const ExpertReport = (props: any) => {
  const [filter, setFilter] = React.useState<Record<string, any>>({});
  const [listData, setListData] = React.useState<any>([]);
  const [parsing, setParsing] = React.useState(false);
  const dataProvider = useDataProvider();
  const { permissions, loaded } = usePermissions();

  const initialFilterValues = React.useMemo(() => {
    const { role, city, office } = permissions || {};
    const ret: any = {
      showStat: false,
      date_lt: endOfDay(Date.now()).toISOString(),
      date_gt: startOfMonth(Date.now()).toISOString(),
    };
    if (role) {
      if (['admin', 'supermanager', 'cityhead'].includes(role)) {
        ret.city = city;
        ret.offices = [office];
      } else if (['officehead'].includes(role)) {
        ret.office = office;
      }
    }
    return ret;
  }, [permissions]);

  React.useEffect(() => {
    const fetchListData = async (date_type: string, offices: string[], date_gt: any, date_lt: any) => {
      const resp = await dataProvider.getList('lists',
        {
          pagination: { page: 1, perPage: 10000 },
          filter: { list_kind: 'all', offices, date_gt, date_lt, date_type },
          sort: { field: 'id', order: 'ASC' }
        });
      return resp.data.filter(d => !d.reject);
    }
    const fetchUsers = async (office: string, date_gt: any) => {
      const resp = await dataProvider.getList('users',
        {
          pagination: { page: 1, perPage: 1000 },
          filter: { office },
          sort: { field: 'id', order: 'ASC' }
        });
      return resp.data.filter(d => (!d.notInManagerReportsFrom || !isBefore(parseISO(d.notInManagerReportsFrom), parseISO(date_gt))) && d.role === 'user');
    }

    const fetchUserStat = async (manager: string) => {
      const resp = await dataProvider.getList('lists',
        {
          pagination: { page: 1, perPage: 10000 },
          filter: { manager },
          sort: { field: 'id', order: 'ASC' }
        });
      return resp.data;
    }


    const fillData = async (offices: string[], date_gt: any, date_lt: any, showStat: boolean) => {
      let users: any = [];
      for (let i = 0; i < offices.length; i++) {
        const resp = await fetchUsers(offices[i], date_gt);
        users = [...users, ...resp];
      }
      const byContract = await fetchListData('contractdate', offices, date_gt, date_lt);
      const byKV = await fetchListData('kvpaiddate', offices, date_gt, date_lt);

      // return users.map((u: any) => {
      //   const userByContracts = byContract.filter(d => d.manager === u.id);
      //   const userByKv = byKV.filter(d => d.manager === u.id);
      //   return {
      //     id: u.id,
      //     name: u.name,
      //     office: u.office,
      //     role: u.role,
      //     contractNum: userByContracts.length,
      //     contractSum: userByContracts.reduce((m, d) => m + Number(d.kv ?? 0), 0),
      //     sellNum: userByKv.length,
      //     sellSum: userByKv.reduce((m, d) => m + Number(d.kv ?? 0), 0),
      //   };
      // });
      const ret: any = [];
      for (let i = 0; i < users.length; i++) {
        const u = users[i];
        const userByContracts = byContract.filter(d => d.manager === u.id);
        const userByKv = byKV.filter(d => d.manager === u.id);

        const obj: any = {
          id: u.id,
          name: u.name,
          office: u.office,
          role: u.role,
          contractNum: userByContracts.length,
          contractSum: userByContracts.reduce((m, d) => m + Number(d.kv ?? 0), 0),
          sellNum: userByKv.length,
          sellSum: userByKv.reduce((m, d) => m + Number(d.kv ?? 0), 0),
        };

        if (showStat) {
          const userStat = await fetchUserStat(u.id);
          obj.statTot = userStat.filter(s => s.list_kind !== 'reject').length;
          obj.statSuccess = userStat.filter(s => s.list_kind === 'success').length;
          obj.statActive = userStat.filter(s => s.list_kind === 'active').length;
          obj.statReject = userStat.filter(s => s.list_kind === 'reject').length;
        }

        ret.push(obj);

      }
      return ret;
    }

    if (Object.keys(filter).length > 0) {
      const offices = filter.office ? [filter.office] : filter.offices;
      setParsing(true);
      fillData(offices, filter.date_gt, filter.date_lt, filter.showStat).then(data => setListData(data));
    }
  }, [filter, dataProvider])

  return (
    <Card>
      <Title title="Отчеты по сделкам" />
      <CardContent>
        <ButtonGroup>
          <Button component={Link} to="/listreports">СПРАВКА</Button>
          <Button disabled>Рейтинг Экспертов</Button>
          <Button component={Link} to="/developerreport">Работа с застройщиками</Button>
        </ButtonGroup>
        <ReportFilter setFilter={setFilter} filterValues={initialFilterValues} role={permissions?.role} disabled={parsing} />
        <Table1 data={listData} parsing={parsing} setParsing={setParsing} />
        {/* <div>
          filter
          <pre>
            {JSON.stringify(filter, null, ' ')}
          </pre>
          data
          <pre>
            {JSON.stringify(listData, null, ' ')}
          </pre>
        </div> */}
      </CardContent>
    </Card>
  )
}

export default ExpertReport;

function Table1(props: any) {
  const { data: rawData, parsing, setParsing } = props;
  const [data, setData] = React.useState([]);
  const timer = React.useRef<any>();
  const dataProvider = useDataProvider();
  const classes = useStyles();
  // const [parsing, setParsing] = React.useState(false);

  React.useEffect(() => {
    let didCancel = false;
    const getResourceData = async (resource: string, id: string) => {
      if (!id || didCancel) {
        return {} as any;
      }
      try {
        const resp = await dataProvider.getOne(resource, { id });
        return resp.data;
      } catch (ex) { }
      return {} as any;
    };
    const fillData = async () => {
      const ret: any = [];
      for (let i = 0; i < rawData.length; i++) {
        if (didCancel) {
          break;
        }
        const item: any = rawData[i];

        const officeData = (await getResourceData('offices', item.office));
        const office = officeData?.name;
        ret.push({ ...item, office });
      }
      return ret;
    }
    if (rawData && !didCancel) {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        setData([]);
        setParsing(true);
        fillData().then(filledData => setData(filledData)).finally(() => setParsing(false));
      }, 1000);
    } else if (!didCancel) {
      setData([]);
    }

    return () => {
      didCancel = true;
    }
  }, [rawData, dataProvider, setParsing]);

  const styleByContracts = (prop: any) => {
    let color = 'red';
    const num = Number(prop);
    if (num <= 2) {
      color = 'red';
    } else if (num < 5) {
      color = 'yellow';
    } else if (num > 4) {
      color = 'green';
    }
    return { background: color };
  }

  const styleBySell = (prop: any) => {
    let color = 'red';
    const num = Number(prop);
    if (num <= 180000) {
      color = 'red';
    } else if (num <= 280000) {
      color = 'yellow';
    } else if (num > 280000) {
      color = 'green';
    }
    return { background: color };
  }

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.headCell}>#</TableCell>
            <TableCell className={classes.headCell}>Офис</TableCell>
            <TableCell className={classes.headCell}>Эксперт</TableCell>
            <TableCell className={classes.headCell}>Количество подписаний</TableCell>
            <TableCell className={classes.headCell}>Сумма подписаний кв</TableCell>
            <TableCell className={classes.headCell}>Количество оплат</TableCell>
            <TableCell className={classes.headCell}>Сумма оплат</TableCell>
            <TableCell className={classes.headCell}>Статистика (а+у/у/а/о)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            parsing && <TableRow><TableCell colSpan={14}>Обработка данных</TableCell></TableRow>
          }
          {
            !parsing && data.map((row: any, ind: number) => (
              <TableRow key={row.id}>
                <TableCell size="small" className={classes.cell}>{ind + 1}</TableCell>
                <TableCell size="small" className={classes.cell}>{row.office}</TableCell>
                <TableCell size="small" className={classes.cell}>{row.name}</TableCell>
                <TableCell size="small" className={classes.cell} align="right" style={styleByContracts(row.contractNum)}><NumberField data={row.contractNum} /></TableCell>
                <TableCell size="small" className={classes.cell} align="right" style={styleByContracts(row.contractNum)}><NumberField data={row.contractSum} /></TableCell>
                <TableCell size="small" className={classes.cell} align="right" style={styleBySell(row.sellSum)}><NumberField data={row.sellNum} /></TableCell>
                <TableCell size="small" className={classes.cell} align="right" style={styleBySell(row.sellSum)}><NumberField data={row.sellSum} /></TableCell>
                <TableCell size="small" className={classes.cell} align="right" >{row.statTot || 0}/{row.statSuccess || 0}/{row.statActive || 0}/{row.statReject || 0}</TableCell>
              </TableRow>
            ))
          }
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell className={classes.cell} align="right"><TotalNumberField data={data} source="contractNum" /></TableCell>
            <TableCell className={classes.cell} align="right"><TotalNumberField data={data} source="contractSum" /></TableCell>
            <TableCell className={classes.cell} align="right"><TotalNumberField data={data} source="sellNum" /></TableCell>
            <TableCell className={classes.cell} align="right"><TotalNumberField data={data} source="sellSum" /></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function NumberField(props: any) {
  const { data } = props;
  if (!data) {
    return null;
  }
  return <span>{Number(data).toLocaleString()}</span>;
}


function TotalNumberField(props: any) {
  const { data, source } = props;

  const sum = React.useMemo(() => {
    if (data && source) {
      return data.reduce((m: number, d: any) => Number(d[source]) ? m + Number(d[source]) : m, 0);
    }
    return undefined;
  }, [data, source]);

  if (sum === undefined) {
    return null;
  }

  return <span>{Number(sum).toLocaleString()}</span>;
}