import React from 'react';
import format from 'date-fns/format';
import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  BooleanInput,
  NumberInput,
  FileInput,
  ImageInput,
  ImageField,
  FileField,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  BooleanField,
  RadioButtonGroupInput,
  SelectField,
  NumberField,
  Toolbar,
  SaveButton,
  usePermissions,
  Filter,
  DateField
} from 'react-admin';
import { FormSpy, useField } from 'react-final-form'

import { MapEdit, MapView } from 'components/map';

export const name = 'secondroom';

const dogovorChoises = [
  { id: '1', name: 'Возмездный' },
  { id: '2', name: 'Эксклюзив' },
  { id: '3', name: 'Нет договора' },
];

const typeChoises = [
  { id: '1', name: 'Смежные' },
  { id: '2', name: 'Не смежные' },
  { id: '3', name: '2 + 1' },
];

const yesnoChoises = [
  { id: '1', name: 'Да' },
  { id: '2', name: 'Нет' },
];

export const remontChoises = [
  { id: '1', name: 'Стройвариант' },
  { id: '2', name: 'Вайтбокс' },
  { id: '3', name: 'Косметический' },
  { id: '4', name: 'Евроремонт' },
  { id: '5', name: 'Старый' },
  { id: '6', name: 'Дизайнерский' },
];

const buildingTypeChoises = [
  { id: '1', name: 'кирпич' },
  { id: '2', name: 'панель' },
  { id: '3', name: 'блок' },
  { id: '4', name: 'монолит' },
];

const SeconRoomsListFilter = (props: any) => (
  <Filter {...props}>
    <BooleanInput source="active" alwaysOn />
  </Filter>
);

export const list = (props: any) => {
  const { permissions = {} } = props;
  const { role, office, user } = permissions;

  if (!role) {
    return null;
  }

  const filter: any = {};
  if (['admin', 'supermanager'].includes(role)) {

  } else if (['officehead'].includes(role)) {
    filter.office = office;
  } else {
    filter.agent = user;
  }

  return (
    <List {...props}
      bulkActionButtons={false}
      filter={filter}
      filters={<SeconRoomsListFilter />}
      filterDefaultValues={{ active: true }}
    >
      <Datagrid rowClick="edit">
        <BooleanField source="active" />
        <ReferenceField source="city" reference="cities" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="district" reference="districts" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="office" reference="offices" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="agent" reference="users" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="complex" reference="complexes" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="address" />
        <TextField source="info" />
        <TextField source="price" />
        <NumberField source="pricepm" />
        <TextField source="space_total" />
        <ReferenceField source="roomtype" reference="roomtypes" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="buildingDate" />
        <SelectField source="buidingType" choices={buildingTypeChoises} />
        <TextField source="client_phone" />
        <TextField source="client_info" />
        <DateInfo source="createdate" />
      </Datagrid>
    </List>
  )
};

export const show = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <BooleanField source="active" />
      <TextField source="address" />
      <TextField source="info" />
      <TextField source="price" />
      <TextField source="space_total" />
      <ReferenceField source="roomtype" reference="roomtypes" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <MapView source="geo" zoom="16" />
    </SimpleShowLayout>
  </Show>
);

const GuestToolbar = (props: any) => {
  const { permissions, loaded } = usePermissions();

  if (!loaded) {
    return null;
  }

  return (
    <Toolbar {...props} >
      <SaveButton disabled={permissions?.role === 'guest'} />
    </Toolbar>
  )
};

export const create = (props: any) => {
  const { permissions = {} } = props;
  const { office, user, city } = permissions;

  if (!user) {
    return null;
  }

  return (
    <Create {...props}>
      <SimpleForm defaultValue={{ office, agent: user, city }} redirect="list" toolbar={<GuestToolbar />}>
        <BooleanInput source="active" />
        <ReferenceInput source="district" reference="districts" filter={{ city, active: true }} sort={{ field: 'name', order: 'ASC' }} perPage={500}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="address" fullWidth multiline />
        <TextInput source="buildingDate" />
        <NumberInput source="buildingStages" />
        <ReferenceInput source="complex" reference="complexes" filter={{ city, active: true }} sort={{ field: 'name', order: 'ASC' }} perPage={500} >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <RadioButtonGroupInput
          source="buidingType"
          choices={buildingTypeChoises}
          fullWidth
        />
        <RadioButtonGroupInput
          source="dogovorChoise"
          choices={dogovorChoises}
          fullWidth
        />
        <ReferenceInput source="roomtype" reference="roomtypes" perPage={100}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="price" />
        <NumberInput source="pricepm" disabled />
        <NumberInput source="space_total" />
        <NumberInput source="space_kitchen" />
        <NumberInput source="space_live" />
        <RadioButtonGroupInput
          source="typeChoise"
          choices={typeChoises}
          fullWidth
        />
        <NumberInput source="floor" />
        <NumberInput source="number" />
        <NumberInput source="height" />
        <RadioButtonGroupInput
          source="remontChoise"
          choices={remontChoises}
          fullWidth
        />
        <RadioButtonGroupInput
          source="ipotekaChoise"
          choices={yesnoChoises}
          fullWidth
        />
        <RadioButtonGroupInput
          source="roomIpotekaChoise"
          choices={yesnoChoises}
          fullWidth
        />
        <RadioButtonGroupInput
          source="matcapitalusedChoise"
          choices={yesnoChoises}
          fullWidth
        />
        <ImageInput source="photo" multiple>
          <ImageField source="src" title="title" />
        </ImageInput>
        <FileInput source="docs" multiple>
          <FileField source="src" title="title" />
        </FileInput>
        <MapEdit source="geo" zoom="16" />
        <TextInput source="info" fullWidth multiline />
        <TextInput source="client_phone" />
        <TextInput source="client_info" fullWidth multiline />

        <TextInput source="roomNums" />
        <TextInput source="sanuzel" />
        <TextInput source="balkon" />
        <TextInput source="balkonGlassing" />
        <TextInput source="sunSides" />
        <TextInput source="waterCalc" />
        <TextInput source="ironDoor" />
        <TextInput source="services" fullWidth multiline />
        <TextInput source="thingsRemains" fullWidth multiline />
        <TextInput source="dvor" fullWidth multiline />
        <TextInput source="parking" fullWidth multiline />


        <FormSpy subscription={{ values: true }}>
          {({ values }) => {
            const { input: { onChange } } = useField('pricepm');
            const { price, space_total } = values;
            if (space_total > 0) {
              const value = price / space_total;
              onChange(value.toFixed(2));
            }
            return null;
          }}
        </FormSpy>
      </SimpleForm>
    </Create>
  )
};

export const edit = (props: any) => {
  const { permissions = {} } = props;
  const { city, role } = permissions;

  if (!city) {
    return null;
  }

  return (
    <Edit {...props}>
      <SimpleForm toolbar={<GuestToolbar />}>
        <BooleanInput source="active" />
        {
          role === 'admin' && (
            [
              <ReferenceInput source="office" reference="offices" key="offices">
                <SelectInput optionText="name" />
              </ReferenceInput>,
              <ReferenceInput source="agent" reference="users" key="users">
                <SelectInput optionText="name" />
              </ReferenceInput>,
              <ReferenceInput source="city" reference="cities" filter={{ active: true }} sort={{ field: 'name', order: 'ASC' }} perPage={500} key="cities">
                <SelectInput optionText="name" />
              </ReferenceInput>
            ]
          )
        }
        <ReferenceInput source="district" reference="districts" filter={{ city, active: true }} sort={{ field: 'name', order: 'ASC' }} perPage={500}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="address" fullWidth multiline />
        <TextInput source="buildingDate" />
        <NumberInput source="buildingStages" />
        <ReferenceInput source="complex" reference="complexes" filter={{ city, active: true }} sort={{ field: 'name', order: 'ASC' }} perPage={500} >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <RadioButtonGroupInput
          source="buidingType"
          choices={buildingTypeChoises}
          fullWidth
        />
        <RadioButtonGroupInput
          source="dogovorChoise"
          choices={dogovorChoises}
          fullWidth
        />
        <ReferenceInput source="roomtype" reference="roomtypes" perPage={100}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="price" />
        <NumberInput source="pricepm" disabled />
        <NumberInput source="space_total" />
        <NumberInput source="space_kitchen" />
        <NumberInput source="space_live" />
        <RadioButtonGroupInput
          source="typeChoise"
          choices={typeChoises}
          fullWidth
        />
        <NumberInput source="floor" />
        <NumberInput source="number" />
        <NumberInput source="height" />
        <RadioButtonGroupInput
          source="remontChoise"
          choices={remontChoises}
          fullWidth
        />
        <RadioButtonGroupInput
          source="ipotekaChoise"
          choices={yesnoChoises}
          fullWidth
        />
        <RadioButtonGroupInput
          source="roomIpotekaChoise"
          choices={yesnoChoises}
          fullWidth
        />
        <RadioButtonGroupInput
          source="matcapitalusedChoise"
          choices={yesnoChoises}
          fullWidth
        />
        <ImageInput source="photo" multiple>
          <ImageField source="src" title="title" />
        </ImageInput>
        <FileInput source="docs" multiple>
          <FileField source="src" title="title" />
        </FileInput>
        <MapEdit source="geo" zoom="16" />
        <TextInput source="info" fullWidth multiline />
        <TextInput source="client_phone" />
        <TextInput source="client_info" fullWidth multiline />

        <TextInput source="roomNums" />
        <TextInput source="sanuzel" />
        <TextInput source="balkon" />
        <TextInput source="balkonGlassing" />
        <TextInput source="sunSides" />
        <TextInput source="waterCalc" />
        <TextInput source="ironDoor" />
        <TextInput source="services" fullWidth multiline />
        <TextInput source="thingsRemains" fullWidth multiline />
        <TextInput source="dvor" fullWidth multiline />
        <TextInput source="parking" fullWidth multiline />

        <FormSpy subscription={{ values: true }}>
          {({ values }) => {
            const { input: { onChange } } = useField('pricepm');
            const { price, space_total } = values;
            if (space_total > 0) {
              const value = price / space_total;
              onChange(value.toFixed(2));
            }
            return null;
            // return (
            //   <pre>
            //     {JSON.stringify(values, null, 2)}
            //   </pre>
            // )
          }}
        </FormSpy>
      </SimpleForm>
    </Edit>
  )
};

function DateInfo(props: any) {
  const { record } = props;
  const { createdate } = record;
  if (!createdate) {
    return null;
  }

  const text = format(createdate.toDate(), 'dd.MM.yyyy')

  return (
    <span>{text}</span>
  )
}