import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  BooleanField,
  DateField,
  EmailField,
  ReferenceField,
  NumberField,
  BooleanInput,
  NumberInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  Filter,
  ImageInput,
  ImageField,
  TabbedForm,
  FormTab,
  FormDataConsumer,
  useDataProvider
} from 'react-admin';

export const statuses = [
  { id: '1', name: 'Лид Распределен' },
  { id: '2', name: 'Звонок' },
  { id: '3', name: 'Встреча Назначена' },
  { id: '4', name: 'Встреча' },
  { id: '5', name: 'Показ' },
  { id: '6', name: 'Бронь' },
  { id: '7', name: 'ДДУ' }
]

export const name = 'offices';

const OfficeListFilter = (props: any) => (
  <Filter {...props}>
    <BooleanInput source="active" alwaysOn />
    <ReferenceInput
      source="city"
      reference="cities"
      alwaysOn
      sort={{ field: 'name', order: 'ASC' }}
      allowEmpty={false}
      perPage={1000}
    >
      <SelectInput source="name" />
    </ReferenceInput>
    {/* <ReferenceInput
      source="city"
      reference="cities"
      filterToQuery={(txt: string) => ({ name: txt })}
      alwaysOn
      allowEmpty={false}
      sort={{ field: 'name', order: 'ASC' }}
      filter={{ active: true }}
    >
      <AutocompleteInput optionText="name" allowEmpty={false} />
    </ReferenceInput> */}
  </Filter>
);

export const list = (props: any) => {
  const { permissions = {} } = props;
  const { city } = permissions;
  if (!city) {
    return null;
  }
  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<OfficeListFilter />}
      filterDefaultValues={{ active: true, city }}
    >
      <Datagrid rowClick="show">
        <BooleanField source="active" />
        <TextField source="address" />
        <ReferenceField source="city" reference="cities">
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="officeSquare" />
        <TextField source="realName" />
        <TextField source="phone" />
        <DateField source="dob" />
        <TextField source="name" />
        <EmailField source="headEmail" />
        <ReferenceField source="region" reference="regions">
          <TextField source="name" />
        </ReferenceField>
        <EmailField source="email" />
        <TextField source="info" />
        <TextField source="headPhone" />
      </Datagrid>
    </List>
  );
};

export const show = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>
      <BooleanField source="active" />
      <TextField source="address" />
      <ReferenceField source="city" reference="cities">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="officeSquare" />
      <TextField source="realName" />
      <TextField source="phone" />
      <DateField source="dob" />
      <TextField source="name" />
      <EmailField source="headEmail" />
      <ReferenceField source="region" reference="regions">
        <TextField source="name" />
      </ReferenceField>
      <EmailField source="email" />
      <TextField source="info" />
      <TextField source="headPhone" />
    </SimpleShowLayout>
  </Show>
);

export const create = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <BooleanInput source="active" />
      <ReferenceInput source="country" reference="countries" perPage={100}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="region" reference="regions" perPage={100}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="city" reference="cities" perPage={100}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="address" />
      <NumberInput source="officeSquare" />
      <TextInput source="realName" />
      {/* <TextInput source="attachedCity" /> */}
      <TextInput source="headPhone" />
      <TextInput source="phone" />
      <DateInput source="dob" />

      <TextInput source="headEmail" />
      <TextInput source="email" />
      <ImageInput source="logo">
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Create>
);

export const edit = (props: any) => (
  <Edit {...props}>
    <TabbedForm>
      <FormTab label="Основное">
        <TextInput source="name" />

        <BooleanInput source="active" />
        <ReferenceInput source="country" reference="countries" perPage={100}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="region" reference="regions" perPage={100}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="city" reference="cities" perPage={100}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="address" />
        <NumberInput source="officeSquare" />
        <TextInput source="realName" />
        {/* <TextInput source="attachedCity" /> */}
        <TextInput source="headPhone" />
        <TextInput source="phone" />
        <DateInput source="dob" />

        <TextInput source="headEmail" />
        <TextInput source="email" />
        <ImageInput source="logo">
          <ImageField source="src" title="title" />
        </ImageInput>
      </FormTab>
      <FormTab label="Интеграция AMOCRM">
        <BooleanInput source="use_amo" />
        <ReferenceInput source="amo_pipeline" reference="amo_pipelines" perPage={1000} sort={{ field: 'sort', order: 'ASC' }}>
          <SelectInput optionText={(record: any) => `${record?.name} (${record?.account_id})`} />
        </ReferenceInput>
        <FormDataConsumer>
          {(consumerProps) => <AmoStatuses {...consumerProps} />}
        </FormDataConsumer>
        <ReferenceInput source="amo_pipeline2" reference="amo_pipelines" perPage={1000} sort={{ field: 'sort', order: 'ASC' }}>
          <SelectInput optionText={(record: any) => `${record?.name} (${record?.account_id})`} />
        </ReferenceInput>
        <FormDataConsumer>
          {(consumerProps) => <AmoStatuses {...consumerProps} ver="2" />}
        </FormDataConsumer>
      </FormTab>
    </TabbedForm>
  </Edit>
);


function AmoStatuses(props: any) {
  const { formData, ver = '', ...other } = props;
  // const { amo_pipeline } = formData;
  const [piplelineStatuses, setPipelineStatuses] = React.useState<any>();
  const dataProvider = useDataProvider();

  const amo_pipeline = formData[`amo_pipeline${ver}`];

  React.useEffect(() => {
    const getData = async () => {
      const resp = await dataProvider.getOne('amo_pipelines', { id: amo_pipeline });
      const arr = resp?.data?._embedded?.statuses;
      if (Array.isArray(arr)) {
        const obj = arr.map(d => ({ id: d.id, name: d.name, sort: d.sort })).sort((a, b) => Number(a.sort) - Number(b.sort));
        setPipelineStatuses(obj);
      }
    }
    if (amo_pipeline && dataProvider) {
      getData();
    }
  }, [amo_pipeline, dataProvider]);

  if (!amo_pipeline || !piplelineStatuses) {
    return null;
  }

  return (
    <>
      {
        statuses.map(st => <SelectInput key={st.id} source={`amo_status${ver}_${st.id}`} choices={piplelineStatuses} label={st.name} {...other} />)
      }
    </>
  )
}