import * as React from 'react';
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
import { parse } from 'query-string';
import SearchDetailsObject3 from 'search/SearchDetailsObject3';
import SearchDetailsObject4 from 'Search2/ComplexInfo';

const ComplexInfo = (props: any) => {
  const { id, v } = parse(props.location.search);
  if (!id) {
    return null
  }

  if (v === '4') {
    return <SearchDetailsObject4 selected={id} />
  }
  return <SearchDetailsObject3 selected={id} />

  // return (
  //   <Card>
  //     <CardContent>
  //       Complex Info
  //       {/* <div>{JSON.stringify(props)}</div> */}
  //       <div>{id}</div>
  //     </CardContent>
  //   </Card>
  // );
}

export default ComplexInfo;