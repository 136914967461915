import React from 'react';
import DG from '2gis-maps';
import { Portal } from 'react-portal';

import { useDispatch, useSelector } from 'react-redux';
import { setCity, setObject } from 'reducers/search-slice';
import { composedRoomTypes } from 'search/SearchData';
import { RoomItem } from 'search/SearchDetailsSecondRooms';
import CancelIcon from '@material-ui/icons/Cancel';

const defaultIcon = DG.divIcon({
  className: 'map-icon',
  iconSize: new DG.Point(15, 15),
});

const defaultView = {
  center: [54.98, 82.89],
  zoom: 4,
};

const SearchMapSecondRooms = (props: any) => {
  const dispatch = useDispatch();
  const { city: selectedCity, viewMap } = useSelector((state: any) => state.search);

  const cities = useSelector((state: any) => state.search.citiesData);

  const secondRooms = useSelector((state: any) => state.search.secondRoomsData);
  const [roomIcons, setRoomIcons] = React.useState<any>([]);

  const ref = React.useRef(null);
  const [map, setMap] = React.useState<any>(null);
  const citiesLayer = React.useRef<any>(null);
  const secondRoomsLayer = React.useRef<any>(null);

  React.useEffect(() => {
    const mapObj = DG.map(ref.current, defaultView);
    mapObj.on('zoomend', (e: any) => {
      if (e.target.getZoom() < 7) {
        dispatch(setCity(''));
        citiesLayer.current?.addTo(mapObj);
        secondRoomsLayer.current?.remove();
      }
    });
    setMap(mapObj);
    return () => {
      mapObj.remove();
    };
  }, [dispatch]);

  // city markers
  React.useEffect(() => {
    if (map && cities && cities.length) {
      const layer = new DG.LayerGroup();
      cities.forEach((c: any) => {
        if (c.geo) {
          const { lat, lng } = c.geo;
          const marker = DG.marker([lat, lng], {
            title: c.name,
            icon: defaultIcon,
          });
          marker.on('click', () => {
            dispatch(setCity(c.id));
          });
          marker.addTo(layer);
        }
      });
      citiesLayer.current = layer;
      layer.addTo(map);
    }
  }, [map, cities, dispatch]);

  // zoom to selected city
  React.useEffect(() => {
    if (map) {
      if (!selectedCity) {
        map.setView(defaultView.center, defaultView.zoom);
      } else if (cities) {
        const city: any = cities.find((c: any) => c.id === selectedCity);
        secondRoomsLayer.current?.remove();
        if (city && city.geo) {
          const { lat, lng } = city.geo;
          map.setView([lat, lng], 12);
          citiesLayer.current?.remove();
        }
      }
    }
  }, [map, cities, selectedCity]);

  // complexes
  React.useEffect(() => {
    if (map && secondRooms && secondRooms.length) {
      secondRoomsLayer.current?.remove();
      const layer = new DG.LayerGroup();
      const iconsData: any = [];
      secondRooms.forEach((c: any) => {
        if (c.geo) {
          try {
            const { lat, lng } = c.geo;
            const roomIcon = DG.divIcon({
              className: 'map-icon-zero',
              iconSize: new DG.Point(15, 15),
              html: `<div id="c_${c.id}"/>`
            });
            const marker = DG.marker([lat, lng], {
              title: c.name,
              icon: roomIcon,
            }); //.bindPopup(c.name);
            marker.on('click', () => {
              dispatch(setObject(c.id));
            });
            marker.addTo(layer);
            iconsData.push(<RoomIcon key={c.id} data={c} />);
          } catch (ex) { }
        }
      });
      secondRoomsLayer.current = layer;
      layer.addTo(map);
      setRoomIcons(iconsData);
    }
  }, [map, secondRooms, dispatch]);

  React.useEffect(() => {
    if (map && viewMap) {
      const { lat, lng } = viewMap || {};
      if (lat && lng) {
        map.setView([lat, lng], 16);
      }
    }
  }, [viewMap, map]);

  return <div style={{ flex: 1, position: 'relative' }} ref={ref}>
    <SelectedRoomInfo />
    {roomIcons}
    {/* <pre>
      {JSON.stringify(cities, null, ' ')}
    </pre> */}
  </div>;
};

export default SearchMapSecondRooms;


export function SelectedRoomInfo(props: any) {

  const { selected, secondRoomsData } = useSelector((state: any) => state.search);
  const dispatch = useDispatch();

  const selectedRoomData = React.useMemo(() => {
    if (selected && secondRoomsData) {
      return secondRoomsData.find((c: any) => c.id === selected);

    }
    return null;
  }, [selected, secondRoomsData]);

  if (!selected || !selectedRoomData) {
    return null;
  }

  return (
    <div style={{ position: 'absolute', top: 0, left: 0, width: 340, background: 'transparent', paddingTop: 10, zIndex: 10000, transform: 'scaleY(0.7) scaleX(0.7)', transformOrigin: 'left top' }}>
      <CancelIcon style={{ position: 'absolute', top: 15, right: 10, cursor: 'pointer', zIndex: 1 }} onClick={() => dispatch(setObject(''))} />
      <RoomItem data={selectedRoomData} />
      {/* <pre>
        {JSON.stringify(selectedComplexData, null, ' ')}
      </pre> */}
    </div>
  )
}


export function RoomIcon(props: any) {
  const { data = {} } = props;
  const { price, roomtype } = data;


  return (
    <Portal node={document && document.getElementById(`c_${data.id}`)}>
      <div className='map-icon' style={{ opacity: 1, background: 'red' }}>
        <div className="complex-name" >
          {/* {JSON.stringify(data)} */}
          {
            `${composedRoomTypes[roomtype] ?? ''}, ${Number(price).toLocaleString()} руб.`
          }
        </div>
      </div>
    </Portal>
  )
}