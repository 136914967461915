import React from 'react';
import createDecorator from 'final-form-calculate';
import { RoomsGridType, EntranceType } from './roomtypes';
import calcrooms from './calcrooms';
import Floor, { InfoFloor } from './Floor';
import { useField } from 'react-final-form';
import Button from '@material-ui/core/Button';

import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import { NumberInput, RadioButtonGroupInput, ReferenceInput, SelectInput, TextInput, SelectArrayInput, ImageInput, ImageField, BooleanInput } from 'react-admin';
import { Form } from 'react-final-form';
import { makeStyles } from '@material-ui/core';

import { statusChoises, stateChoises, dealtypeChoises } from 'resources/entrance-res';
import { roomSides } from 'components/RoomsGrid/Room';

const range = (n: number) => Array.from(Array(n).keys());

const useStyles = makeStyles({
  formDrawer: {
    width: '560px',
    position: 'relative',
    overflow: 'auto',
    padding: 5
  },
  button: {
    margin: 5,
    width: 320
  }
});

const RoomsGrid = (props: RoomsGridType) => {
  const { record } = props;
  const { floorsnum, rooms, roomsPerFloor } = record || { floorsnum: 0, rooms: [], roomsPerFloor: 0 };
  const calcedRooms = React.useMemo(() => calcrooms(record as EntranceType), [record]);
  const floorsArr = React.useMemo(() => range(floorsnum as number), [floorsnum]);

  return (
    <div>
      <InfoFloor floor={0} rooms={rooms} rpf={roomsPerFloor} disabled={false} />
      {
        floorsArr.map((n) => {
          const floor = floorsnum - n;
          return <Floor key={n} floor={floor} rooms={calcedRooms} />
        })
      }
    </div>
  )
}

export default RoomsGrid;

const calc = createDecorator({
  field: /price|space|pricepm/,
  updates: (value, name, allValues: any) => {
    // console.log('---calc---', value, name);
    const extObj: any = {};
    if (name === 'price') {
      if (allValues?.space) {
        extObj.pricepm = Number(value / allValues?.space).toFixed(2);
      }
    }
    // if (name === 'pricepm') {
    //   if (allValues?.space) {
    //     extObj.price = Number(value * allValues?.space).toFixed(2);
    //   }
    // }
    if (name === 'space') {
      if (value && allValues?.price) {
        extObj.pricepm = Number(allValues?.price / value).toFixed(2);
      } else if (value && allValues?.pricepm) {
        extObj.price = Number(allValues?.pricepm * value).toFixed(2);
      }
    }
    return { ...allValues, ...extObj };
  }
});

export const EditRoomsGrid = (props: (RoomsGridType & { onSelectRoom?: any; onSelectFloor?: any })) => {
  const classes = useStyles();
  const { record, onSelectRoom, onSelectFloor } = props;
  const { floorsnum, rooms, roomsPerFloor } = record || { floorsnum: 0, rooms: [], roomsPerFloor: 0 };
  const calcedRooms = React.useMemo(() => calcrooms(record as EntranceType), [record]);
  const floorsArr = React.useMemo(() => range(floorsnum as number), [floorsnum]);

  const [multipleMode, setMultipleMode] = React.useState(false);
  const [selectedRooms, setSelectedRooms] = React.useState<Record<string, unknown>[]>([]);
  const [editMode, setEditMode] = React.useState(false);
  const [multipleSelection, setMultipleSelection] = React.useState(false);

  const { input } = useField('rooms');
  const { input: { onChange: onChangeFloor, value: floorValue } } = useField('floors');
  const { onChange, value } = input;

  const handleSelectRoom = React.useCallback(async (rd, event) => {
    // console.log('---event---', event, rd);
    if (multipleMode && event === 'over' && !multipleSelection) {
      return;
    }
    if (multipleMode && event !== 'click') {
      if (event === 'down') {
        return setMultipleSelection(true);
      }
      if (event === 'up') {
        setMultipleSelection(false);
      }
      setSelectedRooms(state => {
        const found = state.find(s => s.rpf === rd.rpf && s.floor === rd.floor);
        if (!found) {
          return [...state, rd];
        }
        if (event === 'up') {
          return state.filter(s => !(s.rpf === rd.rpf && s.floor === rd.floor));
        }
        return state;
      });
    } else if (!multipleMode && event === 'click') {
      if (onSelectRoom && record) {
        const rooms = record['rooms'] || [];
        const length = rooms.length;
        let index = rooms.findIndex((r: any) => r.rpf === rd.rpf && r.floor === rd.floor);
        if (index === -1) {
          onChange([...value, { ...rd }]);
          index = length;
        }
        onSelectRoom(index);
      }
    }
  }, [record, onSelectRoom, onChange, value, multipleMode, multipleSelection]);

  const handleSelectFloor = React.useCallback((fd) => {
    if (onSelectFloor && record) {
      const floors = record['floors'] || [];
      const length = floors.length;
      let index = floors.findIndex((f: any) => f.number === fd);
      if (index === -1) {
        onChangeFloor([...floorValue, { number: fd }]);
        index = length;
      }
      onSelectFloor(index);
    }
  }, [record, onSelectFloor, onChangeFloor, floorValue]);

  React.useEffect(() => {
    setSelectedRooms([]);
  }, [multipleMode])

  const onSubmit = (values: any) => {
    // console.log('---values---', values);
    if (record) {
      const rooms = record['rooms'] || [];
      // const addRooms: any = [];
      let newValue = [...value];
      selectedRooms.forEach(sr => {
        const foundIndex = rooms.findIndex((r: any) => r.rpf === sr.rpf && r.floor === sr.floor);
        if (foundIndex > -1) {
          newValue[foundIndex] = { ...newValue[foundIndex], ...values };
        } else {
          newValue = [...newValue, { ...sr, ...values }]
        }
      })

      onChange([...newValue]);
    }
    setSelectedRooms([]);
    setEditMode(false);
  }

  return (
    <div>
      <Button color="primary"
        variant="contained" onClick={() => setMultipleMode(!multipleMode)} style={{ margin: 5 }}>{multipleMode ? 'Множественное редактировние' : 'Одиночное редактирование'}</Button>
      {
        !!(multipleMode && selectedRooms?.length) && (
          <Button color="primary"
            variant="contained" onClick={() => setEditMode(true)}>Редактировать</Button>
        )
      }
      {/* <div>
        <pre>
          {JSON.stringify(selectedRooms, null, ' ')}
        </pre>
      </div> */}
      <InfoFloor floor={0} rooms={rooms} rpf={roomsPerFloor} onSelectRoom={handleSelectRoom} onSelectFloor={handleSelectFloor} disabled={multipleMode} />
      {
        floorsArr.map((n) => {
          const floor = floorsnum - n;
          return <Floor key={n} floor={floor} rooms={calcedRooms} onSelectRoom={handleSelectRoom} onSelectFloor={handleSelectFloor} selectedRooms={selectedRooms} />
        })
      }
      <Drawer anchor="right" open={editMode} onClose={() => setEditMode(false)}>
        <div className={classes.formDrawer}>
          <Typography>Квартиры </Typography>
          <div className={classes.formDrawer}>
            <Form onSubmit={onSubmit} decorators={[calc]}>
              {
                ({ handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <ReferenceInput source="type" reference="roomtypes" perPage={100} label="custom.roomType">
                      <SelectInput optionText="name" fullWidth />
                    </ReferenceInput>
                    <SelectArrayInput source="sides" choices={roomSides} label="custom.roomSides" fullWidth />
                    <NumberInput source="price" label="custom.price" fullWidth step={1} />
                    <NumberInput source="space" label="custom.space" fullWidth step={1} />
                    <NumberInput source="pricepm" label="custom.pricepm" fullWidth step={1} />
                    <NumberInput source="space_k" label="custom.space_k" fullWidth />
                    <NumberInput source="space_r" label="custom.space_r" fullWidth />
                    <RadioButtonGroupInput
                      source="status"
                      choices={statusChoises}
                      label="custom.roomStatus"
                    />
                    <RadioButtonGroupInput
                      source="state"
                      choices={stateChoises}
                      label="custom.roomState"
                    />
                    <RadioButtonGroupInput
                      source="dealtype"
                      choices={dealtypeChoises}
                      label="custom.roomDealtype"
                    />
                    <ImageInput source="photo" label="custom.roomPhoto" >
                      <ImageField source="src" title="title" />
                    </ImageInput>
                    <TextInput source="info" label="custom.info" fullWidth multiline />
                    <BooleanInput source="euro" label="custom.euro" />
                    <Button variant="outlined" color="primary" type="submit">Обновить</Button>
                  </form>
                )
              }
            </Form>
          </div>
        </div>
      </Drawer>
    </div>
  )
}

