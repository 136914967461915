import React, { Suspense } from 'react';

const Calendar = React.lazy(() => import('./Calendar'));

function CalendarLoader() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Calendar />
    </Suspense>
  )
}

export default CalendarLoader;
