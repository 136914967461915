import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useField } from 'react-final-form';

const RoomPrice = (props: any) => {
  const { selected } = props;
  const { input: { value: spaceValue, onChange: spaceOnChange } } = useField(`rooms[${selected}].space`);
  const { input: { value: priceValue, onChange: priceOnChange } } = useField(`rooms[${selected}].price`);
  const { input: { value: pricePMValue, onChange: pricePMOnChange } } = useField(`rooms[${selected}].pricepm`);

  const handleChangePrice = (ev: any) => {
    const newPrice = ev.target.value;
    priceOnChange(newPrice);
    if (spaceValue) {
      pricePMOnChange(Number(newPrice / spaceValue).toFixed(2));
    }
  };

  const handleChangePricePM = (ev: any) => {
    const newPricePM = ev.target.value;
    pricePMOnChange(newPricePM);
    if (spaceValue) {
      priceOnChange(Number(newPricePM * spaceValue).toFixed(2));
    }
  };

  const handleSpaceOnChange = (ev: any) => {
    const newSpace = ev.target.value;
    spaceOnChange(newSpace);
    if (newSpace && priceValue) {
      pricePMOnChange(Number(priceValue / newSpace).toFixed(2));
    } else if (newSpace && pricePMValue) {
      priceOnChange(Number(pricePMValue * newSpace).toFixed(2));
    }
  };


  return (
    <div style={{ margin: '0 10px', padding: '0 10px 10px' }} >
      <div>
        <TextField label="Стоимость" type="number" value={priceValue} style={{ marginRight: 10 }} onChange={handleChangePrice} />
        <TextField label="Стоимость за кв.м." type="number" value={pricePMValue} onChange={handleChangePricePM} />
      </div>
      <div>
        <TextField label="Площадь" type="number" value={spaceValue} onChange={handleSpaceOnChange} fullWidth />
      </div>
    </div>
  )
}

export default RoomPrice;