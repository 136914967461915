
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Chip from '@material-ui/core/Chip';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { ViewContext, viewModes } from './Context';
import Card from './Card';
import { SelectControlBroker } from '../Controls';

import TitleIcon, { IconLine } from './TitleIcon';

import Icon1 from '../images/titleicon1.svg';
import Icon2 from '../images/titleicon2.svg';
import Icon3 from '../images/titleicon3.svg';
import Icon4 from '../images/titleicon4.svg';
import Icon5 from '../images/titleicon5.svg';

const useStyles = makeStyles((theme) => ({
  middleContainer: {
    borderTop: '1px solid #E0E0E0',
    borderBottom: '1px solid #E0E0E0',
    padding: '18px 0'
  },
}));


function TitleCard(props: any) {
  const [viewMode, setViewMode] = React.useContext(ViewContext);
  const [tab, setTab] = React.useState(0);
  const {
    name,
    addr,
    price,
    count
  } = props;
  const classes = useStyles();
  return (
    <Card>
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href="/">
            Поиск
          </Link>
          <Typography color="textPrimary">{name}</Typography>
        </Breadcrumbs>
        <div style={{ flex: 1 }} />
        <div>
          <SelectControlBroker data={viewModes} value={viewMode} onChange={setViewMode} />
        </div>
      </div>
      <div className={classes.middleContainer}>
        <div style={{ marginBottom: 18 }}>
          <Chip variant="default" label="Акции" color="primary" style={{ background: '#519872', marginRight: 8 }} />
          <Chip variant="default" label="Хит" color="primary" style={{ background: '#EFA00B' }} />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginBottom: 28 }}>
          <div>
            <Typography variant="h3">{name}</Typography>
            <Typography>{addr}</Typography>
          </div>
          <div>
            <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', width: 230 }}>
              <Typography variant="h5">от</Typography>
              <Typography variant="h4">{price} Р</Typography>
            </div>
            <Typography variant="body2" style={{ marginTop: 8 }}>Осталось квартир: {count}</Typography>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <TitleIcon img={Icon1} title="КЛАСС ЖИЛЬЯ" text="Комфорт" />
          <IconLine />
          <TitleIcon img={Icon2} title="ПЛОЩАДЬ" text="от 22.2 до 79м2" />
          <IconLine />
          <TitleIcon img={Icon3} title="СДАЧА" text="4 кв. 2020" />
          <IconLine />
          <TitleIcon img={Icon4} title="ОТДЕЛКА" text="без отделки" />
          <IconLine />
          <TitleIcon img={Icon5} title="ЦЕНА ЗА М2" text="60 000 Р" />
          <IconLine />
          <TitleIcon img={Icon1} title="АНАЛИЗ М2" text="+10 %" />
        </div>
      </div>
      <div style={{ margin: '16px 0 16px' }}>
        <Tabs
          value={tab}
          onChange={(e: any, v: any) => setTab(v)}
          indicatorColor="primary"
          textColor="primary"
          aria-label="tabs"
          scrollButtons="auto"
          variant="scrollable"
        >
          <Tab label="Галерея" />
          <Tab label="Квартиры" />
          <Tab label="Карта" />
          <Tab label="Описание" />
          <Tab label="Генплан" />
          <Tab label="Акции" />
          <Tab label="Динамика цен" />
          <Tab label="Ипотека" />
          <Tab label="Ход строительства" />
          <Tab label="Документы" />
        </Tabs>
      </div>
    </Card>
  )
}

export default TitleCard;

