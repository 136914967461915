import React from 'react';

import {
  List,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  SelectField,
  ReferenceField,
  BooleanField,
  Filter
} from 'react-admin';

export const name = 'iprograms';

export const programmChoice = [
  { id: 1, name: 'Господдержка' },
  { id: 2, name: 'Семейная ипотека' },
  { id: 3, name: 'Сельская ипотека' },
  { id: 4, name: 'Военная ипотека' },
  { id: 5, name: 'Новостройки' },
  { id: 6, name: 'Готовое жилье' },
  { id: 7, name: 'Дома' },
  { id: 8, name: 'Земельные участки' },
  { id: 9, name: 'Строительство' },
  { id: 10, name: 'Рефинансирование' },
  { id: 11, name: 'Кредит наличными' },
  { id: 12, name: 'Страхование' }
];


const IpotekaProgramListFilter = (props: any) => (
  <Filter {...props}>
    <ReferenceInput source="bank" reference="banks" alwaysOn>
      <SelectInput source="name" />
    </ReferenceInput>
    <SelectInput source="program" choices={programmChoice} alwaysOn />
  </Filter>
);


export const list = (props: any) => (
  <List {...props} bulkActionButtons={false} filters={<IpotekaProgramListFilter />}>
    <Datagrid rowClick="edit">
      <ReferenceField source="bank" reference="banks" link={false}>
        <TextField source="name" />
      </ReferenceField>
      <SelectField source="program" choices={programmChoice} />
      <TextField source="pv" />
      <TextField source="stavka" />
      <TextField source="srok" />
      <TextField source="stazh" />
      <TextField source="vozrast" />
      <TextField source="twodocs_text" />
      <TextField source="other" />

    </Datagrid>
  </List>
);

export const show = (props: any) => (
  <Show {...props}>
    <SimpleShowLayout>

    </SimpleShowLayout>
  </Show>
);

export const create = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput source="bank" reference="banks" filter={{ active: true }} sort={{ field: 'name', order: 'ASC' }} perPage={1000}>
        <SelectInput source="name" fullWidth />
      </ReferenceInput>
      <SelectInput source="program" choices={programmChoice} />
      <TextInput source="pv" />
      <TextInput source="stavka" />
      <TextInput source="srok" />
      <TextInput source="stazh" />
      <TextInput source="vozrast" />
      <TextInput source="twodocs_text" fullWidth multiline />
      <TextInput source="other" fullWidth multiline />
    </SimpleForm>
  </Create>
);

export const edit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceInput source="bank" reference="banks" filter={{ active: true }} sort={{ field: 'name', order: 'ASC' }} perPage={1000}>
        <SelectInput source="name" fullWidth />
      </ReferenceInput>
      <SelectInput source="program" choices={programmChoice} />
      <TextInput source="pv" />
      <TextInput source="stavka" />
      <TextInput source="srok" />
      <TextInput source="stazh" />
      <TextInput source="vozrast" />
      <TextInput source="twodocs_text" fullWidth multiline />
      <TextInput source="other" fullWidth multiline />
    </SimpleForm>
  </Edit>
);
